import { IDynForm } from "@apps/components/dynamic-form/dynamic-form.component";
import {
    EPubDetRelationTypes,
    insertArray,
    pdfGetBasicOwner,
    pdfGetCompany,
    pdfGetIndividual,
    pdfGetSSN,
    pdfGetSSNPrefx,
    pdfGetVerificationDoc,
    pdfRoutingAccount,
    pdfTOS,
} from "./pub-details-form.definition";



export const DynFormDef_us_individual: IDynForm = {
    id: "us_individual",
    name: "Publishing Details",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.indivInfo",
            description: "pubDetails.sections.indivInfoDesc",
            fields: [...insertArray(pdfGetIndividual(), 8, pdfGetSSNPrefx("individual", undefined, 4, undefined, 9))],
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: [...pdfRoutingAccount],
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: [...pdfTOS()],
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescUS",
            singleColumn: true,
            fields: [],
        },
        {
            id: "addInfoPhotoID",
            subTitle: "Photo ID",
            subDesc: "State-issued ID or Government-issued passport belonging to Company Representative",
            fields: [...pdfGetVerificationDoc("individual")],
        },
    ],
};

// ***************************************************************************************
// ************************************* Company *****************************************
// ***************************************************************************************


export const DynFormDef_us_company: IDynForm = {
    id: "us_company",
    name: "pubDetails.title",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.compInfo",
            description: "pubDetails.sections.compInfoDesc",
            fields: pdfGetCompany(),
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: pdfRoutingAccount,
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: pdfTOS(),
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescUS",
            singleColumn: true,
            fields: [],
        },
        {
            id: "addInfoComprep",
            subTitle: "pubDetails.sections.addInfoComprep",
            fields: [...pdfGetBasicOwner(EPubDetRelationTypes.owner, false), ...pdfGetSSN(EPubDetRelationTypes.owner, undefined, 4, undefined, 9)],
        },
        {
            id: "addInfoPhotoID",
            subTitle: "pubDetails.sections.addInfoPhotoID",
            subDesc: "pubDetails.sections.addInfoPhotoIDDesc",
            fields: [...pdfGetVerificationDoc("owners#" + EPubDetRelationTypes.owner)]
        },
    ]
}