﻿import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProgressIndicatorService } from './progress-indicator.service';
// A flag, which when set on a http request reads
// the progress of that request and pushes it to the loading indicator
export const ReportProgressHeader = 'X-Report-Progress';

@Injectable()
export class AppHttpProgressInterceptor implements HttpInterceptor {
  constructor(private svcProgress: ProgressIndicatorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If somebody has set the flag
    if (req.headers.has(ReportProgressHeader)) {
      // Remove the flag so that others can have the option to set or not set it
      const headers = req.headers.delete(ReportProgressHeader);
      const request = req.clone({
        headers,
        reportProgress: true
      });

      // Chain the http progress along to the progress
      // bar service to visually indicate progress
      return next.handle(request).pipe(tap(ev => this.svcProgress.showHttpProgress(ev)));
    }

    return next.handle(req);
  }
}
