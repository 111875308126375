import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppStatus } from '@enums/app-status.enum';
import { App } from 'app/models/app';
import { AppService } from 'app/services/app.service';

@Component({
  selector: 'app-app-status',
  templateUrl: './app-status.component.html',
  styleUrls: ['./app-status.component.scss']
})
export class AppStatusComponent {
  @Input() app: App;
  @Input() appStatus: AppStatus;
  @Input() showDropdown = false;
  @Output() onDropdownClick = new EventEmitter();

  constructor(public svcApp: AppService) {}
}
