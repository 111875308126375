import { CountryCode } from 'app/models/country-code.model';
import { byAlpha2 as country } from 'iso-country-codes';

export type CountryType =
  | 'US'
  | 'CA'
  | 'AU'
  | 'FR'
  | 'GB'
  | 'SG'
  | 'SE'
  | 'AT'
  | 'BE'
  | 'DK'
  | 'FI'
  | 'DE'
  | 'HK'
  | 'IE'
  | 'IT'
  | 'LU'
  | 'NL'
  | 'NZ'
  | 'NO'
  | 'PT'
  | 'ES'
  | 'CH';

// tslint:disable-next-line
export type ICountryRadi = { [P in CountryType]: CountryCode };
// export type ICountry = { [P in CountryType]: CountryCode };

// export enum Country {
//   // 'US', // = country['US'],
//   'CA', // = country['CA'],
//   'AU', // = country['AU'],
//   'FR', // = country['FR'],
//   'GB', // = country['GB'],
//   'SG', // = country['SG'],
//   'SE', // = country['SE'],
//   'AT', // = country['AT'],
//   'BE', // = country['BE'],
//   'DK', // = country['DK'],
//   'FI', // = country['FI'],
//   'DE', // = country['DE'],
//   'HK', // = country['HK'],
//   'IE', // = country['IE'],
//   'IT', // = country['IT'],
//   'LU', // = country['LU'],
//   'NL', // = country['NL'],
//   'NZ', // = country['NZ'],
//   'NO', // = country['NO'],
//   'PT', // = country['PT'],
//   'ES', // = country['ES'],
//   'CH', // = country['CH']
//   'US' = getCountry(country, 'US')
// }

// function getCountry(type: ICountry, key: CountryType): CountryCode {
//   return type[key];
// }

export const Country: ICountryRadi = {
  US: country['US'],
  CA: country['CA'],
  AU: country['AU'],
  FR: country['FR'],
  GB: country['GB'],
  SG: country['SG'],
  SE: country['SE'],
  AT: country['AT'],
  BE: country['BE'],
  DK: country['DK'],
  FI: country['FI'],
  DE: country['DE'],
  HK: country['HK'],
  IE: country['IE'],
  IT: country['IT'],
  LU: country['LU'],
  NL: country['NL'],
  NZ: country['NZ'],
  NO: country['NO'],
  PT: country['PT'],
  ES: country['ES'],
  CH: country['CH']
};
