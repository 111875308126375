import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppStatus } from '../../../enums/app-status.enum';
import { LoginService } from '../../../services/login.service';
import { UserService } from '../../../services/user.service';
import { Subject } from 'rxjs';
import { ComponentCommService, comcUserCountryChange } from '@services/component-comm.service';
import { pubDetSupportedEUCountries, pubDetSupportedNonEUCountries } from '@models/country-code.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  @Input() user: any = null;
  @Input() isHideDetail: boolean;
  @Input() isNewApp = false;
  @Input() status: AppStatus;
  public hasNotSubmit = false;
  destroy$ = new Subject<boolean>();
  supportedUserCountry = true;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private router: Router,
    private comService: ComponentCommService,
  ) {
    this.user = userService.getUser();
  }

  ngOnInit() {
    this.hasNotSubmit = AppStatus[this.status] === AppStatus.not_submitted;
    this.isHideDetail =
      typeof this.isHideDetail !== 'undefined' ? this.isHideDetail : false;

    this.supportedUserCountry = this.hasSupportedCountry();
    this.comService.getChannel(comcUserCountryChange).pipe(takeUntil(this.destroy$)).subscribe(
      () => this.supportedUserCountry = this.hasSupportedCountry()
    )
  }

  hasSupportedCountry() {
    if (this.userService.user && this.userService.user.country) {
      return [...pubDetSupportedEUCountries, ...pubDetSupportedNonEUCountries].includes(this.userService.user.country);
    }
    return false;
  }

  isLoggedIn(): boolean {
    let shouldShow = false;
    // If the user.reasons prop is empty in the DB, it comes back as [""] (1 element: empty string), instead of just an empty array
    // TODO: Laszlo change the reasons prop to come back as a legitimately empty array if empty and change this to check against this.user.reasons.length > 0
    if (
      this.user &&
      this.user.reasons[0] !== '' &&
      this.user.userAcceptedTerms &&
      this.user.userAcceptedEula
    ) {
      shouldShow = true;
    }

    return shouldShow && !this.isHideDetail;
  }

  isAdmin(): boolean {
    return this.userService.isAdmin();
  }

  logout(): void {
    this.loginService.logout();
  }

  logoClick() {
    if (this.isLoggedIn()) {
      this.router.navigate([
        `/main/${this.userService.isAdmin() ? 'admin' : 'dashboard'}`
      ]);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
