import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalComponent } from '../../../common.module/components/modal/modal.component';
import { RedirectUri } from '../../../models/redirect_uri';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('redirectCheckModal') redirectCheckModal: ModalComponent;
  year = new Date().getFullYear();
  redirectUri: RedirectUri;
  modalLink: string;
  email: string;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.useEmail) {
      this.email = this.activatedRoute.snapshot.queryParams.useEmail;
    }
    this.loginService.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
          this.redirectUri = new RedirectUri(
            params['client_id'],
            params['state'],
            params['redirect_uri']
          );

          if (this.redirectUri.isValid()) {
            const url = new URL(this.redirectUri.redirectUri);
            this.modalLink = url.origin;
            this.redirectCheckModal.show();
          } else {
            this.router.navigate(['/main/dashboard']);
          }
        });
      }
    });
  }

  redirectTo(redirect: boolean) {
    if (redirect) {
      this.loginService.oauth2redirect(this.redirectUri);
    } else {
      this.router.navigate(['/main/dashboard']);
    }
  }
}
