﻿import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TreeModule } from 'angular-tree-component';
import { AppPaymentsModule } from 'app/payments.module/payments.module';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { AppCommonModule } from '../common.module/app.common.module';
import { AppMaterialModule } from '../material.module/material.module';
import { AccountComponent } from './components/account/account.component';
import { BankDetailsSummaryComponent } from './components/bank-details-summary/bank-details-summary.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DiscourseForumRedirectComponent } from './components/discourse-forum-redirect/discourse-forum-redirect.component';
import { FaqComponent } from './components/faq/faq.component';
import { MainComponent } from './components/main/main.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { PublishingComponent } from './components/publishing/publishing.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ToolsHelpComponent } from './components/tools-help/tools-help.component';
import { ToolsDocumentationComponent } from './containers/tools-documentation/tools-documentation.component';

@NgModule({
  declarations: [
    PublishingComponent,
    ResourcesComponent,
    ToolsDocumentationComponent,
    FaqComponent,
    ContactUsComponent,
    AccountComponent,
    ToolsHelpComponent,
    MainComponent,
    MyAccountComponent,
    DiscourseForumRedirectComponent,
    BankDetailsSummaryComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AppCommonModule,
    AppMaterialModule,
    AppPaymentsModule,
    TreeModule.forRoot(),
    FormsModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }
      }
    })
  ],
  exports: [
    AppMaterialModule,
    PublishingComponent,
    ResourcesComponent,
    ToolsDocumentationComponent,
    FaqComponent,
    ContactUsComponent,
    AccountComponent,
    ToolsHelpComponent,
    MainComponent,
    MyAccountComponent,
    DiscourseForumRedirectComponent,
    BankDetailsSummaryComponent
  ]
})
export class AppCoreModule {}
