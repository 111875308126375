import { ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, } from '@angular/core';
import { AbstractControl, FormControl, FormControlDirective, FormGroup, Validators, } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { dialogButtons, MessageDialogServiceImpl, MessageDialogServiceToken, } from '@common/components/confimation-modal/dialog.service';
import { ComponentCommService } from '@services/component-comm.service';
import { App } from 'app/models/app';
import { InAppPurchase } from 'app/models/iap';
import { Locale } from 'app/models/locale';
import { AppService } from 'app/services/app.service';
import { IapService } from 'app/services/iap.service';
import { UtilService } from 'app/services/util.service';
import { saveAs as importedSaveAs } from 'file-saver';
import * as _ from 'lodash';
import { from, Observable, Subject } from 'rxjs';
import { map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { AppDetailsResolver } from '../../../../../guards/app-details.resolver';
import { InAppPurchaseTranslation } from '../../../../../models/iapTranslation';
import { commChannelAppDetailsChange } from '../app-edit-details/app-edit-details.component';
import { leiaGlobal } from 'endpoints';
import { iapPriceNaNValidator, maxPriceValidator } from '../app-edit.validators';

@Component({
  selector: 'app-edit-iap',
  templateUrl: './app-edit-iap.component.html',
  styleUrls: ['./app-edit-iap.component.scss'],
})
export class AppEditIapComponent
implements OnInit, OnDestroy {
  @Input() readOnly = false;
  @ViewChild('iapFormDir') iapFormDir: FormControlDirective;
  @ViewChild('uploader') uploader;

  @Input() formGroup: FormGroup;
  @Input() app: App;
  @Input() lists: any;

  @Output() backClick: EventEmitter<any> = new EventEmitter();

  skipUpdate: boolean;
  iaps: InAppPurchase[] = [];
  newIap: InAppPurchase = null;
  emptyIap = true;
  creatingNewIap = false;
  editingIap = false;
  editingIndex: number;
  locales: Locale[] = [];
  languages: any;
  language: string;
  saveTried = false;

  showPrice: any = {};

  saveClicked = false;

  protected onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private appService: AppService,
    private iapService: IapService,
    private route: ActivatedRoute,
    protected svcResolver: AppDetailsResolver,
    protected cdr: ChangeDetectorRef,
    private util: UtilService,
    private commService: ComponentCommService,
    @Inject(leiaGlobal) private leiaGlobalConfig,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {}

  resetForm() {
      this.skipUpdate = true;
      this.lists.locales.forEach(loc => {
        this.formGroup.controls[loc.country_locale].get("price").clearValidators();
      })
      this.util.recurseFormControls(this.formGroup, (ctrl: AbstractControl|FormGroup)=>{
        ctrl.markAsUntouched();
        ctrl.markAsPristine();
        if (ctrl instanceof FormControl) {
            ctrl.setValue(null);
        }
      });
      this.iapFormDir.reset();
      this.skipUpdate = false;
  }

  showAPKErrorModal() {
      this.dialogService.openMessageDialog({
          title: "Missing APK package",
          text: "You cannot add In-App-Purchases until your App Package is successfully uploaded and processed!",
          actions: [dialogButtons.ok],
      });
  }

  priceHasError(locale: string, error: string) {
    return this.formGroup.controls[locale].get("price").hasError(error)
      && this.formGroup.controls[locale].get("price").touched;
  }

  startNewIap() {
    if (this.app.packages.length > 0 && this.app.packageName) {
      this.creatingNewIap = true;
      this.newIap = new InAppPurchase();
      this.newIap.productId = this.app.id;
      this.newIap.active = true;
      this.scrollUp();
      this.resetForm();
      this.formGroup.get("isEdit").setValue(true);
      this.formGroup.get("sku").enable();
      this.selectLanguage(null);
    } else {
        this.showAPKErrorModal();
    }
  }

  uploaderClick() {
    if (this.app.packages.length > 0 && this.app.packageName) {
      this.uploader.nativeElement.click();
    } else {
      this.showAPKErrorModal();
    }
  }

  setFormValues(iap: InAppPurchase) {
      iap.prices.forEach(p => {
        this.formGroup.controls[p.countryCode].get("localeSelected").setValue(true, { emitEvent: false });
        this.formGroup.controls[p.countryCode].get("price").setValidators([
          Validators.required,
          iapPriceNaNValidator,
          maxPriceValidator(200),
        ]);
        this.formGroup.controls[p.countryCode].get("price").setValue(p.price, { emitEvent: false });

        this.formGroup.get("hasLocale").setValue(p.countryCode, { emitEvent: false });
    });
      iap.translations.forEach(tr => {
        this.formGroup.controls[tr.locale].get("title").setValue(tr.title, { emitEvent: false });
        this.formGroup.controls[tr.locale].get("description").setValue(tr.description, { emitEvent: false });
      });
      this.formGroup.get("sku").setValue(iap.sku, { emitEvent: false });
      this.util.recurseFormControls(this.formGroup, (ctrl=>{
          if (ctrl) {
            ctrl.markAsUntouched();
            ctrl.markAsPristine();
          }
      }));
  }

  isValidNumber(val: string) {
      return !isNaN(Number(val));
  }

  trimControlValue(locale: string, fieldName: string) {
    const ctrl: AbstractControl = this.formGroup.controls[locale].get(fieldName);
    const val: string = ctrl.value;
    if (val && val.length) {
        ctrl.setValue(val.trim());
        ctrl.updateValueAndValidity();
    }
  }

  editIap(index) {
    this.resetForm();
    this.newIap = { ...this.iaps[index] };
    this.setFormValues(this.newIap);
    this.formGroup.get("sku").disable();
    this.selectLanguage(null);
    this.editingIap = true;
    this.formGroup.get("isEdit").setValue(true);
    this.scrollUp();
  }

  private scrollUp() {

    const anchor: HTMLElement = document.getElementById("scrollTopAnchor");
    if (anchor) {
        anchor.scrollIntoView();
    }
  }


  showConfirmationModal() {
    this.saveTried = true;
    this.util.recurseFormControls(this.formGroup, ctrl => ctrl && ctrl.markAsTouched());
    if (this.formGroup.valid) {
      if (this.creatingNewIap) {
        this.dialogService.openMessageDialog({
          title: "You are about to Save An In-App Purchase",
          htmlText: `
            <div>
                To ensure the best End User Experience, once an IAP is saved,
                <b>the IAP cannot be deleted and the SKU cannot be modified.</b> IAPs
                with duplicate SKUs are not permitted
            </div>
            <div>
                After an IAP is saved, you <b>will</b> be able to change IAP Title,
                Description, Price, Locale Availability.
            </div>
            <div>
                Individual IAPs can be marked as Active or Inactive via the
                Active/Inactive toggle that will appear after an IAP is saved.
            </div>
          `,
          actions: [dialogButtons.save, dialogButtons.cancel],
        }).afterClosed().subscribe(res => {
            if (res > 0) {
                this.saveItem();
            }
        });
      } else {
        this.saveItem();
      }
    }
  }

  saveItem() {
    const iapPrices = [];
    this.lists.locales.forEach(loc => {
        if (this.formGroup.controls[loc.country_locale].get("localeSelected").value) {
            iapPrices.push({
                countryCode: loc.country_locale,
                price: this.formGroup.controls[loc.country_locale].get("price").value,
            });
        }
    });
    this.newIap.prices = iapPrices;
    this.lists.language.forEach(lang => {
        const ctrlTitle: AbstractControl = this.formGroup.controls[lang.locale].get("title");
        const ctrlDesc: AbstractControl = this.formGroup.controls[lang.locale].get("description");
        const trans = this.newIap.translations.find((tr => tr.locale === lang.locale));
        if (this.hasValue(ctrlTitle.value) || this.hasValue(ctrlDesc.value)) {
            if (trans) {
                trans.description = ctrlDesc.value;
                trans.title = ctrlTitle.value;
            } else {
                this.newIap.translations.push({
                    locale: lang.locale,
                    title: ctrlTitle.value,
                    description: ctrlDesc.value,
                });
            }
        } else {
            if (trans) {
                this.newIap.translations.splice(this.newIap.translations.indexOf(trans), 1);
            }
        }
    });
    this.newIap.sku = this.formGroup.get("sku").value;

    this.iapService.saveIAP(this.newIap).subscribe(async () => {
      await this.iniIAP();
      this.resetForm();
      this.scrollUp();
    });
  }

  cancelEdit() {
    this.resetForm();
    this.creatingNewIap = false;
    this.editingIap = false;
    this.scrollUp();
  }

  toggleActive() {
    this.newIap.active = !this.newIap.active;
  }

  private async iniIAP() {
    this.iaps = await this.iapService.getIAPs(this.app.id);
    this.creatingNewIap = false;
    this.editingIap = false;
    if (this.iaps.length > 0) {
      this.emptyIap = false;
    }
  }


  async ngOnInit() {

    this.locales = this.lists.locales;
    this.updateAppDetails();

    this.commService.getChannel(commChannelAppDetailsChange)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => this.updateAppDetails());

        this.lists.locales.forEach(l => {
        const fg = this.formGroup.controls[l.country_locale];
        if (fg) {
            fg.get("price").valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(
                (val) => this.updatePrice(val, l.country_locale)
            );
            fg.get("localeSelected").valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(
                (val) => this.updateLocaleSelect(val, l.country_locale)
            );
        }
    });

    await this.iniIAP();
  }

  updateAppDetails() {
    const detailLocales = this.app.details.map(detail => detail.locale);

    const tempLanguages = [];

    this.lists.language.forEach(detailLocale => {
      if (detailLocales.includes(detailLocale.locale)) {
        tempLanguages.push(detailLocale);
      }
    });
    this.languages = tempLanguages;
  }

  updateLocaleSelect(value: any, locale: string) {
    if (this.skipUpdate) {
        return;
    }
    if (value) {
        this.formGroup.get("hasLocale").setValue(locale);
        this.formGroup.controls[locale].get("price").setValidators([
            Validators.required,
            iapPriceNaNValidator,
            maxPriceValidator(200),
        ]);
        this.formGroup.controls[locale].get("price").updateValueAndValidity();
        this.focusPrice(locale);
    } else {
        if (!this.lists.locales.some(l => this.formGroup.controls[l.country_locale].get("localeSelected").value)) {
            this.formGroup.get("hasLocale").setValue(null);
        }
        this.formGroup.controls[locale].get("price").setValue(null);
        this.formGroup.controls[locale].get("price").clearValidators();
        this.formGroup.controls[locale].get("price").markAsPristine();
        this.formGroup.controls[locale].get("price").markAsUntouched();
        this.formGroup.controls[locale].get("price").updateValueAndValidity();
    }
  }

  updatePrice(value: any, locale?: string) {
    if (this.skipUpdate) {
        return;
    }
    if (value) {
      let s: string = value;
      s = s.replace(/(^0+)|([^0-9^.])/g, "");
      this.formGroup.controls[locale].get("price").setValue(s, { emitEvent: false });
    }
  }


  selectLanguage(language) {
    this.language = language;
    if (!language) {
      this.language = this.app.details
        .filter(detail => this.leiaGlobalConfig.defaultLocales.includes(detail.locale))
        .map(detail => detail.locale)[0];
    }
  }

  uploadCsv($event) {
    const file = $event.target.files[0];
    const filesize = file.size / 1024 / 1024;

    if (/\.(csv)$/i.test(file.name) === false) {
      alert('The file must be an CSV');
      return;
    }

    this.iapService
      .uploadCSV($event.target.files[0], this.app.id)
      .pipe(switchMap(result => from(this.iapService.getIAPs(this.app.id))))
      .subscribe(
        r => {
          this.iaps = r;
          alert('success');
        },
        null,
        () => {
          $event.target.value = null;
        }
      );
  }
  downloadTemplate() {
    this.iapService.downloadTemplate().subscribe(f => {
      console.log('file downloaded ', f);
      importedSaveAs(f, 'leia_iap_template.csv');
    });
  }

  focusPrice(locale: string) {
    setTimeout(() => {
        const ctrl: HTMLElement = document.getElementById("price_" + locale);
        if (ctrl) {
            ctrl.focus();
        }
    });
  }

  showPriceLabel(locale: string) {
    return !this.showPrice[locale] && this.hasValue(this.formGroup.controls[locale].get('price').value);
  }

  showAddPriceLabel(locale: string) {
      return this.formGroup.controls[locale].get("localeSelected").value
        && !this.showPrice[locale]
        && !this.hasValue(this.formGroup.controls[locale].get('price').value)
  }

  togglePrice(locale, timeout?: boolean) {
      if (this.readOnly) {
        return;
      }
      if (timeout) {
          this.formGroup.controls[locale].get("price").markAsTouched();
      }
      this.showPrice[locale] = !this.showPrice[locale];
      if (this.showPrice[locale]) {
          this.focusPrice(locale);
      }
  }

  backClicked() {
    this.backClick.emit(true);
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hasValue(val: any) {
      return !(val === null || val === undefined || val === "");
  }
}
