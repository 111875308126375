import { Pipe, PipeTransform } from '@angular/core';
import { LineChartModelValueType } from 'app/models/line-chart-value-type.model';

@Pipe({
  name: 'formatYAxis'
})
export class FormatYAxisPipe implements PipeTransform {
  transform(value: any, modelValuetype: LineChartModelValueType): any {
    switch (modelValuetype) {
      case 'downloads':
        return `${value}`;
      case 'stars':
        return `${value}`;
      default:
        if (value >= 1000) {
          return `$${Math.floor(value / 1000)}K`;
        }
        return `$${value}`;
    }
  }
}
