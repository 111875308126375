import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoginData } from '../../../models/login-data';
import { RedirectUri } from '../../../models/redirect_uri';
import { LoginService } from '../../../services/login.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  providers: [LoginService],
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, AfterViewInit {
  loginData: LoginData = new LoginData();
  loginRequestError: boolean;
  redirectUri: RedirectUri;
  @Input() email: string;
  @ViewChild('passwordCtrl') passwordCtrl: ElementRef;
  @ViewChild('emailCtrl') emailCtrl: ElementRef;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.loginRequestError = false;
  }

  ngOnInit() {
    if (this.email) {
      this.loginData.username = this.email;
    }
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.redirectUri = new RedirectUri(
        params['client_id'],
        params['state'],
        params['redirect_uri']
      );
    });
  }

  ngAfterViewInit() {
    if (this.email) {
      this.loginData.username = this.email;
      if (this.passwordCtrl && this.passwordCtrl.nativeElement) {
        this.passwordCtrl.nativeElement.focus();
      }
    } else  {
      if (this.emailCtrl && this.emailCtrl.nativeElement) {
        this.emailCtrl.nativeElement.focus();
      }
    }
  }

  loginInputsChange() {
    this.loginRequestError = false;
  }

  onLogin(): void {
    this.loginService.login(this.loginData).subscribe(
      () => {
        if (this.redirectUri.isValid()) {
          this.loginService.oauth2redirect(this.redirectUri);
        } else {
          const route = `/main/${this.userService.isAdmin() ? 'admin' : 'dashboard'}`
          this.router.navigate([
            route
          ]);
        }
      },
      () => (this.loginRequestError = true)
    );
  }
}
