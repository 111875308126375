import { Component, OnInit } from '@angular/core';
import { Router, } from '@angular/router';
import * as _ from "lodash";
import { RegisterService, RegSteps } from '../../../../services/register.service';
import { UserService } from '../../../../services/user.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'areas-of-interest',
  templateUrl: './areas-of-interest.component.html',
  styleUrls: ['./areas-of-interest.component.scss']
})
export class AreasOfInterestComponent implements OnInit {
  user: any = {};
  reasons = ['Unity SDK', 'Video Tools', 'Something Else', 'Android SDK', 'Publish on LeiaLoft™'];
  existingUser = false;

  constructor(
    private registerService: RegisterService,
    private userService: UserService,
    private router: Router,
  ) {
    if (userService.getUser()) {
      Object.assign(this.user, userService.getUser());
      this.user.reasons = [];
      this.existingUser = true;
    } else {
      this.user = this.registerService.user;
    }
    if (!this.user || !this.user.reasons || !this.user.reasons.length) {
      if (this.userService.user && this.userService.user.reasons && this.userService.user.reasons.length) {
        this.user.reasons = _.clone(this.userService.user.reasons);
      }
    }
  }

  ngOnInit() {
    if (!this.user.emailAddress || !this.userService.getToken()) {
      this.router.navigate(['/register']);
    } else {
      if (this.registerService.registerStepSource.value !== 3) {
        setTimeout(()=>this.registerService.changeStep(3));
      }
      this.registerService.checkRegStep(null, RegSteps[3], this.user.emailAddress);
    }
  }

  toggleReasons(reason) {
    // Push into an array and pick out of the array if already exists?
    if (this.user.reasons.includes(reason)) {
      this.user.reasons.splice(this.user.reasons.indexOf(reason), 1);
    } else {
      this.user.reasons.push(reason);
    }
  }

  submitAreasOfInterest() {
    if (this.user.reasons.length > 0) {
      this.registerService.setUserReasons(this.user).subscribe(
        res => {
          if (this.existingUser) {
            this.userService.setUser(this.user);
            if (!this.user.userAcceptedTerms) {
              this.registerService.changeStep(4);
            } else if (!this.user.userAcceptedEula) {
              this.registerService.changeStep(5);
            } else {
              this.router.navigate(['/main/dashboard']);
            }
          } else {
            this.registerService.changeStep(4);
          }
        },
        err => {
          console.log('Error:');
          console.log(err);
        }
      );
    }
  }
}
