﻿import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProgressIndicatorService {
  private progress = 0;
  private progressSource = new BehaviorSubject<number>(this.progress);
  private inProgressSource = new BehaviorSubject<boolean>(false);

  inProgress$ = this.inProgressSource.asObservable();
  progress$ = this.progressSource.asObservable();

  showHttpProgress(ev: HttpEvent<any>) {
    switch (ev.type) {
      case HttpEventType.UploadProgress:
        let progress = Math.round((100 * ev.loaded) / ev.total);
        if (progress >= 100) {
          progress = 90;
        }
        this.incrementProgress(progress);
        break;
      case HttpEventType.ResponseHeader:
        if (ev.status >= 400) {
          this.animateFinish();
        }
        break;
      case HttpEventType.Response:
        this.animateFinish();
        break;
    }
  }

  // for animating rquests progress bar
  private animateFinish() {
    this.incrementProgress(90);
    setTimeout(() => {
      this.incrementProgress(100);
      this.inProgressSource.next(false);
    }, 300);
  }

  incrementProgress(progress: number) {
    this.inProgressSource.next(true);
    this.progress = progress;
    this.progressSource.next(this.progress);
  }
}
