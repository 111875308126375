import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import * as tinygradient from 'tinygradient';
import { WorldMapData } from '../../../models/world-map-data.model';
import { DashboardService } from '../../../services/dashboard.service';

export const MAX_DISPLAYED_COUNTRIES = 5;

@Directive({
  selector: '.bar'
})
export class BarHighlightDirective {
  @Input()
  hexColor: WorldMapData;
  @HostBinding('style.background')
  get backgroundColor() {
    return this.hexColor;
  }
}
@Directive({
  selector: 'path.datamaps-subunit'
})
export class WorldMapHighlighDirective {
  @HostBinding('style.fill')
  fill = '#4a4a4a';
  isoCountryCode = this.el.nativeElement.classList[1];

  clearFill() {
    this.fill = '#4a4a4a';
  }
  constructor(private el: ElementRef) {}
}

@Component({
  selector: 'app-world-map',
  templateUrl: './world-map.component.html',
  styleUrls: ['./world-map.component.scss']
})
export class WorldMapComponent implements AfterViewInit {
  private _data: WorldMapData[];
  coloring = false;
  colorsRange = tinygradient(['#C89DFF', '#6B2ACC']);

  @ViewChildren(WorldMapHighlighDirective)
  worldMap: QueryList<WorldMapHighlighDirective>;

  get data() {
    return this._data;
  }
  @Input()
  set data(value: WorldMapData[]) {
    if (value) {
      if (value.length > 0) {
        if (value.length > MAX_DISPLAYED_COUNTRIES) {
          value.sort((a, b) => !a.code ? 1 : !b.code ? -1 : b.value - a.value);
          const deleted = value.splice(
            MAX_DISPLAYED_COUNTRIES,
            value.length - MAX_DISPLAYED_COUNTRIES
          );
          const others = deleted
            .map(val => ({ code: null, value: val.value }))
            .reduce((prev, curr) => ({
              code: null,
              value: prev.value + curr.value
            }));
          value.push(others);
        }
        this._data = value;

        if (this.worldMap && this.worldMap.length > 0) {
          this.clearMap();

          if (!this.coloring) {
            this.colorMap();
          }
        }
      } else {
        this._data = [];
        this.clearMap();
      }
    }
  }

  constructor(
    public el: ElementRef,
    private cd: ChangeDetectorRef,
    private svcDashboard: DashboardService
  ) {}

  clearMap() {
    this.coloring = true;
    if (this.worldMap) {
      this.worldMap.forEach((path, i) => path.clearFill());
    }
    this.coloring = false;
  }
  colorMap() {
    this.coloring = true;

    let colorsRGB = this.colorsRange.rgb(2);
    if (this.data.length > 1) {
      colorsRGB = this.colorsRange.rgb(this.data.length);
    }

    // // Sort in value descending order
    this.data.sort((a, b) => b.value - a.value);
    // Reverse the list, darker colors go with larger values
    colorsRGB.sort((a, b) => parseInt(a.toHex(), 16) - parseInt(b.toHex(), 16));

    this._data = this.data.map((country, i) => {
      return {
        ...country,
        hexColor: '#' + colorsRGB[i].toHex()
      };
    });
    this.worldMap.forEach((path, i) => {
      const country = this.data.find(cntry => {
        return cntry && cntry.code && cntry.code.alpha3 === path.isoCountryCode;
      });
      if (country) {
        path.fill = country.hexColor;
      }
    });
    this.cd.detectChanges();
    this.coloring = false;
  }
  ngAfterViewInit() {
    if (this.data && this.worldMap && !this.coloring) {
      this.colorMap();
    }
  }
}
