import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-payment-button-set',
  templateUrl: './payment-button-set.component.html',
  styleUrls: ['./payment-button-set.component.scss']
})
export class PaymentButtonSetComponent {
  @Input()
  payment;
  @Input()
  showButtons = true;
  @Output()
  onHoldClick = new EventEmitter();
  @Output()
  onReleaseClick = new EventEmitter();
  @Output()
  onPayNowClick = new EventEmitter();
  @Output() authClick = new EventEmitter();

  allowAuth(payment) {
    if (payment.totalPayment >= 100) {
      return true;
    }
    return false;
  }

  authChangeClick(payment) {
    if (this.allowAuth(payment)) {
      this.authClick.emit();
    }
  }

  minimumGuarantees() {
    return this.payment.minimumGuarantees.map(o => `${o.title} ($${Math.round(o.amount/1000)}k)`).join(', ');
  }
}
