import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { link } from 'fs';

@Component({
  selector: 'app-question-drawer',
  templateUrl: './question-drawer.component.html',
  styleUrls: ['./question-drawer.component.scss']
})
export class QuestionDrawerComponent implements OnInit {
  @Input() question: any;
  @Input() showDetails: boolean;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    // Trust hardcoded FAQ string as HTML so Angular will render styles and classes
    this.bypassFaqHtmlSanitization()
   
    // Get all link elements from FAQ string and prevent event propagation so website opens instead of div closing
    this.preventLinkEventPropagation()
  }

  bypassFaqHtmlSanitization() {
    this.question.answer = this.sanitizer.bypassSecurityTrustHtml(this.question.answer)
  }

  preventLinkEventPropagation() {
    const linkElements = document.getElementsByClassName('underline-link')
    Array.from(linkElements).forEach(linkElement => {
      let element: HTMLElement = linkElement as HTMLElement
      element.onclick = event => {
        event.cancelBubble = true
        if (event.stopPropagation) event.stopPropagation()
      }
    }) 
  }

}
