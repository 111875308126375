import { regxPatterns } from "@apps/components/dynamic-form/dynamic-form-validator.regexp";
import { IDynForm } from "@apps/components/dynamic-form/dynamic-form.component";
import { stateIE } from "@models/country-code.model";
import {
    EPubDetRelationTypes,
    pdfGetAddress,
    pdfGetBasicOwner,
    pdfGetCompany,
    pdfGetIndividual,
    pdfGetSSN,
    pdfGetVerificationDoc,
    pdfGetAddVerificationDoc,
    pdfIban,
    pdfRoutingAccount,
    pdfTOS,
    prepOptionalRequiredContact,
    setRequired,
} from "./pub-details-form.definition";


export const DynFormDef_ie_individual: IDynForm = {
    id: "ie_individual",
    name: "Publishing Details",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.indivInfo",
            description: "pubDetails.sections.indivInfoDesc",
            fields: [...pdfGetIndividual("pubDetails.stateIE", stateIE, "pubDetails.eirCode", 0, null, true, false)],
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: [...pdfIban],
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: [...pdfTOS("compRepFullName_EU")],
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescEU",
            singleColumn: true,
            fields: [],
        },
        {
            id: "addInfoPhotoID",
            subTitle: "Photo ID",
            subDesc: "State-issued ID or Government-issued passport belonging to Company Representative",
            fields: [...pdfGetVerificationDoc("individual", true, true)],
        },
        {
            id: "addInfoAddDoc",
            subTitle: "pubDetails.sections.addInfoAddDoc",
            subDesc: "pubDetails.sections.addInfoAddDocDesc",
            fields: [...pdfGetAddVerificationDoc("individual", true)],
        },
    ],
};

// ***************************************************************************************
// ************************************* Company *****************************************
// ***************************************************************************************


export const DynFormDef_ie_company: IDynForm = {
    id: "ie_company",
    name: "pubDetails.title",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.compInfo",
            description: "pubDetails.sections.compInfoDesc",
            fields: [...pdfGetCompany("pubDetails.stateIE", stateIE, "pubDetails.eirCode", 0, null, true, false)],
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: pdfIban,
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: pdfTOS("compRepFullName_EU_Comp"),
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescEU",
            singleColumn: true,
            fields: [],
        },
        {
            id: "companyOwner",
            subTitle: "pubDetails.sections.companyOwner",
            subDesc: "pubDetails.sections.companyOwnerDesc",
            fields: [...pdfGetBasicOwner(EPubDetRelationTypes.owner, true), ...pdfGetAddress("owners#owner", "pubDetails.stateIE", stateIE, "pubDetails.eirCode", 0, null, true, false)],
        },
        {
            id: "compOwnerPhotoID",
            subTitle: "pubDetails.sections.compOwnerPhotoID",
            subDesc: "pubDetails.sections.compOwnerPhotoIDDesc",
            fields: [...pdfGetVerificationDoc("owners#" + EPubDetRelationTypes.owner, true, true)]
        },
        {
            id: "compOwnerAddDoc",
            subTitle: "pubDetails.sections.compOwnerAddDoc",
            subDesc: "pubDetails.sections.compOwnerAddDocDesc",
            fields: [...pdfGetAddVerificationDoc("owners#" + EPubDetRelationTypes.owner, true)]
        },
    ]
}