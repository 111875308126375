import { Country } from 'app/enums/country-type.enum';
import {
  CONTACT_INFO,
  DeveloperModel,
  StripeCompanyAccountModel,
  StripeIndividualAccountModel
} from './stripe-account';

export const US_IndividualAccount: StripeIndividualAccountModel = {
  present: true,
  type: 'individual',
  country: Country.US,
  externalAccount: {
    present: true,
    country: Country.US,
    accountNumber: {
      present: true
    },
    routingNumber: {
      present: true
    }
  },
  accountRepresentatives: [
    {
      present: true,
      name: {
        present: true,
        firstName: {
          present: true,
          description: 'The first name of the individual responsible for the bank account.',
          value: null
        },
        lastName: {
          present: true,
          description: 'The last name of the individual responsible for the bank account.',
          value: null
        }
      },
      dob: {
        present: true,
        description: 'The date of birth of the individual responsible for the bank account.',
        value: null
      },
      personalIdNumber: {
        present: true,
        value: null
        //   description: 'The last 4 ssn digits of the individual responsible for the bank account',
      },
      address: {
        present: true,
        addressOne: {
          present: true,
          description: 'The primary address of the individual responsible for the bank account.',
          value: null
        },
        addressTwo: {
          present: true,
          value: null
        },
        city: {
          present: true,
          description: 'The city of the primary address',
          value: null
        },
        zipOrPostalCode: {
          present: true,
          description: 'Zip Code',
          value: null
        },
        stateOrProvince: {
          present: true,
          description: 'The state of the primary address',
          value: null
        }
      }
    }
  ],
  tosAcceptance: {
    present: true,
    fullName: {
      present: true,
      description: `Full name, signifying agreement to the TOS, which must match primary or one of the representative's names.`,
      value: null
    },
    date: {
      present: true,
      description:
        'The Unix timestamp marking when the Stripe Services Agreement was accepted by the account representative.',
      value: null
    },
    ip: {
      present: true,
      description:
        'The IP address from which the Stripe Services Agreement was accepted by the account representative.',
      value: null
    }
  },
  verificationDocument: {
    present: true,
    description:
      '64 bit encoded photo (jpg or png) of the front of an identifying document, either a passport or local ID card.',
    document: {
      present: true,
      front: {
        present: true
      },
      back: {
        present: true
      }
    }
  }
};

export const US_IndividualDeveloper: DeveloperModel = {
  stripeAccount: US_IndividualAccount,
  contactInfo: CONTACT_INFO
};

export const US_CompanyAccount: StripeCompanyAccountModel = {
  type: 'company',
  country: Country.US,
  present: true,
  externalAccount: {
    present: true,
    country: Country.US,
    accountNumber: {
      present: true
    },
    routingNumber: {
      present: true
    }
  },
  address: {
    present: true,
    addressOne: {
      present: true,
      description: 'The address of the business',
      value: null
    },
    addressTwo: {
      present: true,
      description: 'The address of the business',
      value: null
    },
    city: {
      present: true,
      description: 'The city in which the business resides',
      value: null
    },
    stateOrProvince: {
      present: true,
      description: 'The state in which the business resides',
      value: null
    },
    zipOrPostalCode: {
      present: true,
      description: 'This zip code of the business',
      value: null
    }
  },
  name: {
    present: true,
    description: 'The legal name of the business',
    value: null
  },
  taxID: {
    present: true,
    description: '',
    value: 'Employer ID Number (EIN)'
  },
  accountRepresentatives: [
    {
      present: true,
      name: {
        present: true,
        firstName: {
          present: true,
          description: 'The first name of the individual responsible for the bank account',
          value: null
        },
        lastName: {
          present: true,
          description: 'The last name of the individual responsible for the bank account',
          value: null
        }
      },
      dob: {
        present: true,
        description: 'The date of birth of the individual responsible for the bank account',
        value: null
      },
      personalIdNumber: {
        present: true,
        value: null
      }
    }
  ],
  tosAcceptance: {
    present: true,
    fullName: {
      present: true,
      description: `Full name, signifying agreement to the TOS, which must match primary or one of the representative's names.`,
      value: null
    },
    date: {
      present: true,
      description:
        'The Unix timestamp marking when the Stripe Services Agreement was accepted by an account representative.',
      value: null
    },
    ip: {
      present: true,
      description: 'The IP address from which the Stripe Services Agreement was accepted by an account representative.',
      value: null
    }
  },
  verificationDocument: {
    present: true,
    document: {
      present: true,
      description:
        '64 bit encoded photo (jpg or png) of the front of an identifying document, either a passport or local ID card.',
      front: {
        present: true
      },
      back: {
        present: true
      }
    }
  }
};

export const US_CompanyDeveloper: DeveloperModel = {
  stripeAccount: US_CompanyAccount,
  contactInfo: CONTACT_INFO
};
