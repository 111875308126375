import { Injectable } from '@angular/core';
import {
  StripeAccountModel,
  UserBankDetailServerResponse
} from 'app/models/stripe-account';
import { UserService } from 'app/services/user.service';
import { Adapter } from './adapter';

@Injectable()
export class BankDetailAdapter
  implements Adapter<UserBankDetailServerResponse, StripeAccountModel> {
  constructor(private svcUser: UserService) {}

  toModel(item: any): StripeAccountModel {
    //   if (!!item) {
    //     const meta = { type: item.accountType, country: item.country };
    //     const user = this.svcUser.user;
    //     const model = {
    //       stripeDetails: item.stripeBankDetails ? item.stripeBankDetails : null
    //     } as StripeAccountModel;

    //     if (meta.type === 'individual') {
    //       model.details = {
    //         person: {
    //           firstName: item.firstName ? item.firstName : null,
    //           lastName: item.lastName ? item.lastName : null,
    //           dob: item.dob ? item.dob : null
    //         },
    //         contactInfo: {
    //           email: user.emailAddress ? user.emailAddress : null,
    //           phoneNumber: user.phoneNumber ? user.phoneNumber : null
    //         }
    //       } as PersonalDetailsModel;

    //       return model as StripeAccountModel;
    //     } else if (meta.type === 'company') {
    //       model.details = {
    //         person: {
    //           firstName: item.firstName ? item.firstName : null,
    //           lastName: item.lastName ? item.lastName : null,
    //           dob: item.dob ? item.dob : null
    //         },
    //         address: {
    //           addressOne: item.addressLine1 ? item.addressLine1 : null,
    //           addressTwo: item.addressLine2 ? item.addressLine2 : null,
    //           city: item.city ? item.city : null,
    //           stateOrProvince: null, // Backend doesn't support this atm
    //           zipOrPostalCode: item.zip ? item.zip : null
    //         },
    //         company: {},
    //         contactInfo: {
    //           email: user.emailAddress ? user.emailAddress : null,
    //           phoneNumber: user.phoneNumber ? user.phoneNumber : null
    //         },
    //         representatives: null // Backend doesn't support this atm
    //       } as BusinessDetailsModel;

    //       return model as StripeAccountModel;
    //     } else {
    //       return null;
    //     }
    //   } else {
    //     return null;
    //   }

    throw new Error('Method not implemented.');
  }

  fromModel(model: StripeAccountModel): UserBankDetailServerResponse {
    throw new Error('Method not implemented.');
  }
}
