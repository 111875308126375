import { AsyncPipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ModalComponent } from '../../../common.module/components/modal/modal.component';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { ComponentCommService, comcUserCountryChange } from '@services/component-comm.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  providers: [AsyncPipe]
})
export class AccountComponent {
  @ViewChild('updateSuccessModal')
  updateSuccessModal: ModalComponent;

  private _userDetailsTitle: string = null;
  @Input()
  get userDetailsTitle() {
    if (!this._userDetailsTitle) {
      // If title is not set externally
      if (this.user && this.user.roles && this.user.roles.includes('admin')) {
        return (this._userDetailsTitle = 'Your Details');
      } else {
        return (this._userDetailsTitle = 'Personal Details');
      }
    } else {
      return this._userDetailsTitle;
    } // Otherwise, return explicit title
  }
  set userDetailsTitle(title: string) {
    if (title) {
      this._userDetailsTitle = title;
    }
  }

  user$: Observable<User> = this.route.data.pipe(pluck('user'));
  user: User = this.pipeAsync.transform(this.user$);
  isRoutedTo: boolean;
  private sub: any;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private comService: ComponentCommService,
    protected pipeAsync: AsyncPipe,
  ) {
    if (!this.user) {
      this.user = { ...userService.getUser() };
    } else {
      this.isRoutedTo = true;
    }
  }

  updateUser(ev) {
    this.userService.updateUserDetails(this.user).subscribe(
      r => {
        console.log('user saved');
        this.comService.getChannel(comcUserCountryChange).next(true);
        this.userService.setUser(this.user);
        this.updateSuccessModal.show();
      },
      err => {
        if (err && err.error && err.error.error && err.error.error.message === "INVALID_COUNTRY") {
          console.log("AccountComponent.updateUser: Country change rejected");
        }
      }
    );
  }

  isAdmin() {
    return this.userService.isAdmin();
  }
}
