import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../../../common.module/components/modal/modal.component';
import { User, UserStatus } from '../../../models/user';

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss']
})
export class UserStatusComponent {
  @Input()
  user: User;
  @Output()
  userStatusChange = new EventEmitter<User>();
  @ViewChild(ModalComponent)
  modal: ModalComponent;

  passwordInput: string;
  showError: boolean;

  constructor() {}

  confirmPassword() {
    this.modal.hide();
    this.changeUserStatus(this.toggleUserApprovalStatus(this.user.status));
    this.passwordInput = '';
  }

  changeUserStatus(userStatus: UserStatus) {
    this.userStatusChange.emit({
      ...this.user,
      status: userStatus
    });
  }

  toggleUserApprovalStatus(status: UserStatus): UserStatus {
    switch (status) {
      case UserStatus.NOT_APPROVED:
      case UserStatus.INACTIVE:
      case UserStatus.SUSPENDED:
        return UserStatus.APPROVED;
      case UserStatus.APPROVED:
        return UserStatus.SUSPENDED;
      default:
        return UserStatus.NOT_APPROVED;
    }
  }

  getUserApprovalStatusText(status: UserStatus) {
    switch (status) {
      case UserStatus.NOT_APPROVED:
      case UserStatus.INACTIVE:
      case UserStatus.SUSPENDED:
        return 'Approve';
      case UserStatus.APPROVED:
        return 'Suspend';
      default:
        return 'Approve';
    }
  }
}
