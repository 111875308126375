import { AbstractControl, ValidatorFn, } from "@angular/forms";


export function trimmedMinLength(minLength: number): ValidatorFn{
    return (control: AbstractControl) => {
        const s: string =  control.value;
        if (s && s.length && s.trim().length >= minLength) {
            return null;
        }
        return { minlength: true };
    }
}

export function maxPriceValidator(amount: number): ValidatorFn {
    return (control: AbstractControl) => {
        if (!isNaN(control.value)) {
            if (Number(control.value) > amount) {
                // return { maxPrice: true }
                return null;
            }
        }
        return null;
    }
}

export const iapPriceNaNValidator:ValidatorFn = (control: AbstractControl) => {
    if (control && control.value) {
        if (isNaN(Number(control.value))) {
            return { nonNumber: true }
        }
    }
    return null;
}

export const noWhiteSpaceValidator: ValidatorFn = (control: AbstractControl) => {
    if (control) {
        if (control.value && control.value.length) {
            let s: string = control.value;
            s = s.trim();
            if (!s.length) {
                return { required: true }
            }
        }
    }
    return null;
}
