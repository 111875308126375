import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import { UserService } from './user.service';

@Injectable()
export class ContactService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    @Inject(leiaEndpointToken) private leiaEndpoints,
  ) {}

  contactUsEmail(message: string) {
    return this.http.post(
      environment.leiaservices.url +
        this.leiaEndpoints.contact.contact.url,
      { message }
    );
  }
}
