import { Component, Input } from '@angular/core';
import { App, AppVersion } from 'app/models/app';
import { saveAs } from 'file-saver';
import { MenuChoice, MomentRange } from '../../../models/moment-range';
import { DashboardService } from '../../../services/dashboard.service';
import { DownloadListType } from '@enums/download-list-type.enum';

@Component({
  selector: 'app-dashboard-download',
  templateUrl: './dashboard-download.component.html',
  styleUrls: ['./dashboard-download.component.scss']
})
export class DashboardDownloadComponent {
  @Input()
  range: MomentRange;
  @Input()
  isAdmin: boolean;
  @Input()
  app: App;
  @Input()
  version: AppVersion;

  public ListType = DownloadListType;

  constructor(private svcDashboard: DashboardService) {}

  downloadCSV(range: MomentRange, type: DownloadListType) {
    const from = range.from.clone();
    const to = range.to.clone();
    let appId, version, lifetime;

    if (range.label !== MenuChoice.Lifetime) {
      if (this.app && this.app.id) {
        appId = this.app.id;
        version = this.version ? this.version.id : null;
        lifetime = false;
      } else {
        appId = undefined; // ? null
        version = undefined;
        lifetime = false;
      }
    } else {
      if (this.app && this.app.id) {
        appId = this.app.id;
        version = this.version ? this.version.id : null;
        lifetime = true;
      } else {
        appId = undefined; // ? nulll
        version = undefined;
        lifetime = true;
      }
    }

    this.svcDashboard
      .downloadCSVData(from, to, appId, version, lifetime, this.isAdmin, type)
      .subscribe(blob => this.saveBlob(blob as Blob, type));
  }

  private saveBlob(blob: Blob, type: DownloadListType) {
    let filename = 'dashboard-data-' + Date.now() + '.csv';
    if (type === DownloadListType.IAP) {
      filename = 'iap-purchases-' + Date.now() + '.csv';
    } else if (type === DownloadListType.IAPLOG) {
      filename = 'iap-log-' + Date.now() + '.csv';
    }
    saveAs(blob, filename);
  }
}
