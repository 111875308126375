import { Component, EventEmitter, Output } from '@angular/core';
import { FormModal } from 'app/classes/form-modal';

@Component({
  selector: 'app-approve-modal',
  templateUrl: './approve-modal.component.html',
  styleUrls: ['./approve-modal.component.scss']
})
export class ApproveModalComponent extends FormModal {
  @Output()
  onApproveClick = new EventEmitter();
}
