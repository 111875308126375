import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { BankDetailService } from "@services/bank-detail.service";
import { Observable, forkJoin, of } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";

@Injectable()
export class PubDetailsResolver implements Resolve<any> {
  constructor(
      private bankService: BankDetailService,
    ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
        this.bankService.bankAccountExists,
        this.bankService.pubDetailsGet().pipe(catchError(err => of(null))),
    ]);
  }
}
