﻿import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatDatepickerModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatSelectModule,
  MatTooltipModule,
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    MatProgressBarModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatMenuModule,
    MatButtonModule,
  ],
  exports: [
    MatProgressBarModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatMenuModule,
    MatButtonModule,
  ]
})
export class AppMaterialModule {}
