import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { RadioButtonOption } from 'app/common.module/components/radio-button-set/radio-button-set.component';
import { DeveloperModel } from 'app/models/stripe-account';
import * as _ from 'lodash';
import { Moment } from 'moment';
import { AddressInputLabels } from '../../../common.module/components/address-form/address-form.component';

@Component({
  selector: 'app-company-account',
  templateUrl: './company-account.component.html',
  styleUrls: ['./company-account.component.scss']
})
export class CompanyAccountComponent {
  @Input()
  countryTemplate: DeveloperModel;
  @Input()
  modelGroup;
  @Input()
  isWhite = false;
  @Input()
  readOnly = false;
  @Input()
  time: Moment;
  @Input()
  set parent(form: FormGroup) {
    if (!!form) {
      this._parent = form;
      console.log(form);
    }
  }
  get parent() {
    return this._parent;
  }
  private _parent: FormGroup;

  get stripeAccount() {
    if (!!this.countryTemplate) {
      if ('name' in this.countryTemplate.stripeAccount) {
        return this.countryTemplate.stripeAccount;
      }
    }
  }

  get stripeAccountFormGroup() {
    return this.parent.get(['stripeAccount']);
  }
  get addressLabels(): AddressInputLabels {
    return this._addressLabels;
  }

  get accountRepresentatives() {
    return (this.parent.get(['stripeAccount', 'accountRepresentatives']) as FormArray).controls;
  }

  get verificationAsControl() {
    return this.parent.get(['stripeAccount', 'verificationDocument']) as FormGroup;
  }

  get gbAccountTypeOptions() {
    return this._gbAccountOptions;
  }

  get formCountryControl() {
    return this.parent.get(['stripeAccount', 'country']);
  }
  get isGB() {
    return 'GB' === _.get(this.formCountryControl, 'value', null);
  }

  get tosAcceptanceAsControl() {
    return this.parent.get(['stripeAccount', 'tosAcceptance']) as FormGroup;
  }

  set accountTypeSelection(rbOption: RadioButtonOption) {
    if (!!rbOption) {
      const { value } = rbOption;

      if (!!value) {
        const externalAccountControl = this.parent.get(['stripeAccount', 'externalAccount']);
        const accountSelectionControl = this.parent.get(['stripeAccount', 'externalAccount', 'accountTypeSelection']);
        const prevSelection = this._accountTypeSelection;

        if (prevSelection !== value) {
          externalAccountControl.reset();
          accountSelectionControl.setValue(value);
        }

        this._accountTypeSelection = value;
      }
    }
  }
  get accountTypeSelection() {
    if (!this._accountTypeSelection) {
      return this.findGBAccountTypeOption('iban');
    }

    const formControl = this.parent.get(['stripeAccount', 'externalAccount', 'accountTypeSelection']);
    const controlValue = _.get(formControl, 'value', null);
    return this.findGBAccountTypeOption(controlValue);
  }
  private _accountTypeSelection: 'iban' | 'standard';

  private readonly _addressLabels: AddressInputLabels = {
    addressOneLabel: 'Business Address #1',
    addressTwoLabel: 'Business Address #2',
    cityLabel: 'Business City',
    stateOrProvinceLabel: 'Business State/Province',
    zipOrPostalCodeLabel: 'Business Zip/Postal Code'
  };

  // TODO: move to service
  private readonly _gbAccountOptions = [
    { name: 'Standard', value: 'standard' },
    { name: 'IBAN', value: 'iban' }
  ];

  constructor() {}

  findGBAccountTypeOption(option: 'standard' | 'iban'): RadioButtonOption {
    return this.gbAccountTypeOptions.find(op => op.value === option);
  }
}
