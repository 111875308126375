import { regxPatterns } from "@apps/components/dynamic-form/dynamic-form-validator.regexp";
import { IDynForm } from "@apps/components/dynamic-form/dynamic-form.component";
import { IDynFormFieldType, EDynFormFieldDateOption } from "@apps/components/dynamic-form/dynamic-form.service";
import {
    EPubDetRelationTypes,
    pdfGetAddress,
    pdfGetBasicOwner,
    pdfGetCompany,
    pdfGetIndividual,
    pdfGetSSN,
    pdfGetVerificationDoc,
    pdfGetAddVerificationDoc,
    pdfIban,
    pdfRoutingAccount,
    pdfTOS,
    prepOptionalRequiredContact,
    setRequired,
} from "./pub-details-form.definition";

const euPCCaption = "pubDetails.postCode";

export const DynFormDef_eu_individual: IDynForm = {
    id: "eu_individual",
    name: "Publishing Details",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.indivInfo",
            description: "pubDetails.sections.indivInfoDesc",
            fields: [...pdfGetIndividual(undefined, undefined, euPCCaption, 0, regxPatterns.alphanumericWSM, true).filter(f => !f.id.includes("state"))],
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: [...pdfIban],
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: [...pdfTOS("compRepFullName_EU")],
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescEU",
            singleColumn: true,
            fields: [],
        },
        {
            id: "addInfoPhotoID",
            subTitle: "Photo ID",
            subDesc: "State-issued ID or Government-issued passport belonging to Company Representative",
            fields: [...pdfGetVerificationDoc("individual", true, true)],
        },
        {
            id: "addInfoAddDoc",
            subTitle: "pubDetails.sections.addInfoAddDoc",
            subDesc: "pubDetails.sections.addInfoAddDocDesc",
            fields: [...pdfGetAddVerificationDoc("individual", true)],
        },
    ],
};

// ***************************************************************************************
// ************************************* Company *****************************************
// ***************************************************************************************


export const DynFormDef_eu_company: IDynForm = {
    id: "eu_company",
    name: "pubDetails.title",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.compInfo",
            description: "pubDetails.sections.compInfoDesc",
            fields: [...pdfGetCompany(undefined, undefined, euPCCaption, 0, regxPatterns.alphanumericWSM, true).filter(f => !f.id.includes("state"))],
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: pdfIban,
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: pdfTOS("compRepFullName_EU_Comp"),
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescEU",
            singleColumn: true,
            fields: [],
        },
        {
            id: "companyOwner",
            subTitle: "pubDetails.sections.companyOwner",
            subDesc: "pubDetails.sections.companyOwnerDesc",
            fields: [...pdfGetBasicOwner(EPubDetRelationTypes.owner, true), ...pdfGetAddress("owners#owner", undefined, undefined, euPCCaption, 0, regxPatterns.alphanumericWSM, true).filter(f => !f.id.includes("state"))],
        },
        {
            id: "compOwnerPhotoID",
            subTitle: "pubDetails.sections.compOwnerPhotoID",
            subDesc: "pubDetails.sections.compOwnerPhotoIDDesc",
            fields: [...pdfGetVerificationDoc("owners#" + EPubDetRelationTypes.owner, true, true)]
        },
        {
            id: "compOwnerAddDoc",
            subTitle: "pubDetails.sections.compOwnerAddDoc",
            subDesc: "pubDetails.sections.compOwnerAddDocDesc",
            fields: [...pdfGetAddVerificationDoc("owners#" + EPubDetRelationTypes.owner, true)]
        },
    ],
}