import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { isArray } from 'lodash';
import { App, AppVersion } from '../../../../../models/app';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent {
  @Input()
  apps;
  @Output('onAppsSelected')
  onNewAppsSelected = new EventEmitter<App | App[] | AppVersion>();

  selectedApp: App | AppVersion;
  showDropdownItems: boolean;

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onclick(ev: MouseEvent) {
    if (!this.el.nativeElement.contains(ev.target)) {
      this.showDropdownItems = false;
    }
  }

  onAppsSelected(ev: App | App[] | AppVersion) {
    if (!isArray(ev)) {
      this.selectedApp = ev;
    } else {
      this.selectedApp = undefined;
    }
    this.showDropdownItems = false;
    this.onNewAppsSelected.emit(this.selectedApp);
  }

  elipsizeText(title: string) {
    if (title.length > 20) {
      return title.substr(0, 20) + ' . . .';
    } else {
      return title;
    }
  }
}
