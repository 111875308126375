import { Component, EventEmitter, Input, OnChanges, Output, ViewChild, Inject, TemplateRef } from '@angular/core';
import { AppStatus } from '@enums/app-status.enum';
import { APK } from '../../../models/apk';
import { UserService } from '../../../services/user.service';
import { ModalComponent } from '../modal/modal.component';
import {
  MessageDialogService,
  MessageDialogServiceImpl,
  MessageDialogServiceToken,
  dialogButtons,
  IDialogAction
} from '../confimation-modal/dialog.service';
import { filter, flatMap, tap } from 'rxjs/operators';
import { App } from '@models/app';
import { regxPatterns } from '@apps/components/dynamic-form/dynamic-form-validator.regexp';

@Component({
  selector: 'app-apk',
  templateUrl: './apk.component.html',
  styleUrls: ['./apk.component.scss']
})
export class ApkComponent implements OnChanges {
  @Input()
  public apk: APK = {
    fileName: '',
    fileSize: 0,
    status: AppStatus.not_submitted,
    fileSizeLabel: '',
    versionNumber: '1.0',
    fileId: ''
  };

  @ViewChild('modal')
  sendModal: ModalComponent;
  @ViewChild('sendModalBody') sendModalBody;
  @Input()
  language: string;
  @Output()
  uploadApk = new EventEmitter();
  @Output()
  updateApkEvent = new EventEmitter();
  @Output()
  sendToDevice = new EventEmitter();
  @Output()
  public deleteApk = new EventEmitter();
  public emailList: any[] = [];
  @ViewChild('modal')
  errorModal: ModalComponent;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() isChanged = false;
  @Input() app: App;

  public AppStatus = AppStatus;
  public newEmail: string;
  public releaseNotes: string;
  public selectedEmails: string[] = [];
  public sendButton: IDialogAction;

  emailRegX = regxPatterns.email;

  isAdmin: boolean;
  get status() {
    if (!!this.apk && !!this.apk.status) {
      return this.apk.status.split('_').join(' ');
    }
  }
  constructor(
    private userService: UserService,
    @Inject(MessageDialogServiceToken) protected dialogService: MessageDialogServiceImpl
  ) {
    this.isAdmin = userService.isAdmin();

    this.emailList.push({
      isSelected: false,
      address: this.userService.getUser().emailAddress
    });
  }

  ngOnChanges() {
    // // debugger;
    if (this.apk) {
      // debugger;
      // console.log('this.apk:', this.apk);
      this.apk.fileSizeLabel = `${this.apk.fileSize} MB`;

      const locale = this.apk.releaseNotes.find(detailLocale => {
        return detailLocale.locale === this.language;
      });
      console.log('apk.component.ts ngOnChanges()', locale);
      if (locale) {
        this.releaseNotes = locale.releaseNotes;
      } else {
        this.releaseNotes = '';
      }
    }
  }

  releaseNoteChange(releaseNote) {
    this.valueChange.emit({ releaseNote });
  }

  showSendModal() {
    this.sendButton = {
      caption: 'Send APK',
      result: 1,
      disabled: true
    };
    this.emailList = [
      {
        isSelected: false,
        address: this.userService.getUser().emailAddress
      }
    ];

    this.dialogService
      .openMessageDialog({
        title: 'Send APK to device',
        bodyContentRef: this.sendModalBody,
        actions: [this.sendButton, dialogButtons.cancel]
      })
      .afterClosed()
      .pipe(
        filter(res => res > 0),
        tap(() => this.sendToDevice.emit(this.selectedEmails))
      )
      .subscribe();
  }

  onSend() {
    if (this.selectedEmails.length > 0) {
      this.sendModal.hide();
      this.sendToDevice.emit(this.selectedEmails);
    }
  }

  onDelete() {
    this.deleteApk.emit();
  }

  onUpload() {
    this.uploadApk.emit();
  }

  public addEmail() {
    if (this.emailList.length <= 5) {
      this.emailList.push({
        isSelected: true,
        address: this.newEmail
      });
      this.collectEmails();
      // clean input
      this.newEmail = null;
    }
  }

  public collectEmails() {
    this.selectedEmails = this.emailList.filter(email => email.isSelected).map(email => email.address);
    this.sendButton.disabled = !(this.selectedEmails && this.selectedEmails.length);
  }

  updateApk(event) {
    // debugger;
    const position = this.apk.releaseNotes.findIndex(detailLocale => {
      return detailLocale.locale === this.language;
    });

    if (position >= 0) {
      this.apk.releaseNotes[position].releaseNotes = event;
    } else {
      this.apk.releaseNotes.push({
        locale: this.language,
        releaseNotes: event
      });
    }

    this.updateApkEvent.emit(this.apk);
  }

  isEditable() {
    return (
      !this.isAdmin &&
      this.apk &&
      [AppStatus.not_submitted, AppStatus.awaiting_approval, AppStatus.live].includes(this.apk.status)
    );
  }

  allowSendDevice() {
    return this.apk && this.apk.version !== 'Invalid' && !(this.app && this.app.appStatus === AppStatus.live);
  }
}
