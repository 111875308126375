import { AsyncPipe } from '@angular/common';
import { Component, HostListener, Inject, Input, OnDestroy, OnInit, QueryList, ViewChildren, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { dialogButtons, dprmConfirmLeavePageDialog, MessageDialogServiceImpl, MessageDialogServiceToken, } from '@common/components/confimation-modal/dialog.service';
import { INavigationGuardComponent } from '@common/components/navigation-guard/navigation-guard.component';
import { ComponentCommService } from '@services/component-comm.service';
import { ErrorProviderNode } from 'app/models/error-provider.model';
import { AppService } from 'app/services/app.service';
import { UtilService } from 'app/services/util.service';
import { Observable, Subject } from 'rxjs';
import { map, pluck, takeUntil, } from 'rxjs/operators';
import { AppDetailsResolver } from '../../../../../guards/app-details.resolver';
import { App } from '../../../../../models/app';
import { AppLocaleRowComponent } from './locale-row/locale-row.component';

@Component({
  selector: 'app-locale',
  templateUrl: './locales.component.html',
  styleUrls: ['./locales.component.scss'],
  providers: [AsyncPipe]
})
export class AppLocalesComponent
implements
  OnInit,
  ErrorProviderNode<AppLocaleRowComponent>,
  INavigationGuardComponent,
  OnDestroy {
  @Input()
  readOnly = false;
  @ViewChildren(AppLocaleRowComponent)
  children: QueryList<AppLocaleRowComponent>;

  saveClicked = false;

  private _navigatingAway = false;
  private isDirty = false;
  set navigatingAway(value) {
    this._navigatingAway = value;
  }

  get error() {
    if (!!this.children) {
      return this.children.map(child => child.error).some(err => err);
    }
    return false;
  }
  appResolved: App;
  app: Observable<App> = this.route.parent.data.pipe(pluck('appDetails'));
  locales: any = null;
  allPossibleLocales;
  onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected svcResolver: AppDetailsResolver,
    private pipeAsync: AsyncPipe,
    private util: UtilService,
    private appService: AppService,
    private commService: ComponentCommService,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {
    this.app.pipe(takeUntil(this.onDestroy$)).subscribe((app: App) => this.appResolved = app);
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event) {
    if (this.isDirty) {
      event.returnValue = "You have unsaved changes. Are you sure you want to close the page?";
      return "You have unsaved changes. Are you sure you want to close the page?"
    }
    return undefined;
  }

  valueChange(val: any) {
    this.isDirty = true;
  }

  ngOnInit() {
    this.commService.get().pipe(takeUntil(this.onDestroy$)).subscribe(
      (msg) => {
        if (msg && msg.skipNavGuard) {
          this.isDirty = false;
        }
      }
    );

    const { readOnly, lists } = this.route.snapshot.data;

    this.readOnly = readOnly;
    this.allPossibleLocales = lists.locales;
  }

  navigationStart() {
    this.navigatingAway = true;
    this.appService.navigateAway(true);
    this.saveClicked = true;
    this.router.navigate([
      "/app/edit/" +
      this.appResolved.id +
      "/" +
      this.appResolved.productVersionId + "/details"
    ]);
}

  get getWorldwide() {
    return {
      locale: 'WW',
      country_name: 'Worldwide'
    };
  }

  confirmNavigation() {
    if ((!this.isDirty) || this.saveClicked) {
      this.isDirty = false;
      this.saveClicked = false;
      return true;
    }
    return this.dialogService.openMessageDialog(dprmConfirmLeavePageDialog).afterClosed().pipe(
      map(res => {
        this.isDirty = !(res > 0);
        return res > 0;
      }),
    );
  }

  ngOnDestroy(){
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
