import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContactInfoModel } from '@models/stripe-account';
import { StripeModuleValidators } from '@stripe/validators';
import { AsYouType } from 'libphonenumber-js';
import { regxPatterns } from '@apps/components/dynamic-form/dynamic-form-validator.regexp';

@Component({
  selector: 'app-contact-info-form',
  templateUrl: './contact-info-form.component.html',
  styleUrls: ['./contact-info-form.component.scss']
})
export class ContactInfoFormComponent {
  @Input()
  isWhite: boolean;
  @Input()
  parent: FormGroup;
  @Input()
  readOnly = false;
  @Input()
  contactInfoTemplate: ContactInfoModel;

  emailRegX = regxPatterns.email;

  handleKeyUp() {
    const phoneNumber = this.parent.get('contactInfo.phoneNumber.value').value;
    const country = this.parent.get('stripeAccount.country').value;

    this.parent.get('contactInfo.phoneNumber.value').setValue(new AsYouType(country).input(phoneNumber));
    this.setValidators();
  }

  setValidators() {
    const phoneNumber = this.parent.get('contactInfo.phoneNumber.value').value;
    const country = this.parent.get('stripeAccount.country').value;
    this.parent
      .get('contactInfo.phoneNumber.value')
      .setValidators(StripeModuleValidators.validPhoneNumber(phoneNumber, country));
    this.parent.get('contactInfo.phoneNumber.value').updateValueAndValidity();
  }
}
