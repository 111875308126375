import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaymentAdminDetail, PaymentAdminList, TransferStatus } from 'app/models/payment-admin';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import { switchMap } from 'rxjs/operators';
import { ReportProgressHeader } from './http-progress.interceptor';

@Injectable()
export class PaymentService {
  public itemsPerPage = 10;
  public currentPage = 0;

  constructor(private http: HttpClient, @Inject(leiaEndpointToken) private leiaEndpoints) {}

  public getListForDeveloper(page, itemPerPage) {
    const params = new HttpParams().set('page', page).set('perPage', itemPerPage);

    return this.http.get<any>(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.getPaymentListForDeveloper.url}`,
      { params }
    );
  }

  public getUserListById(developerId, page, itemPerPage) {
    const params = new HttpParams().set('page', page).set('perPage', itemPerPage);

    return this.http.get<PaymentAdminList>(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.getUserPaymentList.url}${developerId}`,
      { params }
    );
  }

  public authorizePayment(transferGroup: string, transferKey: string) {
    return this.http.post<any>(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.approveTransfer.url}`,
      {
        transferGroup: transferGroup,
        transferKey: transferKey,
      },
    );
  }

  public getDetailByTransferGroup(transferGroup) {
    return this.http.get<any>(
      `${environment.leiaservices.url}${
        this.leiaEndpoints.stripe.getPaymentDetailByTransferGroup.url
      }${transferGroup}`
    );
  }

  public getUserDetailByTransferKey(developerId, TransferKey, upcoming) {
    const params = new HttpParams().set('upcoming', upcoming.toString());
    const url = this.leiaEndpoints.stripe.getUserPaymentDetailBytransferKey(developerId, TransferKey).url;
    return this.http.get<PaymentAdminDetail>(`${environment.leiaservices.url}${url}`, { params });
  }

  public getListForAdmin(page, itemPerPage) {
    const headers = new HttpHeaders().set(ReportProgressHeader, '');
    const params = new HttpParams().set('page', page).set('perPage', itemPerPage);

    return this.http.get<PaymentAdminList>(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.getPaymentListForAdmin.url}`,
      { params, headers }
    );
  }

  public requestDetailForUpcoming() {
    return this.http.get<PaymentAdminDetail>(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.getPaymentDetailForUpcoming.url}`
    );
  }

  public getDetailBytransferKey(transferKey, upcoming = false) {
    const params = new HttpParams().set('upcoming', upcoming.toString());
    return this.http.get<PaymentAdminDetail[]>(
      `${environment.leiaservices.url}${
        this.leiaEndpoints.stripe.getPaymentDetailBytransferKey.url
      }${transferKey}`, { params }
    );
  }

  public hold(detail) {
    return this.http
      .post<any>(`${environment.leiaservices.url}${this.leiaEndpoints.stripe.holdPayment.url}`, detail)
      .pipe(switchMap(res => this.getHeldList()));
  }

  public payNow(detail) {
    return this.http.post<any>(`${environment.leiaservices.url}${this.leiaEndpoints.stripe.payNow.url}`, detail);
  }

  public getHoldStatusByPartnerId(partnerId) {
    return this.http.get<TransferStatus>(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.getTransferStatusByPartnerId.url}${partnerId}`
    );
  }

  public getDetailWithStatus(userId = null, transferKey = null, upcoming = false) {
    let request;

    // differentiate if the call is for indivisual partner or admin in general
    if (userId) {
      request = this.getUserDetailByTransferKey(userId, transferKey, upcoming);
    } else {
      request = this.requestDetailForUpcoming();
    }

    return request;
  }

  public getHeldList() {
    const headers = new HttpHeaders().set(ReportProgressHeader, '');
    return this.http.get<any>(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.getHeldListForAdmin.url}`,
      { headers }
    );
  }

  downloadCSVData(
    userId: string,
    type: string,
    page: number,
    perPage: number,
    productId: string,
    transferKey: string,
    admin: boolean
  ) {
    const url = this.generateRequestURL(userId, type, page, perPage, productId, transferKey, admin);

    return this.http.get(url, { responseType: 'blob' });
  }

  private generateRequestURL(
    userId: string,
    type: string,
    page: number,
    perPage: number,
    productId: string,
    transferKey: string,
    admin: boolean = false
  ) {
    let params = new HttpParams();
    let baseUrl = `${environment.leiaservices.url}/${this.leiaEndpoints.stripe.report.url}/${
      this.leiaEndpoints.stripe.report.developer.url
    }`;
    if (admin) {
      if (userId) {
        baseUrl = `${environment.leiaservices.url}/${this.leiaEndpoints.stripe.report.url}/${
          this.leiaEndpoints.stripe.report.admin.developer.url
        }`;
      } else {
        baseUrl = `${environment.leiaservices.url}/${this.leiaEndpoints.stripe.report.url}/${
          this.leiaEndpoints.stripe.report.admin.all.url
        }`;
      }
    }

    if (page || perPage) {
      page = page || 0;
      perPage = perPage || 0;
      params = params.set('page', page.toString()).set('perPage', perPage.toString());
    }

    if (productId) {
      params = params.set('productId', productId);
    }

    if (transferKey) {
      params = params.set('transferKey', transferKey);
    }

    if (admin && userId) {
      return `${baseUrl}/${userId}/${type}?${params}`;
    } else {
      return `${baseUrl}/${type}?${params}`;
    }
  }

  private setPaymentDetails(payment, details) {
    payment.details = details.filter(d => d.isHeld === payment.isHeld);
    payment.isDetailLoaded = true;
  }

  getDetail(payment, upcoming?, user?) {
    if (!!payment) {
      if (!payment.details) {
        // Distribution page
        if (!user) {
          // Upcoming detail
          if (!payment.transferKey) {
            this.getDetailWithStatus().subscribe(details => this.setPaymentDetails(payment, details));
          } else {
            // Previous payment detail
            this.getDetailBytransferKey(payment.transferKey, upcoming).subscribe(details =>
              this.setPaymentDetails(payment, details)
            );
          }
        } else {
          // Partners page
          if (!payment.transferKey) {
            this.getDetailWithStatus(user.userId).subscribe(details => this.setPaymentDetails(payment, details));
          } else {
            this.getDetailWithStatus(user.userId, payment.transferKey, upcoming).subscribe(details =>
              this.setPaymentDetails(payment, details)
            );
          }
        }
      }
      payment.showDetail = !payment.showDetail;
    } else {
      throw new Error('payment was undefined');
    }
  }

  holdPayment({ form, modal }, selectedItem) {
    const { value: formValue } = form;
    const requestData = {
      transferGroup: selectedItem.transferGroup,
      password: formValue.password,
      skipNext: !selectedItem.isHeld
    };

    return this.hold(requestData);
  }
}
