import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { LineChartComponent as NgxLineChart } from '@swimlane/ngx-charts';
import { LineChartModelValueType } from 'app/models/line-chart-value-type.model';
import { FormatYAxisPipe } from 'app/pipes/format-y-axis.pipe';
import { detect } from 'detect-browser';
import { isEmpty, maxBy, minBy } from 'lodash';
import * as moment from 'moment';
import * as uuid from 'uuid/v4';
import { LineChartDataElement } from '../../../models/line-chart-data-element.model';
import { DashboardService } from '../../../services/dashboard.service';
import { UtilService } from '../../../services/util.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  providers: [FormatYAxisPipe]
})
export class LineChartComponent implements OnInit, AfterViewInit {
  @ViewChild(NgxLineChart)
  chart: NgxLineChart;

  private _data: LineChartDataElement[];
  protected dataLength = 0;

  @Input()
  startHexColor = '#C89DFF';
  @Input()
  endHexColor = '#6B2ACC';
  @Input()
  width;
  @Input()
  height;
  @Input()
  xAxisLabel = 'Date';
  @Input()
  showYAxisLabel = true;
  @Input()
  yAxisLabel;
  @Input()
  modelValueType: LineChartModelValueType = 'currency';

  get gradientCss() {
    return {
      '--gradient': this.isSafari ? '#8833F5' : `url(#${this.uuid})`
    };
  }
  get data() {
    return this._data;
  }

  get yScaleMax() {
    if (!!this.data) {
      const inner = this.svcUtil.first(this.data);
      if (!!inner && !!inner.series) {
        const res = maxBy(inner.series, 'value');
        return res.value > 0 ? res.value + 1 : res.value;
      }
    }
  }
  get yScaleMin() {
    if (!!this.data) {
      const inner = this.svcUtil.first(this.data);
      if (!!inner && !!inner.series) {
        const res = minBy(inner.series, 'value');
        return res.value > 0 ? res.value - 1 : res.value;
      }
    }
  }
  @Input()
  set data(value: LineChartDataElement[]) {
    if (!!value && !!value[0]) {
      const first = value[0];
      if (isEmpty(first.series)) {
        this._data = [
          {
            // name: 'Not Enough Data',
            name: 'N/A',
            series: [
              {
                name: moment(new Date()).toDate(),
                value: 0
              },
              {
                name: moment(new Date())
                  .subtract('1', 'day')
                  .toDate(),
                value: 0
              }
            ]
          }
        ];
      } else {
        this._data = value;
      }

      this.dataChange.emit(this._data);
    }
  }
  @Output()
  dataChange = new EventEmitter<LineChartDataElement[]>();

  view: any[];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showXAxisLabel = true;
  tooltipDisabled = false;
  showGridLines = false;
  innerPadding = '10%';
  barPadding = 8;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  showSeriesOnHover = true;
  roundEdges = true;
  animations = true;
  xScaleMin: any;
  xScaleMax: any;
  showDataLabel = false;
  colorScheme: any;
  isSafari = false;

  private _linearGradientId = uuid();
  get uuid() {
    return this._linearGradientId;
  }
  get dataSum() {
    return this.svcDashboard.lineChartDataSum(this.data);
  }
  get downloadsPluralizer() {
    return {
      '=1': 'download',
      other: 'downloads'
    };
  }

  constructor(
    private el: ElementRef,
    private svcDashboard: DashboardService,
    public svcUtil: UtilService,
    public pipeFormatYAxis: FormatYAxisPipe
  ) {
    const browser = detect();
    if (browser) {
      this.isSafari = browser.name === 'safari';
    }
  }

  isLine(data: LineChartDataElement[]) {
    const first = this.svcUtil.first(data);
    if (!!first && !!first.series) {
      return first.series.every(
        (val, _, ary) => val.value === this.svcUtil.first(ary).value
      );
    }
    return false;
  }

  ngOnInit() {
    this.view = [this.width, this.height];
    this.colorScheme = {
      name: 'custom',
      selectable: true,
      group: 'Ordinal',
      domain: [
        '#C89DFF',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      ]
    };
  }
  ngAfterViewInit() {
    // Could add plurality in the future
    this.chart.yAxisTickFormatting = value =>
      this.pipeFormatYAxis.transform(value, this.modelValueType);
  }
}
