import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { FormGroup, NgControl, NgModel, ValidationErrors } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent, MatFormFieldControl } from '@angular/material';
import { Moment } from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-material-date-input',
  templateUrl: './material-date-input.component.html',
  styleUrls: ['./material-date-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: MaterialDateInputComponent }]
})
export class MaterialDateInputComponent implements MatFormFieldControl<MaterialDateInputComponent>, OnDestroy {
  @Input()
  picker: MatDatepicker<Moment>;
  @Input()
  noUnderline = true;
  @Input()
  parent: FormGroup;
  @ViewChild(NgModel)
  input: NgModel;
  @Output()
  change = new EventEmitter<any>();
  @Input()
  name;
  @Input()
  hasInfo = false;
  @Input()
  infoText: string;
  @Input()
  label: string;
  @Input()
  type: string;
  @Input()
  isWhite = false;
  @Input()
  errorText = 'An error occurred!';
  @Input()
  isDisabled = false;
  @Input()
  isHidden = false;
  @Input()
  mimeType;
  @Input()
  maxLength = 100;
  @Input()
  set errors(errors: ValidationErrors) {
    this._errors = errors;
    if (!!errors && !!errors.message && !this.errorText.includes(errors.message)) {
      const err = errors.message + this.errorText;
      this.errorText = err;
    }
  }
  get errors() {
    return this._errors;
  }
  private _errors: ValidationErrors;
  @Input() // This was on the getter so some reason
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }
  private _required = false;
  @Input() // This was on the getter so some reason
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parent.disable() : this.parent.enable();
    this.stateChanges.next();
  }
  get disabled(): boolean {
    return this._disabled;
  }
  private _disabled = false;
  @Input()
  set value(value) {
    this._value = value;
    this.notifyValueChange(value);
    this.stateChanges.next();
  }

  get value() {
    return this._value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  @HostBinding('attr.aria-describedby') describedBy = '';
  @Output()
  dateChange = new EventEmitter<MatDatepickerInputEvent<Moment>>();
  private _value: any;

  get empty() {
    const n = this.parent.value;
    return !n; // !n.area && !n.exchange && !n.subscriber;
  }

  stateChanges = new Subject<void>();
  id: string;
  placeholder: string;
  focused: boolean;
  errorState = !!this.errors;
  controlType? = 'app-material-date-input';
  autofilled?: boolean;
  isHovered = false;

  onChange: (value) => {};
  onTouched: () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    this.type = 'text';
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
}
