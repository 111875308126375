﻿import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppPaymentsModule } from 'app/payments.module/payments.module';
import { AppAppsModule } from '../apps.module/apps.module';
import { AppCommonModule } from '../common.module/app.common.module';
import { AppCoreModule } from '../core.module/core.module';
import { UserStatusComponent } from './components/user-status/user-status.component';
import { PartnersPageComponent } from './partners.page.component';

@NgModule({
  declarations: [PartnersPageComponent, UserStatusComponent],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    AppPaymentsModule,
    AppCommonModule,
    AppCoreModule,
    AppAppsModule
  ],
  exports: [PartnersPageComponent, UserStatusComponent]
})
export class AppPartnersModule {}
