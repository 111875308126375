import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { PaymentAdmin } from 'app/models/payment-admin';
import { PaymentDeveloper } from 'app/models/payment-developer';
import { PaymentService } from 'app/services/payment.service';
import { sortBy } from 'lodash';

@Component({
  selector: 'app-payment-table',
  templateUrl: './payment-table.component.html',
  styleUrls: ['./payment-table.component.scss']
})
export class PaymentTableComponent implements OnInit {
  @Input()
  payments: Array<PaymentAdmin | PaymentDeveloper> = [];
  @Input()
  tableAttributes: any[] = [];
  @Input()
  showButtons: boolean;
  @Input()
  groupPaymentsEnabled = false;
  @Output()
  onHoldClick = new EventEmitter();
  @Output()
  onReleaseClick = new EventEmitter();
  @Output()
  onPayNowClick = new EventEmitter();
  @Output()
  clickExpand: EventEmitter<any> = new EventEmitter();
  @Output() authClick = new EventEmitter();
  @ContentChild('optionButtons')
  optionButtonsTmpl: TemplateRef<any>;

  public showDetail = false;
  public attrs: string[];

  constructor(private paymentSvc: PaymentService) {
    this.showButtons = false;
  }

  sortBy<T>(arrayLike: ArrayLike<T>) {
    return sortBy(arrayLike);
  }

  ngOnInit() {
    // the tableAttributes contain title for the table
    // and the required attributes for the rest columns
    // since the developer and admin are accessing different fields
    this.attrs = this.tableAttributes
      .filter(tableAttribute => tableAttribute.attr)
      .map(tableAttribute => tableAttribute.attr);

    // Show payment buttons (paynow, hold/release) if there isn't clickExpand
  }

  public onClickExpand(payment) {
    this.clickExpand.emit(payment);
  }
}
