import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  Renderer2,
  ViewChild
} from '@angular/core';
import { LegendComponent } from '@swimlane/ngx-charts';
import { detect } from 'detect-browser';
import { BasePieChartComponent } from '../../../common.module/components/base-pie-chart/base-pie-chart.component';
import { PieChartData } from '../../../models/pie-chart-data.model';
import {
  DashboardService,
  NOT_ENOUGH_DATA
} from '../../../services/dashboard.service';

@Component({
  selector: 'app-horizontal-pie-chart',
  templateUrl: './horizontal-pie-chart.component.html',
  styleUrls: ['./horizontal-pie-chart.component.scss']
})
export class HorizontalPieChartComponent extends BasePieChartComponent {
  @ViewChild(LegendComponent)
  legend: LegendComponent;

  split: PieChartData[] = [];
  isSafari = false;

  @Input()
  public set data(value: PieChartData[]) {
    if (value && value.length > 0) {
      // Calculate total sum of values in data array
      const sum = value
        .map(item => item.value)
        .reduce((prev, next) => prev + next);

      // Set our prop for parent components
      this.totalRenevue = sum;

      // Calculate the split for every item in the data array
      const split = value.map((item, i) => {
        return {
          name: item.name,
          value: Math.round((item.value / sum) * 100)
        };
      });

      this.split = split;
      this._data = value;
    } else {
      this.totalRenevue = 0;
      this.split = [NOT_ENOUGH_DATA, NOT_ENOUGH_DATA];
      this._data = [NOT_ENOUGH_DATA, NOT_ENOUGH_DATA];
    }
    super.setLegendAndColors();
  }
  public get data() {
    return this._data;
  }

  totalRenevue = 0;

  constructor(
    public el: ElementRef,
    public cd: ChangeDetectorRef,
    public zone: NgZone,
    public renderer: Renderer2,
    public currency: CurrencyPipe,
    public svcDashboard: DashboardService
  ) {
    super(el, cd, zone, renderer, svcDashboard);

    const browser = detect();
    if (browser) {
      this.isSafari = browser.name === 'safari';
    }
  }
}
