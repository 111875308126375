import { Component, OnInit } from '@angular/core';
import { LoginService } from '@services/login.service';

@Component({
  selector: 'app-logout',
  template: '<div></div>'
})
export class AppLogoutComponent implements OnInit {
  constructor(
    private loginService: LoginService,
  ) {}

  ngOnInit() {
      this.logout();
  }

  logout(): void {
    this.loginService.logout();
  }
}
