import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NOT_ENOUGH_DATA } from 'app/services/dashboard.service';
import { BasePieChartComponent } from '../../../common.module/components/base-pie-chart/base-pie-chart.component';

@Component({
  selector: 'app-vertical-pie-chart',
  templateUrl: './vertical-pie-chart.component.html',
  styleUrls: ['./vertical-pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalPieChartComponent extends BasePieChartComponent {
  get notEnoughData() {
    return NOT_ENOUGH_DATA;
  }
  // http://userguide.icu-project.org/formatparse/messages
  get appPlurality() {
    return {
      '=1': 'Download',
      other: 'Downloads'
    };
  }
}
