import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject, } from "rxjs";

export const comcUserCountryChange = "comcUserCountryChange";

@Injectable()
export class ComponentCommService implements OnDestroy {

    private channels: any = {};
    private vars: Object = {};
    messageSubject: Subject<any> = new Subject();
    constructor() {}

    get(): Subject<any> {
        return this.messageSubject;
    }

    getChannel(channelName: string): Subject<any> {
        if (!this.channels[channelName]) {
            this.channels[channelName] = new Subject<any>();
        }
        return this.channels[channelName];
    }

    getBehaviorChannel(channelName: string, defaultValue?: any) {
        if (!this.channels[channelName]) {
            this.channels[channelName] = new BehaviorSubject<any>(defaultValue);
        }
        return this.channels[channelName];
    }

    varExists(varName: string) {
        return this.vars.hasOwnProperty(varName);
    }

    setVar(varName: string, value?: any) {
        this.vars[varName] = value;
    }
    getVar(varName: string): any {
        return this.vars[varName];
    }

    ngOnDestroy() {
        if (!this.messageSubject.closed) {
            this.messageSubject.complete();
        }
        for(const key in this.channels) {
            if (this.channels.hasOwnProperty([key]) && this.channels[key].complete && (!this.channels[key].closed)) {
                this.channels[key].complete();
            }
        }
    }

}
