﻿import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatBadgeModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatTabsModule,
  MatTooltipModule,
  MatSlideToggleModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AppCommonModule } from '../common.module/app.common.module';
import { AppFocus } from '../directives/focus.directive';
import { AppHttpProgressInterceptor } from '../services/http-progress.interceptor';
import { UtilService } from '../services/util.service';
import { AppStatusComponent } from './components/app-status/app-status.component';
import { AppSubmissionComponent } from './components/app-submission/app-submission.component';
import { ApproveModalComponent } from './components/approve-modal/approve-modal.component';
import { AppsSearchComponent } from './components/apps-search/apps-search.component';
import { AppsTableComponent } from './components/apps-table/apps-table.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormService } from './components/dynamic-form/dynamic-form.service';
import { AppEditDetailsComponent } from './components/my-apps/app-edit/app-edit-details/app-edit-details.component';
import { AppEditIapComponent } from './components/my-apps/app-edit/app-edit-iap/app-edit-iap.component';
import { AppEditImageryComponent } from './components/my-apps/app-edit/app-edit-imagery/app-edit-imagery.component';
import { AppEditLocaleRowComponent } from './components/my-apps/app-edit/app-edit-locales/app-edit-locale-row/app-edit-locale-row.component';
import { AppEditLocalesComponent } from './components/my-apps/app-edit/app-edit-locales/app-edit-locales.component';
import { AppEditPackagesComponent } from './components/my-apps/app-edit/app-edit-packages/app-edit-packages.component';
import { AppEditComponent } from './components/my-apps/app-edit/app-edit.component';
import { CheckboxAnchorDirective, MyAppsComponent } from './components/my-apps/my-apps.component';
import { AppDetailsComponent } from './components/my-apps/new-app/details/details.component';
import { AppIapComponent } from './components/my-apps/new-app/iap/iap.component';
import { AppLocaleRowComponent } from './components/my-apps/new-app/locales/locale-row/locale-row.component';
import { AppLocalesComponent } from './components/my-apps/new-app/locales/locales.component';
import { AppNewAppComponent } from './components/my-apps/new-app/new-app.component';
import { AppPackagesComponent } from './components/my-apps/new-app/packages/packages.component';
import { RejectModalComponent } from './components/reject-modal/reject-modal.component';
import { AppMainTileComponent } from './components/submission/app-main-tile.component';
import { UpdateSuccessModalComponent } from './components/update-success-modal/update-success-modal.component';
import { UsersReportComponent } from './components/users-report/users-report.component';
import { UsersComponent } from './components/users/users.component';
import { AppImageryComponent } from './containers/imagery/imagery.component';
import { CustomReuseStrategy } from './custom-reuse.strategy';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'app/pipes/pipes.module';

@NgModule({
  declarations: [
    MyAppsComponent,
    AppNewAppComponent,
    AppDetailsComponent,
    AppIapComponent,
    AppImageryComponent,
    AppLocalesComponent,
    AppLocaleRowComponent,
    AppPackagesComponent,
    AppsTableComponent,
    AppSubmissionComponent,
    AppMainTileComponent,
    UsersComponent,
    CheckboxAnchorDirective,
    AppsSearchComponent,
    ApproveModalComponent,
    RejectModalComponent,
    UpdateSuccessModalComponent,
    AppStatusComponent,
    AppFocus,
    AppEditComponent,
    AppEditDetailsComponent,
    AppEditLocalesComponent,
    AppEditLocaleRowComponent,
    AppEditImageryComponent,
    AppEditPackagesComponent,
    AppEditIapComponent,
    UsersReportComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppCommonModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    TranslateModule,
    MatSlideToggleModule,
    PipesModule
  ],
  exports: [
    MyAppsComponent,
    AppNewAppComponent,
    AppDetailsComponent,
    AppIapComponent,
    AppImageryComponent,
    AppLocalesComponent,
    AppLocaleRowComponent,
    AppPackagesComponent,
    AppsTableComponent,
    AppSubmissionComponent,
    AppMainTileComponent,
    UsersComponent,
    CheckboxAnchorDirective,
    AppsSearchComponent,
    ApproveModalComponent,
    RejectModalComponent,
    UpdateSuccessModalComponent,
    AppStatusComponent,
    AppEditComponent,
    AppEditDetailsComponent,
    AppEditLocalesComponent,
    AppEditLocaleRowComponent,
    AppEditImageryComponent,
    AppEditPackagesComponent,
    AppEditIapComponent,
    UsersReportComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpProgressInterceptor,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    UtilService,
    DynamicFormService
  ]
})
export class AppAppsModule {}
