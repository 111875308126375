import { CountryCode } from './country-code.model';

//
// Old models
//
export interface UserBankDetailServerResponse {
  firstName?: string;
  lastName?: string;
  dob?: string;
  companyName?: string;
  country: CountryCode; // ISO, e.g. 'us', 'gb', etc.
  addressLine1: string;
  addressLine2: string;
  city: string;
  zip: string;
  stripeBankDetails: StripeBankDetails;
  accountType: 'individual' | 'company';
}

export interface StripeBankDetails {
  country: CountryCode; // ISO, e.g. 'us', 'gb', etc.
  routingNumber: string | null;
  accountNumber: string | null;
  bsb: string | null;
  iban: string | null;
  sortCode: string | null;
  bankCode: string | null;
  branchCode: string | null;
}

//
// New models
//

export type AccountType = 'individual' | 'company';

export interface DOB {
  day: string;
  month: string;
  year: string;
}

export interface Property {
  present: boolean;
  description?: string;
  value?: string | DOB | Date;
}

export interface AddressModel extends Property {
  addressOne: Property;
  addressTwo?: Property; // Optional - not-required
  city?: Property; // E.g. - SG - not required
  stateOrProvince?: Property; // E.g. - HK - not required
  zipOrPostalCode?: Property; // E.g. - HK - not required
}

export interface NameModel extends Property {
  firstName: Property;
  lastName: Property;
}

export interface AccountRepresentativeModel extends Property {
  name: NameModel;
  dob: Property;
  address?: AddressModel; // E.g. - AT - not required
  personalIdNumber?: Property;
  ssnLast4?: Property;
}

export interface TOSAcceptanceModel extends Property {
  fullName: Property; // Full name, which must match primary or one of the representative's names
  date: Property;
  ip: Property;
}

export interface Document extends Property {
  front: Property;
  back: Property;
}
export interface VerficationDocumentModel extends Property {
  document: Document; // Base64 string of a JPG/PNG
}

export interface StripeExternalAccountModel extends Property {
  country: CountryCode; // ISO, e.g. 'us', 'gb', etc.
  routingNumber?: Property;
  accountNumber?: Property;
  bsb?: Property;
  iban?: Property;
  sortCode?: Property;
  bankCode?: Property;
  branchCode?: Property;
}
export interface StripeAccountModel extends Property {
  type: AccountType;
  country: CountryCode;
  externalAccount: StripeExternalAccountModel;
  address?: AddressModel;
  accountRepresentatives: AccountRepresentativeModel[];
  tosAcceptance: TOSAcceptanceModel;
  verificationDocument: VerficationDocumentModel;
}

export interface StripeIndividualAccountModel extends StripeAccountModel {
  type: 'individual';
}

export interface StripeCompanyAccountModel extends StripeAccountModel {
  type: 'company';
  name: Property;
  taxID: Property; // EIN / TaxID
  address: AddressModel; // Business address
}

export interface ContactInfoModel extends Property {
  name: NameModel;
  email: Property;
  phoneNumber: Property;
  rllDeveloperName: Property;
}

export interface DeveloperModel {
  stripeAccount: StripeAccountModel | StripeCompanyAccountModel;
  contactInfo: ContactInfoModel;
}

// export const INITIAL_DOB: DOB = { day: 1, month: 1, year: 1900 };
export const CONTACT_INFO: ContactInfoModel = {
  present: true,
  name: {
    present: true,
    firstName: {
      present: true,
      description: `First name of the developer from the Your Details Page`,
      value: ''
    },
    lastName: {
      present: true,
      description: `Last name of this account's developer from the Your Details Page`,
      value: ``
    }
  },
  email: {
    present: true,
    description: `Please contact Leia admin if you wish to change the email address associated with this account`,
    value: ``
  },
  phoneNumber: {
    present: true,
    description: `This is the contact number in which you can be reached.`,
    value: ``
  },
  rllDeveloperName: {
    present: true,
    description: 'This is your Developer name as it appears listed with your apps on Red Leia Loft',
    value: null
  }
};
