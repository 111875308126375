﻿import { CommonModule, CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PipesModule } from 'app/pipes/pipes.module';
import { AppCommonModule } from '../common.module/app.common.module';
import { AppDashboardPageModule } from '../dashboard.module/dashboard.page.module';
import { AppHttpAuthdRequestInterceptor } from '../services/http-auth.interceptor';
import { AdminDashboardPageComponent } from './admin-dashboard.page.component';
import { AppChartLegendComponent } from './components/app-chart-legend/app-chart-legend.component';
import { AppLegendEntryComponent } from './components/app-chart-legend/app-chat-legend-entry';
import { AwaitingApprovalComponent } from './components/awaiting-approval/awaiting-approval.component';
import { HorizontalPieChartComponent } from './components/horizontal-pie-chart/horizontal-pie-chart.component';
import { SummaryItemComponent } from './components/summary-item/summary-item.component';
import { SummaryComponent } from './components/summary/summary.component';
import { TotalRevenueComponent } from './components/total-revenue/total-revenue.component';

@NgModule({
  declarations: [
    // All of these components should ideally start with the prefix of their selectors
    // e.g. AppAdminDashboardPageComponent etc...
    AdminDashboardPageComponent,
    AwaitingApprovalComponent,
    TotalRevenueComponent,
    HorizontalPieChartComponent,
    SummaryComponent,
    SummaryItemComponent,
    AppChartLegendComponent,
    AppLegendEntryComponent
  ],
  imports: [
    CommonModule,
    NgxChartsModule,
    AppCommonModule,
    AppDashboardPageModule,
    PipesModule
  ],
  providers: [
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpAuthdRequestInterceptor,
      multi: true
    }
  ],
  exports: [AdminDashboardPageComponent, AwaitingApprovalComponent]
})
export class AdminDashboardPageModule {}
