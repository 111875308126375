import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-distribution-agreement-details',
  templateUrl: './distribution-agreement-details.component.html',
  styleUrls: ['./distribution-agreement-details.component.scss']
})
export class DistributionAgreementDetailsComponent implements OnInit {
  distText: string;

  constructor() {}

  ngOnInit() {
    this.distText = 'LeiaLoft Developer Distribution Agreement\n\n' +

    'The Leia Developer Distribution Agreement (​ “Agreement” ​) is an agreement ' +
    'between you and Leia, Inc. (​ "Leia", “we” or “us” ​) regarding the terms ' +
    'that govern your role as an authorized ​ Developer (“you”) and your use of ' +
    'the​ Site ​to distribute ​ Products ​ in accordance with this Agreement. By ' +
    'signing up to be a Developer and in order to use the Site to distribute ' +
    'Products, you agree to be bound by the terms of this Agreement. You may ' +
    'not distribute Products on the Site if you do not accept this Agreement.\n\n' +
    
    '1. Definitions Brand Features ​: The trade names, trademarks, service marks, ' +
    'logos, domain names, and other distinctive brand features of a party, as ' + 
    'owned (or licensed) by such party from time to time. Developer Brand ' + 
    'Features shall include all Brand Features relating to a Product, including ' +
    'characters and game-play videos.\n\n' +
    
    'Developer ​: Any person or company who provides Products for distribution ' +
    'through the Site in accordance with the terms of this Agreement.\n\n' +
    
    'Developer Account ​: A publishing account issued to a Developer in ' + 
    'connection with the distribution of Products via the Site.\n\n' +
    
    'Intellectual Property Rights ​: All patent rights, copyrights, trademark ' + 
    'rights, rights in trade secrets, database rights, moral rights, and any ' + 
    'other intellectual property rights (registered or unregistered) ' + 
    'throughout the world.\n\n' +
    
    'Payment Processors ​: The entity(ies) authorized by Leia to provide ' + 
    'services that enable Developers to be paid for Products.\n\n' +
    
    'Products ​: Software, content, digital materials, and other items, ' + 
    'including materials made available for in-app purchase, as made available ' +
    'by Developers via the Site under the terms of this Agreement.\n\n' +
    
    'Site ​: The software and on-line tools provided by Leia to Developers ' + 
    'through the Leia Developer website or any other location designated by ' +  
    'Leia, which allow Developers to distribute Products to users of supported devices.\n\n' +
    
    '2. Relationship With Leia\n\n' +
    
    'You understand and agree that by registering with Leia to become a ' + 
    'Developer, no legal partnership or agency relationship is created between ' + 
    'you and Leia, and you agree not to represent otherwise. By agreeing to be ' + 
    'bound by this Agreement, you represent and warrant that you have full ' +
    'legal authority to bind yourself to this Agreement. You also certify that ' +
    'you are at least eighteen years of age, or the age of majority in the ' +
    'country in which you reside, and you are legally permitted to register as ' +
    'a Developer. This Agreement is void where prohibited by law and the right ' + 
    'to register as a Developer is not granted in such jurisdictions.\n\n' +​
    
    '3. Developer Account\n\n' +
    
    'In order to distribute Product on the Site, you must register and maintain a valid Developer Account. You are responsible for maintaining the confidentiality of your Leia ID and password and for any activity in connection with your account. The Leia ID and password you use to log into your Developer account cannot be shared in any way or with anyone. Unless otherwise agreed or permitted by Leia in writing, you cannot share or transfer any benefits, rights or permissions you receive from Leia in connection with being a Developer. ' +
    'If you want to charge a fee for your Product, you must also acquire and maintain a valid payment account from an authorized Payment Processor.\n\n' +
    
    '4. Use of Site to Distribute Product\n\n' +
    
    'Subject to your compliance with the terms of this Agreement and the Leia Privacy Policy ' +
    'http://www.leialoft.com/us/home/privacy​ and Terms of Use ​http://www.leialoft.com/us/terms/​, both of which are incorporated into this Agreement, Developers may distribute Product on the Site. Except for the license rights granted by Developer to Leia in this Agreement or in connection with a Leia Developer Toolkit License, Leia agrees that it obtains no right, title or interest from you or your licensors under this Agreement to any Product, including any intellectual property rights in those Products. ' +
    'You may not use the Site to distribute Product unless you are verified as a Developer in good standing.\n\n' +
    
    'This Agreement will automatically terminate if you are\n\n' +
    '(a) not a Leia Developer in good standing, or\n\n' +
    '(b) a person or entity barred from using software under the laws of the United States or other countries including the country in which you reside.\n\n' + 
    
    'You agree to use the Site only for purposes that are permitted by\n\n' +
    '(a) this Agreement and\n\n' +
    '(b) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions (including any laws regarding the export of data or software to and from the United States or other relevant countries).\n\n' +
    
    'You will not engage in any activity with the Site, including the development or distribution of Product, that interferes with, disrupts, damages, or accesses in an unauthorized manner the devices, servers, networks, or other properties or services of Leia or any third party. You may not use customer information obtained from the Site to sell or distribute Product outside of the Site. ' +
    'You are solely responsible for (and that Leia has no responsibility to you or to any third party for) any Product you distribute through the Site and for the consequences of your actions (including any loss or damage which Leia may suffer) by doing so. These consequences include, but are not limited to, product liability, consumer protection, and/or intellectual property claims relating to your Product. ' +
    'You will be responsible for uploading your Product to the Site, providing required Product information and support to users, and accurately disclosing the security permissions necessary for the Product to function on user devices. You are solely responsible for providing support for your Product. Leia will instruct buyers to contact the Developer concerning any defects or performance issues in applications accessed, downloaded or installed from the Site. You will be solely responsible for, and Leia will have no responsibility to undertake or handle support and maintenance of your Product or any complaints about your Product. You must supply and maintain valid and accurate contact information that will be displayed in each application detail page on the Site and made available to users for customer support and legal purposes. Product that is not uploaded in accordance with this clause will not be published.\n\n' +
    
    'Any Product you distribute on the Site must not\n\n' +
    "(a) be unlawful, tortious, abusive, intrusive on another's privacy, harassing, libelous, defamatory, embarrassing, obscene, threatening, or hateful;\n\n" +
    '(b) violate, infringe, or otherwise violate any Intellectual Property Rights (as well as rights of publicity and privacy) of a third party; or \n\n' +
    '(c) contain viruses or any other computer code, files, or programs which might interrupt, limit, or interfere with the functionality of any computer software or hardware or telecommunications equipment.\n\n' +
    
    'You represent and warrant that you own all Intellectual Property Rights in and to your Product. If you use third-party materials, you represent and warrant that you have the right to distribute the third-party material in the Product. You agree that you will not submit any Product to Leia or the Site that is copyrighted, protected by trade secret or otherwise subject to third party proprietary rights, including patent, privacy and publicity rights, unless you are the owner of such rights or have permission from their rightful owner. ' +
    "Upon using the Site to distribute Product, you agree to protect the privacy and legal rights of users. If users provide you with, or your Product accesses or uses, user names, passwords, or other login information or personal information, you must make the users aware that the information will be available to your Product, and you must provide legally adequate privacy notice and protection for those users. Further, your Product may only use that information for the limited purposes for which the user has given you permission to do so. If your Product stores personal or sensitive information provided by users, it must do so securely and only for as long as it is needed. But if the user has opted into a separate agreement with you that allows you or your Product to store or use personal or sensitive information directly related to your Product (not including other products or applications) then the terms of that separate agreement will govern your use of such information. If the user provides your Product with information related to such user’s account with Leia, your Product may only use that information to access the user's Leia account when, and for the limited purposes for which, the user has given you express permission to do so.\n\n" +
    
    '5. License Grant\n\n' +
    
    'You grant to Leia a nonexclusive, worldwide, and royalty-free license to: reproduce, perform, display, analyze, use and market Products in connection with\n\n' +
    '(i) the operation and marketing of the Site;\n\n' +
    '(ii) the marketing of devices and services that support the use of the Product,\n\n' +
    '(iii) making improvements to the Site, and\n\n' +
    '(iv) auditing compliance with this Agreement.\n\n' +
    
    'You grant to Leia a nonexclusive, worldwide, and royalty-free license to\n\n' +
    '(i) distribute Products on the Site and\n\n' +
    '(ii) to use the data provided in Performance Reports (defined below) in connection with the operation, maintenance and improvement of the Site.\n\n' +
    
    "You grant to Leia a limited, non-exclusive, royalty-free license during the term of this Agreement to display Developer’s Brand Features for marketing purposes, including online and on mobile devices, in connection with the distribution and sale of the Product, the marketing of promotions in connection with the Product or the Site, or to otherwise fulfill Leia’s obligations under this Agreement. Nothing in this Agreement gives the Developer a right to use any of Leia's Brand Features. If the Developer discontinues the distribution of specific Product on the Site, Leia will cease use of the discontinued Product's Brand Features, except as necessary to effectuate reinstalls by users.\n\n" +
    
    'You grant to the end-user a non-exclusive, worldwide, and perpetual license to perform, display, and use the Product on the Site or supported device. If you choose, you may include a separate end user license agreement (EULA) in your Product that will govern the user’s rights to the Product, but, to the extent that EULA conflicts with this Agreement, this Agreement shall supersede the EULA.\n\n' +
    
    'You agree that users are allowed unlimited reinstalls of each previously installed Product, provided however that if you remove such Product(s) from the Site, such Product(s) shall be removed from all portions of the Site and users shall no longer have a right or ability to reinstall the affected Product. ' +
    'After termination of this Agreement, Leia will not distribute your Product, but may retain copies of the Product for archival purposes, use such copies to effectuate reinstalls by users, and to maintain the Site.\n\n' +
    
    '6. Product Takedowns\n\n' +
    
    "You may remove your Product from the Site at any time, but you must comply with this Agreement and the Payment Processor's payment account terms of service for any Product distributed through the Site, including but not limited to refund requirements. Removing your Product from the Site does not\n\n" +
    '(a) affect the license rights of users who have previously purchased or downloaded your Product,\n\n' +
    '(b) remove your Product from devices or from any part of the Site where previously purchased or downloaded applications are stored on behalf of users, or\n\n' +
    '(c) change your obligation to deliver or support for Product or services that have been previously purchased or downloaded by users.\n\n' +
    
    'Notwithstanding the foregoing, you must provide Leia with written notice immediately upon removing such Product if such removal was due to\n\n' +
    '(i) an allegation of infringement, or actual infringement, of any Intellectual Property Rights of any person,\n\n' +
    '(ii) an allegation of defamation or actual defamation,\n\n' +
    "(iii) an allegation of violation, or actual violation, of any third party's right of publicity or privacy, or\n\n" +
    "(iv) an allegation or determination that such Product does not comply with applicable law.\n\n" +
    
    'Leia does not monitor the Product or its content, but if Leia is notified by you or otherwise becomes aware and determines in its sole discretion that the Product or any portion of Developer’s​ ​Brand Features\n\n' +
    '(a) violates the Intellectual Property Rights or any other rights of any third party;\n\n' +
    '(b) violates any applicable law or is subject to an injunction;\n\n' +
    "(c) is pornographic, obscene or otherwise violates Leia's Terms of Use http://www.leialoft.com/us/terms/​;\n\n" +
    "(d) may have an adverse impact on Leia's or a third party’s servers or network;\n\n" +
    "(e) violates the terms of this Agreement; or\n\n" +
    "(f) or may otherwise subject Leia to liability or harm, " +
    "then Leia may remove the Product from the Site at its sole discretion at any time without providing notice to you. Leia reserves the right to suspend and/or bar any Developer from the Site at its sole discretion. If your Product contains elements that could cause serious harm to user devices or data, Leia may at its discretion disable the Product or remove it from devices on which it has been installed.\n\n" +
    
    '7. Pricing and Payments\n\n' +
    
    'This Agreement covers Product you choose to distribute for free and Product for which you charge a fee. In order to charge a fee for your Product, you must have a valid payment account under a separate agreement with a Payment Processor. If there is a conflict between your Payment Processor agreement and this Agreement, the terms of this Agreement will apply. ' +
    'Product are displayed to users on your behalf, at prices you establish in your sole discretion. Leia may include applicable taxes in the price charged to users who Purchase Product on the Site. You may set the price for your Product in the currencies permitted by the Payment Processor. Leia may display the price of Product to users in their native currency, but it is not responsible for the accuracy of currency rates or currency conversion.\n\n' +
    
    'The price you set for Product will determine the amount of payment you will receive. Leia shall charge a Transaction Fee, defined below, on the sales price of any Product, including in-app purchases made within Products. Leia shall be the merchant of record for Product you sell on the Site, and shall withhold any taxes ("​ Withholding Taxes ​") on payments made or received by it and deduct an amount equal to such Withholding Taxes from the sales price. Withholding Taxes include, but are not limited to, withholding tax obligations on cross-border payments or imposed by telecommunications taxes. The remainder (sales price less Transaction Fee, and less the amount equal to any Withholding Taxes) will be remitted to you.\n\n' +
    
    'The Transaction Fee is equivalent to 30% of the sales price net of any carrier costs. Developer shall receive 70% of the payment received by Leia (net of any carrier costs) and the remaining 30% shall go to Leia. You are responsible for providing any applicable tax residency certificates to Leia. If Leia or its service provider does not receive such documentation, Leia will withhold at the domestic withholding tax rate.\n\n' +
    
    'You may also choose to distribute Product free of charge. If the Product is free, you will not be charged a Transaction Fee. You may not start charging a user for the Product that was initially free unless the charge correlates with an alternative version of the Product. ' +
    'You authorize Leia to give the user a full refund of the price of the Product on your behalf if the user requests the refund at any time after purchase in accordance with Leia’s refund policies, located here http://www.leialoft.com/us/terms/​. In all other respects, the Payment Processor’s standard terms and conditions regarding refunds will apply. User refunds may be exclusive of taxes previously charged to users for Product purchases. ' +
    'LeiaLoft currently supports automatic bank payouts to Developers located in the following countries/regions:\n\n' +
    
    '● Australia\n\n' +
    '● Austria\n\n' +
    '● Belgium\n\n' +
    '● Canada\n\n' +
    '● France\n\n' +
    '● Germany\n\n' +
    '● Spain\n\n' +
    '● Sweden\n\n' +
    '● Switzerland\n\n' +
    '● United Kingdom\n\n' +
    '● United States\n\n' +
    
    'If you are located in, or need to receive funds in a country or region not listed above, payouts may need to be made by international wire transfer or other non-direct means agreed to by you and Leia, Inc. and which may require additional information collected from the Developer to initiate. Payouts to unsupported countries will be subject to a monthly revenue threshold of at least $5000 USD of total gross revenue from a single Developer. If gross Developer revenue is less than $5000 USD, then payouts will be made on a quarterly schedule based on the calendar year.\n\n' +
    
    '8. Export Controls\n\n' +
    
    'Product distributed on the Site may be subject to United States export laws and regulations. You must comply with all domestic and international export laws and regulations that apply to your distribution or use of Product. These laws include restrictions on destinations, users and end use.\n\n' +
    
    '9. Privacy\n\n' +
    
    'Leia may collect certain usage statistics from the Site and devices, including but not limited to, information on how the Site and devices are being used. The data collected is examined in the aggregate to improve the Site for users and Developers and is maintained in accordance with Leia’s Privacy Policy ' +
    'http://www.leialoft.com/us/home/privacy​.\n\n' +
    
    '10. Amendments\n\n' +
    
    'Leia reserves the right, at its discretion, to modify this Agreement, including any rules and policies, at any time. You will be responsible for reviewing and becoming familiar with any such modifications (including new terms, updates, revisions, supplements, modifications, and additional rules, policies, terms and conditions) (“​ Additional Terms ​”) communicated to you by Leia. All Additional Terms are hereby incorporated into this Agreement by this reference and your continued use of the Site will indicate your acceptance of any Additional Terms. If you do not agree to the Additional Terms, you may terminate your use of the Site, which will be your sole and exclusive remedy.\n\n' +
    
    '11. Term and Termination\n\n' +
    
    'Leia may terminate or suspend this Agreement at any time and in Leia’s sole discretion if:\n\n' +
    'a) you have breached any provision of this Agreement;\n\n' +
    'b) Leia is required to do so by law;\n\n' +
    'c) you stop being an authorized Developer; or\n\n' + 
    'd) Leia decides to no longer provide the Site. If Leia terminates you as an ' +
    'authorized Developer, Leia reserves the right to deny your re-application at any time in Leia’s sole discretion.\n\n' +
    
    'You may terminate your participation as a registered Developer at any time, for any reason, by notifying Leia by removing all of your Products from the Site and ceasing all use of the Site and any relevant Developer credentials. ' +
    'Upon any termination or, at Leia’s discretion, suspension, all rights and licenses granted to you by Leia will cease, including your right to access the Site. No refund or partial refund of any fees paid hereunder or any other fees will be made for any reason. Following termination of this Agreement, Sections 5-9, 12-17 shall continue to bind the parties.\n\n' +
    
    '12. Leia Independent Development\n\n' +
    
    'Nothing in this Agreement will limit Leia’s right to develop, acquire, license, market, promote or distribute products, software or technologies that perform the same or similar functions as, or otherwise compete with, any other products, software or technologies that you may develop, produce, market, or distribute, including the Products. In the absence of a separate written agreement to the contrary, Leia will be free to use any information, suggestions or recommendations you provide to Leia pursuant to this Agreement for any purpose, subject to any applicable patents or copyrights.\n\n' +
    
    '13. No Warranty\n\n' +
    
    'YOU UNDERSTAND AND EXPRESSLY AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK AND THAT THE SITE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND. YOUR USE OF THE SITE AND ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH USE. LEIA FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.\n\n' +
    
    '14. Limitation of Liability\n\n' +
    
    'TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU UNDERSTAND AND EXPRESSLY AGREE THAT LEIA, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS WILL NOT BE LIABLE TO YOU UNDER ANY THEORY OF LIABILITY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES THAT MAY BE INCURRED BY YOU, INCLUDING ANY LOSS OF DATA, WHETHER OR NOT LEIA OR ITS REPRESENTATIVES HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING. IN NO EVENT SHALL LEIA’S TOTAL LIABILITY TO YOU UNDER THIS AGREEMENT FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT COLLECTED BY LEIA IN TRANSACTION FEES IN THE PRECEDING TWELVE MONTHS.\n\n' +
    
    '15. Indemnification\n\n' +
    
    'To the maximum extent permitted by law, you agree to defend, indemnify, and hold harmless Leia, its affiliates, and their respective directors, officers, employees and agents from and against any and all third party claims, actions, suits, or proceedings, as well as any and all losses, liabilities, damages, costs, and expenses (including reasonable attorneys’ fees) arising out of or accruing from (a) Developer’s use of the ' +
    
    'Site in violation of this Agreement; (b) infringement or violation by your Product(s) of any Intellectual Property Right or any other right of any person; or (c) you or your Product(s)’ violation of any law.\n\n' +
    
    '16. Governing Law\n\n' +
    
    'All claims arising out of or relating to this Agreement will be governed by the laws of the State of California, excluding California’s conflict of laws provisions. Both parties agree to submit to the exclusive jurisdiction of the federal or state courts located within the county of Santa Clara, California to resolve any legal matter arising from or relating to this Agreement, except that you agree that Leia will be allowed to apply for injunctive relief in any jurisdiction.\n\n' +
    
    '17. Miscellaneous\n\n' +
    
    'No delay or failure to take action under this Agreement will constitute a waiver unless expressly waived in writing, signed by a duly authorized representative of Leia, and no single waiver will constitute a continuing or subsequent waiver. This Agreement will bind your successors but may not be assigned, in whole or part, by you without the written approval of an authorized representative of Leia. Any non-conforming assignment shall be null and void. If any provision is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and enforceable. This Agreement constitutes the entire agreement between the parties with respect to its subject matter and supersedes all prior or contemporaneous understandings regarding such subject matter. No addition to or removal or modification of any of the provisions of this Agreement will be binding upon Leia unless made in writing and signed by an authorized representative of Leia. The English language version of this Agreement shall control, and translations, if any, are non-binding and for reference only.\n\n' +
    
    'Last Updated: July 22, 2019\n\n' +
    
    'Section(s) Updated: Section 7: Pricing and Payments'
  }
}
