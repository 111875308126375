import { ChangeDetectorRef, Component, forwardRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NgModel, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { UtilService } from '../../../services/util.service';
import { MaterialInputComponent } from '../material-input/material-input.component';
import { ModalComponent } from '../modal/modal.component';

export interface ChangePasswordModalData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const CHANGE_PASSWORD_MODAL_VALUE_ACCESOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ChangePasswordModalComponent),
  multi: true
};

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
  providers: [CHANGE_PASSWORD_MODAL_VALUE_ACCESOR]
})
export class ChangePasswordModalComponent implements ControlValueAccessor {
  set oldPasswordIncorrect(errors: ValidationErrors) {
    if (!!errors && !!this.oldPasswordInput) {
      this.oldPasswordInput.control.setErrors(errors);
    }
  }

  get oldPasswordIncorrect() {
    return this._oldPasswordIncorrect;
  }

  constructor(
    protected svcUtil: UtilService,
    protected cdr: ChangeDetectorRef,
    protected userService: UserService
  ) {}

  get value() {
    return this._value;
  }

  set value(value: ChangePasswordModalData) {
    this._value = value;
    this.notifyValueChange(value);
  }
  private _value: ChangePasswordModalData = {
    confirmPassword: '',
    newPassword: '',
    oldPassword: ''
  };
  @ViewChildren(MaterialInputComponent)
  inputs: QueryList<MaterialInputComponent>;
  @ViewChild(ModalComponent)
  childModal: ModalComponent;
  @ViewChild('oldPassword')
  oldPasswordInput: NgModel;
  _oldPasswordIncorrect: ValidationErrors;
  errorText = `Required. Minimum six characters,
   at least one uppercase letter, one lowercase letter,
   one number and one special character
   (!"#$%&'()*+,-./:;<=>?@[\]^_\`{|}~).
   No white space permitted`;

  onChange: (value: ChangePasswordModalData) => {};
  onTouched: () => {};

  show() {
    this.childModal.show();
  }

  clearModal() {
    this.value = {
      confirmPassword: '',
      newPassword: '',
      oldPassword: ''
    };
    this.inputs.forEach(input => input.markAsPristine());
  }

  savePassword(oldPassword: string, newPassword: string) {
    this.userService.changePassword(oldPassword, newPassword).subscribe(
      res => {
        this.clearModal();
        this.childModal.hide();
      },
      err => {
        if (err.status === 401) {
          this.oldPasswordIncorrect = {
            message: 'Your old password was incorrect. '
          };
        }
      }
    );
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: ChangePasswordModalData): void {
    if (value !== this._value && this.svcUtil.doesExist(value)) {
      this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
