﻿import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { RECAPTCHA_SETTINGS, RecaptchaModule } from 'ng-recaptcha';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { AppCommonModule } from '../common.module/app.common.module';
import { AreasOfInterestComponent } from './components/create-account/areas-of-interest/areas-of-interest.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { DistributionAgreementComponent } from './components/create-account/distribution-agreement/distribution-agreement.component';
import { EulaComponent } from './components/create-account/eula/eula.component';
import { GetStartedComponent } from './components/create-account/get-started/get-started.component';
import { TermsAndConditionsComponent } from './components/create-account/terms-and-conditions/terms-and-conditions.component';
import { ValidateEmailComponent } from './components/create-account/validate-email/validate-email.component';
import { YourDetailsComponent } from './components/create-account/your-details/your-details.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/forgot-password/reset-password.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginComponent } from './components/login/login.component';
import { OauthLoginComponent } from './components/oauth-login/oauth-login.component';
import { MatSelectModule } from '@angular/material';

@NgModule({
  declarations: [
    CreateAccountComponent,
    GetStartedComponent,
    ValidateEmailComponent,
    YourDetailsComponent,
    AreasOfInterestComponent,
    TermsAndConditionsComponent,
    EulaComponent,
    DistributionAgreementComponent,
    LoginComponent,
    LoginFormComponent,
    OauthLoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    AppCommonModule,
    MatSelectModule,
    RecaptchaModule.forRoot(),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }
      }
    })
  ],
  exports: [
    CreateAccountComponent,
    GetStartedComponent,
    ValidateEmailComponent,
    YourDetailsComponent,
    AreasOfInterestComponent,
    TermsAndConditionsComponent,
    EulaComponent,
    DistributionAgreementComponent,
    LoginComponent,
    LoginFormComponent,
    OauthLoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' }
    }
  ]
})
export class AppAuthModule {}
