import { AfterViewInit, ChangeDetectorRef, Component, Input, Optional, Self, ViewChild } from '@angular/core';
import { FormGroup, NgControl } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material';
import { DOB } from '@models/stripe-account';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MaterialDateInputComponent } from '../material-date-input/material-date-input.component';

@Component({
  selector: 'app-material-date-picker-input',
  templateUrl: './material-date-picker-input.component.html',
  styleUrls: ['./material-date-picker-input.component.scss']
})
export class MaterialDatePickerInputComponent implements AfterViewInit {
  @Input()
  title: string;
  @Input()
  parent: FormGroup;
  @Input()
  label: string;
  @Input()
  unixTimeStamp = false;
  @Input()
  readOnly = false;
  @ViewChild(MatDatepicker)
  picker: MatDatepicker<Moment>;
  @ViewChild(MaterialDateInputComponent)
  matDateInput: MaterialDateInputComponent;
  @Input()
  errorText: string;
  @Input()
  validateAge = false;
  @Input()
  validateTodaysDate = false;
  @Input()
  time: Moment;
  @Input()
  set required(val: boolean) {
    this._required = val;
    if (!!val) {
      this.errorText += ' Required. ';
    }
  }
  get required() {
    return this._required;
  }
  private _required = true;

  get thisControl() {
    return this.parent.get('value');
  }

  set value(value: DOB | string) {
    this._value = value;
    this.notifyValueChange(value);
  }
  get value() {
    return this._value;
  }
  private _value: DOB | string;

  private _viewModel;
  set viewModel(val: number) {
    if (!!val) {
      this._viewModel = this.toViewObj(val);
    }
  }
  get viewModel() {
    return this._viewModel;
  }

  constructor(@Optional() @Self() public ngControl: NgControl, private cdr: ChangeDetectorRef) {
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  onChange: (value) => {};
  onTouched: () => {};

  resetPicker() {
    this.parent.get('value').reset();
  }

  toDateObj($event: MatDatepickerInputEvent<Moment>) {
    const { value } = $event;

    if (!!value) {
      let month;
      let day;
      let year;

      if (!!this.unixTimeStamp) {
        this.value = value.format('X');
      } else {
        month = value.format('M');
        day = value.format('D');
        year = value.format('YYYY');

        this.value = {
          day,
          month,
          year
        };
      }

      if (this.validateAge) {
        if (this.isYoungerThan18(value)) {
          // < 18
          this.thisControl.setErrors({ ageInvalid: true });
        }
      }
      if (this.validateTodaysDate) {
        if (!this.isTodaysDate(value)) {
          // !todays date
          this.thisControl.setErrors({ dateInvalid: true });
        }
      }
    }
  }

  isYoungerThan18(val: Moment): boolean {
    const yearsAgo = moment()
      .clone()
      .subtract(18, 'year');

    return val > yearsAgo; // Selected date is greater than 13 years ago.
  }

  isTodaysDate(val: Moment): boolean {
    const now = this.time;
    if (!!now) {
      return (
        now
          .clone()
          .subtract(1, 'day')
          .unix() < val.clone().unix() &&
        val.clone().unix() <
          now
            .clone()
            .endOf('day')
            .unix()
      );
    }

    return null;
  }

  toViewObj(value: DOB | number) {
    if (!!value) {
      if (typeof value === 'number') {
        return moment.unix(value);
      }
      if ('day' in value && 'month' in value && 'year' in value) {
        const strVal = `${value.year}-${value.month}-${value.day}`;
        return moment(strVal, 'YYYY-MM-DD');
      }
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    if (value !== this._value) {
      this._value = value;
      this.picker.select(this.toViewObj(value));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
