import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Output() pageChanged: EventEmitter<any> = new EventEmitter();
  @Input() totalLength: Number = 0;
  @Input() itemsPerPage: Number = 0;

  constructor() {}

  ngOnInit() {}
}
