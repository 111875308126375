import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioButtonOption } from '../radio-button-set/radio-button-set.component';

@Component({
  selector: 'app-radio-button-option',
  templateUrl: './radio-button-option.component.html',
  styleUrls: ['./radio-button-option.component.scss']
})
export class RadioButtonOptionComponent {
  @Input()
  option: RadioButtonOption;
  @Input()
  isWhite = false;
  @Output()
  onRadioOptionClick = new EventEmitter<RadioButtonOption>();

  get imgSrc() {
    return this.option.selected
      ? `/assets/img/radio-selected-${this.isWhite ? 'white' : 'black'}.svg`
      : `/assets/img/radio-unselected-${this.isWhite ? 'white' : 'black'}.svg`;
  }
}
