import { AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, } from '@angular/core';
import { NgForm, NgModel, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { dialogButtons, dprmConfirmLeavePageDialog, MessageDialogServiceImpl, MessageDialogServiceToken, } from '@common/components/confimation-modal/dialog.service';
import { INavigationGuardComponent } from '@common/components/navigation-guard/navigation-guard.component';
import { ComponentCommService } from '@services/component-comm.service';
import { MaterialTextareaComponent } from 'app/common.module/components/material-textarea/material-textarea.component';
import { ToggleSelectorComponent } from 'app/common.module/components/toggle-selector/toggle-selector.component';
import { ErrorProviderNode } from 'app/models/error-provider.model';
import { UtilService } from 'app/services/util.service';
import * as _ from "lodash";
import { Observable, Subject } from 'rxjs';
import { map, pluck, takeUntil, } from 'rxjs/operators';
import { AppDetailsResolver } from '../../../../../guards/app-details.resolver';
import { App, AppDetailsChanges, AppDetailsItem } from '../../../../../models/app';
import { AppService } from '../../../../../services/app.service';
import { UserService } from '../../../../../services/user.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [AsyncPipe]
})
export class AppDetailsComponent
implements
  OnInit,
  ErrorProviderNode<ToggleSelectorComponent>,
  INavigationGuardComponent,
  OnDestroy,
  AfterViewInit {
  @ViewChildren(ToggleSelectorComponent)
  children: QueryList<ToggleSelectorComponent>;
  @ViewChild('titleText', { read: NgModel })
  titleTextModel: NgModel;
  @ViewChild('copyrightText', { read: NgModel })
  copyrightTextModel: NgModel;
  @ViewChild(MaterialTextareaComponent, { read: NgModel })
  matTextArea: NgModel;
  @ViewChild('form') ngForm: NgForm;

  @Input()
  readOnly = false;

  app$: Observable<App> = this.route.parent.data.pipe(pluck('appDetails'));
  app: App; // = this.pipeAsync.transform(this.app$);

  appChanges$: Observable<AppDetailsChanges[]> = this.route.parent.data.pipe(pluck('appChanges'));
  appChanges: AppDetailsChanges[];

  language: string;
  genres: any;
  languages: any;

  detail: AppDetailsItem = {};

  isDirty = false;
  onDestroy$: Subject<boolean> = new Subject();
  lastChange: any = null;
  startValueCheck = false;
  appAssigned = false;
  saveClicked = false;
  isAdmin = false;

  private _navigatingAway = false;
  set navigatingAway(value) {
    this._navigatingAway = value;
  }
  get viewInitialized() {
    return (
      !!this.children &&
      this.children.length > 0 &&
      !!this.titleTextModel &&
      !!this.copyrightTextModel &&
      !!this.matTextArea
    );
  }

  get titleError() {
    return (
      (this._navigatingAway && !this.titleTextModel.dirty && !this.detail.title) ||
      (this.titleTextModel.dirty && !this.detail.title)
    );
  }
  get copyrightTextError() {
    return (
      (this._navigatingAway && !this.copyrightTextModel.dirty && !this.app.copyright) ||
      (this.copyrightTextModel.dirty && !this.app.copyright)
    );
  }
  get descriptionError() {
    return (
      (this._navigatingAway && !this.matTextArea.dirty && !this.detail.description) ||
      (this.matTextArea.dirty && !this.detail.description)
    );
  }
  get childSelected() {
    return this.children.map(child => child.checked).some(checked => checked === true);
  }
  get childTouched() {
    return this.children.map(child => child.touched).some(touched => touched === true);
  }
  get genreError() {
    if (!!this.viewInitialized) {
      return (this._navigatingAway && !this.childSelected) || (this.childTouched && !this.childSelected);
    }
    return false;
  }

  get error() {
    if (!!this.viewInitialized) {
      const err = this.titleError || this.copyrightTextError || this.descriptionError || this.genreError;
      return err;
    }
    return false;
  }

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    protected svcResolver: AppDetailsResolver,
    protected cdr: ChangeDetectorRef,
    protected pipeAsync: AsyncPipe,
    private svcUtil: UtilService,
    private router: Router,
    private userService: UserService,
    private commService: ComponentCommService,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {
    this.isAdmin = userService.isAdmin();
  }
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event) {
    if (this.isDirty) {
      event.returnValue = "You have unsaved changes. Are you sure you want to close the page?";
      return "You have unsaved changes. Are you sure you want to close the page?"
    }
    return undefined;
  }


  ngDoCheck() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.isDirty = false;
    this.ngForm.valueChanges.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe((change) => {
        if (this.startValueCheck){
          const merged = _.mergeWith(change, this.lastChange, (dstVal, srcVal) => {
          if (((!srcVal) && dstVal) || (srcVal && dstVal && srcVal != dstVal)) {
            console.log(dstVal)
          }
          if (!dstVal) {
            return srcVal;
          } else {
            return dstVal;
          }
        });
        if (this.lastChange) {
          if (!_.isEqual(merged, this.lastChange)) {
            this.isDirty = true;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.commService.get().pipe(takeUntil(this.onDestroy$)).subscribe(
      (msg) => {
        if (msg && msg.skipNavGuard) {
          this.isDirty = false;
        }
      }
    );

    const { readOnly, lists } = this.route.snapshot.data;

    this.genres = lists.genre;
    this.languages = lists.language;

    this.app$.pipe(takeUntil(this.onDestroy$)).subscribe((app: App) => {
      this.app = app;
      this.cdr.detectChanges();
      setTimeout(()=>{ this.startValueCheck = true }, 0);
    });

    this.appChanges$.pipe(takeUntil(this.onDestroy$)).subscribe((appChanges: AppDetailsChanges[]) => {
      this.appChanges = appChanges;
    });

    // the API doesn't return default structure
    if (!this.app.genres) {
      this.app.genres = [];
    }
    this.readOnly = readOnly;
    this.lastChange = this.app;
    this.selectLanguage(null);
  }

  navigationStart() {
    this.navigatingAway = true;
    this.appService.navigateAway(true);
    this.saveClicked = true;
    this.router.navigate(["/app/edit/" + this.app.id + "/" + this.app.productVersionId + "/imagery"]);
  }

  selectLanguage(language) {
    this.startValueCheck = false;
    this.language = language || 'en_US';
    this.detail = this.app.details.find(detailLocale => {
      return detailLocale.locale === this.language;
    });
    if (!this.detail) {
      this.detail = {
        locale: this.language,
        title: '',
        description: '',
        releaseNote: ''
      };
      this.app.details.push(this.detail);

      this.cdr.detectChanges();
      setTimeout(()=>{
        if (this.detail) {
          this.lastChange.title = this.detail.title;
          this.lastChange.description = this.detail.description;
          this.lastChange.releaseNote = this.detail.releaseNote;
        }
        this.startValueCheck = true;
      });
    }
  }

  toggleGenre(genre) {
    if (!this.readOnly) {
      // Push into an array and pick out of the array if already exists?
      if (this.app.genres && this.app.genres.includes(genre)) {
        this.app.genres.splice(this.app.genres.indexOf(genre), 1);
      } else {
        this.app.genres = [];
        this.app.genres.push(genre);
      }
      this.isDirty = true;
    }
  }

  confirmNavigation() {
    if ((!this.isDirty) || this.saveClicked) {
      this.isDirty = false;
      this.saveClicked = false;
      return true;
    }
    return this.dialogService.openMessageDialog(dprmConfirmLeavePageDialog).afterClosed().pipe(
      map(res => {
        this.isDirty = !(res > 0);
        return res > 0;
      }),
    );
  }

  ngOnDestroy(){
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  checkChanges(title: string) {
    return this.appChanges ? this.appChanges.some(chg => chg.fieldName === `${title}_${this.language}`) : false;
  }
}
