import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, } from '@angular/core';
import { RegistrationStatus } from 'app/enums/registration-status.enum';
import { of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { RegisterService, RegSteps, } from '../../../../services/register.service';
import { regxPatterns } from '@apps/components/dynamic-form/dynamic-form-validator.regexp';


@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  providers: [],
  styleUrls: ['../create-account.component.scss']
})
export class GetStartedComponent implements OnInit, AfterViewInit {
  @Input()
  email: string;
  @Input()
  recaptcha: any;
  invalidEmail = false;
  saveInProgress = false;
  @ViewChild('emailCtrl') emailCtrl: ElementRef;
  emailRegX = regxPatterns.email;

  constructor(
    private registerService: RegisterService,
  ) {}

  ngAfterViewInit() {
    if (this.emailCtrl && this.emailCtrl.nativeElement) {
      this.emailCtrl.nativeElement.focus();
    }
  }

  ngOnInit() {}

  getStarted() {
    if (this.saveInProgress) {
      return;
    }
    this.saveInProgress = true;
    this.registerService.setEmail(this.email).pipe(
      flatMap((res: any)  => {
        if (res && res.status && ([RegistrationStatus.VERIFICATION_REQUIRED, RegistrationStatus.USER_INFO_REQUIRED].includes(res.status))) {
          return of(res);
        } else {
          return this.registerService.getRegStatus(this.email);
        }
      }),
    ).subscribe(res => {
      const status = res['status'];
      if (status === RegistrationStatus.VERIFICATION_REQUIRED) {
        this.registerService.changeStep(1);
      } else if (status === RegistrationStatus.USER_INFO_REQUIRED) {
        this.registerService.changeStep(2);
      } else {
        this.registerService.checkRegStep(status, RegSteps[0], this.email);
        // pop up already verified message
        this.invalidEmail = true;
      }
    },
    () => {},
    () => {
      this.saveInProgress = false;
    });
  }

  captchaResponse(event) {
    if (event) {
      // TODO: Add some conditional logic here?
    }
  }
}
