import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppStatus } from 'app/enums/app-status.enum';
import { App } from 'app/models/app';
import { Subscription } from 'rxjs';
import { AppService } from '../../../services/app.service';

export const APPS_SEARCH_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AppsSearchComponent),
  multi: true
};

@Component({
  selector: 'app-apps-search',
  templateUrl: './apps-search.component.html',
  styleUrls: ['./apps-search.component.scss'],
  providers: [APPS_SEARCH_VALUE_ACCESSOR]
})
export class AppsSearchComponent implements ControlValueAccessor, OnInit {
  get apps() {
    return this._apps;
  }

  set apps(apps: App[]) {
    if (!this._unfilteredApps) {
      this._unfilteredApps = apps;

      if (apps && this.statusFilter) {
        apps = this._unfilteredApps.filter(app => this.appStatusWhitelist.includes(app.appStatus));
        this.statusFilter = null;
      }
    }

    this._apps = apps;
    this.notifyValueChange(apps);
  }

  constructor(public appService: AppService, private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}
  private _apps: App[];
  private _unfilteredApps: App[];
  private statusFilter = null;

  onChange: (value) => {};
  onTouched: () => {};

  appStatusWhitelist: AppStatus[] = this.getAllStatuses();
  subscriptions: Subscription[] = [];
  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  ngOnInit(): void {
    this.statusFilter = this.route.snapshot.queryParams['filter'];
    if (this.statusFilter) {
      this.initFilter();
    }
  }

  writeValue(apps: App[]): void {
    if (apps !== this.apps) {
      this.apps = apps;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  shouldBeChecked(checkBox: HTMLInputElement) {
    const status = checkBox.id as AppStatus | 'all';

    if (status === 'all') {
      return this.appStatusWhitelist.length === Object.keys(AppStatus).length;
    }

    return this.appStatusWhitelist.includes(checkBox.id as AppStatus);
  }

  getAllStatuses(): AppStatus[] {
    return Object.keys(AppStatus) as AppStatus[];
  }

  updateWhitelist(checkBox: HTMLInputElement) {
    const status = checkBox.id as AppStatus | 'all';
    if (checkBox.checked) {
      if (status === 'all') {
        this.appStatusWhitelist.push(...this.getAllStatuses());
        this.filterApps();
        return;
      }

      if (!this.appStatusWhitelist.includes(status)) {
        this.appStatusWhitelist.push(status);
      }
    } else {
      if (status === 'all') {
        this.appStatusWhitelist = [];
        this.filterApps();
        return;
      }

      if (this.appStatusWhitelist.includes(status)) {
        this.appStatusWhitelist.splice(this.appStatusWhitelist.indexOf(status), 1);
      }
    }

    this.filterApps();
  }

  filterApps() {
    if (this._unfilteredApps) {
      const appStatusWhitelist = this.appStatusWhitelist;
      this.apps = this._unfilteredApps.filter(app => appStatusWhitelist.includes(app.appStatus));
    }
  }

  initFilter() {
    this.appStatusWhitelist = this.appStatusWhitelist.filter(v => v === this.statusFilter);
  }

  onSearch(ev: KeyboardEvent, query: string) {
    // On backspace
    if (ev.keyCode === 8) {
      this.apps = this._unfilteredApps;
      this.queryApps(query);
    }

    this.queryApps(query);
  }

  queryApps(query: string) {
    this.apps = this._unfilteredApps.filter(app => {
      const regex = /[^\w]+/g;
      const title = app.title as string;
      const normalizedTitle = title.toLowerCase().replace(regex, '-');
      const normalizedQuery = query.toLowerCase().replace(regex, '-');
      return normalizedTitle.includes(normalizedQuery);
    });
  }
}
