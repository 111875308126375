import { Component, forwardRef, Input, OnInit, ViewChild, ElementRef, Renderer2, ViewEncapsulation, Output, EventEmitter, AfterViewInit, HostListener, Injector, HostBinding, } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, AbstractControl, NgControl, } from '@angular/forms';
import { MatSelect } from '@angular/material';
import * as lpn from 'libphonenumber-js';
import { PhoneInputCountry, PhoneInputCountryCodes } from './model/phone-input-countries.model';
import { phoneNumberValidator, ParseErrorCodes } from './phone-input.validator';

export const colorError = "#FE423F";
export const colorWarning = "#FFD42A";
export const colorDefault = "rgba(255, 255, 255, 0.7)";

export const defaultPhoneInputErrorMessage = "Invalid phone number.";
export const defaultPhoneInputRequiredMessage = "'Phone' is required.";
export interface IPhoneInputError {
	isValid: boolean;
	message?: string;
}

export interface IPhoneNumber {
	countryCode?: string;
	internationalNumber?: string;
	nationalNumber?: string;
	number: string;
}

export enum phoneInputState {
	valid = 1,
	warning = -1,
	error = -2,
}

@Component({
	selector: 'app-phone-input',
	templateUrl: './phone-input.component.html',
	styleUrls: ['./phone-input.component.scss'],
	providers: [
		PhoneInputCountryCodes,
		{
			provide: NG_VALUE_ACCESSOR,
			// tslint:disable-next-line:no-forward-ref
			useExisting: forwardRef(() => PhoneInputComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			// useValue: phoneNumberValidator,
			useExisting: forwardRef(() => PhoneInputComponent),
			multi: true,
		}
	],
	encapsulation: ViewEncapsulation.None,
})
export class PhoneInputComponent implements AfterViewInit, OnInit, ControlValueAccessor, Validator {

	@Input() value = '';
	// @Input() preferredCountries: Array<string> = [];
	@Input() preferredCountries: string[] = [];
	@Input() enablePlaceholder = true;
	@Input() cssClass = 'form-control';
	// @Input() onlyCountries: Array<string> = [];
	@Input('countryList')
	get countryList(): string[] {
		return this.onlyCountries;
	}
	set countryList(ctryList: string[]) {
		this.onlyCountries = ctryList || [];
		this.fetchCountryData();
		if (this.allCountries && this.allCountries.length) {
			this.onCountrySelect(this.allCountries[0]);
		}
	}
	@Input() enableAutoCountrySelect = true;
	@Input() parseInput = true;
	@Input() placeholderText: string;
	@Input() forceInternational: false;
	@Input() strictValidation: false;
	@Input() showErrors: true;
  @Input() showErrorIcon: true;
	@Input() label = "Phone Number";
	@Input() infoText = "Enter a phone number";
	@Input() useMaterialInput = false;
	@Input() prefillCountryCode = false;
	@Output() validationWarning: EventEmitter<IPhoneInputError> = new EventEmitter();

	onlyCountries: string[] = [];
	phoneNumber = '';
	allCountries: PhoneInputCountry[] = [];
	preferredCountriesInDropDown: PhoneInputCountry[] = [];
	selectedCountry: PhoneInputCountry;
	phoneUtil = lpn;
	disabled = false;
	phoneParseError: IPhoneInputError;
	defaultErrorMessage = defaultPhoneInputErrorMessage;
	ctrlTouched = false;
	ctrlStates = phoneInputState;
	formControl: FormControl;

	@ViewChild("matSelectCountry") matSelectCountry: MatSelect;
	@ViewChild("focusable") ctrlInput: ElementRef;

	@HostBinding('tabindex') tabindex = 0;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setMatSelectPanelWidth();
	}
	@HostListener('focus')
	focusHandler() {
		this.focus();
	}

	onTouched = () => { };
	propagateChange = (_: any) => { };

	constructor(
		private countryCodeData: PhoneInputCountryCodes,
		private renderer: Renderer2,
		private injector: Injector,
	) {}

	focus() {
		if (this.ctrlInput && this.ctrlInput.nativeElement) {
			(this.ctrlInput.nativeElement as HTMLElement).focus();
		}
	}

	setMatSelectPanelWidth() {
		this.matSelectCountry.overlayDir.minWidth = (this.ctrlInput.nativeElement as HTMLElement).clientWidth - 30;
		if (this.matSelectCountry.panelOpen) {
			this.matSelectCountry.close();
			this.matSelectCountry.open()
		} else {
			this.matSelectCountry.open();
			this.matSelectCountry.close()
		}
	}
	ngAfterViewInit() {
		const ngCtrl: NgControl = this.injector.get(NgControl, null);
		if (ngCtrl) {
			this.formControl = ngCtrl.control as FormControl;
		}

		setTimeout(()=>{this.setMatSelectPanelWidth();});
		this.matSelectCountry.disableOptionCentering = true;
	}

	ngOnInit() {

		this.fetchCountryData();

		if (this.preferredCountries.length) {
			this.preferredCountries.forEach(iso2 => {
				const preferredCountry = this.allCountries.filter((c) => c.iso2 === iso2);

				this.preferredCountriesInDropDown.push(preferredCountry[0]);
			});
		}
		if (this.onlyCountries.length) {
			this.allCountries = this.allCountries.filter(c => this.onlyCountries.includes(c.iso2));
		}
		if (this.preferredCountriesInDropDown.length) {
			this.selectedCountry = this.preferredCountriesInDropDown[0];
		} else {
			this.selectedCountry = this.allCountries[0];
		}
	}

	public onPhoneNumberChange(propagate = true): void {

		if (this.forceInternational) {
			if (this.phoneNumber && this.phoneNumber.length) {
					if (this.phoneNumber[0] !== "+") {
						this.phoneNumber = "+" + this.phoneNumber;
					}
			}
		}

		this.value = this.phoneNumber;
		const cc : lpn.CountryCode = (this.selectedCountry.iso2.toUpperCase() as lpn.CountryCode);
		let number: lpn.PhoneNumber;
		try {
			number = this.phoneUtil.parsePhoneNumber(this.phoneNumber, cc);
		} catch (e) {
		}

		let countryCode = this.selectedCountry.iso2;
		// auto select country based on the extension (and areaCode if needed) (e.g select Canada if number starts with +1 416)
		if (this.enableAutoCountrySelect) {
			let newCountryCode: string;
			try {
				number = this.phoneUtil.parsePhoneNumber(this.phoneNumber);
			} catch (e) {
			}
			newCountryCode = number && number.country
				? number.country
				: this.selectedCountry.iso2;
			if (newCountryCode) {
				newCountryCode = newCountryCode.toLowerCase();
			}
			if (newCountryCode && newCountryCode !== this.selectedCountry.iso2) {
				const newCountry = this.allCountries.find(c => c.iso2 === newCountryCode);
				if (newCountry) {
					this.selectedCountry = newCountry;
					countryCode = newCountryCode;
				}
			}
		}
		countryCode = countryCode ? countryCode : this.selectedCountry.iso2;

		if (!this.value) {
			// tslint:disable-next-line:no-null-keyword
			if (propagate) {
				this.propagateChange(null);
			}
		} else {
			if (propagate) {
				const pn: lpn.PhoneNumber = lpn.parsePhoneNumberFromString(this.value);
				this.propagateChange({
					number: this.value,
					internationalNumber: number ? number.formatInternational() : '',
					nationalNumber: number ? number.formatNational() : '',
					countryCode: countryCode.toUpperCase()
				});
			}
		}
	}

	clickSelectToggle() {
		if (this.matSelectCountry.panelOpen) {
			this.matSelectCountry.close();
		} else {
			if (this.allCountries && this.allCountries.length > 1) {
				this.matSelectCountry.overlayDir.minWidth = (this.ctrlInput.nativeElement as HTMLElement).clientWidth - 30;
				setTimeout(()=>{
					this.matSelectCountry.open();
					this.matSelectCountry.close();
					this.matSelectCountry.open();
					this.matSelectCountry.focus();
				});
			}
		}
	}

	public onCountrySelect(country: PhoneInputCountry, propagate: boolean = true, el?:HTMLElement): void {
		this.selectedCountry = country;

		if (this.phoneNumber.length > 0) {
			this.value = this.phoneNumber;

			let number: lpn.PhoneNumber;
			try {
				number = this.phoneUtil.parsePhoneNumberFromString(this.phoneNumber, (this.selectedCountry.iso2.toUpperCase() as lpn.CountryCode));
			} catch (e) {
			}
			if (propagate) {
				this.propagateChange({
					number: this.value,
					internationalNumber: number ? number.formatInternational() : '' ,
					nationalNumber: number ? number.formatNational() : '',
					countryCode: this.selectedCountry.iso2.toUpperCase()
				});	
			}
		} else {
			if (el) {
				this.phoneNumber = "+" + this.selectedCountry.dialCode;
				if (propagate) {
					this.propagateChange({
						number: this.phoneNumber,
						internationalNumber: '' ,
						nationalNumber: '',
						countryCode: this.selectedCountry.iso2.toUpperCase()
					});
				}
			}
		}

		if (el) {
			el.focus();
		}
	}

	public onInputKeyPress(event): void {
		const pattern = /[0-9\+\-\ ]/;
		const inputChar = String.fromCharCode(event.charCode);
		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	protected fetchCountryData(): void {

		this.allCountries = [];
		this.countryCodeData.allCountries.forEach(c => {

			if (this.onlyCountries && this.onlyCountries.length) {
				if (!this.onlyCountries.includes(c[1].toString())){
					return;
				}
			}
			const country: PhoneInputCountry = {
				name: c[0].toString(),
				iso2: c[1].toString(),
				dialCode: c[2].toString(),
				priority: +c[3] || 0,
				areaCodes: c[4] as string[] || undefined,
				flagClass: c[1].toString().toLocaleLowerCase(),
				placeHolder: ''
			};

			if (this.enablePlaceholder) {
				country.placeHolder = this.getPhoneNumberPlaceHolder(country.iso2.toUpperCase());
			}

			this.allCountries.push(country);
		});
	}

	protected getPhoneNumberPlaceHolder(countryCode: string): string {
		try {
			return this.placeholderText?this.placeholderText:'';
		} catch (e) {
			return e;
		}
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	writeValue(obj: any): void {
		if (obj) {
			if (obj.hasOwnProperty('number') || obj.hasOwnProperty('countryCode')) {
				let pnStr = obj.number;
				if (obj.countryCode) {
					const country: PhoneInputCountry = this.allCountries.find((c: PhoneInputCountry) => c.iso2.toLowerCase() === obj.countryCode.toLowerCase());
					if (country) {
						if (this.forceInternational && obj.number && obj.number.length && obj.number[0] !== "+") {
							let pn: lpn.PhoneNumber;
							pn = this.phoneUtil.parsePhoneNumberFromString(obj.number, country.iso2.toUpperCase() as lpn.CountryCode)
							if (pn) {
								pnStr = pn.formatInternational();
							}
						}
						this.onCountrySelect(country, false);
					}
				}
				this.phoneNumber = (pnStr && pnStr.length)?pnStr:obj.number;
			} else {
				if (typeof obj === "string") {
					const pn: lpn.PhoneNumber = this.phoneUtil.parsePhoneNumberFromString(obj);
					if (pn.isValid) {
						this.phoneNumber = this.forceInternational?pn.formatInternational():pn.formatNational();
					} else {
						this.phoneNumber = obj;
					}
				} else {
					this.phoneNumber = obj;
				}
			}
		} else {
			this.phoneNumber = "";
		}
		setTimeout(() => {
			this.onPhoneNumberChange(false);
		}, 1);
	}

	addCountryCode() {
		if (this.prefillCountryCode) {
			if (!this.phoneNumber) {
				if (this.selectedCountry) {
					this.phoneNumber = "+" + this.selectedCountry.dialCode;
				}
			}
		}
	}
	blurControl(){
		if (this.prefillCountryCode) {
			if (this.selectedCountry && this.phoneNumber === ("+" + this.selectedCountry.dialCode)) {
				this.phoneNumber = "";
				this.phoneParseError = null;
				if (this.validationWarning) {
					this.validationWarning.emit({
						isValid: true,
						message: null,
					});
				}
				this.propagateChange(null);
			}
		}
		if (this.parseInput) {
			try {
				if (this.phoneNumber && this.phoneNumber.length) {
					if (this.phoneNumber[0] === "+") {
						const tryParse:lpn.PhoneNumber = this.phoneUtil.parsePhoneNumberFromString(this.phoneNumber);
						if (tryParse && tryParse.isValid) {
							this.renderer.setProperty(this.ctrlInput.nativeElement, "value", tryParse.formatInternational());
						}
					} else {
						let countryCode: lpn.CountryCode;
						if (this.selectedCountry && this.selectedCountry.iso2) {
							countryCode = (this.selectedCountry.iso2.toUpperCase() as lpn.CountryCode);
						}
						const tryParse:lpn.PhoneNumber = this.phoneUtil.parsePhoneNumberFromString(this.phoneNumber, countryCode);
						if (tryParse && tryParse.isValid) {
							this.renderer.setProperty(this.ctrlInput.nativeElement, "value", tryParse.formatNational());
						}
					}
				}
			} catch (e) {}
		}
		this.ctrlTouched = true;
		if (this.onTouched) {
			this.onTouched();
		}
	}

	getErrorMessage() {
		if (this.phoneParseError && (!this.phoneParseError.isValid)) {
			return this.phoneParseError.message?this.phoneParseError.message:this.defaultErrorMessage;
		} else if (this.getControlState() === phoneInputState.error) {
      return defaultPhoneInputRequiredMessage;
    }
	}

	setWarning(error: any) {
		this.phoneParseError = {
			isValid: false,
			message: error.validatePhoneNumber.message,
		}
		if (this.validationWarning) {
			this.validationWarning.emit(this.phoneParseError);
		}
	}

	getInputBorderColor(): string {
		switch (this.getControlState()) {
			case phoneInputState.error: return colorError;
			case phoneInputState.warning: return colorWarning;
		}
		return colorDefault;
	}

	getLabelColor(): string {
		switch (this.getControlState()) {
			case phoneInputState.error: return colorError;
		}
		return colorDefault;
	}
  getIsError() {
    return (
      this.getControlState() === phoneInputState.error
    )
  }

	getControlState(): phoneInputState {
		if (this.ctrlTouched && this.formControl && this.formControl.errors) {
			return phoneInputState.error;
		}
		if (this.phoneParseError && (!this.phoneParseError.isValid)) {
			if (this.strictValidation) {
				return phoneInputState.error;
			} else {
				return phoneInputState.warning;
			}
		}
		return phoneInputState.valid;
	}

	validate(control: AbstractControl) {
		const isRequired = control.errors && control.errors.required === true;
		const error = { validatePhoneNumber: { valid: false, message: null } };
		let number: lpn.PhoneNumber;
		let warningSet = false;
		if (control.value) {
			this.ctrlTouched = true;
			this.onTouched();
		}
		if (isRequired === true && !(control.value && control.value.number && control.value.number.length)) {
			return control.errors;
		}
		try {
			if (control.value && control.value.number) {
				number = lpn.parsePhoneNumber(control.value.number, control.value.countryCode);
			} else if (typeof control.value === "string") {
				number = lpn.parsePhoneNumber(control.value);
			}
		} catch (e) {
			if (e && e.message) {
				error.validatePhoneNumber.message = ParseErrorCodes[e.message];
				this.setWarning(error);
			}
		}

		if (control.value) {
			if (!number) {
				if ((!(typeof control.value === "string")) && (!control.value.number)) {
					return;
				}
				this.setWarning(error);
				warningSet = true;
				if (this.strictValidation) {
					return error;
				}
			} else {
				if (this.forceInternational) {
					if ((!number.isValid()) || (control.value.countryCode && !lpn.isValidNumberForRegion(number.nationalNumber, control.value.countryCode))) {
						this.setWarning(error);
						warningSet = true;
						if (this.strictValidation) {
							return error;
						}
					}

				} else {
					if (!lpn.isValidNumberForRegion(number.nationalNumber, control.value.countryCode)) {
						this.setWarning(error);
						warningSet = true;
						if (this.strictValidation) {
							return error;
						}
					}
				}
			}
		}
		if (!warningSet) {
				this.phoneParseError = null;
				if (this.validationWarning) {
					this.validationWarning.emit({
						isValid: true,
						message: null,
					});
				}
		}
		return;
	}
}