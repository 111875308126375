import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oauth-login',
  templateUrl: './oauth-login.component.html',
  styleUrls: ['./oauth-login.component.scss']
})
export class OauthLoginComponent implements OnInit {
  year = new Date().getFullYear();

  constructor() {}

  ngOnInit() {}
}
