export enum VerificationStatus {
  NONE = 'none',                            // Default state (it's own state because the stripe account doesn't exist at this point)
  PENDING = 'pending',
  ENABLED = 'enabled',                      // It was the unverified state earlier
  COMPLETED = 'completed',                  // It was the verified state earlier
  VERIFIED_OVERRIDE = 'verified-override',  // Leia own state to override the verification
  RESTRICTED = 'restricted',                // It was the past-due state earlier
  RESTRICTED_SOON = 'restricted-soon',      // It was the upcoming state earlier
  REJECTED = 'rejected'
}
