export const regxPatterns = {
    alphanumeric: /^[a-zA-Z0-9]*$/,
    numeric: /^[0-9]*$/,
    alpha: /^[a-zA-Z]*$/,
    startsWithPO: /^(?![pPoO].*$).*/,
    alphanumericWS: /^[a-zA-Z0-9\s]*$/,
    alphanumericWSM: /^[a-zA-Z0-9\s-]*$/,
    numericWS: /^[0-9\s]*$/,
    numericWSM: /^[0-9\s-]*$/,
    alphaWS: /^[a-zA-Z\s]*$/,

    uAlphanumeric: /^[\\p{L}0-9]*$/,

    email: /[a-zA-Z0-9]{1,}[a-zA-Z0-9\.\-\_\+]{0,}@[a-zA-Z0-9\.\-]{2,}[.]{1}[a-zA-Z0-9]{2,}/,
}
