import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-info-container',
  templateUrl: './payment-info-container.component.html',
  styleUrls: ['./payment-info-container.component.scss']
})
export class PaymentInfoContainerComponent {
  @Input()
  title;
}
