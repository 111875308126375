import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { PublishingDetailsComponent } from '@stripe/containers/publishing-details/publishing-details.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<PublishingDetailsComponent> {
  canDeactivate(component: PublishingDetailsComponent) {
    if (!!component.form.dirty && !component.isSubmitSuccess) {
      // component.showNavigationGuardModal();
      // return false;
      return component.confirmNavigation();
    }
    return true;
  }
}
