import { regxPatterns } from "@apps/components/dynamic-form/dynamic-form-validator.regexp";
import { IDynForm } from "@apps/components/dynamic-form/dynamic-form.component";
import { stateCA } from "@models/country-code.model";
import {
    EPubDetRelationTypes,
    insertArray,
    pdfGetAddress,
    pdfGetBasicOwner,
    pdfGetCompany,
    pdfGetIndividual,
    pdfGetSSN,
    pdfGetSSNPrefx,
    pdfGetVerificationDoc,
    pdfRoutingAccount,
    pdfTOS,
} from "./pub-details-form.definition";
import { IDynFormField, IDynFormFieldType, IDynFormFieldValidatorType, IDynFormLengthValidationOptions } from "@apps/components/dynamic-form/dynamic-form.service";


const caPCCaption = "pubDetails.postCode";
const caPCLength = 6;


export const pdfCAAccountNumber: IDynFormField[] = [
    {
        id: "external_account#account_number",
        name: "pubDetails.external_account_account_number",
        infoText: "pubDetails.external_account_account_number_info",
        required: true,
        schema: {
            type: IDynFormFieldType.string,
            validators: [
                {
                    type: IDynFormFieldValidatorType.pattern,
                    pattern: regxPatterns.numericWSM,
                    errorMessage: "dynForm.errors.numericWSM",
                }
            ]
        }
    },
];

export const pdfCARoutingNumber: IDynFormField[] = [
    {
        id: "external_account#routing_number",
        name: "pubDetails.external_account_routing_number",
        required: true,
        schema: {
            type: IDynFormFieldType.string,
            validators: [
                {
                    type: IDynFormFieldValidatorType.pattern,
                    pattern: regxPatterns.numericWSM,
                    errorMessage: "dynForm.errors.numericWSM",
                },
            ]
        }
    },
];

export const DynFormDef_ca_individual: IDynForm = {
    id: "ca_individual",
    name: "Publishing Details",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.indivInfo",
            description: "pubDetails.sections.indivInfoDesc",
            fields: [...insertArray(pdfGetIndividual("pubDetails.province", stateCA, caPCCaption, caPCLength, regxPatterns.alphanumericWS), 8, pdfGetSSNPrefx("individual", "pubDetails.SIN", 9, true))],
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: [...pdfCARoutingNumber, ...pdfCAAccountNumber],
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: [...pdfTOS("compRepFullName_EU")],
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescCA",
            singleColumn: true,
            fields: [],
        },
        {
            id: "addInfoPhotoID",
            subTitle: "Photo ID",
            subDesc: "State-issued ID or Government-issued passport belonging to Company Representative",
            fields: [...pdfGetVerificationDoc("individual")],
        },
    ],
};

// ***************************************************************************************
// ************************************* Company *****************************************
// ***************************************************************************************


export const DynFormDef_ca_company: IDynForm = {
    id: "ca_company",
    name: "pubDetails.title",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.compInfo",
            description: "pubDetails.sections.compInfoDesc",
            fields: pdfGetCompany("pubDetails.province", stateCA, caPCCaption, caPCLength, regxPatterns.alphanumericWS),
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: [...pdfCARoutingNumber, ...pdfCAAccountNumber],
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: pdfTOS(),
        },
        {
            id: "addInfoComprep",
            subTitle: "pubDetails.sections.addInfoComprep",
            fields: [
              ...pdfGetBasicOwner(EPubDetRelationTypes.representative, true), 
              ...pdfGetSSN(EPubDetRelationTypes.representative, "pubDetails.SIN", 9, true),
              ...pdfGetAddress("owners#representative", "pubDetails.province", stateCA, caPCCaption, 0, regxPatterns.alphanumericWSM)
            ],
        },
        {
            id: "addInfoPhotoID",
            subTitle: "pubDetails.sections.addInfoPhotoID",
            subDesc: "pubDetails.sections.addInfoPhotoIDDesc",
            fields: [...pdfGetVerificationDoc("owners#" + EPubDetRelationTypes.representative, true)]
        },
    ]
}