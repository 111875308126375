import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SummaryItemModel } from '../../../models/summary-item.model';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  page = 1;
  itemsPerRow = 5;

  @Output() loadMoreItems = new EventEmitter<LoadMoreItems>();

  private _summaryItems: SummaryItemModel[][] = [];
  get summaryItems(): SummaryItemModel[][] {
    return this._summaryItems;
  }
  @Input()
  set summaryItems(value: SummaryItemModel[][]) {
    if (value) {
      this._summaryItems = value;
    }
  }
  constructor() {}

  loadMore() {
    this.loadMoreItems.emit({
      row: ++this.page,
      itemsPerRow: this.itemsPerRow
    });
  }

  ngOnInit() {}
}
export interface LoadMoreItems {
  row: number;
  itemsPerRow: number;
}
