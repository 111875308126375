import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppCommonModule } from 'app/common.module/app.common.module';
import { PaymentService } from 'app/services/payment.service';
import { DuplicateInfoCheckboxComponent } from '../stripe.module/components/duplicate-info-checkbox/duplicate-info-checkbox.component';
import { DistributionComponent } from './components/distribution/distribution.component';
import { HeldPaymentsComponent } from './components/held-payments/held-payments.component';
import { HoldPaymentModalComponent } from './components/hold-payment-modal/hold-payment-modal.component';
import { PayNowModalComponent } from './components/pay-now-modal/pay-now-modal.component';
import { PaymentButtonSetComponent } from './components/payment-button-set/payment-button-set.component';
import { PaymentButtonComponent } from './components/payment-button/payment-button.component';
import { PaymentRowDetailComponent } from './components/payment-row-detail/payment-row-detail.component';
import { PaymentRowComponent } from './components/payment-row/payment-row.component';
import { PaymentTableComponent } from './components/payment-table/payment-table.component';
import { PaymentComponent } from './components/payment/payment.component';
import {
  MatTooltipModule
} from '@angular/material';

@NgModule({
  imports: [CommonModule, FormsModule, AppCommonModule, MatTooltipModule],
  declarations: [
    DistributionComponent,
    HoldPaymentModalComponent,
    PayNowModalComponent,
    PaymentComponent,
    PaymentTableComponent,
    PaymentButtonComponent,
    PaymentButtonSetComponent,
    PaymentRowComponent,
    PaymentRowDetailComponent,
    HeldPaymentsComponent,
    DuplicateInfoCheckboxComponent
  ],
  providers: [PaymentService],
  exports: [
    DistributionComponent,
    HoldPaymentModalComponent,
    PayNowModalComponent,
    PaymentComponent,
    PaymentTableComponent,
    PaymentButtonComponent,
    PaymentButtonSetComponent,
    PaymentRowComponent,
    PaymentRowDetailComponent,
    HeldPaymentsComponent
  ]
})
export class AppPaymentsModule {}
