import { Component, OnInit, Inject } from '@angular/core';
import { PaymentDeveloper } from 'app/models/payment-developer';
import { PaymentService } from 'app/services/payment.service';
import { UserService } from 'app/services/user.service';
import { PaymentAdmin } from '@models/payment-admin';
import { MessageDialogServiceToken, MessageDialogServiceImpl, dialogButtons } from '@common/components/confimation-modal/dialog.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public itemsPerPage = 10;
  public currentPage = 0;
  public tableAttributes = [
    {
      name: 'Date'
    },
    {
      name: 'Total Rev.',
      attr: 'totalRevenue'
    },
    {
      name: 'In App Rev.',
      attr: 'iapRevenue'
    },
    {
      name: 'Download Rev.',
      attr: 'downloadRevenue'
    },
    {
      name: 'Tax Collected',
      attr: 'taxCollected'
    }
  ];
  public previousPayments: PaymentDeveloper[] = [];
  public upcomingPayments: PaymentDeveloper[] = [];
  public previousPaymentsTotalCount: 0;

  constructor(
    private paymentSvc: PaymentService,
    protected svcUser: UserService,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {}

  private getPayments(page) {
    this.paymentSvc
      .getListForDeveloper(page, this.itemsPerPage)
      .subscribe(({ previousPayments, upcomingPayments }) => {
        this.upcomingPayments = upcomingPayments;
        this.previousPayments = previousPayments.data;
        this.previousPaymentsTotalCount = previousPayments.maxCount;
      });
  }

  ngOnInit() {
    this.getPayments(this.currentPage);
  }

  public getPage(pageObj) {
    this.currentPage = pageObj.page - 1;
    this.getPayments(this.currentPage);
  }

  public getDetail(payment: PaymentDeveloper) {
    if (!payment.details) {
      this.paymentSvc.getDetailByTransferGroup(payment.transferGroup).subscribe(
        details => {
          payment.details = details;
        },
        null,
        () => {
          payment.isDetailLoaded = true;
        }
      );
    }

    payment.showDetail = !payment.showDetail;
  }

  public authorizePayment(payment: PaymentAdmin) {
    this.paymentSvc.authorizePayment(payment.transferGroup, payment.transferKey)
      .subscribe(
        () => {
          payment.isAuthorized = !payment.isAuthorized;
        },
        (err) => this.dialogService.openMessageDialog({
          title: "Error Authorizing Payment",
          text: "An error occured during payment authorization",
          actions: [dialogButtons.ok],
        }),
      );
  }

}
