import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, DoCheck, HostListener, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { dialogButtons, dprmConfirmLeavePageDialog, MessageDialogServiceImpl, MessageDialogServiceToken, } from '@common/components/confimation-modal/dialog.service';
import { INavigationGuardComponent } from '@common/components/navigation-guard/navigation-guard.component';
import { VariableAlertModalComponent } from '@common/components/variable-alert-modal/variable-alert-modal.component';
import { ComponentCommService } from '@services/component-comm.service';
import { ToggleSelectorComponent } from 'app/common.module/components/toggle-selector/toggle-selector.component';
import { App } from 'app/models/app';
import { ErrorProviderNode } from 'app/models/error-provider.model';
import { InAppPurchase } from 'app/models/iap';
import { Locale } from 'app/models/locale';
import { AppService } from 'app/services/app.service';
import { IapService } from 'app/services/iap.service';
import { UtilService } from 'app/services/util.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { from, Observable, Subject } from 'rxjs';
import { map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { ModalComponent } from '../../../../../common.module/components/modal/modal.component';
import { AppDetailsResolver } from '../../../../../guards/app-details.resolver';
import { InAppPurchaseTranslation } from '../../../../../models/iapTranslation';

@Component({
  selector: 'app-iap',
  templateUrl: './iap.component.html',
  styleUrls: ['./iap.component.scss'],
  providers: [AsyncPipe]
})
export class AppIapComponent implements OnInit, DoCheck, ErrorProviderNode<ToggleSelectorComponent>, INavigationGuardComponent, OnDestroy {
  @Input()
  readOnly = false;
  @ViewChild('errorModal')
  errorModal: ModalComponent;
  @ViewChild(VariableAlertModalComponent)
  alertModal: VariableAlertModalComponent;
  @ViewChild('uploader')
  uploader;
  @ViewChild('titleText', { read: NgModel })
  titleText: NgModel;
  @ViewChild('skuText', { read: NgModel })
  skuText: NgModel;
  @ViewChild('descText', { read: NgModel })
  descText: NgModel;
  @ViewChildren(ToggleSelectorComponent)
  children: QueryList<ToggleSelectorComponent>;

  app$: Observable<App> = this.route.parent.data.pipe(pluck('appDetails'));
  app: App = this.pipeAsync.transform(this.app$);

  private _navigatingAway = false;
  set navigatingAway(value) {
    this._navigatingAway = value;
  }
  get childSelected() {
    return this.children.map(child => child.checked).some(checked => checked === true);
  }
  get childTouched() {
    return this.children.map(child => child.touched).some(touched => touched === true);
  }
  get childError() {
    if (!!this.children && this.children.length > 0) {
      const errored = this.children.map(child => child.error).some(err => err === true);
      return (
        errored ||
        (this._navigatingAway && this.editingIap && !this.saveTried && !this.childSelected && !errored) ||
        (!this.saveTried && !this.childSelected && this.childTouched && !errored) || // No save, but children were interacted with
        (this.saveTried && !this.childSelected)
      ); // A certain child has an error or no children were selected
    }
    return false;
  }
  get error() {
    const localError = this.iapTitleError || this.iapSKUError || this.iapDescriptionError;
    return localError || this.childError;
  }
  iaps: InAppPurchase[] = [];
  newIap: InAppPurchase = null;
  emptyIap = true;
  creatingNewIap = false;
  editingIap = false;
  editingIndex: number;
  locales: Locale[] = [];
  languages: any;
  language: string;
  saveTried = false;
  detail: InAppPurchaseTranslation = {
    title: '',
    description: '',
    locale: ''
  };
  isDirty = false;
  saveClicked = false;

  protected onDestroy$: Subject<boolean> = new Subject();

  get iapTitleError() {
    if (!!this.titleText) {
      return (
        (this.saveTried && !this.titleText.dirty && !this.detail.title) ||
        (this._navigatingAway && this.editingIap && !this.titleText.dirty && !this.detail.title) ||
        (this.titleText.dirty && !this.detail.title)
      );
    }
    return false;
  }

  get iapDescriptionError() {
    if (!!this.descText) {
      return (
        (this.saveTried && !this.descText.dirty && !this.detail.description) ||
        (this._navigatingAway && this.editingIap && !this.descText.dirty && !this.detail.description) ||
        (this.descText.dirty && !this.detail.description)
      );
    }
    return false;
  }
  get iapSKUError() {
    if (!!this.skuText) {
      return (
        (this.saveTried && !this.skuText.dirty && !this.newIap.sku) ||
        (this._navigatingAway && this.editingIap && !this.skuText.dirty && !this.newIap.sku) ||
        (this.skuText.dirty && !this.newIap.sku)
      );
    }
    return false;
  }
  constructor(
    private appService: AppService,
    private iapService: IapService,
    private route: ActivatedRoute,
    protected svcResolver: AppDetailsResolver,
    protected cdr: ChangeDetectorRef,
    protected pipeAsync: AsyncPipe,
    private util: UtilService,
    private commService: ComponentCommService,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event) {
    if (this.isDirty) {
      event.returnValue = "You have unsaved changes. Are you sure you want to close the page?";
      return "You have unsaved changes. Are you sure you want to close the page?"
    }
    return undefined;
  }

  ngDoCheck() {
    this.cdr.detectChanges();
  }

  startNewIap() {
    if (this.app.packages.length > 0 && this.app.packageName) {
      const { lists } = this.route.snapshot.data;

      this.creatingNewIap = true;
      this.locales = lists.locales;
      this.newIap = new InAppPurchase();
      this.newIap.productId = this.app.id;
      this.newIap.active = true;
      this.scrollUp();
      this.selectLanguage(null);
    } else {
      this.errorModal.show();
    }
    this.cdr.detectChanges();
  }

  uploaderClick() {
    if (this.app.packages.length > 0 && this.app.packageName) {
      this.uploader.nativeElement.click();
    } else {
      this.errorModal.show();
    }
  }

  editIap(index) {
    const { lists } = this.route.snapshot.data;

    this.newIap = { ...this.iaps[index] };
    this.selectLanguage(null);
    this.locales = lists.locales.map(l => {
      const found = this.newIap.prices.find(a => a.countryCode === l.country_locale);
      if (found) {
        l.price = found.price;
      }
      return l;
    });
    this.editingIap = true;
    this.scrollUp();
  }
  private scrollUp() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 200) {
        window.scrollTo(0, pos - pos / 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

  checkAvailabilityError() {
    return (
      this.locales.filter(l => l.price === 0).length > 0 ||
      this.locales.length === 0
    );
  }

  showConfirmationModal() {
    this.saveTried = true;
    if (!this.checkErrors()) {
      if (this.creatingNewIap) {
        this.alertModal.headerText = `You're about to Save An In-App Purchase`;
        this.alertModal.showAlert(
          `<div>
            To ensure the best End User Experience, once an IAP is saved,
            <b>the IAP cannot be deleted and the SKU cannot be modified.</b> IAPs
            with duplicate SKUs are not permitted
          </div>
          <div>
            After an IAP is saved, you <b>will</b> be able to change IAP Title,
            Description, Price, Locale Availability.
          </div>
          <div>
            Individual IAPs can be marked as Active or Inactive via the
            Active/Inactive toggle that will appear after an IAP is saved.
          </div>`
        );
      } else {
        this.saveItem();
      }
    }
  }

  saveItem() {
    this.newIap.prices = this.locales
      .map(lr => {
        const c = {
          countryCode: lr.country_locale,
          price: lr.price
        };
        return c;
      });

    this.isDirty = false;
    this.iapService.saveIAP(this.newIap).subscribe(async () => {
      await this.iniIAP();
    });
  }

  toggleActive() {
    this.newIap.active = !this.newIap.active;
  }

  checkErrors() {
    return this.checkAvailabilityError() || !this.detail.description || !this.newIap.sku || !this.detail.title;
  }

  private async iniIAP() {
    this.iaps = await this.iapService.getIAPs(this.app.id);
    this.creatingNewIap = false;
    this.editingIap = false;
    if (this.iaps.length > 0) {
      this.emptyIap = false;
    }
  }
  async ngOnInit() {

    this.commService.get().pipe(takeUntil(this.onDestroy$)).subscribe(
      (msg) => {
        if (msg && msg.skipNavGuard) {
          this.isDirty = false;
        }
      }
    );
    const { readOnly, lists } = this.route.snapshot.data;

    this.readOnly = readOnly;

    const detailLocales = this.app.details.map(detail => detail.locale);

    this.languages = [];

    lists.language.forEach(detailLocale => {
      if (detailLocales.includes(detailLocale.locale)) {
        this.languages.push(detailLocale);
      }
    });
    await this.iniIAP();
  }

  selectLanguage(language) {
    this.language = language || 'en_US';
    this.detail = this.newIap.translations.find(detailLocale => {
      return detailLocale.locale === this.language;
    });
    if (!this.detail) {
      this.detail = {
        locale: this.language,
        title: '',
        description: ''
      };
      this.newIap.translations.push(this.detail);
    }
  }

  uploadCsv($event) {
    const file = $event.target.files[0];
    const filesize = file.size / 1024 / 1024;

    if (/\.(csv)$/i.test(file.name) === false) {
      alert('The file must be an CSV');
      return;
    }

    this.iapService
      .uploadCSV($event.target.files[0], this.app.id)
      .pipe(switchMap(result => from(this.iapService.getIAPs(this.app.id))))
      .subscribe(
        r => {
          this.iaps = r;
          alert('success');
        },
        null,
        () => {
          $event.target.value = null;
        }
      );
  }
  downloadTemplate() {
    this.iapService.downloadTemplate().subscribe(f => {
      console.log('file downloaded ', f);
      importedSaveAs(f, 'leia_iap_template.csv');
    });
  }

  titleChange(title) {
    this.detail.title = title;
    this.isDirty = true;
  }

  skuChange(sku) {
    this.newIap.sku = sku;
    this.isDirty = true;
  }

  descriptionChange(description) {
    this.detail.description = description;
    this.isDirty = true;
  }

  localeChange(change, locale) {
    this.isDirty = true;
  }

  confirmNavigation() {
    if ((!this.isDirty) || this.saveClicked) {
      this.isDirty = false;
      this.saveClicked = false;
      return true;
    }
    return this.dialogService.openMessageDialog(dprmConfirmLeavePageDialog).afterClosed().pipe(
      map(res => {
        this.isDirty = !(res > 0);
        return res > 0;
      }),
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
