﻿import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AppStatus } from '@enums/app-status.enum';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable()
export class ReadonlyResolver implements Resolve<boolean> {
  constructor(private svcUser: UserService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let app = route.parent.data.appDetails;
    if (!app) {
      app = route.data.appDetails;
    }
    return of(this.svcUser.isAdmin() || (!this.svcUser.isAdmin() && app && app.appStatus === AppStatus.awaiting_approval));
  }
}
