import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/models/user';
import { LoginService } from '../../../../services/login.service';
import { UserService } from '../../../../services/user.service';

@Component({
  host: {
    '(document:click)': 'offClickHandler($event)'
  },
  selector: 'app-main-menu-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class MainMenuDropdownComponent implements OnInit {
  @Input() showDropdown: boolean;
  @Input() isNewApp = false;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private _eref: ElementRef
  ) {}

  ngOnInit() {}

  get user(): User {
    return this.userService.getUser();
  }

  offClickHandler(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  redirect(pagename: string) {
    this.showDropdown = false;
    this.router.navigate(['/' + pagename]);
  }

  logout(): void {
    // this.loginService.logout();
    this.router.navigate(['/logout']);
  }
}
