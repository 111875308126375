import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VariableAlertModalComponent } from 'app/common.module/components/variable-alert-modal/variable-alert-modal.component';
import { RegisterService, RegSteps, } from '../../../../services/register.service';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit, AfterViewInit {
  @ViewChild('keyOne')
  keyOne: ElementRef;
  @Input()
  key1: string;
  @Input() key2: string;
  @Input() key3: string;
  @Input() key4: string;
  @Input() key5: string;
  @Input() userEmail: string;
  isLoading = false;
  codeValid = false;
  displayError = false;

  @ViewChild(VariableAlertModalComponent)
  alertModal: VariableAlertModalComponent;

  constructor(
    private registerService: RegisterService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.userEmail = this.registerService.user.emailAddress;
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.email) {
      this.userEmail = this.activatedRoute.snapshot.queryParams['email'];
      this.registerService.user.emailAddress = this.userEmail;
    }
    if (!this.userEmail) {
      this.router.navigate(['/register']);
    } else {
      this.registerService.checkRegStep(null, RegSteps[1], this.userEmail);
    }
  }

  ngAfterViewInit() {
    if (this.keyOne && this.keyOne.nativeElement) {
      this.keyOne.nativeElement.focus();
    }
  }

  processKeyUp(e, el) {
    if (
      e.key !== 'Backspace' &&
      e.key !== 'Shift' &&
      e.key !== 'Alt' &&
      e.key !== 'Meta' &&
      e.key !== 'Tab' &&
      e.key !== 'Alt' &&
      e.key !== 'Control' &&
      e !== 'CapsLock'
    ) {
      if (el) {
        el.focus();
      } else if (!el) {
        setTimeout(()=>this.validateEmail());
      }
    }
  }

  validateEmail() {
    this.isLoading = true;
    const code = this.key1 + this.key2 + this.key3 + this.key4 + this.key5;
    this.registerService.validateEmail(code).subscribe(
      res => {
        this.codeValid = true;
        this.isLoading = false;
        this.displayError = false;
      },
      msg => {
        this.isLoading = false;
        this.displayError = true;
        this.key1 = this.key2 = this.key3 = this.key4 = this.key5 = '';
        this.keyOne.nativeElement.focus();
      }
    );
  }

  resendCode() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.registerService.resendCode().subscribe(
      res => {
        this.isLoading = false;
        this.displayError = false;
        this.alertModal.showAlert(
          `A verification code has been resent to ${this.userEmail}. ` +
            `Please check your inbox, and then enter the new code above.`
        );
      },
      msg => {
        this.isLoading = false;
        this.displayError = true;
      }
    );
  }
  completeValidation() {
    this.registerService.changeStep(2);
  }
}
