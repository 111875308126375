import { Country } from 'app/enums/country-type.enum';
import {
  CONTACT_INFO,
  DeveloperModel,
  StripeCompanyAccountModel,
  StripeIndividualAccountModel
} from './stripe-account';

export const CA_IndividualAccount: StripeIndividualAccountModel = {
  present: true,
  type: 'individual',
  country: Country.BE,
  externalAccount: {
    present: true,
    country: Country.BE,
    accountNumber: {
      present: true
    },
    routingNumber: {
      present: true
    }
  },
  accountRepresentatives: [
    {
      present: true,
      name: {
        present: true,
        firstName: {
          present: true,
          value: null
        },
        lastName: {
          present: true,
          value: null
        }
      },
      address: {
        present: true,
        city: {
          present: true,
          value: null
        },
        addressOne: {
          present: true,
          value: null
        },
        addressTwo: {
          present: true,
          value: null
        },
        zipOrPostalCode: {
          present: true,
          value: null
        },
        stateOrProvince: {
          present: true,
          value: null
        }
      },
      dob: {
        present: true,
        value: null
      },
      personalIdNumber: {
        present: true,
        value: null
      }
    }
  ],
  tosAcceptance: {
    present: true,
    fullName: {
      present: true
    },
    date: {
      present: true
    },
    ip: {
      present: true
    }
  },
  verificationDocument: {
    present: true,
    document: {
      present: true,
      front: {
        present: true
      },
      back: {
        present: true
      }
    }
  }
};
export const CA_IndividualDeveloper: DeveloperModel = {
  contactInfo: CONTACT_INFO,
  stripeAccount: CA_IndividualAccount
};

export const CA_CompanyAccount: StripeCompanyAccountModel = {
  present: true,
  type: 'company',
  country: Country.BE,
  externalAccount: {
    present: true,
    country: Country.BE,
    accountNumber: {
      present: true
    },
    routingNumber: {
      present: true
    }
  },
  address: {
    present: true,
    city: {
      present: true
    },
    addressOne: {
      present: true
    },
    addressTwo: {
      present: true,
      value: null
    },
    zipOrPostalCode: {
      present: true
    },
    stateOrProvince: {
      present: true
    }
  },
  name: {
    present: true
  },
  taxID: {
    present: true
  },
  accountRepresentatives: [
    {
      present: true,
      name: {
        present: true,
        firstName: {
          present: true,
          value: null
        },
        lastName: {
          present: true,
          value: null
        }
      },
      address: {
        present: true,
        city: {
          present: true,
          value: null
        },
        addressOne: {
          present: true,
          value: null
        },
        addressTwo: {
          present: true,
          value: null
        },
        zipOrPostalCode: {
          present: true,
          value: null
        }
      },
      dob: {
        present: true,
        value: null
      },
      personalIdNumber: {
        present: true,
        value: null
      }
    }
  ],
  tosAcceptance: {
    present: true,
    fullName: {
      present: true
    },
    date: {
      present: true
    },
    ip: {
      present: true
    }
  },
  verificationDocument: {
    present: true,
    document: {
      present: true,
      front: {
        present: true
      },
      back: {
        present: true
      }
    }
  }
};

export const CA_CompanyDeveloper: DeveloperModel = {
  contactInfo: CONTACT_INFO,
  stripeAccount: CA_CompanyAccount
};
