import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { camelCase, sortBy, startCase } from 'lodash';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { Observable, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  private _users: User[] = [];

  get users() {
    return this._users;
  }
  set users(value: User[]) {
    if (value) {
      this._users = this.sortedUsers = value;
    }
  }
  sortField: string;
  filterExp: string;
  statusFilter: string;
  sortedUsers: User[];
  reverseSort = false;
  onDestroy$: Subject<any> = new Subject();
  fakeDate: Date = new Date(Date.now());
  @HostBinding('class')
  classes = 'container-fluid';

  constructor(private userService: UserService, private route: ActivatedRoute) {
    this.statusFilter = this.route.snapshot.queryParams['filter'];
  }

  ngOnInit() {
    this.userService.getUsers().subscribe(users => {
      this.users = users.filter(u => this.statusFilter ? u.status === this.statusFilter : true);
    });
    const filterCtrl: HTMLElement = document.getElementById("userSearchBox");
    if (filterCtrl) {
      fromEvent(filterCtrl, 'input').pipe(takeUntil(this.onDestroy$)).subscribe(
        (ev) => {
          if (ev && ev.target) {
            this.filterExp = (ev.target as any).value;
            this.userSortFilter();
          }
        }
      );

    }
  }

  sortUsers(fieldName: string) {
    this.sortField = fieldName;
    this.userSortFilter();
  }

  filterUsers(filterText: string) {
    this.filterExp = filterText;
    this.userSortFilter();
  }

  userSortFilter() {
    if (this.reverseSort) {
      this.sortedUsers = sortBy(this.users.slice(), this.sortField).reverse();
    } else {
      this.sortedUsers = sortBy(this.users.slice(), this.sortField);
    }
    this.reverseSort = !this.reverseSort;
    if (this.filterExp && this.filterExp.length) {
      this.sortedUsers = this.sortedUsers.filter((u) => {
        let f: string = u.firstName || "";
        let l: string = u.lastName || "";
        f = f.toUpperCase();
        l = l.toUpperCase();
        const name: string = f + ((f && l && f.length && l.length)?" ":"") + l;
        return name.includes(this.filterExp.toUpperCase());
      })
    }
  }

  upperCaseStatus(status) {
    return startCase(camelCase(status));
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
