import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { MessageDialogServiceImpl, MessageDialogServiceToken, } from '@common/components/confimation-modal/dialog.service';
import { VariableModalComponent } from '@common/components/variable-modal/variable-modal.component';
import { AppStatus } from '@enums/app-status.enum';
import { VerificationStatus } from '@enums/verification-status.enum';
import { ComponentCommService } from '@services/component-comm.service';
import { IapService } from '@services/iap.service';
import { VariableAlertModalComponent } from 'app/common.module/components/variable-alert-modal/variable-alert-modal.component';
import { ErrorProviderNode } from 'app/models/error-provider.model';
import { ISubMenuError, SubMenu } from 'app/models/sub-menu';
import { Observable } from 'rxjs';
import { flatMap, pluck, takeUntil } from 'rxjs/operators';
import { ModalComponent } from '../../../../common.module/components/modal/modal.component';
import { AppDetailsResolver } from '../../../../guards/app-details.resolver';
import { APK } from '../../../../models/apk';
import { App } from '../../../../models/app';
import { AppService } from '../../../../services/app.service';
import { RegisterService } from '../../../../services/register.service';
import { UserService } from '../../../../services/user.service';
import { UtilService } from '../../../../services/util.service';
import { AppImageryComponent } from '../../../containers/imagery/imagery.component';
import { AppDetailsComponent } from './details/details.component';
import { AppIapComponent } from './iap/iap.component';
import { AppLocalesComponent } from './locales/locales.component';
import { AppPackagesComponent, flagPristineAPK } from './packages/packages.component';
import { leiaGlobal } from 'endpoints';

@Component({
  selector: 'app-new-app',
  templateUrl: './new-app.component.html',
  styleUrls: ['./new-app.component.scss'],
  providers: [AsyncPipe]
})
export class AppNewAppComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {
  @ViewChild('updateSuccessModal')
  updateSuccessModal: ModalComponent;
  @ViewChild('validatingErrorModal')
  validatingErrorModal: ModalComponent;
  @ViewChild('submitSuccessModal')
  submitSuccess: ModalComponent;
  @ViewChild('distributionAgreementModal')
  distributionAgreementModal: ModalComponent;
  @ViewChild('variableAlertModal')
  alertModal: VariableAlertModalComponent;
  @ViewChild(RouterOutlet)
  activeTab: RouterOutlet;
  @ViewChild('statusMissingModal')
  statusMissingModal: VariableModalComponent;
  @ViewChild('statusUnverifiedModal')
  statusUnverifiedModal: VariableModalComponent;
  @ViewChild('statusRejectedModal')
  statusRejectedModal: VariableModalComponent;

  @ViewChild('distAgreement') distAgreement: ElementRef;

  @HostBinding('class')
  classes = 'light-grey-background';

  app$: Observable<App> = this.route.data.pipe(pluck('appDetails'));
  app: App = this.pipeAsync.transform(this.app$);

  user: any = null;
  isAdmin = false;
  rejectionNotes: string;
  textExpanded = false;
  localesError = false;
  detailsError = false;
  imageneryError = false;
  appPackageError = false;
  isSubmitDisabled = false;

  public AppStatus = AppStatus;

  get error() {
    const errorProvider = this.activeTab.component as ErrorProviderNode<any>;
    return this.subMenu.map(entry => entry.error).some(error => error === true) || errorProvider.error;
  }
  subMenu: ISubMenuError[] = [
    {
      title: 'Locales',
      router: 'locales',
      error: false,
      component: AppLocalesComponent
    },
    {
      title: 'Details',
      router: 'details',
      error: false,
      component: AppDetailsComponent
    },
    {
      title: 'Imagery',
      router: 'imagery',
      error: false,
      component: AppImageryComponent
    },
    {
      title: 'App Package',
      router: 'packages',
      error: false,
      component: AppPackagesComponent
    },
    {
      title: 'In-App-Purchases',
      router: 'iap',
      error: false,
      component: AppIapComponent
    }
  ];
  showDropdown = false;

  constructor(
    private userService: UserService,
    private appService: AppService,
    private iapService: IapService,
    private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
    protected svcUtil: UtilService,
    protected svcResolver: AppDetailsResolver,
    protected pipeAsync: AsyncPipe,
    protected cdr: ChangeDetectorRef,
    protected commService: ComponentCommService,
    @Inject(leiaGlobal) private leiaGlobalConfig,
    @Inject(MessageDialogServiceToken) protected dialogService: MessageDialogServiceImpl,
  ) {
    this.isAdmin = userService.isAdmin();
    this.user = userService.getUser();
    this.isAdmin = this.userService.isAdmin();
    // this.commService.setVar(flagPristineAPK, true);
  }

  onNavigationChange($event) {
    // if ($event && $event.oldItem) {
    //   const menuItem = this.subMenu.find(item => item.router === $event.oldItem.router);
    //   if (menuItem && $event.oldItem.component.prototype.getError) {
    //     menuItem.error = $event.oldItem.component.prototype.getError();
    //   }
    // }
    if (!!this.activeTab) {
      const cmpNavigatingTo = this.activeTab.component as any; // TODO: Make an interface for this Navigable<T> component
      cmpNavigatingTo.navigatingAway = true;
    }

  }

  ngDoCheck() {
    this.setError();
  }

  setError() {
    try {
      if (!!this.activeTab) {
        const errorProvider = this.activeTab.component as ErrorProviderNode<any>;
        this.subMenu.forEach(entry => {
          if (errorProvider instanceof entry.component) {
            entry.error = errorProvider.error;
          }
        });
      }
    } catch (err) {} // Handle  router outlet not active error when accessing activeTab.component too early; do nothing
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.appService.setFooterWhite(false);
    this.appService.navigatingAway.subscribe(value => this.setError());
  }

  getTitle() {
    const detail = this.app.details
      .sort((a, b) => (a.locale > b.locale) ? 1 : (a.locale < b.locale ? -1 : 0))
      .find(detailLocale => this.leiaGlobalConfig.defaultLocales.includes(detailLocale.locale));

    if (detail && detail.title !== '') {
      return detail.title;
    } else {
      return this.svcUtil.first(this.app.details).title;
    }
  }

  saveChanges(silent: boolean = false) {
    if (!this.error) {
      this.appService.saveOrUpdate(this.app).subscribe(
        res => {
          this.commService.get().next({ skipNavGuard: true })
          console.log(res);
          if (!silent) {
            this.updateSuccessModal.show();
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  checkDistributionAgreement() {
    if (this.isSubmitDisabled) {
      return;
    }
    this.isSubmitDisabled = true;
    if (!this.user.userAcceptedDistribution) {
      this.dialogService.openMessageDialog({
        actions: [{ caption: "I Agree", result: true }, { caption: "Not now", result: false }],
        title: "Distribution Agreement",
        bodyContentRef: this.distAgreement,
      }).afterClosed().subscribe((res => {
        this.isSubmitDisabled = false;
        if (res) {
          this.acceptDistributionAgreement();
        }
      }));
    } else {
      this.checkStripeAccount();
    }
  }

  // checkDistributionAgreement() {
  //   if (!this.user.userAcceptedDistribution || true) {
  //     this.distributionAgreementModal.show();
  //   } else {
  //     this.checkStripeAccount();
  //   }
  // }

  acceptDistributionAgreement() {
    this.registerService.setUserAgreedToDistributionAgreement(this.user, true).subscribe(
      res => {
        this.user.userAcceptedDistribution = true;
        this.userService.setUser(this.user);
        this.distributionAgreementModal.hide();
        this.checkStripeAccount();
      },
      err => console.log(err)
    );
  }

  async checkStripeAccount() {
    if (!this.error) {
      const check = await this.checkPaidContent();
      this.isSubmitDisabled = false;
      if (check) {
        this.appService.canCreateOrEditApps().subscribe(permissions => {
          const allowed = permissions.canCreateOrEditApps && [VerificationStatus.COMPLETED, VerificationStatus.VERIFIED_OVERRIDE].includes(permissions.bankAccountStatus);
          if (allowed) {
            this.submitApp();
          } else {
            this.saveChanges(true);

            switch (permissions.bankAccountStatus) {
              case VerificationStatus.NONE:
                this.statusMissingModal.headerText = "There was a small problem";
                this.statusMissingModal.showAlert(null);
                break;
              case VerificationStatus.REJECTED:
                this.statusRejectedModal.headerText = "There was a small problem";
                this.statusRejectedModal.showAlert(null);
                break;
              case VerificationStatus.PENDING:
              case VerificationStatus.ENABLED:
              case VerificationStatus.RESTRICTED_SOON:
              case VerificationStatus.RESTRICTED:
                this.statusUnverifiedModal.headerText = "There was a small problem";
                this.statusUnverifiedModal.showAlert(null);
                break;
            }
          }
        });
      } else {
        this.submitApp();
      }
    } else {
      this.isSubmitDisabled = false;
      this.validatingErrorModal.show();
    }
  }

  async checkPaidContent() {
    const iaps = await this.iapService.getIAPs(this.app.id);
    const priceExists = this.app.prices.some((price) => price.price > 0);
    const paidIapExists = iaps.length > 0;
    return priceExists || paidIapExists;
  }

  submitApp() {
    this.appService
      .saveAndSubmit(this.app)
      .subscribe(
        (res: any) => {
        this.commService.get().next({ skipNavGuard: true });

          if (res && res.status === 'INVALID_APK_FILE') {
            this.alertModal.headerText = `Alert`;
            this.alertModal.showAlert(
              `This APK has an invalid version code and version name.
            All APKs submitted for an app update must contain a higher
            Version Code and Version Number than the current Live Version.`
            );
          } else if (res && res.status === 'INVALID_PRODUCT') {
            this.alertModal.headerText = 'Alert';
            this.alertModal.showAlert('Invalid Product');
          } else {
            this.submitSuccess.show();
          }
        },
        (err: HttpErrorResponse) => {
          this.handleError(err);
        }
      );
  }

  handleError(err: HttpErrorResponse) {
    if ('code' in err.error) {
      this.alertModal.headerText = err.error.code;
      this.alertModal.showAlert(err.error.message);
    } else if ('error' in err.error) {
      if ('message' in err.error.error) {
        this.alertModal.headerText = err.error.status;
        this.alertModal.showAlert(err.error.error.message);
      } else {
        console.log(err);
      }
    }
  }

  isApkHeader(apk: APK) {
    return apk.status === AppStatus.not_submitted || apk.status === AppStatus.awaiting_approval;
  }

  approveApp() {
    this.appService.approve(this.app.id).subscribe(result => {
      this.back();
    });
  }

  rejectApp() {
    this.appService.reject(this.app.id, this.rejectionNotes).subscribe(result => {
      this.back();
    });
  }

  back() {
    if (this.isAdmin) {
      this.router.navigate(['/main/admin-apps']);
    } else {
      this.router.navigate(['/main/my-apps']);
    }
  }

  backSubmitSuccess() {
    this.commService.get().next({ skipNavGuard: true });
    this.back();
  }

  ngOnDestroy() {
    this.appService.setFooterWhite(true);
  }
}
