import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { formatLabel, LegendComponent } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './app-chart-legend.component.html',
  styleUrls: ['./app-chart-legend.component.scss'],
  providers: [CurrencyPipe]
})
export class AppChartLegendComponent extends LegendComponent {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  getLegendEntries(): any[] {
    const items = [];

    for (const { name, value } of this.data) {
      const formattedLabel = formatLabel(name);

      const idx = items.findIndex(i => {
        return i.label === formattedLabel;
      });

      if (idx === -1) {
        items.push({
          name,
          formattedLabel,
          color: this.colors.getColor(name),
          value
        });
      }
    }

    return items;
  }
}
