﻿import * as moment from 'moment';
import { Moment, unitOfTime } from 'moment';

export enum MenuChoice {
  'Today' = 'Today',
  '7 Days' = '7 Days',
  '30 Days' = '30 Days',
  '1 Year' = '1 Year',
  'Lifetime' = 'Lifetime',
  'Custom' = 'Custom'
}

export interface MomentRange {
  label: MenuChoice;
  from: Moment;
  to: Moment;
  unit: unitOfTime.Base;
}

export const MenuChoices: MomentRange[] = [
  {
    label: MenuChoice.Today,
    from: moment()
      .subtract(24, 'hours')
      .clone(),
    to: moment(),
    unit: 'hours'
  },
  {
    label: MenuChoice['7 Days'],
    from: moment()
      .subtract(7, 'days')
      .clone(),
    to: moment(),
    unit: 'days'
  },
  {
    label: MenuChoice['30 Days'],
    from: moment()
      .subtract(30, 'days')
      .clone(),
    to: moment(),
    unit: 'days'
  },
  {
    label: MenuChoice['1 Year'],
    from: moment()
      .subtract(12, 'months')
      .clone(),
    to: moment(),
    unit: 'months'
  },
  {
    label: MenuChoice.Lifetime,
    from: moment()
      .subtract(24, 'hours')
      .clone(),
    to: moment(),
    unit: 'hours'
  },
  {
    label: MenuChoice.Custom,
    from: undefined,
    to: undefined,
    unit: 'days'
  }
];
