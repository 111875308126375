import { Component, Input, OnChanges } from '@angular/core';
import * as tinygradient from 'tinygradient';

@Component({
  selector: 'app-custom-linear-gradient',
  templateUrl: './custom-linear-gradient.component.html',
  styleUrls: ['./custom-linear-gradient.component.scss']
})
export class CustomLinearGradientComponent implements OnChanges {
  colorsRange: any[];

  @Input()
  id;
  @Input()
  startHexColor;
  @Input()
  endHexColor;
  @Input()
  numColorStops;
  @Input()
  gradientUnits: 'objectBoundingBox' | 'userSpaceOnUse' = 'userSpaceOnUse';

  ngOnChanges() {
    this.colorsRange = tinygradient([
      `${this.startHexColor}`,
      `${this.endHexColor}`
    ]).rgb(this.numColorStops ? this.numColorStops : 2);
  }
}
