import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TOSAcceptanceModel } from 'app/models/stripe-account';
import { Moment } from 'moment';

@Component({
  selector: 'app-tos-acceptance-form',
  templateUrl: './tos-acceptance-form.component.html',
  styleUrls: ['./tos-acceptance-form.component.scss']
})
export class TosAcceptanceFormComponent {
  @Input()
  isWhite = false;
  @Input()
  parent: FormGroup;
  @Input()
  template: TOSAcceptanceModel;
  @Input()
  readOnly = false;
  @Input()
  time: Moment;

  openTOS() {
    const countryCode = this.parent.root.get('stripeAccount.country').value;
    window.open(`https://stripe.com/${countryCode || 'us'}/connect-account/legal#translation`, '_blank');
  }
}
