import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterService } from '../../../../services/register.service';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent implements OnInit {
  termsRead = false;
  existingUser = false;
  user: any = {};
  eulaText: string;

  constructor(
    private registerService: RegisterService,
    private userService: UserService,
    private router: Router,
  ) {
    if (userService.getUser()) {
      Object.assign(this.user, userService.getUser());
      this.existingUser = true;
    } else {
      this.user = this.registerService.user;
    }
  }

  ngOnInit() {
    if (!(this.user.emailAddress && this.userService.getToken())) {
      this.router.navigate(['/login']);
    } else {
      if (this.registerService.registerStepSource.value !== 5) {
        setTimeout(()=>this.registerService.changeStep(5));
      }
      this.registerService.getAgreement('eula').subscribe(eulaText => (this.eulaText = eulaText));
    }
  }

  onScroll(event) {
    if (event) {
      const element = event.target;
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
        this.termsRead = true;
      }
    }
  }

  agreeToEula() {
    if (this.termsRead) {
      this.registerService.setUserAgreedToEula(this.user).subscribe(
        res => {
          if (this.existingUser) {
            this.user.userAcceptedEula = true;
            this.userService.setUser(this.user);
            if (!this.user.userAcceptedDistribution) {
              this.registerService.changeStep(6);
            } else {
              this.router.navigate(['/main/dashboard']);
            }
          } else {
            this.registerService.changeStep(6);
          }
        },
        err => console.log(err)
      );
    }
  }

  cancel() {
    this.registerService.cancelRegistration();
  }
}
