import { App, AppVersion } from "./app";

export class AppData {
    app: App | App[];
    version: AppVersion;

    constructor(app: App | App[], version?: AppVersion) {
      this.app = app;
      this.version = version;
    }
}
