import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { LeiaNotification } from '../../../models/leia-notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnChanges {
  @Input()
  public notification: LeiaNotification;
  @Output()
  close = new EventEmitter<LeiaNotification>();

  constructor() {}

  ngOnChanges() {
    console.log('changing', this.notification);
  }

  onClose() {
    this.close.emit(this.notification);
  }
}
