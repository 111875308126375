import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOB } from 'app/models/stripe-account';
import { MaterialInputComponent } from '../material-input/material-input.component';

export const DOB_MATERIAL_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DobMaterialInputComponent),
  multi: true
};

@Component({
  selector: 'app-dob-material-input',
  templateUrl: './dob-material-input.component.html',
  styleUrls: ['./dob-material-input.component.scss'],
  providers: [DOB_MATERIAL_INPUT_VALUE_ACCESSOR]
})
export class DobMaterialInputComponent implements ControlValueAccessor {
  @Input()
  hasInfo = false;
  @Input()
  infoText: string;
  @Input()
  name: string;

  constructor(protected cdr: ChangeDetectorRef) {}

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.notifyValueChange(value);
  }
  @ViewChildren(MaterialInputComponent)
  inputs: QueryList<MaterialInputComponent>;
  @Input()
  isWhite = false;

  _value: DOB;

  onChange: (value) => {};
  onTouched: () => {};

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  markAsPristine() {
    this.inputs.forEach(input => input.markAsPristine());
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  formatForModel(value: DOB) {
    if (!!value) {
      const regex = new RegExp(/\//, 'g');
      // value = value.replace(regex, '-');
    }
    return value;
  }

  formatForDisplay(value: DOB) {
    if (!!value) {
      const regex = new RegExp(/-/, 'g');
      // value = value.replace(regex, '/');
    }
    return value;
  }

  writeValue(value: any): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
