import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { ModalComponent } from 'app/common.module/components/modal/modal.component';

export class FormModal extends ModalComponent {
  @Input() validationError: ValidationErrors;
  @Output() closeCick: EventEmitter<any> = new EventEmitter();
  @Output() confirmClick: EventEmitter<any> = new EventEmitter();
  @ViewChild('modal') modal: ModalComponent;

  constructor() {
    super();
  }

  public show() {
    return this.modal.show();
  }

  public hide() {
    return this.modal.hide();
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  onClose(form, modal) {
    this.closeCick.emit({ form, modal });
  }

  onConfirm(form, modal) {
    this.markFormGroupTouched(form);
    this.confirmClick.emit({ form, modal });
  }
}
