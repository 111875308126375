import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormGroup, NgModel } from '@angular/forms';
import {
  dialogButtons,
  dprmConfirmLeavePageDialog,
  MessageDialogServiceImpl,
  MessageDialogServiceToken
} from '@common/components/confimation-modal/dialog.service';
import { RadioButtonOption } from '@common/components/radio-button-set/radio-button-set.component';
import { AppStatus } from '@enums/app-status.enum';
import { ComponentCommService } from '@services/component-comm.service';
import { ApkComponent } from 'app/common.module/components/apk/apk.component';
import { ModalComponent } from 'app/common.module/components/modal/modal.component';
import { ApkStatus } from 'app/enums/apk-status.enum';
import { leiaGlobal } from 'endpoints';
import * as _ from 'lodash';
import { forkJoin, interval, Observable, Subject } from 'rxjs';
import { map, pluck, takeUntil, takeWhile } from 'rxjs/operators';
import { AppDetailsResolver } from '../../../../../guards/app-details.resolver';
import { APK } from '../../../../../models/apk';
import { App, AppDetailsChanges } from '../../../../../models/app';
import { AppService } from '../../../../../services/app.service';
import { UserService } from '../../../../../services/user.service';
import { UtilService } from '../../../../../services/util.service';
import { commChannelAppDetailsChange } from '../app-edit-details/app-edit-details.component';

export const flagPristineAPK = 'AppPackagesComponent_isPristine';
@Component({
  selector: 'app-edit-packages',
  templateUrl: './app-edit-packages.component.html',
  styleUrls: ['./app-edit-packages.component.scss']
})
export class AppEditPackagesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('ensureCorrectDeviceModal')
  ensureCorrectDeviceModal: ModalComponent;
  @ViewChild('changeDeviceModelModal')
  changeDeviceModelModal: ModalComponent;

  @ViewChild('uploader', { read: NgModel }) uploader;

  @ViewChildren(ApkComponent)
  children: QueryList<ApkComponent>;
  @Input() readOnly = false;
  @Input() app: App;
  @Input() appChanges: AppDetailsChanges[];
  @Input() lists: any;
  @Input() formGroup: FormGroup;

  @Output() backClick: EventEmitter<any> = new EventEmitter();
  @Output() nextClick: EventEmitter<any> = new EventEmitter();
  @Output() saveApp: EventEmitter<any> = new EventEmitter();

  showUploader = false;
  showNoAppYet = false;
  keepPulling = false;
  package: APK;
  filename = '';
  file: any;
  languages: any;
  language: string;
  deviceModelOptions: RadioButtonOption[]
  isAdmin = false;

  onDestroy$: Subject<boolean> = new Subject();
  emails: string;

  constructor(
    private appService: AppService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    protected svcResolver: AppDetailsResolver,
    private svcUtil: UtilService,
    protected commService: ComponentCommService,
    @Inject(leiaGlobal) private leiaGlobalConfig,
    @Inject(MessageDialogServiceToken) protected dialogService: MessageDialogServiceImpl
  ) {
    this.isAdmin = this.userService.isAdmin();
  }

  ngOnInit() {
    this.getLanguages();
    this.checkApkVisibility();
    this.commService
      .getChannel(commChannelAppDetailsChange)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.getLanguages());
    this.deviceModelOptions = this.getDeviceModelOptions()
  }

  ngAfterViewInit() {
    this.renderCurrentApk();
    this.cdr.detectChanges();
  }

  getLanguages() {
    const locales = this.app.details.map(detail => detail.locale);
    const tmp = [];

    this.lists.language.forEach(detailLocale => {
      if (locales.includes(detailLocale.locale)) {
        tmp.push(detailLocale);
      }
    });

    this.languages = tmp;
  }

  checkApkVisibility() {
    this.showNoAppYet = !this.app.packages || this.app.packages.length === 0;

    this.showUploader = this.showNoAppYet || !this.isApkHeader(this.app.packages[0]);

    this.selectLanguage(null);
  }

  selectLanguage(language) {
    const selectedDefaults = this.app.details
      .filter(d => this.leiaGlobalConfig.defaultLocales.includes(d.locale))
      .map(d => d.locale);
    this.language = language || selectedDefaults[0];
  }

  getDeviceModelOptions(): RadioButtonOption[] {
    return this.lists.models.map((model) => ({
      name: model.device_model_name,
      value: model.device_model_id,
      selected: this.app.deviceModel === model.device_model_id
    }))
  }

  getReleaseNote(locale: string) {
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < this.app.packages.length; c++) {
      const res = this.app.packages[c].releaseNotes.filter(
        rn => rn.locale === locale && rn.releaseNotes && rn.releaseNotes.trim() !== ''
      );
      if (res.length) {
        // console.log('app-edit-packages getReleaseNote res:', res[0]);
        return res[0];
      }
    }
    return null;
  }

  showAlert(messageText) {
    this.dialogService.openMessageDialog({
      title: 'Alert',
      text: messageText,
      actions: [dialogButtons.ok]
    });
  }

  uploadNewPackage($event) {
    // Save all changes before uploading an APK
    this.saveApp.emit(true)

    this.file = $event.target.files[0];
    if (this.file) {
      const filesize = this.file.size / 1024 / 1024;
      if (/\.(apk)$/i.test(this.file.name) === false) {
        this.showAlert('The file must be an APK');
      } else if (filesize > this.leiaGlobalConfig.apkMaxSize) {
        this.showAlert(`The file upload limit is ${this.leiaGlobalConfig.apkMaxSize}MB`);
      } else {
        this.formGroup.get('packageCount').markAsTouched();
        this.filename = $event.target.files[0].name;

        this.showUploader = false;
        this.showNoAppYet = false;
        this.appService.submitAppAPK(this.app, this.filename, $event.target.files[0]).subscribe(
          (r: any) => {
            this.uploader.reset();
            this.package = r.package;
            // console.log('app-edit-packages, uploadNewPackage, this.pacakge:', this.package);
            this.app.productVersionId = r.productVersionId;
            this.app.packages.unshift(this.package);
            this.formGroup.get('packageCount').setValue(this.app.packages.length);
            this.renderCurrentApk();
            this.pullingVersionName();
            console.log('app-edit-packages, uploadNewPackage, this.app:', this.app);
            // console.log('app-edit-packages, uploadNewPackage, this.filename', this.filename);
            console.log('app-edit-packages, uploadNewPackage, $event.target.files[0]:', $event.target.files);
          },
          error => {
            console.log(error);
          }
        );
      }
    }
  }

  pullingVersionName() {
    this.keepPulling = true;
    interval(5000)
      .pipe(takeWhile(() => this.keepPulling))
      .subscribe(() => {
        this.appService.getAPKRefresh(this.app.id, this.app.productVersionId).subscribe((result: any) => {
          if (result.status !== ApkStatus.PROCESSING) {
            this.keepPulling = false;
            this.svcUtil.first(this.app.packages).version = result.version;
            this.app.packageName = result.packageName;

            if (result.status !== 'OK') {
              this.sendStatusNotification(result);
            }
          }
        });
      });
  }

  sendStatusNotification(data: any) {
    let content = null;
    switch (data.status) {
      case ApkStatus.INVALID_VERSION_CODE:
      case ApkStatus.MISSING_VERSION_CODE_OR_NAME:
        content = `Please make sure that the versionCode is greater than ${data.lastVersionCode}. If you need help, please <b><a href='mailto:${this.leiaGlobalConfig.contactEmail}'>contact us</a></b>.`;
        break;
      case ApkStatus.INVALID_VERSION_NAME:
        content = `Please make sure that the version name is different than the previous one. If you need help, please <b><a href='mailto:${this.leiaGlobalConfig.contactEmail}'>contact us</a></b>.`;
        break;
      case ApkStatus.INVALID_CERTIFICATE:
        content = `Please make sure that your app has a valid, signed certificate. If you need help please <b><a href='mailto:${this.leiaGlobalConfig.contactEmail}'>contact us</a></b>.`;
        break;
      case ApkStatus.INVALID_CERTIFICATE_AND_VERSIONCODE:
        content =
          `Please make sure your app has a versionCode greater than ${data.lastVersionCode} and that your app has a valid, signed certificate. ` +
          `If you need help, please <b><a href='mailto:${this.leiaGlobalConfig.contactEmail}'>contact us</a></b>.`;
        break;
    }

    if (content) {
      this.dialogService.openMessageDialog({
        actions: [dialogButtons.ok],
        title: 'APK upload error',
        htmlText: content
      });
    }
  }

  send(emails, fileId) {
    const sendApkRequests = emails.map(email => this.appService.sendAPKToDev(this.app.id, fileId, email));

    forkJoin(sendApkRequests).subscribe(r => {
      this.dialogService.openMessageDialog({
        title: 'Success',
        text: 'APK sent to your Devices.',
        actions: [dialogButtons.continue]
      });
    });
  }

  delete() {
    this.keepPulling = false;
    this.appService.deleteApk(this.app.id, this.app.packages[0].fileId).subscribe(r => {
      this.app.packages.splice(0, 1);
      this.formGroup.get('packageCount').setValue(this.app.packages.length ? this.app.packages.length : null);
      this.package = null;
      this.checkApkVisibility();
    });
  }

  renderCurrentApk() {
    if (this.app.packages.length > 0 && this.isApkHeader(this.app.packages[0])) {
      this.package = this.app.packages[0];
    }
  }

  isApkHeader(apk: APK) {
    return apk.status === AppStatus.not_submitted || apk.status === AppStatus.awaiting_approval;
  }

  updateApk(event) {
    const position = this.app.packages.findIndex(packageModel => {
      // console.log('app-edit-packages updateApk packageModel.fileId:', packageModel);
      // console.log('app-edit-packages updateApk packageModel.fileId:', packageModel.fileId, 'updateApk event.fileId:', event.fileId);
      return packageModel.fileId === event.fileId;
    });

    this.app.packages[position] = event;
    // debugger;
    // console.log('app-edit-packages updateApk()', event);
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  checkChanges() {
    return this.appChanges ? this.appChanges.some(chg => chg.fieldName === `releaseNote_${this.language}`) : false;
  }

  backClicked() {
    this.backClick.emit(true);
  }

  nextClicked() {
    if (this.isAdmin) {
      this.nextClick.emit(true)
    } else {
      this.ensureCorrectDeviceModal.show()
    }
  }

  onConfirmationModalClose() {
    this.ensureCorrectDeviceModal.hide()
    this.nextClick.emit(true)
  }

  deviceIsNotChosen(): boolean {
    return !this.app.deviceModel
  }

  isDeviceModelControlDisabled(): boolean {
    if (this.app.packages && this.app.packages.length) {
      // Disable when at least one package exists
      return true
    }
    return this.readOnly || this.app.appStatus !== AppStatus.not_submitted
  }

  deviceModelControlClicked() {
    if (this.isDeviceModelControlDisabled()) {
      this.changeDeviceModelModal.show()
    }
  }
}
