import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppStatus } from '@enums/app-status.enum';
import { App } from 'app/models/app';
import { AppService } from '../../../../../services/app.service';

@Component({
  selector: 'app-main-menu-dropdown-popup',
  templateUrl: './dropdown-popup.component.html',
  styleUrls: ['./dropdown-popup.component.scss']
})
export class MainMenuDropdownPopupComponent {
  @Input()
  productId: string;
  @Input()
  app: App;

  show = false;

  public AppStatus = AppStatus;

  constructor(
    protected appService: AppService,
    protected router: Router,
    protected el: ElementRef
  ) {}

  @HostListener('document:click', ['$event.target'])
  hide(target: HTMLElement) {
    if (!this.el.nativeElement.contains(target)) {
      this.show = false;
    }
  }

  unpublish(): void {
    this.appService.unpublish(this.productId).subscribe(r => {
      this.router.navigate(['/main/my-apps']);
    });
  }
}
