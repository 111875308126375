import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-nav',
  templateUrl: './modal-nav.component.html',
  styleUrls: ['./modal-nav.component.scss']
})
export class ModalNavComponent {
  @Input()
  isWhite = false;
  @Input()
  showBack = false;
  @Input()
  backDisabled = false;
  @Input()
  showNext = true;
  @Input()
  nextDisabled = false;
  @Input()
  showSubmitApp = false;
  @Input()
  submitAppDisabled = false;
  @Input()
  showFinish = false;
  @Input()
  finishDisabled = false;

  @Output()
  onNextClick = new EventEmitter<MouseEvent>();
  @Output()
  onBackClick = new EventEmitter<MouseEvent>();
  @Output()
  onSubmitAppClick = new EventEmitter<MouseEvent>();
  @Output()
  onFinishClick = new EventEmitter<MouseEvent>();
}
