import { regxPatterns } from "@apps/components/dynamic-form/dynamic-form-validator.regexp";
import { IDynForm } from "@apps/components/dynamic-form/dynamic-form.component";
import {
    EDynFormFieldDateOption,
    IDynFormField,
    IDynFormFieldOption,
    IDynFormFieldType,
    IDynFormFieldValidator,
    IDynFormFieldValidatorType,
    IDynFormLengthValidationOptions,
} from "@apps/components/dynamic-form/dynamic-form.service";
import { stateUS } from "@models/country-code.model";
import { leiaGlobalConfig } from "endpoints";
import * as _ from "lodash";
import * as moment from 'moment';
import { AbstractControl, FormGroup } from "@angular/forms";
import { IPubDetailOwner } from "./pub-details.model";

const postCodeMaxLength = 10;

export enum EPubDetRelationTypes {
    account_opener = "account_opener",
    owner = "owner",
    director = "director",
    executive = "executive",
    representative = "representative",
}
export const PubDetRelationTypes = {
    account_opener: "account_opener",
    owner: "owner",
    director: "director",
    executive: "executive",
    representative: "representative",
}

export const PubDetStripeFieldMap: Array<{stripeName: string, modelName: string}> = [
    {
        stripeName: "legal_entity[phone_number]",
        modelName: "{{type}}#phone",
    },
    {
        stripeName: "external_account[routing_number]",
        modelName: "external_account#routing_number"
    },
    {
        stripeName: "legal_entity[business_tax_id]",
        modelName: "company#tax_id"
    }
];

const pdfGetConditionalRequiredValidator = (ownerType: EPubDetRelationTypes) =>{
    const prefx = 'owners#' + ownerType;
    const fieldList = ['first_name', 'last_name', 'dob', 'address#line1', 'address#line2', 'address#city', 'address#state', 'address#postal_code', 'phone'];
    return (ctrl: AbstractControl, fg: FormGroup, field: IDynFormField) => {
        let req = false;
        fieldList.forEach(f => {
            const fn = prefx + f;
            if (fg && fg.get(fn) && fg.get(fn).value) {
                req = true;
            }
        });
        if (req && !ctrl.value) {
            return { [IDynFormFieldValidatorType.callback]: true }
        }
    }
}

const pdfOptionalContactValidator: IDynFormFieldValidator = {
    type: IDynFormFieldValidatorType.callback,
    callback: pdfGetConditionalRequiredValidator(EPubDetRelationTypes.director),
    errorMessage: "pubDetails.errors.optionalContactValidator",
}

export const prepOptionalRequiredContact = (fields: IDynFormField[]): IDynFormField[] => {
    const fieldList = ['first_name', 'last_name', 'dob', 'address#line1', 'address#city', 'address#state', 'address#postal_code',];
    if (fields && fields.length) {
        fields.forEach(f => {
            f.required = false;
            // tslint:disable-next-line:prefer-for-of
            for (let c = 0; c < fieldList.length; c++) {
                if (f.id.includes(fieldList[c])) {
                    const v: IDynFormFieldValidator = {
                        type: IDynFormFieldValidatorType.callback,
                        callback: pdfGetConditionalRequiredValidator(EPubDetRelationTypes.director),
                        errorMessage: "pubDetails.errors.optionalContactValidator",
                    }
                    if (f.schema.validators) {
                        f.schema.validators.push(v);
                    } else {
                        f.schema.validators = [v];
                    }
                    break;
                }
            }
        });
    }
    return fields;
}

const getLengthOption = (pattern: RegExp) => {
    switch (pattern) {
        case regxPatterns.alphanumericWSM:
            return IDynFormLengthValidationOptions.ignoreWhiteSpaceMinus;
        case regxPatterns.alphanumericWS:
        case regxPatterns.numericWS:
            return IDynFormLengthValidationOptions.ignoreWhiteSpace;
        default:
            return undefined;
    }
}

export const insertArray = (arr: any[], index: number, items: any[]): any[] => {
    const res = _.cloneDeep(arr);
    res.splice(index, 0, ...items);
    return res;
}

export const pdfRoutingNumber: IDynFormField[] = [
    {
        id: "external_account#routing_number",
        name: "pubDetails.external_account_routing_number",
        required: true,
        schema: {
            type: IDynFormFieldType.string,
            validators: [
                {
                    type: IDynFormFieldValidatorType.pattern,
                    pattern: regxPatterns.numericWS,
                    errorMessage: "dynForm.errors.numeric",
                },
                {
                    type: IDynFormFieldValidatorType.length,
                    minLength: 9,
                    maxLength: 9,
                    lengthOption: IDynFormLengthValidationOptions.ignoreWhiteSpace,
                    errorMessage: "dynForm.errors.length",
                    // errorMessageParam: "9",
                }
            ]
        }
    },
];

export const pdfIban: IDynFormField[] = [
    {
        id: "external_account#iban",
        name: "pubDetails.external_account_iban",
        required: true,
        schema: {
            type: IDynFormFieldType.string,
        }
    }
];

export const pdfAccountNumber: IDynFormField[] = [
    {
        id: "external_account#account_number",
        name: "pubDetails.external_account_account_number",
        infoText: "pubDetails.external_account_account_number_info",
        required: true,
        schema: {
            type: IDynFormFieldType.string,
            validators: [
                {
                    type: IDynFormFieldValidatorType.pattern,
                    pattern: regxPatterns.numericWS,
                    errorMessage: "dynForm.errors.numeric",
                },
                {
                    type: IDynFormFieldValidatorType.length,
                    minLength: 6,
                    maxLength: 17,
                    lengthOption: IDynFormLengthValidationOptions.ignoreWhiteSpace,
                    errorMessage: "dynForm.errors.length",
                    // errorMessageParam: "9-12",
                }
            ]
        }
    },
];

export const pdfRoutingAccount: IDynFormField[] = [...pdfRoutingNumber, ...pdfAccountNumber];

export const pdfTOS = (compRepFieldCaption: string = "compRepFullName") => {
    return [
        {
            id: "company_representative",
            name: "pubDetails." + compRepFieldCaption,
            required: true,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "tos_acceptance#date",
            name: "pubDetails.tos_acceptance_date",
            required: true,
            schema: {
                type: IDynFormFieldType.datetime,
                dateType: EDynFormFieldDateOption.unix,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.callback,
                        callback: (ctrl) => (ctrl && ctrl.value && moment.duration(moment(ctrl.value).diff(moment())).days() !== 0)?{ [IDynFormFieldValidatorType.callback]: true }:null,
                        errorMessage: "dynForm.errors.todayDate",
                    }
                ]
            }
        },
    ];
}

export const pdfGetAddress = (
    prefix: string,
    stateName: string = "pubDetails.individual_address_state",
    stateList: IDynFormFieldOption[] = stateUS,
    postCodeCaption: string = "pubDetails.individual_address_postal_code",
    postCodeLength: number = 5,
    postCodePattern: RegExp = regxPatterns.numeric,
    phoneRequired: boolean = false,
    postalCodeRequired = true
) => {
    const pcValidators: IDynFormFieldValidator[] = [];
    pcValidators.push({
        type: IDynFormFieldValidatorType.pattern,
        pattern: postCodePattern,
        errorMessage: (postCodePattern === regxPatterns.numeric || postCodePattern === regxPatterns.numericWS) ? "dynForm.errors.numeric" : "dynForm.errors.alphanumeric",
    });
    pcValidators.push({
        type: IDynFormFieldValidatorType.length,
        minLength: postCodeLength,
        maxLength: postCodeLength === 0 ? postCodeMaxLength : postCodeLength,
        lengthOption: getLengthOption(postCodePattern),
        errorMessage: "dynForm.errors.length",
        // errorMessageParam: "5",
    });
    return [
        {
            id: prefix + "#address#line1",
            name: "pubDetails.individual_address_line1",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: prefix + "#address#line2",
            name: "pubDetails.individual_address_line2",
            required: false,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: prefix + "#address#city",
            name: "pubDetails.individual_address_city",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: prefix + "#address#state",
            name: stateName,
            required: true,
            schema: {
                type: IDynFormFieldType.option,
            },
            allowedValues: stateList,
        },
        {
            id: prefix + "#address#postal_code",
            name: postCodeCaption,
            required: postalCodeRequired,
            schema: {
                type: IDynFormFieldType.string,
                validators: pcValidators,
            }
        },
        {
            id: prefix + "#phone",
            name: "pubDetails.individual_address_phone",
            required: phoneRequired,
            schema: {
                type: IDynFormFieldType.phone,
            },
        },
    ];
}

export const pdfGetPhone = (
  prefix: string,  
  phoneRequired = false
) => {  
  return [      
      {
          id: prefix + "#phone",
          name: "pubDetails.individual_address_phone",
          required: phoneRequired,
          schema: {
              type: IDynFormFieldType.phone,
          },
      },
  ];
}

// ***********************************************************************************
// company

export const pdfGetCompany = (
    stateName: string = "pubDetails.company_address_state",
    stateList: IDynFormFieldOption[] = stateUS,
    postCodeCaption: string = "pubDetails.company_address_postal_code",
    postCodeLength: number = 5,
    postCodePattern: RegExp = regxPatterns.numeric,
    phoneRequired: boolean = false, 
    postalCodeRequired = true
): IDynFormField[] => {
    return [
        {
            id: "company#name",
            name: "pubDetails.company_name",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
                // validators: [
                //     {
                //         type: IDynFormFieldValidatorType.pattern,
                //         pattern: regxPatterns.alphanumericWS,
                //         errorMessage: "dynForm.errors.alphanumeric",
                //     }
                // ]
            }
        },
        {
            id: "company#tax_id",
            name: "Business ID or Tax ID (EIN)",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.pattern,
                        pattern: regxPatterns.numeric,
                        errorMessage: "dynForm.errors.numeric",
                    },
                ]
            }
        },
        {
            id: "company#address#line1",
            name: "pubDetails.company_address_line1",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.pattern,
                        pattern: regxPatterns.startsWithPO,
                        errorMessage: "dynForm.errors.numeric",
                    },
                ]
            }
        },
        {
            id: "company#address#line2",
            name: "pubDetails.company_address_line2",
            required: false,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "company#address#city",
            name: "pubDetails.company_address_city",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "company#address#state",
            name: stateName,
            required: true,
            schema: {
                type: IDynFormFieldType.option,
            },
            allowedValues: stateList,
        },
        {
            id: "company#address#postal_code",
            name: postCodeCaption,
            required: postalCodeRequired,
            schema: {
                type: IDynFormFieldType.string,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.pattern,
                        pattern: postCodePattern,
                        errorMessage: (postCodePattern === regxPatterns.numeric || postCodePattern === regxPatterns.numericWS) ? "dynForm.errors.numeric" : "dynForm.errors.alphanumeric",
                    },
                    {
                        type: IDynFormFieldValidatorType.length,
                        minLength: postCodeLength,
                        maxLength: postCodeLength === 0 ? postCodeMaxLength : postCodeLength,
                        lengthOption: getLengthOption(postCodePattern),
                        errorMessage: "dynForm.errors.length",
                        // errorMessageParam: "5",
                    }
                ]
            }
        },
        {
            id: "company#phone",
            name: "pubDetails.company_address_phone",
            required: phoneRequired,
            schema: {
                type: IDynFormFieldType.phone,
            },
        },
    ];
}

export const pdfGetSSNPrefx = (prefix: string, caption: string = "pubDetails.individual_ssn_last_4", minLength: number = 4, required = false, minLength2: number = null): IDynFormField[] => {
    let v: IDynFormFieldValidator = {
        type: IDynFormFieldValidatorType.length,
        maxLength: minLength,
        minLength: minLength,
        errorMessage: "dynForm.errors.length",
    };
    if (minLength && minLength2) {
        v = {
            type: IDynFormFieldValidatorType.callback,
            errorMessage: "pubDetails.errors.validationFail_US_SSN",
            callback: (ctrl: AbstractControl, fg: FormGroup, field: IDynFormField) => {
                if (ctrl && ctrl.value && ctrl.value.length) {
                    const val: string = ctrl.value;
                    const len = val.replace(/\s/g, '').length;
                    if (len === minLength || len === minLength2) {
                        return null;
                    } else {
                        return { [IDynFormFieldValidatorType.callback]: "pubDetails.errors.validationFail_US_SSN" };
                    }
                }
                return null;
            }
        }
    }
    return [
        {
            id: prefix + "#id_number",
            name: caption,
            required: required,
            infoText: minLength2?"pubDetails.individual_ssn_last_4_info":undefined,
            schema: {
                type: IDynFormFieldType.string,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.pattern,
                        pattern: regxPatterns.numeric,
                        errorMessage: "dynForm.errors.numeric",
                    },
                    v,
                ]
            }
        },
    ];

}
export const pdfGetSSN = (ownerType: EPubDetRelationTypes, caption: string = "pubDetails.individual_ssn_last_4", minLength: number = 4, required = false, minLength2: number = undefined): IDynFormField[] => {
    return pdfGetSSNPrefx("owners#" + ownerType, caption, minLength, required, minLength2);
}

export const setRequired = (fields: IDynFormField[], required = true): IDynFormField[] => {
    if (fields && fields.length) {
        fields.forEach(f => f.required = required);
    }
    return fields;
}

export const pdfGetVerificationDoc = (
    prefix: string,
    required: boolean = false,
    requiredBack: boolean = false
    ) => {
    return [
        {
            id: prefix + "#verification#document#front",
            name: "pubDetails.individual_verification_document_front",
            required: required,
            schema: {
                type: IDynFormFieldType.upload,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.fileLength,
                        maxLength: leiaGlobalConfig.publishingDetails.fileMaxSize,
                    }
                ]
            }
        }, 
        {
            id: prefix + "#verification#document#back",
            name: "pubDetails.individual_verification_document_back",
            required: requiredBack,
            schema: {
                type: IDynFormFieldType.upload,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.fileLength,
                        maxLength: leiaGlobalConfig.publishingDetails.fileMaxSize,
                    }
                ]
            }
        },
    ];
}

export const pdfGetAddVerificationDoc = (prefix: string, required = false, requiredBack = false) => {
  return [
      {
          id: prefix + "#verification#additional_document#front",
          name: "pubDetails.individual_verification_additional_document_front",
          required: required,
          schema: {
              type: IDynFormFieldType.upload,
              validators: [
                  {
                      type: IDynFormFieldValidatorType.fileLength,
                      maxLength: leiaGlobalConfig.publishingDetails.fileMaxSize,
                  }
              ]
          }
      }, 
      {
          id: prefix + "#verification#additional_document#back",
          name: "pubDetails.individual_verification_additional_document_back",
          required: requiredBack,
          schema: {
              type: IDynFormFieldType.upload,
              validators: [
                  {
                      type: IDynFormFieldValidatorType.fileLength,
                      maxLength: leiaGlobalConfig.publishingDetails.fileMaxSize,
                  }
              ]
          }
      },
  ];
}

export const pdfGetBasicOwner = (ownerType: EPubDetRelationTypes, nameRequired: boolean = true) => {
    return [
        {
            id: "owners#" + ownerType + "#first_name",
            name: nameRequired ? "pubDetails.individual_first_name" : "pubDetails.firstNameOpt",
            infoText: "pubDetails.company_first_name_info",
            required: nameRequired,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "owners#" + ownerType + "#last_name",
            name: nameRequired ? "pubDetails.individual_last_name" : "pubDetails.lastNameOpt",
            infoText: "pubDetails.company_last_name_info",
            required: nameRequired,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "owners#" + ownerType + "#dob",
            name: "pubDetails.individual_dob",
            required: nameRequired,
            schema: {
                type: IDynFormFieldType.datetime,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.callback,
                        callback: (ctrl) => {
                            if (ctrl.value) {
                                const s = moment(ctrl.value);
                                const e = moment();
                                const diff = moment.duration(e.diff(s));
                                if (diff.years() < 18) {
                                    return {
                                        [IDynFormFieldValidatorType.callback]: true,
                                    }
                                }
                            }
                            return null;
                        },
                        errorMessage: "dynForm.errors.older18",
                    }
                ]
            }
        },
    ]
}


// ************************************************************************************
// individual


export const pdfGetIndividual = (
    stateName: string = "pubDetails.individual_address_state",
    stateList: IDynFormFieldOption[] = stateUS,
    postCodeCaption: string = "pubDetails.individual_address_postal_code",
    postCodeLength: number = 5,
    postCodePattern: RegExp = regxPatterns.numeric,
    phoneRequired = false,
    postalCodeRequired = true
): IDynFormField[] => {
    return [
        {
            id: "individual#first_name",
            name: "pubDetails.individual_first_name",
            infoText: "pubDetails.individual_first_name_info",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "individual#last_name",
            name: "pubDetails.individual_last_name",
            infoText: "pubDetails.individual_last_name_info",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "individual#business_url",
            name: "pubDetails.individual_business_url",
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "individual#address#line1",
            name: "pubDetails.individual_address_line1",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "individual#address#line2",
            name: "pubDetails.individual_address_line2",
            required: false,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "individual#address#city",
            name: "pubDetails.individual_address_city",
            required: true,
            schema: {
                type: IDynFormFieldType.string,
            }
        },
        {
            id: "individual#address#state",
            name: stateName,
            required: true,
            schema: {
                type: IDynFormFieldType.option,
            },
            allowedValues: stateList,
        },
        {
            id: "individual#address#postal_code",
            name: postCodeCaption,
            required: postalCodeRequired,
            schema: {
                type: IDynFormFieldType.string,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.pattern,
                        pattern: postCodePattern,
                        errorMessage: (postCodePattern === regxPatterns.numeric || postCodePattern === regxPatterns.numericWS) ? "dynForm.errors.numeric" : "dynForm.errors.alphanumeric",
                    },
                    {
                        type: IDynFormFieldValidatorType.length,
                        minLength: postCodeLength,
                        maxLength: postCodeLength === 0 ? postCodeMaxLength : postCodeLength,
                        errorMessage: "dynForm.errors.length",
                        lengthOption: getLengthOption(postCodePattern),
                        // errorMessageParam: "5",
                    }
                ]
            }
        },
        {
            id: "individual#dob",
            name: "pubDetails.individual_dob",
            required: true,
            schema: {
                type: IDynFormFieldType.datetime,
                validators: [
                    {
                        type: IDynFormFieldValidatorType.callback,
                        callback: (ctrl) => {
                            if (ctrl.value) {
                                const s = moment(ctrl.value);
                                const e = moment();
                                const diff = moment.duration(e.diff(s));
                                if (diff.years() < 18) {
                                    return {
                                        [IDynFormFieldValidatorType.callback]: true,
                                    }
                                }
                            }
                            return null;
                        },
                        errorMessage: "dynForm.errors.older18",
                    }
                ]
            }
        },
        {
            id: "individual#phone",
            name: "pubDetails.individual_address_phone",
            required: phoneRequired,
            schema: {
                type: IDynFormFieldType.phone,
            },
        },
    ];
}
