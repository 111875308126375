import { Directive, Host, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NameFormComponent } from '@common/components/name-form/name-form.component';
import { Subscription } from 'rxjs';
import { StripeModuleValidators } from './validators';

@Directive({
  selector: '[appIsMatchFirstLastName]'
})
export class IsMatchFirstLastNameDirective implements OnInit, OnDestroy {
  @Input()
  repIndex: number;
  // tslint:disable-next-line:no-input-rename
  @Input('appIsMatchFirstLastName')
  set matchTarget(target: FormGroup) {
    if (!!target && target instanceof FormControl) {
      this._matchTarget = target;
      if (this.repIndex === 0) {
        this.firstName = this.hostAppNameForm.parent.get('firstName.value') as FormControl;
        this.lastName = this.hostAppNameForm.parent.get('lastName.value') as FormControl;
        if (!!this.matchTarget && !!this.firstName.value && !!this.lastName.value) {
          this.setValidators(this.firstName.value, this.lastName.value);
        }
      }
    }
  }
  get matchTarget() {
    return this._matchTarget;
  }
  private _matchTarget: FormGroup;

  firstName: FormControl;
  lastName: FormControl;
  subs = new Array<Subscription>();

  constructor(@Host() @Self() @Optional() public hostAppNameForm: NameFormComponent) {
    if (!hostAppNameForm) {
      throw new Error('[IsMatchFirstLastNameDirective] - Is meant to be used on a NameFormComponent');
    }
  }

  ngOnInit() {
    if (!!this.firstName && !!this.lastName) {
      this.subs.push(
        this.firstName.valueChanges.subscribe(fName => this.setValidators(fName, this.lastName.value)),
        this.lastName.valueChanges.subscribe(lName => this.setValidators(this.firstName.value, lName))
      );
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setValidators(firstName: string, lastName: string) {
    this.matchTarget.setValidators([StripeModuleValidators.firstNameLastNameMatch(firstName, lastName)]);
    this.matchTarget.updateValueAndValidity();
  }
}
