import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { VerificationStatus } from '@enums/verification-status.enum';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, flatMap, map, switchMap } from 'rxjs/operators';
import { AppStatusText } from '../enums/app-status.enum';
import { App, AppDetailsChanges } from '../models/app';
import { BankDetailService } from './bank-detail.service';
import { ReportProgressHeader } from './http-progress.interceptor';
import { NotificationsService } from './notifications.service';
import { UserService } from './user.service';
import { UserStatus } from '@models/user';

export interface IAppPermissions {
  canCreateOrEditApps: boolean;
  bankAccountStatus: VerificationStatus;
  bankAccountExists: boolean;
}

@Injectable()
export class AppService {
  appsUrl = environment.leiaservices.url + this.leiaEndpoints.apps.url;
  private footerWhite = new BehaviorSubject<boolean>(true);
  navigatingAway = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private svcNotifications: NotificationsService,
    private svcUser: UserService,
    private svcBank: BankDetailService,
    @Inject(leiaEndpointToken) private leiaEndpoints
  ) {}

  navigateAway(value: boolean) {
    this.navigatingAway.next(value);
  }
  setFooterWhite(isWhite: boolean) {
    this.footerWhite.next(isWhite);
  }

  footerSubscribe(): Observable<boolean> {
    return this.footerWhite.asObservable();
  }

  startNewApp(title) {
    // debugger;
    const app = new App();
    app.details = [
      {
        locale: 'en_US',
        title: title
      }
    ];
    return this.http.post<any>(this.appsUrl, app).pipe(map(res => res.app as App));
  }

  getApps(): Observable<App[]> {
    // debugger;
    return this.http.get<App[]>(this.appsUrl);
  }

  getAdminApps(): Observable<App[]> {
    const url = `${environment.leiaservices.url}/${this.leiaEndpoints.dashboard.url}/${this.leiaEndpoints.dashboard.applist.url}`;
    return this.http.get<App[]>(url);
  }

  getAppsByUserID(userId: string): Observable<App[]> {
    // debugger;
    const url = `${this.appsUrl}${this.leiaEndpoints.apps.list.url}${userId}`;
    return this.http.get<App[]>(url);
  }

  getAppDetails(appId: string, productVersionId: string) {
    const headers = new HttpHeaders({ 'Cache-Control': 'no-cache' });
    return this.http.get<App>(`${this.appsUrl}${this.leiaEndpoints.apps.details.url}${appId}/${productVersionId}`, {
      headers
    });
  }

  getAppDetailsChanges(appId: string, productVersionId: string) {
    // debugger;
    return this.http.get<AppDetailsChanges[]>(
      `${this.appsUrl}${this.leiaEndpoints.apps.details.url}${this.leiaEndpoints.apps.details.changes.url}${appId}/${productVersionId}`
    );
  }

  getLists() {
    return this.http.get(`${this.appsUrl}${this.leiaEndpoints.apps.lists.url}`);
  }

  saveOrUpdate(app): Observable<any> {
    return this.http.post(this.appsUrl, app);
  }

  saveAndSubmit(app) {
    return this.saveOrUpdate(app).pipe(flatMap(result => this.submit(app.id)));
  }

  saveAppAutoUpdate(productId: string, autoUpdate: boolean) {
    return this.http.post(`${this.appsUrl}${this.leiaEndpoints.apps.auto.url}${productId}`, { state: autoUpdate });
  }

  submit(id) {
    // debugger;
    return this.http.post(`${this.appsUrl}${this.leiaEndpoints.apps.submit.url}${id}`, null);
  }

  submitAppAPK(app: App, fileName: string, file: Blob) {
    const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream').set(ReportProgressHeader, '');

    const url = this.leiaEndpoints.apps.uploadApkFile(app.id, app.productVersionId, fileName).url;

    return this.http.post(`${environment.leiaservices.url}${url}`, file, {
      headers
    });
  }

  sendAPKToDev(appId: string, fileId: string, email: string) {
    return this.http.post(`${this.appsUrl}${this.leiaEndpoints.apps.sendApkToDev.url}`, {
      emailAddress: email,
      productId: appId,
      fileId: fileId
    });
  }

  deleteApk(appId: string, fileId: string) {
    return this.http.delete(
      `${this.appsUrl}${this.leiaEndpoints.apps.deleteAPK.url}?product_id=${appId}&file_id=${fileId}`
    );
  }

  getAPKRefresh(appId: string, appVersionId: string) {
    const url = `${this.appsUrl}${this.leiaEndpoints.apps.apk.url}${appId}/${appVersionId}`;

    return this.http.get(url);
  }

  deleteImage(app, type: string) {
    console.log('Deleting Image');
    const productId = app.id;
    const productVersionId = app.productVersionId;

    const deleteUrl =
      environment.leiaservices.url +
      this.leiaEndpoints.apps.url +
      this.leiaEndpoints.apps.delete_image.url +
      '?product_id=' +
      productId +
      '&product_version_id=' +
      productVersionId +
      '&type=' +
      type;
    console.log(deleteUrl);

    return this.http.delete(deleteUrl);
  }

  deleteScreenshoot(app: App, screenshotId) {
    console.log('Deleting Screenshoot');
    const productId = app.id;
    const productVersionId = app.productVersionId;

    const deleteUrl =
      environment.leiaservices.url +
      this.leiaEndpoints.apps.url +
      this.leiaEndpoints.apps.delete_image.url +
      '?product_id=' +
      productId +
      '&product_version_id=' +
      productVersionId +
      '&type=screenshot&screenshot_id=' +
      app.screenshots[screenshotId].screenshotId;
    console.log(deleteUrl);

    return this.http.delete(deleteUrl);
  }

  uploadToimagery(
    app: App,
    fileType:
      | ''
      | 'cover'
      | 'thumbnail'
      | 'promoVideo'
      | 'promoVideoPreview'
      | 'screenshots0'
      | 'screenshots1'
      | 'screenshots2'
      | 'screenshots3'
      | 'screenshots4'
      | 'screenshot',
    fileData: File
  ): Observable<any> {
    const uploadUrl =
      environment.leiaservices.url +
      this.leiaEndpoints.apps.url +
      this.leiaEndpoints.apps.images.url +
      '?product_id=' +
      app.id +
      '&product_version_id=' +
      app.productVersionId +
      '&type=' +
      fileType +
      '&filename=' +
      fileData.name;
    console.log(uploadUrl);
    const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream').set(ReportProgressHeader, '');
    return this.http.post(uploadUrl, fileData, { headers });
  }

  getStatusMap(status) {
    return AppStatusText[status];
  }

  private requestChangeStatus(id, status, content = null) {
    const apiConfig = this.leiaEndpoints.apps[status];

    return this.http.request(apiConfig.type, `${this.appsUrl}${apiConfig.url}${id}`, { body: content });
  }

  unpublish(id) {
    return this.http.post(`${this.appsUrl}${this.leiaEndpoints.apps.unpublish.url}${id}`, null);
  }

  approve(id) {
    return this.requestChangeStatus(id, 'approve');
  }

  reject(id, content) {
    return this.requestChangeStatus(id, 'reject', {
      rejectionNote: content
    });
  }

  canCreateOrEditApps(): Observable<IAppPermissions> {
    return this.svcUser.getLoggedInUserRemote().pipe(
      switchMap(user =>
        this.svcBank.bankAccountExists.pipe(
          map(bank => ({ bank, user })),
          map(({ user, bank }) => {
            return {
              canCreateOrEditApps: user.status === UserStatus.APPROVED,
              bankAccountStatus: bank.status,
              bankAccountExists: bank.exists
            };
          }),
          catchError((err: HttpErrorResponse) =>
            of({
              canCreateOrEditApps: false,
              bankAccountStatus: VerificationStatus.NONE,
              bankAccountExists: false
            })
          )
        )
      )
    );
  }
}
