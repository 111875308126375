import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  AdvancedPieChartComponent,
  ColorHelper,
  LegendComponent,
  PieChartComponent
} from '@swimlane/ngx-charts';
import * as tinygradient from 'tinygradient';
import { IColorScheme } from '../../../models/color-scheme.model';
import { ILegendOptions } from '../../../models/legend-options.model';
import { PieChartData } from '../../../models/pie-chart-data.model';
import {
  DashboardService,
  NOT_ENOUGH_DATA
} from '../../../services/dashboard.service';

export const MAX_DISPLAYED_APPS = 5;

@Component({
  selector: 'app-base-pie-chart',
  templateUrl: './base-pie-chart.component.html',
  styleUrls: ['./base-pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasePieChartComponent extends AdvancedPieChartComponent {
  @Input()
  get innerRadius() {
    return this._innerRadius;
  }
  set innerRadius(value: number) {
    if (value) {
      this._innerRadius = value;
    }
  }
  @Input()
  get outerRadius() {
    return this._outerRadius;
  }
  set outerRadius(value: number) {
    if (value) {
      this._outerRadius = value;
    }
  }
  public get data() {
    return this._data;
  }
  @Input()
  public set data(value: PieChartData[]) {
    if (value) {
      if (value.length > MAX_DISPLAYED_APPS) {
        value.sort((a, b) => b.value - a.value);
        const deleted = value.splice(
          MAX_DISPLAYED_APPS,
          value.length - MAX_DISPLAYED_APPS
        );
        const others = deleted
          .map(val => ({ name: '', value: val.value }))
          .reduce((prev, curr) => ({
            name: 'OTHER',
            value: prev.value + curr.value
          }));
        value.push(others);
      }
      if (value.length === 0) {
        this._data = [NOT_ENOUGH_DATA];
      } else {
        this._data = value;
      }
      this.setLegendAndColors();
    }
  }

  constructor(
    public el: ElementRef,
    public cd: ChangeDetectorRef,
    public zone: NgZone,
    protected renderer: Renderer2,
    protected svcDashboard: DashboardService
  ) {
    super(el, zone, cd);
  }
  @ViewChild(PieChartComponent)
  chart: AdvancedPieChartComponent;
  @ViewChild(LegendComponent)
  legend;

  _innerRadius = 70;
  _outerRadius = 100;
  @Input()
  animations = false;
  @Input()
  gradient = false;

  protected _data: PieChartData[] = [];
  colorsRange = tinygradient(['#C89DFF', '#6B2ACC']);

  width: number;
  height: number;

  // options
  showLabels = true;
  explodeSlices = false;
  doughnut = false;
  arcWidth = 0.25;
  transform = `translate(100, 100)`;
  colorScheme: IColorScheme;
  view: number[];
  tmp: string[];
  legendOptions: ILegendOptions = {
    scaleType: 'legend',
    colors: null,
    domain: [],
    title: ''
  };
  labelToHexColorMap: any[];
  colorsRGB;

  setLegendAndColors() {
    let colorsRGB = this.colorsRange.rgb(2);
    if (this.data.length > 1) {
      colorsRGB = this.colorsRange.rgb(this.data.length);
    }
    this.colorScheme = {
      name: 'custom',
      selectable: true,
      group: 'ordinal',
      domain: colorsRGB.map(val => '#' + val.toHex())
    };
    this.scheme = this.colorScheme;
    this.domain = this.data.map(val => val.name);
    this.colors = new ColorHelper(
      this.colorScheme,
      this.colorScheme.group,
      this.domain
    );
    this.legendOptions = {
      // scaleType: 'legend',
      domain: this.domain,
      colors: this.colors,
      title: ''
    };
  }

  // Override super.update to prevent it from setting the innerRadius and outerRadius
  // Might break future re-use of this component
  update() {}
}
