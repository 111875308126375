import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Tool } from '../models/tool';
import { UserService } from './user.service';

@Injectable()
export class ToolsService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    @Inject(leiaEndpointToken) private leiaEndpoints,
  ) {}

  getAll(): Observable<Tool[]> {
    return this.http.get<Tool[]>(
      environment.leiaservices.url + this.leiaEndpoints.tools.url
    );
  }

  updateDownloadHistory(toolId: string, downloadId: string): Observable<void> {
    return this.http.post<void>(
      environment.leiaservices.url +
      this.leiaEndpoints.tools.url +
      this.leiaEndpoints.tools.download.url,
      { toolId, downloadId }
    );
  }
}
