import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserStatus } from 'app/models/user';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import * as JWT from 'jwt-decode';
import { Observable } from 'rxjs';
import { ReportProgressHeader } from './http-progress.interceptor';

@Injectable()
export class UserService {
  _user: User = null;
  get user() {
    return this.getUserLocal() || this._user;
  }
  set user(value: User) {
    this._user = value;
    this.storeUserLocally(value);
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(leiaEndpointToken) private leiaEndpoints,
  ) {}

  setUser(user: User): void {
    this.user = user;
  }

  unsetUser(): void {
    this.user = null;
  }

  getUser(): User {
    return this.user;
  }

  get userId() {
    const jwt: any = JWT(this.getToken());
    return jwt.id;
  }

  get partnerId() {
    const jwt: any = JWT(this.getToken());
    return jwt.partner;
  }

  getUserLocal(): User {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }


  getUserRemote(): Observable<User> {
    const url = environment.leiaservices.url + this.leiaEndpoints.user.url;
    return this.http.get<User>(url);
  }

  // TODO: make this private, the point of the user.service is to provide a user || null, no more no leiaservices
  // this breaks encapsulation and separation of concerns
  getToken(): string {
    if (this.user && this.user.token) {
      return this.user.token.accessToken;
    }
    return '';
  }

  isAdmin(): boolean {
    if (this.user && this.user.roles && this.user.roles.includes('admin')) {
      return true;
    }
    return false;
  }

  requiresAdmin() {
    if (!this.isAdmin()) {
      this.router.navigate(['/main/dashboard']);
    }
  }

  updateUserDetails(user: User) {
    this.user = { ...user };

    this.storeUserLocally(user);

    return this.storeUserRemotely(user);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(
      environment.leiaservices.url + this.leiaEndpoints.admin.users.url
    );
  }

  getUsersCSV(): Observable<Blob> {
    const url = environment.leiaservices.url + this.leiaEndpoints.admin.users.url + `?type=CSV`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getLoggedInUserRemote() {
    const headers = new HttpHeaders().set(ReportProgressHeader, '');
    const url = `${environment.leiaservices.url}${
      this.leiaEndpoints.user.url
    }`;
    return this.http.get<User>(url, { headers });
  }

  getUserById(id: string): Observable<User> {
    const headers = new HttpHeaders().set(ReportProgressHeader, '');

    return this.http.get<User>(
      `${environment.leiaservices.url}${
        this.leiaEndpoints.admin.users.url
      }/${id}`,
      { headers }
    );
  }

  changeUserStatus(user: User, newStatus: UserStatus): Observable<Object> {
    if (user && user.status && newStatus) {
      const url = `${environment.leiaservices.url}${
        this.leiaEndpoints.admin.status.url
      }`;
      return this.http.put(url, {
        userId: user.userId,
        status: newStatus
      });
    }
  }

  private storeUserLocally(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  private storeUserRemotely(user: User) {
    return this.http.post<User>(
      environment.leiaservices.url +
        this.leiaEndpoints.register.details.url,
      user
    );
  }

  changePassword(oldPassword, newPassword) {
    return this.http.put(
      environment.leiaservices.url +
        this.leiaEndpoints.register.change_password.url,
      { password: newPassword, old_password: oldPassword },
      { headers: { Authorization: 'Bearer ' + this.getToken() } }
    );
  }
}
