import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomQueryEncoderHelper } from 'app/common.module/util/custom-query-encoder.class';
import { environment } from 'environments/environment';
import * as JWT from 'jwt-decode';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginData } from '../models/login-data';
import { RedirectUri } from '../models/redirect_uri';
import { User } from '../models/user';
import { InterceptorSkipHeader } from './http-auth.interceptor';
import { UserService } from './user.service';

@Injectable()
export class LoginService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  private setUserLocal(user: User): void {
    this.userService.setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
    return;
  }

  /**
   * Determines the logged-in status of a user, by checking if their token.exp has expired.
   * If the token has expired, it attempts to use the refreshToken to get a new accessToken.
   *
   * @returns {Observable<boolean>} True if the user has a valid accessToken to make valid API requests with, otherwise false
   * @memberof LoginService
   */
  isLoggedIn(): Observable<boolean> {
    const localUser = this.userService.getUserLocal();
    // If we have a user in localStorage, and they have a token object, with an accessToken
    if (localUser && localUser.token && localUser.token.accessToken) {
      // Check to see if we can use the accessToken (expires in 12 hours)
      if (!this.isTokenExpired(localUser.token.accessToken)) {
        // If the token has not expired, we are ok to make API requests

        // Reset the user in localStorage
        this.userService.setUser(localUser);

        // We are for all intents and purposes logged in, because we can make valid API requests
        return of(true);
      } else {
        // Try to use the refreshToken to get a new accessToken
        if (!this.isTokenExpired(localUser.token.refreshToken)) {
          // If our refreshToken has not expired (expires in 1 week)
          return this.getNewAccessToken(localUser.token.refreshToken).pipe(
            map(({ accessToken }) => {
              // Reset the user's localStorage token to the new granted accessToken
              localUser.token.accessToken = accessToken;

              // Save the user
              this.userService.setUser(localUser);

              // This user can now make valid API requests with the new accessToken
              return true;
            })
          );
        }
      }
    }
    // Otherwise, both the accessToken in localStorage, and the refreshToken in localStorage have expired
    return of(false);
  }

  /**
   * Attempts to decode an encoded JWT token and compares it's .exp field to Date.now() to see
   * wether or not the token has expired.
   * @private
   * @param {*} rawJWTToken A encoded JWT token which can be decoded by the jwt-decode library
   * @returns {boolean} True, if the decoded rawJWTToken.exp exists, and is less than Date.now(), otherwise false
   * @memberof LoginService
   */
  private isTokenExpired(rawJWTToken: any): boolean {
    if (rawJWTToken) {
      const decodedToken = JWT(rawJWTToken) as any;
      if (decodedToken.exp) {
        return decodedToken.exp < Date.now() / 1000; // Exclude milliseconds, precision to the second
      }
    }
    return false;
  }

  private handleResponse(response: any): any {
    if (response && response.status === 200) {
      this.setUserLocal(response.body);
      return response.body;
    } else {
      throwError(new Error());
    }
  }

  login(loginData: LoginData): Observable<User> {
    const body = new HttpParams({ encoder: new CustomQueryEncoderHelper() })
      .set('username', loginData.username)
      .set('password', loginData.password)
      .set('grant_type', 'password')
      .set('client_id', environment.leiaservices.client_id)
      .set('client_secret', environment.leiaservices.client_secret);

    return this.http
      .post<User>(
        environment.leiaservices.url + environment.leiaservices.login,
        body.toString(),
        // options
        {
          observe: 'response',
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        }
      )
      .pipe(
        map(response => this.handleResponse(response)),
        catchError((error: HttpErrorResponse) => {
          return throwError(new Error());
        })
      );
  }

  getAuthCode(client_id: string, state: string, redirect_uri: string): any {
    const url = `${environment.leiaservices.url +
      environment.leiaservices
        .login}/authorize?client_id=${client_id}&response_type=code&redirect_uri=${encodeURIComponent(
      redirect_uri
    )}&state=${state}`;

    return this.http.get(url);
  }

  getNewAccessToken(refreshToken: string) {
    const url = `${environment.leiaservices.url}${environment.leiaservices.login}/token`;
    const headers = new HttpHeaders()
      .set(InterceptorSkipHeader, '')
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const body = new HttpParams()
      .set('refresh_token', refreshToken)
      .set('grant_type', 'refresh_token')
      .set('client_id', environment.leiaservices.client_id)
      .set('client_secret', environment.leiaservices.client_secret);
    return this.http
      .post(url, body.toString(), {
        headers,
        observe: 'response'
      })
      .pipe(
        map(response => this.handleResponse(response)),
        catchError((error: HttpErrorResponse) => {
          return throwError(new Error());
        })
      );
  }

  oauth2redirect(redirectUri: RedirectUri) {
    this.getAuthCode(redirectUri.clientId, redirectUri.state, redirectUri.redirectUri).subscribe(
      res => {
        const result = res instanceof Object ? res : JSON.parse(res);
        this.document.location.href = result.redirect_uri;
      },
      error => {
        console.log(error);
      }
    );
  }

  logout(): void {
    this.userService.unsetUser();
    localStorage.setItem('user', '');
    this.router.navigate(['/login']);
  }
}
