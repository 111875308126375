﻿import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppService } from 'app/services/app.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable()
export class AppGuard implements CanActivate {
  constructor(private svcApp: AppService, private svcUser: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.svcApp
      .canCreateOrEditApps()
      .pipe(map(val => this.svcUser.isAdmin() || (val.canCreateOrEditApps)));
  }
}
