import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CountryType } from '@enums/country-type.enum';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export class StripeModuleValidators {
  static firstNameLastNameMatch(firstName: string, lastName: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!!firstName && !!lastName) {
        const fullName = `${firstName.trim()} ${lastName.trim()}`;
        return control.value === fullName ? null : { firstNameLastNameInvalid: true };
      }
      return null;
    };
  }

  static validPhoneNumber(phoneNumber: string, country: CountryType) {
    return (control: AbstractControl) => {
      if (!!phoneNumber && !!country) {
        const phone = parsePhoneNumberFromString(phoneNumber, country);

        if (!phone) {
          return { phoneNumberInvalid: true };
        }

        return phone.isValid() ? null : { phoneNumberInvalid: true };
      }
      return null;
    };
  }
}
