import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { App } from '../../../../../../models/app';

const hintPriceReadOnly = "Price cannot be set due to non verified bank account";

@Component({
  selector: 'app-edit-locale-row',
  templateUrl: './app-edit-locale-row.component.html',
  styleUrls: ['./app-edit-locale-row.component.scss']
})
export class AppEditLocaleRowComponent implements
    OnInit,
    OnDestroy,
    AfterViewInit
{
    @ViewChild('priceInput') priceInput: ElementRef;
    @Input() app: App = null;
    @Input() showPriceInput = false;
    @Input() locale: any;
    @Input() readOnly = false;
    @Input() priceReadOnly = false;
    @Input() formGroup: FormGroup;

    ratings = ['3+', '7+', '12+', '16+'];
    hintPrice: string;

    private onDestroy$: Subject<boolean> = new Subject();

    constructor(private _eref: ElementRef, private route: ActivatedRoute) {}


  showPrice() {
    if (!(this.readOnly || this.priceReadOnly)) {
      this.showPriceInput = true;
      if (this.priceInput && this.priceInput.nativeElement) {
        setTimeout(()=> { this.priceInput.nativeElement.focus() })
      }
    }
  }

    onEnter() {
        const price = this.formGroup.get("price").value;
        if (Number.isNaN(price) || price < 0) {
            this.formGroup.get("price").setValue(null);
        }
        this.showPriceInput = false;
    }

    ngOnInit() {
        if (this.priceReadOnly) {
            this.hintPrice = hintPriceReadOnly;
        }
    }

    ngAfterViewInit() {
    }

    openESRBExternalSite() {
        window.open('http://www.esrb.org/', '_blank');
    }

    shouldShowAddPrice() {
        if (!this.showPriceInput
            && this.formGroup.get("localeSelected").value
            && (this.formGroup.get("price").value === null || this.formGroup.get("price").value === undefined)) {
            return true;
        }
        return false;
    }

    shouldShowPriceLabel() {
        return this.formGroup.get("localeSelected").value
            && !this.showPriceInput
            && this.formGroup.get("price").value >= 0;
    }

    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
