import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-component',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  @Input()
  title: string;
  @Input()
  value: number;
  @Input()
  currency = false;
  @Input()
  textValue = false;
  constructor(public el: ElementRef) {}
}
