import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class AppHttpAuthdRequestInterceptor implements HttpInterceptor {
  constructor(private svcUser: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    if (this.svcUser.getToken()) {
      const authenticatedRequest = req.clone({
        headers: req.headers.set(
          `Authorization`,
          `Bearer ${this.svcUser.getToken()}`
        )
      });

      return next.handle(authenticatedRequest);
    } else {
      return next.handle(req);
    }
  }
}
