import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProgressIndicatorService } from './services/progress-indicator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor(
    public svcProgress: ProgressIndicatorService,
    private trans: TranslateService,
  ) {
    this.trans.setDefaultLang('en');
    this.trans.use('en');
  }

  onActivate(event) {
    window.scroll(0, 0);
  }
}
