import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-set-up-payment-details',
  templateUrl: './set-up-payment-details.component.html',
  styleUrls: ['./set-up-payment-details.component.scss']
})
export class SetUpPaymentDetailsComponent {
  @Output()
  shouldShowDetails = new EventEmitter<boolean>();
}
