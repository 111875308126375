import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, QueryList, ViewChild, ViewChildren, Inject } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Data, UrlSegment } from '@angular/router';
import { UserService } from '@services/user.service';
import { Tab } from 'app/common.module/components/tabs/tab.component';
import { Tabs } from 'app/common.module/components/tabs/tabs.component';
import { App } from 'app/models/app';
import { PaymentAdmin, PaymentAdminDetail } from 'app/models/payment-admin';
import { PaymentService } from 'app/services/payment.service';
import { UtilService } from 'app/services/util.service';
import { Observable } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { AppService } from '../services/app.service';
import { MessageDialogServiceToken, MessageDialogServiceImpl, dialogButtons } from '@common/components/confimation-modal/dialog.service';
import { PayNowModalComponent } from '@payments/components/pay-now-modal/pay-now-modal.component';

@Component({
  selector: 'app-partners-page',
  templateUrl: './partners.page.component.html',
  styleUrls: ['./partners.page.component.scss'],
  providers: [AsyncPipe]
})
export class PartnersPageComponent implements AfterViewInit {
  @ViewChild(Tabs)
  tabs: Tabs;
  @ViewChildren(Tab)
  tabChildren: QueryList<Tab>;
  @ViewChild('payModal') payModal: PayNowModalComponent;

  user$: Observable<User> = this.route.data.pipe(pluck('user'));
  user: User = this.pipeAsync.transform(this.user$);
  urlSegment: UrlSegment = this.svcUtil.first(this.pipeAsync.transform(this.route.firstChild.url));
  apps$: Observable<App[]> = this.route.data.pipe(pluck('apps'));
  apps: App[] = this.pipeAsync.transform(this.apps$);

  public tableAttributes = [
    {
      name: 'Date'
    },
    {
      name: 'Total Rev.',
      attr: 'totalRevenue'
    },
    {
      name: 'Leia Rev.',
      attr: 'leiaRevenue'
    },
    {
      name: 'Tax Collected',
      attr: 'taxCollected'
    },
    {
      name: 'Total Pmnt',
      attr: 'totalPayment'
    },
    {
      name: 'Held',
      attr: 'isHeld'
    }
  ];

  public previousPayments$: Observable<PaymentAdmin[]> = this.route.data.pipe(
    pluck('payments', 'previousPayments', 'data')
  );
  public upcomingPayments$: Observable<PaymentAdmin[]> = this.route.data.pipe(
    pluck<Data, any>('payments', 'upcomingPayments'),
    map(payment => (payment && payment.length ? payment : []))
    // map(payment => (payment && payment.transferGroup ? [payment] : []))
  );
  public previousPaymentsTotalCount$ = this.route.data.pipe(pluck('payments', 'previousPayments', 'maxCount'));
  public selectedItem: PaymentAdminDetail;
  public itemsPerPage = 10;
  public currentPage = 0;
  public previousPayments: PaymentAdmin[] = this.pipeAsync.transform(this.previousPayments$);
  public upcomingPayments: PaymentAdmin[] = this.pipeAsync.transform(this.upcomingPayments$);
  public previousPaymentsTotalCount = this.pipeAsync.transform(this.previousPaymentsTotalCount$);
  public validationError: ValidationErrors;

  constructor(
    public route: ActivatedRoute,
    public svcUser: UserService,
    public svcApps: AppService,
    public paymentSvc: PaymentService,
    public svcUtil: UtilService,
    public pipeAsync: AsyncPipe,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {}

  ngAfterViewInit() {
    const selectedTabPath = this.urlSegment.path;
    const selectedTab = this.tabChildren.find(tab => tab.link === selectedTabPath);
    this.tabs.selectTab(selectedTab);
  }

  changeUserStatus(updatedUser: User) {
    this.user = updatedUser;

    this.svcUser.changeUserStatus(updatedUser, updatedUser.status).subscribe();
  }

  public holdPayment({ form, modal }) {
    this.validationError = null;
    if (form.invalid) {
      return;
    }

    if (this.selectedItem && !this.selectedItem.partnerId) {
      this.selectedItem.partnerId = this.user.partnerId;
    }
    this.paymentSvc.holdPayment({ form, modal }, this.selectedItem).subscribe(
      result => {
        this.selectedItem.isHeld = !this.selectedItem.isHeld;
        this.closeFormModal({ form, modal });
      },
      (err: HttpErrorResponse) => {
        this.validationError = err.error;
      }
    );
  }

  private getPayments(page) {
    this.paymentSvc
      .getUserListById(this.user.userId, page, this.itemsPerPage)
      .subscribe(({ previousPayments, upcomingPayments }) => {
        if (upcomingPayments && upcomingPayments.transferGroup) {
          this.upcomingPayments.push(upcomingPayments as PaymentAdmin);
        }
        this.previousPayments = previousPayments.data;
        this.previousPaymentsTotalCount = previousPayments.maxCount;
      });
  }

  public getPage(pageObj) {
    this.currentPage = pageObj.page - 1;
    this.getPayments(this.currentPage);
  }

  public startPaymentDialog(item: PaymentAdminDetail) {
    this.selectedItem = item;
    this.payModal.startPayment(item).afterClosed().pipe(
      tap(res => {
        this.upcomingPayments = [];
        this.getPayments(this.paymentSvc.currentPage);
      }),
    ).subscribe(
      ()=>{},
      (err) => this.dialogService.openMessageDialog({
        title: "An Error Occurred",
        text: "Please retry later.",
        actions: [dialogButtons.ok],
      }),
    );
  }


  public payPayment({ form, modal }) {
    if (!form.invalid) {
      this.paymentSvc
        .payNow({
          transferGroup: this.selectedItem.transferGroup,
          password: form.value.password
        })
        .subscribe(
          result => {
            this.upcomingPayments = [];
            this.getPayments(this.paymentSvc.currentPage);
            this.closeFormModal({ form, modal });
          },
          (err: HttpErrorResponse) => {
            this.validationError = err.error;
          }
        );
    }
  }

  public closeFormModal({ form, modal }) {
    form.reset();
    modal.hide();
  }

  public getUpcomingDetail(payment) {
    this.paymentSvc.getDetail(payment, true, this.user);
  }

  public getDetail(payment) {
    this.paymentSvc.getDetail(payment, false, this.user);
  }

  public authorizePayment(payment: PaymentAdmin) {
    this.paymentSvc.authorizePayment(payment.transferGroup, payment.transferKey)
      .subscribe(
        () => {
          payment.isAuthorized = !payment.isAuthorized;
        },
        (err) => this.dialogService.openMessageDialog({
          title: "Error Authorizing Payment",
          text: "An error occured during payment authorization",
          actions: [dialogButtons.ok],
        }),
      );
  }
}
