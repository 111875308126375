import { Country } from 'app/enums/country-type.enum';
import {
  CONTACT_INFO,
  DeveloperModel,
  StripeCompanyAccountModel,
  StripeIndividualAccountModel
} from './stripe-account';

export const AT_IndividualAccount: StripeIndividualAccountModel = {
  present: true,
  type: 'individual',
  country: Country.AT,
  externalAccount: {
    present: true,
    country: Country.AT,
    iban: {
      present: true
    }
  },
  accountRepresentatives: [
    {
      present: true,
      name: {
        present: true,
        firstName: {
          present: true,
          value: null
        },
        lastName: {
          present: true,
          value: null
        }
      },
      address: {
        present: true,
        city: {
          present: true,
          value: null
        },
        addressOne: {
          present: true,
          value: null
        },
        addressTwo: {
          present: true,
          value: null
        },
        zipOrPostalCode: {
          present: true,
          value: null
        }
      },
      dob: {
        present: true,
        value: null
      }
    }
  ],
  tosAcceptance: {
    present: true,
    fullName: {
      present: true
    },
    date: {
      present: true
    },
    ip: {
      present: true
    }
  },
  verificationDocument: {
    present: true,
    document: {
      present: true,
      front: {
        present: true
      },
      back: {
        present: true
      }
    }
  }
};
export const AT_IndividualDeveloper: DeveloperModel = {
  contactInfo: CONTACT_INFO,
  stripeAccount: AT_IndividualAccount
};

export const AT_CompanyAccount: StripeCompanyAccountModel = {
  present: true,
  type: 'company',
  country: Country.AT,
  externalAccount: {
    country: Country.AT,
    present: true,
    iban: {
      present: true
    }
  },
  name: {
    present: true
  },
  taxID: {
    present: true
  },
  address: {
    present: true,
    city: {
      present: true,
      value: null
    },
    addressOne: {
      present: true,
      value: null
    },
    addressTwo: {
      present: true,
      value: null
    },
    zipOrPostalCode: {
      present: true,
      value: null
    }
  },
  accountRepresentatives: [
    {
      present: true,
      name: {
        present: true,
        firstName: {
          present: true,
          value: null
        },

        lastName: {
          present: true,
          value: null
        }
      },
      address: {
        present: true,
        city: {
          present: true,
          value: null
        },
        addressOne: {
          present: true,
          value: null
        },
        addressTwo: {
          present: true,
          value: null
        },
        zipOrPostalCode: {
          present: true,
          value: null
        }
      },
      dob: {
        present: true,
        value: null
      }
    },
    {
      present: true,
      name: {
        present: true,
        firstName: {
          present: true,
          value: null
        },

        lastName: {
          present: true,
          value: null
        }
      },
      address: {
        present: true,
        city: {
          present: true,
          value: null
        },
        addressOne: {
          present: true,
          value: null
        },
        addressTwo: {
          present: true,
          value: null
        },
        zipOrPostalCode: {
          present: true,
          value: null
        }
      },
      dob: {
        present: true,
        value: null
      }
    },
    {
      present: true,
      name: {
        present: true,
        firstName: {
          present: true,
          value: null
        },

        lastName: {
          present: true,
          value: null
        }
      },
      address: {
        present: true,
        city: {
          present: true,
          value: null
        },
        addressOne: {
          present: true,
          value: null
        },
        addressTwo: {
          present: true,
          value: null
        },
        zipOrPostalCode: {
          present: true,
          value: null
        }
      },
      dob: {
        present: true,
        value: null
      }
    }
  ],
  tosAcceptance: {
    present: true,
    fullName: {
      present: true
    },
    date: {
      present: true
    },
    ip: {
      present: true
    }
  },
  verificationDocument: {
    present: true,
    document: {
      present: true,
      front: {
        present: true
      },
      back: {
        present: true
      }
    }
  }
};

export const AT_CompanyDeveloper: DeveloperModel = {
  contactInfo: CONTACT_INFO,
  stripeAccount: AT_CompanyAccount
};
