import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { head } from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class UtilService {
  first<T>(array: ArrayLike<T>) {
    return head(array);
  }

  doesExist(val: any) {
    return !!val;
  }

  recurseFormControls(fg: FormGroup, callback: (control: AbstractControl|FormGroup)=>void) {
    Object.keys(fg.controls).map(x => fg.controls[x]).forEach((control: any) => {
      callback(control);
      if (control.controls) {
          this.recurseFormControls(control, callback);
      }
    });
  }


  readFile(file: File): Observable<string> {
    return Observable.create(observer => {
      if (!(file)) {
        observer.error(new Error('UtilService.readFile: missing file.'));
        observer.complete();
        return;
      }
      const reader = new FileReader();
      reader.onabort = (err) => observer.error(err);
      reader.onerror = (err) => observer.error(err);
      reader.onloadend = () => observer.complete();
      reader.onload = (ev) => {
        if (ev && ev.target && (ev.target as any).result) {
          observer.next((ev.target as any).result);
        }
      }
      reader.readAsDataURL(file);
    });
  }
}
