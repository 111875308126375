import { Component, ElementRef, Inject, OnDestroy, ViewEncapsulation, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, } from '@angular/material';
import { DomSanitizer, SafeHtml, } from '@angular/platform-browser';
import { IDialogAction, } from './dialog.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfimationModalComponent implements OnDestroy {

  public headerText: string;
  public bodyText: string;
  public bodyHtml: SafeHtml;
  public disableBackClick: boolean;
  public disableCloseBtn: boolean;
  public buttons: IDialogAction[];
  public headerTemplate: ElementRef;
  public footerTemplate: ElementRef;
  public bodyTemplate: ElementRef;
  public noScrollBody: boolean;
  public hPadding: string;
  public headerPaddingTop: string;
  public headerMarginBotttom: string;
  public footerMinHeight: string;

  private defaultHPadding = "initial"; // 96px;
  private defaultHeaderPaddingTop = "56px";
  private defaultHeaderMarginBottom = "40px";
  private defaultFooterMinHeight = "initial"; // "108px";

  constructor(
    private dialog: MatDialogRef<ConfimationModalComponent>,
    private sanitize: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.bodyText = data.messageData.text;
    if (data.messageData.htmlText) {
        this.bodyHtml = this.sanitize.bypassSecurityTrustHtml(data.messageData.htmlText);
    }
    this.headerText = data.messageData.title;
    this.buttons = data.messageData.actions;
    this.disableBackClick = data.messageData.disableBackClick;
    this.disableCloseBtn = data.messageData.disableCloseBtn;
    this.headerTemplate = data.messageData.headerContentRef;
    this.footerTemplate = data.messageData.footerContentRef;
    this.bodyTemplate = data.messageData.bodyContentRef;
    this.noScrollBody = data.messageData.noScrollBody;
    this.hPadding = data.messageData.hPadding?data.messageData.hPadding:this.defaultHPadding;
    this.headerPaddingTop = data.messageData.headerPaddingTop?data.messageData.headerPaddingTop:this.defaultHeaderPaddingTop;
    this.headerMarginBotttom = data.messageData.hPadding?data.messageData.headerMarginBotttom:this.defaultHeaderMarginBottom;
    this.footerMinHeight = data.messageData.footerMinHeight?data.messageData.footerMinHeight:this.defaultFooterMinHeight;
  }

  closeDialog(result?: any) {
      this.dialog.close(result);
  }

  buttonClick(btn: IDialogAction) {
    if (btn.disabled) {
      return;
    }
    if (btn.callBack) {
        if (btn.keepOpen) {
          btn.callBack(this.dialog);
        } else {
          this.dialog.close(btn.callBack(this.dialog));
        }
    } else {
        this.dialog.close(btn.result);
    }
  }

  ngOnDestroy() {
  }
}
