import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  questions = [
    {
      question: 'What types of hardware is Leia featured in?',
      answer:
        'We just recently launched on the <a class="underline-link" style="text-decoration: underline;" href="https://www.lume-pad.com" target="_blank" rel="noopener noreferrer">Lume Pad</a> Tablet and previously on the RED Hydrogen™ One mobile device. Our ecosystem will continue to grow, eventually including other devices such as laptops and TV screens.'
    },
    {
      question: 'Which operating systems do you support?',
      answer:
        "We currently support Android mobile operating system running on the Lume Pad Tablet. We plan to expand to other devices and OS platforms in the future, so keep checking back for updates."
    },
    {
      question: 'What development tools work with Leia technology?',
      answer:
        "Leia currently supports Android Native, Blender, Maya and Unity. Soon, Leia will also work with Unreal Engine, Autodesk, After Effects and SOLIDWORKS. Additionally, with Leia's Lightfield Studio, we give video creators the power to convert their own 2D content into dazzling 3D with ease."
    },
    {
      question: 'On average, how long does it take to implement Leia compatibility within an application?',
      answer:
        "In Unity, for example, it takes all of 5 minutes to implement the plug in -- and from there, from 30 minutes to about 2 days of optimizing in most cases. We should note that the Android Native SDK is the exception and may take longer."
    },
    {
      question: 'Will In-App Purchase (IAP) be supported?',
      answer: 'Leia appstore supports IAP.'
    },
    {
      question: 'Will Leia appstore support ads?',
      answer:
        "We don't currently support ads directly, however apps distributed through the Leia appstore are not prohibited from displaying ads using another ad network."
    },
    {
      question: 'Can I adapt my current application for Leia appstore?',
      answer:
        "We provide tools such as the Leia Unity and Android SDK's to make the adaptation of existing applications easy and straightforward."
    },
    {
      question:
        'What impact does this have on application size or performance?',
      answer: 'There is minimal impact on both size and performance.'
    },
    {
      question: 'Can I preview my application on any device?',
      answer:
        'You can preview your Android application on any Android device (requires minimum Android version 10.0 on the Lume Pad). However, there are tools to help simulate the effect of having a Leia Display. To see the real thing, join our community to gain access to a Developer Kit, Lit by Leia™.'
    },
    {
      question: 'How different is the display in the Lume Pad than previous Leia-powered devices?',
      answer:
        "We are constantly improving the technology behind our screens. The Lume Pad features our 2nd-generation Lightfield display, taking the immersive feel to a whole new level.<br/><br/>It’s also the biggest display commercialized by Leia to date. The 10.8-inch screen delivers stunning, immersive experiences right in the palm of your hands. On top of that, we’ve made improvements to the brightness and physical Lightfield design for an optimum viewing comfort. In short, better viewing angles and easier to see the effect."
    },
    {
      question: 'What powers the Lume Pad?',
      answer:
        'The Lume Pad runs briskly on Android 10 (with GMS support), powered by a SnapDragon 845 2.8GHz chipset. With it, the Lume Pad is able to capture, display -- and even quickly edit -- 3D content.'
    },
    {
      question: "What are the Lume Pad's on-board cameras capable of capturing?",
      answer:
        "The main 16MP camera - with it's rear-mounted dual lenses -- offers f/1.8, wide, close-loop, and AF. Features include an LED flash, auto-HDR...and, of course, it's capable of capturing 3D pictures and videos as well as real time depth maps.<br/>The main camera can record video at 1080p@30/60fps, 720p@30fps/60fps/120fps<br/><br/>The front-facing 8MP selfie cameras are set at f/2.0, wide (main); TOF HFOV 87.8°), QVGA (Aux). It works great for video calls as well as offering auto-HDR and Depth Sensing."
    },
    {
      question: 'What 3D formats of pictures and videos are supported on the Lume Pad?',
      answer:
        "The LumePad supports both Leia Image Format and SBS 3D JPEG images. Additional formats may be added in the future so keep checking back for more updates.<br/><br/>Photos<br/>JPEG (Leia Image Format, 2D)<br/>PNG (Leia Image Format, 2x2, 2x1, 2D)<br/>Webp (2x2, 2x1, 2D)<br/><br/>Videos<br/>MP4 (.H4V + 2D) WEBM<br/>(2x1,2x2,2D)"
    },
    
  ];

  @HostBinding('class')
  classes = 'container-fluid';
}
