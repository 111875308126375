import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as ip from 'public-ip';

export const IP_ADDRESS_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IpAddressInputComponent),
  multi: true
};
@Component({
  selector: 'app-ip-address-input',
  templateUrl: './ip-address-input.component.html',
  styleUrls: ['./ip-address-input.component.scss'],
  providers: [IP_ADDRESS_INPUT_VALUE_ACCESSOR]
})
export class IpAddressInputComponent implements OnInit {
  @Input()
  ipv4: string;
  @Output()
  ipv4Change = new EventEmitter<string>();

  private _value: string;
  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.notifyValueChange(value);
  }

  onChange: (value) => {};
  onTouched: () => {};

  ngOnInit() {
    this.getIP();
  }

  ngOnDestroy() {
    this.getIP();
  }

  async getIP() {
    return ip
      .v4()
      .then(ipaddr => {
        this.ipv4 = ipaddr;
        this.ipv4Change.emit(ipaddr);
        console.log(ipaddr);
        this.value = ipaddr;
      })
      .catch(err => console.log(err));
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    if (value === null) {
      this.getIP();
    }

    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
