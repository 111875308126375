import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators, } from '@angular/forms';
import { FileValidator } from "ngx-material-file-input";

export enum IDynFormFieldType {
    'string' = 'string',
    'option' = 'option',
    'optionWithChild' = 'option-with-child',
    'datetime' = 'datetime',
    'upload' = 'upload',
    'phone' = 'phone',
}

// export const validationErrors = {
//     dynFormRange: {
//         id: "dynFormRange",
//         errorMessage: ""
//     }
// }

export enum IDynFormFieldValidatorType {
    pattern = "dfvPattern",
    range = "dfvRange",
    callback = "dfvCallback",
    length = "dfvLength",
    fileLength = "dfvFileLength",
}

export enum IDynFormLengthValidationOptions {
    trim,
    ignoreWhiteSpace,
    ignoreWhiteSpaceMinus,
}

export interface IDynFormFieldValidator {
    type: IDynFormFieldValidatorType;
    minValue?: number;
    maxValue?: number;
    minLength?: number;
    maxLength?: number;
    lengthOption?: IDynFormLengthValidationOptions;
    pattern?: RegExp;
    callback?: (formCtrl: AbstractControl, formGroup: FormGroup, field: IDynFormField) => { [key: string]: any },
    errorMessage?: string;
    errorMessageParam?: any;
}

export interface IDynFormFieldOption {
    id?: string;
    key?: string;
    name?: string;
    value?: string;
    children?: IDynFormFieldOption[];
    parentId?: string;
}

export enum EDynFormFieldDateOption {
    unix,
    utc,
    millisec,
    ymdStruct,
}

export interface IDynFormField {
    id: string;
    name: string;
    required?: boolean;
    schema?: {
        type: IDynFormFieldType;
        validators?: IDynFormFieldValidator[];
        dateType?: EDynFormFieldDateOption;
    };
    value?: string;
    allowedValues?: IDynFormFieldOption[];
    sortOrder?: number;
    readOnly?: boolean;
    infoText?: string;
    disabled?: boolean;
}

@Injectable()
export class DynamicFormService {

    getFileLengthValidator(maxLength: number, errorMessage: string) {
        if (maxLength) {
            return FileValidator.maxContentSize(maxLength);
        }
    }

    getLengthValidator(
        minLength: number,
        maxLength: number = null,
        opt: IDynFormLengthValidationOptions = null,
        errorMessage?: string,
    ): ValidatorFn {

        return (ctrl: AbstractControl) => {
            const err: any = {};
            err[IDynFormFieldValidatorType.length] = errorMessage;
            if (ctrl) {
                let val: string = ctrl.value
                // if (minLength && !(val && val.length)) {
                //     return err;
                // }
                if (!(val && val.length)) {
                    return null;
                }
                switch (opt) {
                    case IDynFormLengthValidationOptions.ignoreWhiteSpace:
                        val = val.replace(/\s/g,'');
                    break;
                    case IDynFormLengthValidationOptions.ignoreWhiteSpaceMinus:
                        val = val.replace(/-|\s/g,'');
                    break;
                    case IDynFormLengthValidationOptions.trim:
                        val = val.trim();
                    break;
                    default:
                }
                if ((minLength && val.length < minLength) || (maxLength && val.length > maxLength)) {
                    return err;
                }
            }
            return null;
        }
    }

    getPatternValidator(expr: RegExp, errorMessage: string): ValidatorFn {
        return (ctrl: AbstractControl) => {
            if (expr && ctrl.value && !expr.test(ctrl.value)) {
                const err: any = {};
                err[IDynFormFieldValidatorType.pattern] = errorMessage || true;
                return err;
            }
            return null;
        }
    }

    getRangeValidator(minVal: number, maxVal: number, errorMessage: string): ValidatorFn {
        return (ctrl: AbstractControl) => {
            let fail = false;
            if (ctrl && ctrl.value) {
                if (minVal && ctrl.value < minVal) {
                    fail = true;
                } else if (maxVal && ctrl.value > maxVal) {
                    fail = true;
                }
                if (fail) {
                    const err: any = {};
                    err[IDynFormFieldValidatorType.range] = errorMessage || true;
                    return err;
                }
            }
            return null;
        }
    }

    getCallbackValidator(callback: (formCtrl: AbstractControl, formGroup: FormGroup, field: IDynFormField) => { [key: string]: any }, formGroup, errorMessage: string, field: IDynFormField): ValidatorFn {
        return (ctrl: AbstractControl) => {
            return callback(ctrl, formGroup, field);
        }
    }

    getValidators(field: IDynFormField, formGroup?: FormGroup): ValidatorFn[] {
        const validators: ValidatorFn[] = [];
        if (field.required) {
            validators.push(Validators.required);
        }
        if (field.schema.validators && field.schema.validators.length) {
            field.schema.validators.forEach(v => {
                switch (v.type) {
                    case IDynFormFieldValidatorType.range:
                        validators.push(this.getRangeValidator(v.minValue, v.maxValue, v.errorMessage));
                        break;
                    case IDynFormFieldValidatorType.length:
                        validators.push(this.getLengthValidator(v.minLength, v.maxLength, v.lengthOption, v.errorMessage));
                        break;
                    case IDynFormFieldValidatorType.pattern:
                        validators.push(this.getPatternValidator(v.pattern, v.errorMessage));
                        break;
                    case IDynFormFieldValidatorType.callback:
                        validators.push(this.getCallbackValidator(v.callback, formGroup, v.errorMessage, field));
                        break
                    case IDynFormFieldValidatorType.fileLength:
                        if (field.schema.type === IDynFormFieldType.upload) {
                            validators.push(FileValidator.maxContentSize(v.maxLength));
                        }
                        break;
                    default:
                }
            });
        }
        return validators;
    }

    createDynForm(fieldList: IDynFormField[]): FormGroup {
        const fg = new FormGroup({});
        fieldList.forEach((field) => {
            fg.addControl(field.id, this.createDynField(field, fg));
        });
        return fg;
    }

    createDynField(field: IDynFormField, formGroup?: FormGroup) {
        return new FormControl({ value: field.value, disabled: false }, this.getValidators(field, formGroup));
    }
}
