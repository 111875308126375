import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-awaiting-approval',
  templateUrl: './awaiting-approval.component.html',
  styleUrls: ['./awaiting-approval.component.scss']
})
export class AwaitingApprovalComponent implements OnInit {
  @Input() quantity;
  @Input() label;
  @Input() title;
  @Output() showApproval = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  showApprovalList() {
    this.showApproval.emit();
  }
}
