import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { RadioButtonOption } from 'app/common.module/components/radio-button-set/radio-button-set.component';
import { DeveloperModel } from 'app/models/stripe-account';
import * as _ from 'lodash';
import { Moment } from 'moment';
import { AddressInputLabels } from '../../../common.module/components/address-form/address-form.component';

@Component({
  selector: 'app-individual-account',
  templateUrl: './individual-account.component.html',
  styleUrls: ['./individual-account.component.scss']
})
export class IndividualAccountComponent {
  @Input()
  readOnly = false;
  @Input()
  isWhite = false;
  @Input()
  time: Moment;
  @Input()
  parent: FormGroup;

  private _parent: FormGroup;

  private readonly _addressLabels: AddressInputLabels = {
    addressOneLabel: 'Business Address #1',
    addressTwoLabel: 'Business Address #2',
    cityLabel: 'Business City',
    stateOrProvinceLabel: 'Business State/Province',
    zipOrPostalCodeLabel: 'Business Zip/Postal Code'
  };

  // TODO: move to service
  private readonly _gbAccountOptions = [
    { name: 'Standard', value: 'standard' },
    { name: 'IBAN', value: 'iban' }
  ];

  private _accountTypeSelection: 'iban' | 'standard';
  private _accountOptions = [
    { name: 'Standard', value: 'standard' },
    { name: 'IBAN', value: 'iban', selected: true }
  ];

  private _countryTemplate: DeveloperModel;
  get addressLabels(): AddressInputLabels {
    return this._addressLabels;
  }

  get verificationAsControl() {
    return this.parent.get(['stripeAccount', 'verificationDocument']) as FormGroup;
  }

  get tosAcceptanceAsControl() {
    return this.parent.get(['stripeAccount', 'tosAcceptance']) as FormGroup;
  }

  get gbAccountTypeOptions() {
    return this._gbAccountOptions;
  }
  get accountTypeSelection() {
    if (!this._accountTypeSelection) {
      return this.findGBAccountTypeOption('iban');
    }

    const formControl = this.parent.get(['stripeAccount', 'externalAccount', 'accountTypeSelection']);
    const controlValue = _.get(formControl, 'value', null);
    return this.findGBAccountTypeOption(controlValue);
  }
  set accountTypeSelection(rbOption: RadioButtonOption) {
    if (!!rbOption) {
      const { value } = rbOption;

      if (!!value) {
        const externalAccountControl = this.parent.get(['stripeAccount', 'externalAccount']);
        const accountSelectionControl = this.parent.get(['stripeAccount', 'externalAccount', 'accountTypeSelection']);
        const prevSelection = this._accountTypeSelection;

        if (prevSelection !== value) {
          externalAccountControl.reset();
          accountSelectionControl.setValue(value);
        }

        this._accountTypeSelection = value;
      }
    }
  }

  get accountOptions() {
    return this._accountOptions;
  }

  get country() {
    return this.parent.get(['stripeAccount', 'country']) as FormControl;
  }

  get isGB() {
    return 'GB' === _.get(this.country, 'value', null);
  }

  @Input()
  set countryTemplate(model: DeveloperModel) {
    if (!!model) {
      this._countryTemplate = model;
      // this.cdr.detectChanges();
    }
  }
  get countryTemplate() {
    return this._countryTemplate;
  }

  get accountRepresentatives() {
    return (this.parent.get(['stripeAccount', 'accountRepresentatives']) as FormArray).controls;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  findGBAccountTypeOption(option: 'standard' | 'iban'): RadioButtonOption {
    return this.gbAccountTypeOptions.find(op => op.value === option);
  }
}
