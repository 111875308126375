import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, OnInit } from '@angular/core';
import { SubMenu } from '../../../models/sub-menu';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

@Component({
  selector: 'sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit {
  @ContentChild(TemplateRef)
  template: TemplateRef<any>;
  @Input() defaultNavWrapperWidth = '25';
  @Input() menu: SubMenu[];
  @Output() navigationChange = new EventEmitter<any>();

  private activeItem: SubMenu;
  constructor(private router: Router) {}

  ngOnInit() {
    if (this.menu && this.menu.length) {
      const urlTree: UrlTree = this.router.parseUrl(this.router.url);
      let path: string;
      if (urlTree.root.children.primary.segments.length) {
        path = urlTree.root.children.primary.segments[urlTree.root.children.primary.segments.length-1].path;
      }
      if (path) {
        this.activeItem = this.menu.find(item => item.router === path);
      }
    }
  }


  navChange(newItem: SubMenu) {
    this.navigationChange.emit({ oldItem: this.activeItem, newItem: newItem });
    this.activeItem = newItem;
  }
}
