import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GuardsCheckEnd, Router } from '@angular/router';
import { ModalComponent } from 'app/common.module/components/modal/modal.component';
import { App } from 'app/models/app';
import { sortBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from '../../../services/app.service';
import { UserService } from '../../../services/user.service';
import { UtilService } from '../../../services/util.service';
import { IAutoUpdateChange } from '../submission/app-main-tile.component';
import { AppStatus } from '@enums/app-status.enum';

export interface TitleListItem {
  name: string;
  value: string;
  class: string;
}

@Component({
  selector: 'apps-table',
  templateUrl: './apps-table.component.html',
  styleUrls: ['./apps-table.component.scss']
})
export class AppsTableComponent implements OnInit, OnDestroy {
  @ViewChild('accessDeniedModal')
  accessDeniedModal: ModalComponent;

  @ViewChild('approveModal')
  approveModal: ModalComponent;

  appSelected$: Observable<App>;
  index$: Subject<number> = new Subject();
  private _apps: App[];

  destroy$: Subject<boolean> = new Subject();

  get apps() {
    return this._apps;
  }
  @Input()
  set apps(value: App[]) {
    if (!!value) {
      this._apps = value;
      // there is no any sorted list yet
      if (!this.sortedApps) {
        // select first item as default
        this.sortApps(this.svcUtil.first(this.titleList).value);
      } else {
        this.sortApps(this.currentSortingType, true);
      }
    }
  }

  sortedApps: App[];
  isAdmin = false;
  isReverseSort = false;
  currentSortingType = '';
  hideTestApps = true;

  private adminTitleItem: TitleListItem = {
    name: 'DEV',
    value: 'developerName',
    class: 'dev'
  };

  titleList: TitleListItem[] = [
    {
      name: 'APP NAME',
      value: 'title',
      class: 'app'
    },
    {
      name: 'DEVICE MODEL',
      value: 'deviceModel',
      class: 'device-model'
    },
    {
      name: 'RATING',
      value: 'userRating',
      class: 'rating'
    },
    {
      name: 'VER',
      value: 'appVersion',
      class: 'ver'
    },
    {
      name: 'DWNLDS',
      value: 'downloads',
      class: 'dwnlds'
    },
    {
      name: 'REVENUE',
      value: 'revenue',
      class: 'revenue'
    },
    {
      name: 'LAST UPDATE',
      value: 'lastUpdate',
      class: 'update'
    },
    {
      name: 'STATUS',
      value: 'appStatus',
      class: 'status'
    }
  ];

  constructor(
    private appService: AppService,
    private userService: UserService,
    private router: Router,
    protected svcUtil: UtilService
  ) {
    this.isAdmin = userService.isAdmin();
    if (this.isAdmin) {
      this.titleList.push({
        name: 'AUTOUPDATE',
        value: 'autoUpdate',
        class: 'autoupdate'
      });

      this.titleList.splice(3, 0, this.adminTitleItem);
    }
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof GuardsCheckEnd && !event.shouldActivate) {
        this.accessDeniedModal.show();
      }
    });
  }
  onShowTestApp() {
    console.log('hello', this.hideTestApps);
  }

  sortApps(prop, keepSortStatus = false) {
    const sortedList = sortBy(this.apps.slice(), prop);

    if (!keepSortStatus) {
      if (this.currentSortingType === prop) {
        this.isReverseSort = !this.isReverseSort;
      } else {
        this.currentSortingType = prop;
        this.isReverseSort = false;
      }
    }

    this.sortedApps = this.isReverseSort ? sortedList : sortedList.reverse();
  }

  clickApp(app) {
    this.router.navigate(['/app/editapp', app.id, app.productVersionId]);
  }

  clickAppTitle(app) {
    this.router.navigate(['/app/editapp', app.id, app.productVersionId]);
  }

  updateListAutoUpdate(rec: IAutoUpdateChange) {
    this.apps
      .filter(
        app =>
          app.id === rec.appId &&
          (app.appStatus === AppStatus.awaiting_approval ||
            app.appStatus === AppStatus.live ||
            app.appStatus === AppStatus.not_submitted)
      )
      .forEach(app => {
        app.forceUpdate = rec.autoUpdate;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
