import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-button',
  templateUrl: './modal-button.component.html',
  styleUrls: ['./modal-button.component.scss'],
  exportAs: 'app-modal-button'
})
export class ModalButtonComponent {
  @Input()
  isWhite = false;
  @Input()
  isDisabled = false;
  @Output()
  onClick = new EventEmitter<MouseEvent>();
}
