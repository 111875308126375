import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { dialogButtons, MessageDialogServiceImpl, MessageDialogServiceToken, } from '@common/components/confimation-modal/dialog.service';
import { ModalComponent } from '@common/components/modal/modal.component';
import { App, AppDetailsChanges, } from '@models/app';
import { AppService } from '@services/app.service';
import { UserService } from '@services/user.service';
import { UtilService } from '@services/util.service';
import { leiaEndpointToken } from 'endpoints';
import { Observable, Subject } from 'rxjs';
import { flatMap, pluck, takeUntil } from 'rxjs/operators';

type UploaderImage =
  | 'cover'
  | 'thumbnail'
  | 'promoVideo'
  | 'promoVideoPreview'
  | 'screenshots0'
  | 'screenshots1'
  | 'screenshots2'
  | 'screenshots3'
  | 'screenshots4'
  | '';

@Component({
  selector: 'app-edit-imagery',
  templateUrl: './app-edit-imagery.component.html',
  styleUrls: ['./app-edit-imagery.component.scss'],
})
export class AppEditImageryComponent implements OnInit {
  @ViewChild('uploader')
  uploader;

  @ViewChild('uploaderPng')
  uploaderPng;

  @ViewChild('uploaderMp4')
  uploaderMp4;

  @ViewChild('goBackConfirmation')
  goBackConfirmation: ModalComponent;

  @Input() readOnly = false;
  @Input() formGroup: FormGroup;
  @Input() lists: any;

  onDestroy$: Subject<boolean> = new Subject();
  isAdmin = false;
  fileExtensionError = false;
  imageDimensionsError = false;
  isDownloadTypeDisabled: any = {};
  dropHoverColor = "#eee";

  get thumbnailError() { return this.formGroup.get("thumbnail").invalid && this.formGroup.get("thumbnail").touched; }
  get coverError() { return this.formGroup.get("cover").invalid && this.formGroup.get("cover").touched; }
  get screenShotError() {
      return (this.formGroup.get("screenshots0").invalid && this.formGroup.get("screenshots0").touched)
        || (this.formGroup.get("screenshots1").invalid && this.formGroup.get("screenshots1").touched)
        || (this.formGroup.get("screenshots2").invalid && this.formGroup.get("screenshots2").touched);
  }

  @Input() app: App;
  @Input() appChanges: AppDetailsChanges[];

  file: any;
  url: string = null;
  uploaderImageType: UploaderImage = '';
  saveInProgress = false;

  @Output() backClick: EventEmitter<any> = new EventEmitter();
  @Output() nextClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private userService: UserService,
    protected svcUtil: UtilService,
    protected cdr: ChangeDetectorRef,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
    @Inject(leiaEndpointToken) private leiaEndpoints,
  ) {
    this.isAdmin = userService.isAdmin();
  }

  showAlert(messageText: string) {
      this.dialogService.openMessageDialog({
          title: "Alert",
          text: messageText,
          actions: [dialogButtons.ok],
      });
  }

  ngOnInit() {
  }

  dropFile(imageType: UploaderImage, files: FileList) {
    if (this.readOnly) {
      return;
    }

    this.uploaderImageType = imageType;

    switch (imageType) {
        case "thumbnail": {
          this.uploaderPng.nativeElement.files = files;
          this.uploaderPng.nativeElement.dispatchEvent(new Event("change"));
          break;
        }
        case "cover":
        case "promoVideoPreview" :
        case "screenshots0" :
        case "screenshots1" :
        case "screenshots2" :
        case "screenshots3" :
        case "screenshots4" : {
          this.uploader.nativeElement.files = files;
          this.uploader.nativeElement.dispatchEvent(new Event("change"));
          break;
        }
        case "promoVideo" : {
          this.uploaderMp4.nativeElement.files = files;
          this.uploaderMp4.nativeElement.dispatchEvent(new Event("change"));
          break;
        }
      }
  }

  uploaderClick(imageType: UploaderImage) {
    if (this.readOnly) {
      return;
    }
    this.uploaderImageType = imageType;
    this.uploader.nativeElement.click();
  }

  uploaderPngClick(imageType: UploaderImage) {
    if (this.readOnly) {
      return;
    }
    this.uploaderImageType = imageType;
    this.uploaderPng.nativeElement.click();
  }

  uploaderVideoClick() {
    if (this.readOnly) {
      return;
    }
    this.uploaderMp4.nativeElement.click();
  }

  uploadImage($event) {
    if (this.readOnly) {
      return;
    }
    // TODO: Needs to use image uploader to AWS (similar to old submission apps)
    this.file = this.svcUtil.first($event.target.files);

    if ($event.target.files && this.file && this.uploaderImageType.length > 0) {
      const reader = new FileReader();

      const filesize = this.file.size / 1024 / 1024;

      this.fileExtensionError = this.checkExtensionsError();
      if (!this.fileExtensionError) {
        reader.onload = (event: any) => {
          const image = new Image();
          image.onload = () => {
            this.imageDimensionsError = this.checkDimensionImageError(image.height, image.width);
            if (!this.imageDimensionsError) {
              this.uploadNewImage(this.file, this.uploaderImageType);
            }
          };
          image.src = event.target.result;
        };
        reader.readAsDataURL(this.file);
      }
    }
  }

  checkExtensionsError() {
    switch (this.uploaderImageType) {
      case 'thumbnail':
        if (/\.(png)$/i.test(this.file.name) === false) {
          this.showAlert('The file must be a PNG');
          return true;
        }
        break;
      case 'cover':
      case 'promoVideoPreview':
      case 'screenshots0':
      case 'screenshots1':
      case 'screenshots2':
      case 'screenshots3':
      case 'screenshots4':
        if (
          /\.(png)$/i.test(this.file.name) === false &&
          /\.(jpg)$/i.test(this.file.name) === false &&
          /\.(jpeg)$/i.test(this.file.name) === false
        ) {
          this.showAlert('The file must be a PNG or JPG');
          return true;
        }
        break;
      case 'promoVideo':
        if (/\.(mp4)$/i.test(this.file.name) === false) {
          this.showAlert('The file must be a MP4');
          return true;
        }
        break;
    }

    return false;
  }

  checkDimensionImageError(height: number, width: number) {
    if (this.isLp1()) {
      return this.checkDimensionImageErrorLp1(height, width);
    } else {
      return this.checkDimensionImageErrorLp2(height, width);
    }
  }

  checkDimensionImageErrorLp1(height: number, width: number) {
    switch (this.uploaderImageType) {
      case 'thumbnail':
        if (width !== 512 || height !== 512) {
          this.showAlert('The App Icon must be 512x512px');
          return true;
        }
        break;
      case 'cover':
        if (
          (width === 480 && height === 384) ||
          (width === 1920 && height === 1536)
        ) {
          return false;
        } else {
          this.showAlert('The Hero image must be 480x384px or 1920x1536px');
          return true;
        }
      case 'promoVideoPreview':
        if (
          (height === 360 && width === 640) ||
          (height === 640 && width === 360) ||
          (height === 720 && width === 1280) ||
          (height === 1280 && width === 720) ||
          (height === 1440 && width === 2560) ||
          (height === 2560 && width === 1440)
        ) {
          return false;
        } else {
          this.showAlert('The landscape promotional video image must be 640x360px or 1280x720px or 2560x1440px and the portrait promotional video image must be 360x640px or 720x1280px or 1440x2560px');
          return true;
        }
      case 'screenshots0':
      case 'screenshots1':
      case 'screenshots2':
      case 'screenshots3':
      case 'screenshots4':
        if (
          (height === 360 && width === 640) ||
          (height === 640 && width === 360) ||
          (height === 720 && width === 1280) ||
          (height === 1280 && width === 720) ||
          (height === 1440 && width === 2560) ||
          (height === 2560 && width === 1440)
        ) {
          return false;
        } else {
          this.showAlert(
            'The landscape screenshots must be 640x360px or 1280x720px or 2560x1440px and the portrait screenshots must be 360x640px or 720x1280px or 1440x2560px'
          );
          return true;
        }
    }

    return false;
  }

  checkDimensionImageErrorLp2(height: number, width: number) {
    switch (this.uploaderImageType) {
      case 'thumbnail':
        if (width !== 512 || height !== 512) {
          this.showAlert('The App Icon must be 512x512px');
          return true;
        }
        break;
      case 'cover':
        if (
          (width === 480 && height === 384) ||
          (width === 1920 && height === 1536)
        ) {
          return false;
        } else {
          this.showAlert('The Hero image must be 480x384px or 1920x1536px');
          return true;
        }
      case 'promoVideoPreview':
        if (
          (height === 360 && width === 640) ||
          (height === 720 && width === 1280) ||
          (height === 1440 && width === 2560)
        ) {
          return false;
        } else {
          this.showAlert('The promotional video image for Lume Pad 2 app must be landscape 640x360px or 1280x720px or 2560x1440px');
          return true;
        }
      case 'screenshots0':
      case 'screenshots1':
      case 'screenshots2':
      case 'screenshots3':
      case 'screenshots4':
        if (
          (height === 360 && width === 640) ||
          (height === 720 && width === 1280) ||
          (height === 1440 && width === 2560)
        ) {
          return false;
        } else {
          this.showAlert('The screenshots for Lume Pad 2 app must be landscape 640x360px or 1280x720px or 2560x1440px');
          return true;
        }
    }

    return false;
  }

  uploadVideo($event) {
    if (this.readOnly) {
      return;
    }
    // TODO: Needs to use image uploader to AWS (similar to old submission apps)
    if ($event.target.files && $event.target.files[0]) {
      const reader = new FileReader();

      this.file = $event.target.files[0];
      const filesize = this.file.size / 1024 / 1024;

      if (/\.(mp4)$/i.test(this.file.name) === false) {
        this.showAlert('The file must be a MP4');
      } else {
        reader.onload = (event: any) => {
          const videoTitle = ($event && $event.target && $event.target.files && $event.target.files.length)
            ?$event.target.files[0].name:"";
          this.uploaderImageType = 'promoVideo';
          this.uploadNewImage($event.target.files[0], this.uploaderImageType);
          this.app[this.uploaderImageType] = null;
          this.app.promoVideoTitle = videoTitle;
        };
        reader.readAsDataURL($event.target.files[0]);
      }
    }
  }

  updateFormFields(imgType: UploaderImage, update: boolean, touch?: boolean) {
    if(imgType.includes("screenshot")) {
      [0, 1, 2, 3, 4].forEach(i =>{
        const scrFlt = this.app.screenshots.filter(s => !!s);
        if (scrFlt && (scrFlt.length - 1 >= i)) {
            if (update) {
                this.formGroup.get("screenshots" + i).setValue(scrFlt[i]);
            }
            if (touch) {
                this.formGroup.get("screenshots" + i).markAsTouched();
            }
        } else {
            if (update) {
                this.formGroup.get("screenshots" + i).setValue(null);
            }
            if (touch) {
                this.formGroup.get("screenshots" + i).markAsTouched();
            }
        }
      });
    } else {
      switch (imgType) {
        case "thumbnail":
        case "promoVideo":
        case "promoVideoPreview":
        case "cover":
            if (update) {
                this.formGroup.get(imgType).setValue(this.app[imgType]);
            }
            if (touch) {
                this.formGroup.get(imgType).markAsTouched();
            }
        break;
      }
    }
  }

  uploadNewImage(data, uploaderImageType: UploaderImage) {
    if (this.readOnly) {
      return;
    }

    const type = uploaderImageType.includes("screenshots")?"screenshot":uploaderImageType;

    this.uploaderPng.nativeElement.value = '';
    this.uploaderMp4.nativeElement.value = '';
    this.uploader.nativeElement.value = '';

    // console.log(data);

    if (this.app && this.app.id) {
      let screenshotNum: number = null;
      this.updateFormFields(uploaderImageType, false, true);

      let httpCall: Observable<any> = this.appService.uploadToimagery(this.app, type, data);
      this.isDownloadTypeDisabled[uploaderImageType] = true;
      if (uploaderImageType.includes('screenshots')) {
        screenshotNum = Number(uploaderImageType[uploaderImageType.length - 1]);
        if (isNaN(screenshotNum) || screenshotNum === null) {
          console.log("Error: AppImageryComponent.uploadNewImage(): incorrect upload type/screenshot number.");
          this.isDownloadTypeDisabled[uploaderImageType] = false;
          return;
        }
        if (this.app.screenshots[screenshotNum]) {
          httpCall = this.appService.deleteScreenshoot(this.app, screenshotNum).pipe(
            flatMap((res)=>{
              this.app.screenshots[screenshotNum].url = null;
              return this.appService.uploadToimagery(this.app, type, data);
            })
          );
        } else {
          const screenshot = {
            product_id: this.app.id,
            url: null,
          };
          this.app.screenshots[screenshotNum] = screenshot;
        }
      } else {
        this.app[uploaderImageType] = null;
      }
      httpCall.subscribe(
        (res: any) => {
          console.log('Success upload of image...');
          console.log(res);
          if (res && res.result && res.result.Location) {
            if (type === "screenshot" && (screenshotNum !== null) && this.app.screenshots && this.app.screenshots[screenshotNum]) {
              this.app.screenshots[screenshotNum].url = res.result.Location;
              this.app.screenshots[screenshotNum].screenshotId = res.screenshotId;
            } else {
              this.app[type] = res.result.Location;
            }
            this.isDownloadTypeDisabled[uploaderImageType] = false;
          }
          this.updateFormFields(uploaderImageType, true, true);
        },
        error => {
            this.isDownloadTypeDisabled[uploaderImageType] = false;
            console.log('Error...');
            console.log(error);
        }
      );
    }
  }

  deleteImage(type) {
    if (this.saveInProgress) {
      return;
    }
    this.saveInProgress = true;
    this.appService.deleteImage(this.app, type).subscribe(
      r => {
        switch (type) {
          case 'thumbnail':
            this.app.thumbnail = '';
            break;
          case 'cover':
            this.app.cover = '';
            break;
          case 'promoVideoPreview':
            this.app.promoVideoPreview = '';
            break;
          case 'promoVideo':
            this.app.promoVideo = '';
            this.app.promoVideoTitle = '';
            break;
        }
        this.updateFormFields(type, true, true);
      },
      () => {},
      () => {
        this.saveInProgress = false;
      }
    );
  }

  deleteScreenshot(screenshotId) {
    if (this.saveInProgress) {
      return;
    }
    this.saveInProgress = true;
    this.appService.deleteScreenshoot(this.app, screenshotId).subscribe(
      r => {
        this.app.screenshots.splice(screenshotId, 1);
        this.updateFormFields(("screenshots" + screenshotId) as UploaderImage, true, true);
      },
      () => {},
      () => this.saveInProgress = false
    );
  }

  downloadImage(image) {
    if (image) {
        window.open(image, "_blank");
    //   window.location.href = image;
    }
  }

  dropErrorDialog(errMsg: string) {
    this.showAlert(errMsg);
  }

  checkChanges(title: string) {
    return this.appChanges ? this.appChanges.some(chg => chg.fieldName === title) : false;
  }

  deviceDisplayName(): string {
    if (!this.app.deviceModel) {
      return ''
    }
    return this.lists.models.find((m) => m.device_model_id === this.app.deviceModel).device_model_name
  }

  isLp1(): boolean {
    return this.app.deviceModel === 'LP1'
  }

  backClicked() {
    if (this.isAdmin) {
      this.backClick.emit(true)
    } else {
      this.goBackConfirmation.show()
    }
  }

  nextClicked() {
      this.nextClick.emit(true);
  }

  onGoBackConfirmationClose() {
    this.goBackConfirmation.hide()
    this.backClick.emit(true)
  }
}
