import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InAppPurchase } from 'app/models/iap';
import { Locale } from 'app/models/locale';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import { ReportProgressHeader } from './http-progress.interceptor';
import { ProgressIndicatorService } from './progress-indicator.service';
import { Observable } from 'rxjs';

@Injectable()
export class IapService {
  constructor(
    private http: HttpClient,
    private svcProgress: ProgressIndicatorService,
    @Inject(leiaEndpointToken) private leiaEndpoints,
  ) {}
  async getIAPs(productId: string): Promise<InAppPurchase[]> {
    return this.http
      .get<InAppPurchase[]>(
        environment.leiaservices.url +
          this.leiaEndpoints.iap.getIaps.url +
          productId
      )
      .toPromise();
  }

  getIAPsObs(productId: string): Observable<InAppPurchase[]> {
    return this.http
      .get<InAppPurchase[]>(
        environment.leiaservices.url +
          this.leiaEndpoints.iap.getIaps.url +
          productId
      );
  }

  saveIAP(iap: InAppPurchase) {
    // const headers = new HttpHeaders().set(ReportProgressHeader, '');

    return this.http.post(
      environment.leiaservices.url + this.leiaEndpoints.iap.saveIap.url,
      iap,
      // { headers }
    );
  }
  uploadCSV(file: Blob, productId: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', ' plain/text')
      .set(ReportProgressHeader, '');
    return this.http.post(
      `${environment.leiaservices.url}${
        this.leiaEndpoints.iap.upload.url
      }${productId}`,
      file,
      { headers }
    );
  }
  downloadTemplate() {
    return this.http.get(
      `${environment.leiaservices.url}${
        this.leiaEndpoints.iap.download.url
      }`,
      { responseType: 'blob' }
    );
  }
}
