import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { NgModel } from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  DefaultUrlSerializer
} from '@angular/router';
import {
  dialogButtons,
  dprmConfirmLeavePageDialog,
  MessageDialogServiceImpl,
  MessageDialogServiceToken
} from '@common/components/confimation-modal/dialog.service';
import { INavigationGuardComponent } from '@common/components/navigation-guard/navigation-guard.component';
import { AppStatus } from '@enums/app-status.enum';
import { ComponentCommService } from '@services/component-comm.service';
import { ApkComponent } from 'app/common.module/components/apk/apk.component';
import { ModalComponent } from 'app/common.module/components/modal/modal.component';
import { ApkStatus } from 'app/enums/apk-status.enum';
import { ErrorProviderLeaf } from 'app/models/error-provider.model';
import { leiaGlobal } from 'endpoints';
import { forkJoin, interval, Observable, Subject } from 'rxjs';
import { map, pluck, takeUntil, takeWhile } from 'rxjs/operators';
import { AppDetailsResolver } from '../../../../../guards/app-details.resolver';
import { APK } from '../../../../../models/apk';
import { App, AppDetailsChanges } from '../../../../../models/app';
import { AppService } from '../../../../../services/app.service';
import { UserService } from '../../../../../services/user.service';
import { UtilService } from '../../../../../services/util.service';

export const flagPristineAPK = 'AppPackagesComponent_isPristine';
@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
  providers: [AsyncPipe]
})
export class AppPackagesComponent
  implements OnInit, OnDestroy, AfterViewInit, ErrorProviderLeaf, INavigationGuardComponent {
  @ViewChild('updateSuccessModal')
  updateSuccessModal: ModalComponent;
  @ViewChild('uploader', { read: NgModel }) uploader;

  // private readonly NOT_SUBMITTED: string = 'not submitted';
  // private readonly AWAITING_APPROVAL: string = 'awaiting approval';
  @ViewChildren(ApkComponent)
  children: QueryList<ApkComponent>;
  @Input()
  readOnly = false;

  private _navigatingAway = false;
  set navigatingAway(value) {
    this._navigatingAway = value;
  }
  get error() {
    // const isPristine = this.commService.getVar(flagPristineAPK);
    if (!!this.app && this.app.packages && this.app.packages.length > 0) {
      return false;
    }
    return true;
    // if (!isPristine || this.isDirty) {
    //   return true;
    // } else {
    //   return false;
    // }
  }
  app$: Observable<App> = this.route.parent.data.pipe(pluck('appDetails'));
  app: App = this.pipeAsync.transform(this.app$);
  appChanges$: Observable<AppDetailsChanges[]> = this.route.parent.data.pipe(pluck('appChanges'));
  appChanges: AppDetailsChanges[];

  showUploader = false;
  showNoAppYet = false;
  keepPulling = false;
  package: APK;
  filename = '';
  file: any;
  languages: any;
  language: string;
  isAdmin = false;

  isDirty = false;
  saveClicked = false;
  onDestroy$: Subject<boolean> = new Subject();
  emails: string;

  constructor(
    private appService: AppService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    protected svcResolver: AppDetailsResolver,
    private svcUtil: UtilService,
    protected pipeAsync: AsyncPipe,
    protected commService: ComponentCommService,
    @Inject(leiaGlobal) private leiaGlobalConfig,
    @Inject(MessageDialogServiceToken) protected dialogService: MessageDialogServiceImpl
  ) {
    this.isAdmin = userService.isAdmin();
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event) {
    if (this.isDirty) {
      event.returnValue = 'You have unsaved changes. Are you sure you want to close the page?';
      return 'You have unsaved changes. Are you sure you want to close the page?';
    }
    return undefined;
  }

  // getError() {
  //   const isPristine = this.commService.getVar(flagPristineAPK);
  //   if (!!this.app && this.app.packages && this.app.packages.length > 0) {
  //     return false;
  //   }
  //   // return true;
  //   if (!isPristine || this.isDirty) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  navigationStart() {
    this.saveClicked = true;
    // this.setPristine(false);
    this.navigatingAway = true;
    this.appService.navigateAway(true);
    this.router.navigate(['/app/edit/' + this.app.id + '/' + this.app.productVersionId + '/iap']);
  }

  ngOnInit() {
    this.commService
      .get()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(msg => {
        if (msg && msg.skipNavGuard) {
          this.isDirty = false;
        }
      });

    this.appChanges$.pipe(takeUntil(this.onDestroy$)).subscribe((appChanges: AppDetailsChanges[]) => {
      this.appChanges = appChanges;
    });

    const { readOnly, lists } = this.route.snapshot.data;

    this.readOnly = readOnly;
    const locales = this.app.details.map(detail => detail.locale);

    this.languages = [];

    lists.language.forEach(detailLocale => {
      if (locales.includes(detailLocale.locale)) {
        this.languages.push(detailLocale);
      }
    });

    this.checkApkVisibility();
  }

  ngAfterViewInit() {
    this.renderCurrentApk();
    this.cdr.detectChanges();
  }

  checkApkVisibility() {
    this.showNoAppYet = !this.app.packages || this.app.packages.length === 0;

    this.showUploader = this.showNoAppYet || !this.isApkHeader(this.app.packages[0]);

    this.selectLanguage(null);
  }

  selectLanguage(language) {
    this.language = language || 'en_US';
  }

  uploadNewPackage($event) {
    this.file = $event.target.files[0];
    if (this.file) {
      const filesize = this.file.size / 1024 / 1024;
      if (/\.(apk)$/i.test(this.file.name) === false) {
        alert('The file must be an APK');
      } else if (filesize > this.leiaGlobalConfig.apkMaxSize) {
        alert(`The file upload limit is ${this.leiaGlobalConfig.apkMaxSize}MB`);
      } else {
        this.filename = $event.target.files[0].name;
        console.log('upload new package event:', $event.target.files[0].name);
        this.showUploader = false;
        this.showNoAppYet = false;
        this.appService.submitAppAPK(this.app, this.filename, $event.target.files[0]).subscribe(
          (r: any) => {
            this.uploader.reset();
            this.package = r.package;
            this.app.productVersionId = r.productVersionId;
            this.app.packages.unshift(this.package);
            this.renderCurrentApk();
            this.pullingVersionName();
          },
          error => {
            console.log(error);
          }
        );
      }
    }
  }

  pullingVersionName() {
    this.keepPulling = true;
    interval(5000)
      .pipe(takeWhile(() => this.keepPulling))
      .subscribe(() => {
        this.appService.getAPKRefresh(this.app.id, this.app.productVersionId).subscribe((result: any) => {
          if (result.status !== ApkStatus.PROCESSING) {
            this.keepPulling = false;
            this.svcUtil.first(this.app.packages).version = result.version;
            this.app.packageName = result.packageName;

            if (result.status !== 'OK') {
              this.sendStatusNotification(result);
            }
          }
        });
      });
  }

  sendStatusNotification(data: any) {
    let content = null;
    switch (data.status) {
      case ApkStatus.INVALID_VERSION_CODE:
      case ApkStatus.MISSING_VERSION_CODE_OR_NAME:
        content = `Please make sure that the versionCode is greater than ${data.lastVersionCode}.If you need help, please <b><a href='mailto:${this.leiaGlobalConfig.contactEmail}'>contact us</a></b>.`;
        break;
      case ApkStatus.INVALID_CERTIFICATE:
        content = `Please make sure that your app has a valid, signed certificate. If you need help please <b><a href='mailto:${this.leiaGlobalConfig.contactEmail}'>contact us</a></b>.`;
        break;
      case ApkStatus.INVALID_CERTIFICATE_AND_VERSIONCODE:
        content =
          `Please make sure your app has a versionCode greater than ${data.lastVersionCode} and that your app has a valid, signed certificate. ` +
          `If you need help, please <b><a href='mailto:${this.leiaGlobalConfig.contactEmail}'>contact us</a></b>.`;
        break;
    }

    if (content) {
      this.dialogService.openMessageDialog({
        actions: [dialogButtons.ok],
        title: 'APK upload error',
        htmlText: content
      });
    }
  }

  send(emails, fileId) {
    const sendApkRequests = emails.map(email => this.appService.sendAPKToDev(this.app.id, fileId, email));

    forkJoin(sendApkRequests).subscribe(r => {
      this.updateSuccessModal.show();
    });
  }

  delete() {
    this.keepPulling = false;
    this.appService.deleteApk(this.app.id, this.app.packages[0].fileId).subscribe(r => {
      this.app.packages.splice(0, 1);
      this.package = null;
      this.checkApkVisibility();
    });
  }

  renderCurrentApk() {
    if (this.app.packages.length > 0 && this.isApkHeader(this.app.packages[0])) {
      this.package = this.app.packages[0];
    }
  }

  isApkHeader(apk: APK) {
    return apk.status === AppStatus.not_submitted || apk.status === AppStatus.awaiting_approval;
  }

  updateApk(event) {
    const position = this.app.packages.findIndex(packageModel => {
      return packageModel.fileId === event.fileId;
    });

    this.app.packages[position] = event;
    console.log('NEW APP packages.component updateApk()', event);
  }

  valueChange(change) {
    this.isDirty = true;
  }

  confirmNavigation(state: RouterStateSnapshot) {
    // const urlTree: UrlTree = new DefaultUrlSerializer().parse(state.url);
    // let stayInTabs = false;
    // console.log(urlTree.fragment);
    // if (urlTree.root.children.primary.segments.filter((seg) => {
    //   return seg.path === "imagery" || seg.path === "details" || seg.path === "locales" || seg.path === "iap"
    // }).length) {
    //   stayInTabs = true;
    // }
    // this.setPristine(false);
    if (!this.isDirty || this.saveClicked) {
      this.isDirty = false;
      this.saveClicked = false;
      return true;
    }
    return this.dialogService
      .openMessageDialog(dprmConfirmLeavePageDialog)
      .afterClosed()
      .pipe(
        map(res => {
          this.isDirty = !(res > 0);
          // if (!stayInTabs && res > 0) {
          //   this.setPristine(false);
          // }
          return res > 0;
        })
      );
  }

  setPristine(val: boolean) {
    this.commService.setVar(flagPristineAPK, val);
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  checkChanges() {
    return this.appChanges ? this.appChanges.some(chg => chg.fieldName === `releaseNote_${this.language}`) : false;
  }
}
