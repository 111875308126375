export enum AppStatusText {
  'not_submitted' = 'NOT SUBMITTED',
  'awaiting_approval' = 'AWAITING APPROVAL',
  'live' = 'LIVE',
  'rejected' = 'REJECTED',
  'not_active' = 'UNPUBLISHED'
}

export enum AppStatus {
  'not_submitted' = 'not_submitted',
  'awaiting_approval' = 'awaiting_approval',
  'live' = 'live',
  'rejected' = 'rejected',
  'not_active' = 'not_active'
}
