import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubMenu } from '../../../models/sub-menu';
import { UserService } from '../../../services/user.service';
import { pubDetSupportedEUCountries, pubDetSupportedNonEUCountries } from '@models/country-code.model';
import { ComponentCommService, comcUserCountryChange } from '@services/component-comm.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, OnDestroy {
  defaultMenu: SubMenu[] = [
    {
      title: 'Your Details',
      router: 'account'
    },
    {
      title: 'Publishing Details',
      router: 'publishing-details'
    }
  ];

  menu: SubMenu[] = [];
  destroy$ = new Subject<boolean>();

  constructor(
    private svcUser: UserService,
    private comService: ComponentCommService,
  ) {
    this.checkCountry();
  }

  ngOnInit() {
    this.comService.getChannel(comcUserCountryChange).pipe(
      takeUntil(this.destroy$),
    ).subscribe(() => this.checkCountry());
  }

  checkCountry() {
    if (this.isSupportedCountry() || (!this.svcUser.user.country)) {
      this.menu = this.defaultMenu;
    } else {
      this.menu = this.defaultMenu.filter(i => !(i.router === "publishing-details"));
    }
  }

  isSupportedCountry() {
    if (pubDetSupportedEUCountries.includes(this.svcUser.user.country)
     || pubDetSupportedNonEUCountries.includes(this.svcUser.user.country)) {
       return true;
     }
     return false;
  }

  isAdmin() {
    return this.svcUser.isAdmin();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
