import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormModal } from 'app/classes/form-modal';

@Component({
  selector: 'app-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.scss']
})
export class RejectModalComponent extends FormModal {
  @Input()
  rejectionNotes;
  @Output()
  rejectionNotesChange = new EventEmitter();
  @Output()
  onRejectClick = new EventEmitter();
}
