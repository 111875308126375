import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-duplicate-info-checkbox',
  templateUrl: './duplicate-info-checkbox.component.html',
  styleUrls: ['./duplicate-info-checkbox.component.scss']
})
export class DuplicateInfoCheckboxComponent {
  @Input()
  description;
  @Output()
  change = new EventEmitter<any>();
}
