import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { InterceptorSkipHeader } from './http-auth.interceptor';
import { ReportProgressHeader } from './http-progress.interceptor';
import { LoginService } from './login.service';
import { ToolsService } from './tools.service';
import { UserService } from './user.service';

export interface IUserRegStatus {
  status: RegStatus,
}

export const RegSteps = {
  0: '/get-started',
  1: '/validate-email',
  2: '/your-details',
  3: '/areas-of-interest',
  4: '/terms-and-conditions',
  5: '/eula',
  6: '/distribution-agreement'
};

export enum RegStatus {
  NOT_VERIFIED  = "NOT_VERIFIED",
  VERIFIED      = "VERIFIED",
  CREATED       = "CREATED",
  COMPLETED     = "COMPLETED",
}

@Injectable()
export class RegisterService {
  user: User = new User();
  // get user(): User { return this.userService.user };
  registerStepSource = new BehaviorSubject<number>(0);
  registerStep = this.registerStepSource.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private loginService: LoginService,
    private svcTool: ToolsService,
    @Inject(leiaEndpointToken) private leiaEndpoints,
  ) {}

  getAgreement(key: string) {
    return this.svcTool.getAll().pipe(
      switchMap(tools => {
        const EULA = tools.filter(tool => tool.documentUid === key);

        const eulaArry = EULA.map(tool => tool.downloads).map(dwl => dwl.map(dl => dl.url));
        const eulaURL = [].concat.apply([], eulaArry)[0];

        const headers = new HttpHeaders().set(InterceptorSkipHeader, '').set(ReportProgressHeader, '');

        return this.http.get(eulaURL, {
          headers,
          responseType: 'text'
        });
      })
    );
  }

  changeStep(number) {
    this.registerStepSource.next(number);
  }

  cancelRegistration() {
    this.user = new User();
    this.loginService.logout();
  }

  setEmail(email) {
    this.user.emailAddress = email;
    return this.http.put(environment.leiaservices.url + this.leiaEndpoints.register.email.url, {
      emailAddress: email
    });
  }

  getRegStatus(email): Observable<IUserRegStatus> {
    return this.http.post<IUserRegStatus>(environment.leiaservices.url + this.leiaEndpoints.register.reg_status.url, {
      emailAddress: email,
    });
  }

  _checkRegStep(status: RegStatus, currentRoute: string, email: string) {
    switch (status) {
      case RegStatus.NOT_VERIFIED:
        if (currentRoute !== RegSteps[1]) {
          this.changeStep(1);
        }
      break;
      case RegStatus.VERIFIED:
        if (currentRoute !== RegSteps[2]) {
          this.changeStep(2);
        }
      break;
      case RegStatus.CREATED:
        if (currentRoute !== RegSteps[3]) {
          if (!(this.user && this.user.token) && !(this.userService.user && this.userService.user.token)) {
            this.router.navigate(['/login'], { queryParams: { useEmail: email }});
          } else {
            this.changeStep(3);
          }
        }
      break;
      case RegStatus.COMPLETED:
          if (!(this.user && this.user.token) && !(this.userService.user && this.userService.user.token)) {
            this.router.navigate(['/login'], { queryParams: { useEmail: email }});
          }
      break;
      default:
        if (currentRoute !== RegSteps[0]) {
          this.changeStep(0);
        }
    }
  }

  checkRegStep(status: RegStatus, currentRoute: string, email: string) {
    if (!status) {
      this.getRegStatus(email).subscribe((res) => {
        this._checkRegStep(res.status, currentRoute, email)
      });
    } else {
      this._checkRegStep(status, currentRoute, email);
    }
  }

  validateEmail(code) {
    return this.http.post(environment.leiaservices.url + this.leiaEndpoints.register.validate_email.url, {
      emailAddress: this.user.emailAddress,
      verificationCode: code
    });
  }

  resendCode() {
    return this.http.put(environment.leiaservices.url + this.leiaEndpoints.register.resend_code.url, {
      emailAddress: this.user.emailAddress
    });
  }

  setUserAgreedToTermsAndConditions(user) {
    return this.http.post(environment.leiaservices.url + this.leiaEndpoints.register.agreements.url, {
      emailAddress: user.emailAddress,
      userAcceptedTerms: true
    });
  }

  setUserAgreedToEula(user) {
    return this.http.post(environment.leiaservices.url + this.leiaEndpoints.register.agreements.url, {
      emailAddress: user.emailAddress,
      userAcceptedEula: true
    });
  }

  setUserAgreedToDistributionAgreement(user, accepted) {
    return this.http.post(environment.leiaservices.url + this.leiaEndpoints.register.agreements.url, {
      emailAddress: user.emailAddress,
      userAcceptedDistribution: accepted
    });
  }

  setUserDetails(data) {
    this.user = { ...data };

    if (this.userService.getToken() === '') {
      const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

      return this.http
        .post<User>(environment.leiaservices.url + this.leiaEndpoints.register.details.url, this.user, {
          headers
        })
        .pipe(tap(user => this.userService.setUser(user)));
    } else {
      return this.http.post(environment.leiaservices.url + this.leiaEndpoints.register.details.url, this.user);
    }
  }

  isUnique(developerName: string) {
    const url = environment.leiaservices.url + this.leiaEndpoints.user.unique.url;

    return this.http.post(url, { developerName });
  }

  setUserReasons(user) {
    return this.http.post(environment.leiaservices.url + this.leiaEndpoints.register.details.url, user);
  }

  forgotPassword(email) {
    return this.http.post(environment.leiaservices.url + this.leiaEndpoints.register.forgot_password.url, {
      emailAddress: email
    });
  }

  resetPassword(token, password) {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '').set('Authorization', `Bearer ${token}`);

    return this.http.post(
      environment.leiaservices.url + this.leiaEndpoints.register.reset_password.url,
      { password },
      { headers }
    );
  }
}
