import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wrapFirstCharacter'
})
export class WrapFirstCharacterPipe implements PipeTransform {
  transform(content, classes) {
    return (
      content &&
      `<span class="${classes}">${content.substring(
        0,
        1
      )}</span>${content.substring(1)}`
    );
  }
}
