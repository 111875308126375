import { Component, Directive, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  dialogButtons,
  MessageDialogServiceImpl,
  MessageDialogServiceToken
} from '@common/components/confimation-modal/dialog.service';
import { VariableModalComponent } from '@common/components/variable-modal/variable-modal.component';
import { VerificationStatus } from '@enums/verification-status.enum';
import { Observable, Subscription } from 'rxjs';
import { ModalComponent } from '../../../common.module/components/modal/modal.component';
import { App } from '../../../models/app';
import { AppService } from '../../../services/app.service';
import { UserService } from '../../../services/user.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[type=checkbox]'
})
export class CheckboxAnchorDirective {
  constructor(public el: ElementRef) {}
}

// tslint:disable-next-line:max-classes-per-file
@Component({
  selector: 'app-my-apps',
  styleUrls: ['./my-apps.component.scss'],
  templateUrl: './my-apps.component.html'
})
export class MyAppsComponent implements OnInit, OnDestroy {
  @ViewChild('submitNewApp')
  submitNewApp: ElementRef;
  @ViewChild('accessGrantedModal')
  accessGrantedModal: ModalComponent;
  // @ViewChild('accessDeniedModal')
  // accessDeniedModal: ModalComponent;
  @ViewChild('tempRestrictedModal')
  tempRestrictedModal: VariableModalComponent;
  @ViewChild('statusUnverifiedModal')
  statusUnverifiedModal: VariableModalComponent;
  @ViewChild('statusRejectedModal')
  statusRejectedModal: VariableModalComponent;

  subscriptions: Subscription[] = [];
  showApprovedModal = false;
  clickSubmitNewApp$: Observable<MouseEvent>;
  titleCharAllowed = 28;
  title: string;
  isAdmin = false;
  appsTitle = 'My Applications';
  allApps: App[];
  apps: App[];
  query: string;

  private _isSubmitDisabled = false;
  get isSubmitDisabled() {
    return this._isSubmitDisabled;
  }
  set isSubmitDisabled(value: boolean) {
    this._isSubmitDisabled = value;
  }

  constructor(
    private appService: AppService,
    private userService: UserService,
    private router: Router,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl
  ) {
    this.isAdmin = userService.isAdmin();
    if (this.isAdmin) {
      this.appsTitle = 'All Applications';
    }
  }

  ngOnInit() {
    this.subscriptions = [...this.subscriptions, this.appService.getApps().subscribe(apps => (this.apps = apps))];
  }

  shouldAllowAccess() {
    if (this.isSubmitDisabled) {
      return;
    }
    this.isSubmitDisabled = true;
    this.subscriptions = [
      ...this.subscriptions,

      this.appService.canCreateOrEditApps().subscribe(permissions => {
        this.isSubmitDisabled = false;
        if (!!permissions) {
          const allowed =
            permissions.canCreateOrEditApps &&
            [VerificationStatus.COMPLETED, VerificationStatus.ENABLED, VerificationStatus.VERIFIED_OVERRIDE].includes(
              permissions.bankAccountStatus
            );
          if (allowed) {
            this.accessGrantedModal.show();
          } else if (permissions.canCreateOrEditApps) {
            switch (permissions.bankAccountStatus) {
              case VerificationStatus.NONE:
                this.tempRestrictedModal.headerText = 'Just a little business we need to take care of first...';
                this.tempRestrictedModal.showAlert(null);
                break;
              case VerificationStatus.REJECTED:
                this.statusRejectedModal.headerText = 'Before We Continue...';
                this.statusRejectedModal.showAlert(null);
                break;
              case VerificationStatus.PENDING:
              // case VerificationStatus.ENABLED:
              case VerificationStatus.RESTRICTED_SOON:
              case VerificationStatus.RESTRICTED:
                this.statusUnverifiedModal.headerText = 'Before We Continue...';
                this.statusUnverifiedModal.showAlert(null);
                break;
            }
          } else {
            // this.accessDeniedModal.show();
            this.showAccessDeniedDialog();
          }
        } else {
          // this.accessDeniedModal.show();
          this.showAccessDeniedDialog();
        }
      })
    ];
  }

  private showAccessDeniedDialog() {
    this.dialogService.openMessageDialog({
      title: 'There was a small problem',
      text:
        'You are currently not allowed to submit a new app. You can try again later or if you need help, please contact us at help@leiainc.com.',
      actions: [dialogButtons.ok]
    });
  }

  newApp() {
    this.appService.startNewApp(this.title).subscribe(app => {
      if (app) {
        this.router.navigate(['/app/editapp', app.id, app.productVersionId]);
      } else {
        this.dialogService.openMessageDialog({
          title: 'Alert',
          text: 'The App could not be created.',
          actions: [dialogButtons.ok]
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
