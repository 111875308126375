import { HttpClient, HttpClientModule, } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AdminDashboardPageModule } from './admin-dashboard.module/admin-dashboard.page.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppAppsModule } from './apps.module/apps.module';
import { AppAuthModule } from './auth.module/auth.module';
import { AppCommonModule } from './common.module/app.common.module';
import { AppCoreModule } from './core.module/core.module';
import { AppDashboardPageModule } from './dashboard.module/dashboard.page.module';
import { AppPartnersModule } from './partners.module/partners.module';
import { AppPaymentsModule } from './payments.module/payments.module';
import { ProgressIndicatorService } from './services/progress-indicator.service';
import { AppStripeModule } from './stripe.module/stripe.module';

import { leiaEndpointConfig, leiaEndpointToken, leiaGlobal, leiaGlobalConfig } from 'endpoints';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppCoreModule,
    AppCommonModule,
    AppRoutingModule,
    AppAuthModule,
    AppDashboardPageModule,
    AdminDashboardPageModule,
    AppAppsModule,
    AppPartnersModule,
    AppPaymentsModule,
    AppStripeModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
  }),
  ],
  exports: [AppComponent, TranslateModule],
  bootstrap: [AppComponent],
  providers: [
    ProgressIndicatorService,
    { provide: leiaEndpointToken, useValue: leiaEndpointConfig },
    { provide: leiaGlobal, useValue: leiaGlobalConfig },
  ],
})
export class AppModule {}
