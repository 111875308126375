import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-payment-row',
  templateUrl: './payment-row.component.html',
  styleUrls: ['./payment-row.component.scss']
})
export class PaymentRowComponent {
  @Input()
  payment;
  @Input()
  rowAttributes;
  @Input()
  expandable = false;
  @Input()
  showButtons: boolean;
  @Input()
  groupPaymentsEnabled = false;
  @Output()
  onExpandClick = new EventEmitter();
  @Output()
  onHoldClick = new EventEmitter();
  @Output()
  onReleaseClick = new EventEmitter();
  @Output()
  onPayNowClick = new EventEmitter();
  @Output() authClick = new EventEmitter();


  authPaymentEmit(ev) {
    if (this.payment.transferGroup && this.payment.transferKey) {
      this.authClick.emit(this.payment);
    } else {
      this.authClick.emit(ev);
    }
  }
}
