import { Component, Input } from '@angular/core';
import { FormModal } from 'app/classes/form-modal';

@Component({
  selector: 'app-hold-payment-modal',
  templateUrl: './hold-payment-modal.component.html',
  styleUrls: ['./hold-payment-modal.component.scss']
})
export class HoldPaymentModalComponent extends FormModal {
  @Input() isHeld = true;

  public isTouched: boolean;
  public password = '';

  constructor() {
    super();
    this.isTouched = false;
  }

  public confirm(holdForm, modal) {
    this.isTouched = true;
    this.onConfirm(holdForm, modal);
  }
}
