import { FormControl } from '@angular/forms';
import * as lpn from 'libphonenumber-js';

export const ParseErrorCodes = {
    INVALID_COUNTRY: "Invalid country code",
    NOT_A_NUMBER: "Phone number too short",
    TOO_SHORT: "Phone number too short",
    TOO_LONG: "Phone number too long",
}

export const phoneNumberValidator = (control: FormControl) => {
	const isRequired = control.errors && control.errors.required === true;
	const error = { validatePhoneNumber: { valid: false, message: null } };
	let number: lpn.PhoneNumber;

	try {
		// number = lpn.PhoneNumberUtil.getInstance().parse(control.value.number, control.value.countryCode);
		number = lpn.parsePhoneNumber(control.value.number, control.value.countryCode);
	} catch (e) {
        if (e && e.message) {
            error.validatePhoneNumber.message = ParseErrorCodes[e.message];
        }
		if (isRequired === true) { return error; }
	}

	if (control.value) {
		if (!number) {
			return error;
		} else {
			// if (!lpn.PhoneNumberUtil.getInstance().isValidNumberForRegion(number, control.value.countryCode)) {
            if (!lpn.isValidNumberForRegion(number.nationalNumber, control.value.countryCode)) {
				return error;
			}
		}
	}

	return;
};