import { Component, EventEmitter, Input, Output } from '@angular/core';
import { range } from 'lodash';

@Component({
  selector: 'app-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss']
})
export class StepIndicatorComponent {
  @Input()
  isWhite = false;
  @Input()
  showBack = false;
  @Input()
  backDisabled = false;
  @Input()
  showNext = true;
  @Input()
  nextDisabled = false;
  @Input()
  showSubmitApp = false;
  @Input()
  submitAppDisabled = false;
  @Input()
  showFinish = false;
  @Input()
  finishDisabled = false;
  @Input()
  numSteps = 3;
  @Input()
  currentStep = 1;

  @Output()
  onNextClick = new EventEmitter<number>();
  @Output()
  onBackClick = new EventEmitter<number>();
  @Output()
  onFinishClick = new EventEmitter<number>();
  // Outputs -1 for the currentStep
  @Output()
  onSubmitAppClick = new EventEmitter<number>();

  private _steps = range(this.numSteps);
  get steps() {
    return this._steps;
  }

  get lastStep() {
    return this.currentStep === this._steps.length;
  }

  get middleStep() {
    return this.currentStep > 1;
  }

  handleBack() {
    this.currentStep--;
    this.onBackClick.emit(this.currentStep);
  }
  handleNext() {
    this.currentStep++;
    this.onNextClick.emit(this.currentStep);
  }
  handleSubmitApp() {
    this.onSubmitAppClick.emit(-1);
  }
  handleFinish() {
    if (!this.lastStep) {
      throw Error(
        '[app-step-indicator-component] - Finish clicked, but not on last step'
      );
    }
    this.onFinishClick.emit(this.currentStep);
  }
}
