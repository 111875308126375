import { InAppPurchaseTranslation } from 'app/models/iapTranslation';

export class InAppPurchase {
  public partnerAppId: string;
  public productId: string;
  public active: boolean;

  public title: string;
  public sku: string;

  public prices: any[] = [];
  public description: string;
  public translations: InAppPurchaseTranslation[] = [];

  constructor() {}
}
