import { Injectable } from '@angular/core';
import { default as androidSDKMD } from 'raw-loader!../../assets/docs/android.md';
import { default as mayaSDKMD } from 'raw-loader!../../assets/docs/maya.md';
import { default as unitySDKMD } from 'raw-loader!../../assets/docs/unity.md';
import { default as lightfieldstudioSDKMD } from 'raw-loader!../../assets/docs/lightfieldstudio.md';
import { default as androidSDKToc } from '../../assets/docs/android.toc.json';
import { default as mayaSDKToc } from '../../assets/docs/maya.toc.json';
import { default as unitySDKToc } from '../../assets/docs/unity.toc.json';
import { default as lightfieldstudioSDKToc } from '../../assets/docs/lightfieldstudio.toc.json';

@Injectable()
export class DocsService {
  constructor() {}

  preLoadTOC(docType: 'android' | 'unity' | 'maya' | 'lightfieldstudio') {
    if (docType === 'android') {
      return androidSDKToc;
    } else if (docType === 'unity') {
      return unitySDKToc;
    } else if (docType === 'maya') {
      return mayaSDKToc;
    } else if (docType === 'lightfieldstudio') {
      return lightfieldstudioSDKToc;
    }
  }

  preLoadMarkdown(docType: 'android' | 'unity' | 'maya' | 'lightfieldstudio') {
    if (docType === 'android') {
      return androidSDKMD;
    } else if (docType === 'unity') {
      return unitySDKMD;
    } else if (docType === 'maya') {
      return mayaSDKMD;
    } else if (docType === 'lightfieldstudio') {
      return lightfieldstudioSDKMD;
    }
  }
}
