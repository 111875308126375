import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { UserService } from '../services/user.service';
@Injectable()
export class AdminGuard implements Resolve<boolean> {
  constructor(private userService: UserService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot) {
    if (!this.userService.isAdmin()) {
      this.router.navigate(['/main/dashboard']);
    }

    return this.userService.isAdmin();
  }
}
