import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { CountryType } from '@enums/country-type.enum';
import { VerificationStatus } from '@enums/verification-status.enum';
import { AT_CompanyDeveloper, AT_IndividualDeveloper } from '@models/stripe-account-at';
import { AU_CompanyDeveloper, AU_IndividualDeveloper } from '@models/stripe-account-au';
import { BE_CompanyDeveloper, BE_IndividualDeveloper } from '@models/stripe-account-be';
import { CA_CompanyDeveloper, CA_IndividualDeveloper } from '@models/stripe-account-ca';
import { FR_CompanyDeveloper, FR_IndividualDeveloper } from '@models/stripe-account-fr';
import { SE_CompanyDeveloper, SE_IndividualDeveloper } from '@models/stripe-account-se';
import { UK_CompanyDeveloper, UK_IndividualDeveloper } from '@models/stripe-account-uk';
import { US_CompanyDeveloper, US_IndividualDeveloper } from '@models/stripe-account-us';
import { BankDetailAdapter } from 'app/adapters/bank-detail.adapter';
import { DeveloperModel, UserBankDetailServerResponse } from 'app/models/stripe-account';
import { ReportProgressHeader } from 'app/services/http-progress.interceptor';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from './user.service';
import { IPubDetailModel } from '@stripe/containers/publishing-details/pub-details.model';

export interface BankDetailServiceServerErrorResponse {
  code: string;
  message: string;
}
export const BANK_ACCOUNT_DOESNT_EXIST: BankDetailServiceServerErrorResponse = {
  code: 'BadRequest',
  message: "Stripe account doesn't exist"
};

@Injectable()
export class BankDetailService {
  constructor(
    private http: HttpClient,
    private adapter: BankDetailAdapter,
    private svcUser: UserService,
    @Inject(leiaEndpointToken) private leiaEndpoints,
    ) {}

  getDetail() {
    const headers = new HttpHeaders().set(ReportProgressHeader, '');

    return this.http.get(`${environment.leiaservices.url}${this.leiaEndpoints.stripe.getDetails.url}`, {
      headers
    });
    // .pipe(map(res => this.adapter.toModel(res)));
  }

  get serverTime() {
    const url = `${environment.leiaservices.url}${this.leiaEndpoints.time.url}`;
    return this.http.get(url);
  }

  get bankAccountExists() {
    return this.getDetail().pipe(
      map((bankDetails: any) => {
        if (!!bankDetails) {
          return {
            exists: true,
            status: bankDetails.verificationStatus
          };
        }
        return { exists: false, status: VerificationStatus.NONE };
      }),
      catchError((err: HttpErrorResponse) => {
        return of({ exists: false, status: VerificationStatus.NONE });
      })
    );
  }

  createOrUpdateIndividual(bankDetail: UserBankDetailServerResponse) {
    const headers = new HttpHeaders().set(ReportProgressHeader, '');
    return this.http.post(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.createOrUpdateDev.url}`,
      bankDetail,
      {
        headers
      }
    );
  }

  stripePOSTPartner(model: DeveloperModel) {
    const headers = new HttpHeaders().set(ReportProgressHeader, '');
    return this.http.post(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.createOrUpdateDev.url}`,
      model,
      { headers }
    );
  }

  pubDetailsSave(rec: IPubDetailModel): Observable<any> {
    const headers = new HttpHeaders().set(ReportProgressHeader, '');
    return this.http.post(
      `${environment.leiaservices.url}${this.leiaEndpoints.stripe.savePubDetails.url}`,
      rec,
      { headers }
    );
  }

  pubDetailsGet(): Observable<IPubDetailModel> {
    return this.http.get<IPubDetailModel>(`${environment.leiaservices.url}${this.leiaEndpoints.stripe.getPubDetails.url}`);
  }

  copyUserContactInfoTo(form: FormGroup) {
    const { firstName, lastName, emailAddress, phoneNumber, developerName } = this.svcUser.getUser();
    const control = form.get('contactInfo');

    const firstRep = (form.get('stripeAccount.accountRepresentatives') as FormArray).at(0);

    control.get('rllDeveloperName.value').setValue(developerName);
    control.get('name.firstName.value').setValue(firstName);
    control.get('name.lastName.value').setValue(lastName);
    firstRep.get('name.firstName.value').setValue(firstName);
    firstRep.get('name.lastName.value').setValue(lastName);

    control.get('email.value').setValue(emailAddress);
    control.get('phoneNumber.value').setValue(phoneNumber);
  }

  selectCompanyDeveloper(country: CountryType) {
    switch (country) {
      case 'AT':
        return AT_CompanyDeveloper;
      case 'AU':
        return AU_CompanyDeveloper;
      case 'BE':
        return BE_CompanyDeveloper;
      case 'CA':
        return CA_CompanyDeveloper;
      case 'FR':
        return FR_CompanyDeveloper;
      case 'SE':
        return SE_CompanyDeveloper;
      case 'GB':
        return UK_CompanyDeveloper;
      case 'US':
        return US_CompanyDeveloper;
    }
  }

  selectIndividualDeveloper(country: CountryType) {
    switch (country) {
      case 'AT':
        return AT_IndividualDeveloper;
      case 'AU':
        return AU_IndividualDeveloper;
      case 'BE':
        return BE_IndividualDeveloper;
      case 'CA':
        return CA_IndividualDeveloper;
      case 'FR':
        return FR_IndividualDeveloper;
      case 'SE':
        return SE_IndividualDeveloper;
      case 'GB':
        return UK_IndividualDeveloper;
      case 'US':
        return US_IndividualDeveloper;
    }
  }
}
