﻿import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AppDetailsChanges } from 'app/models/app';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { UserService } from '../services/user.service';

@Injectable()
export class AppDetailsChangesResolver implements Resolve<AppDetailsChanges[]> {
  constructor(private svcUser: UserService, private svcApp: AppService) {}

  currentApp$: Observable<AppDetailsChanges[]>;

  resolve(route: ActivatedRouteSnapshot): Observable<AppDetailsChanges[]> {
    const id = route.paramMap.get('id') || route.parent.paramMap.get('id');
    const productVersionId = route.paramMap.get('productVersionId') || route.parent.paramMap.get('productVersionId');
    // this.currentApp$ = this.svcUser.isAdmin() ? this.svcApp.getAppDetailsChanges(id, productVersionId) : null;
    // console.log('TEST', this.currentApp$, this.svcUser.isAdmin());
    this.currentApp$ = this.svcApp.getAppDetailsChanges(id, productVersionId);
    return this.currentApp$;
  }
}
