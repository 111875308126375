import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  template: `
    <div
      (click)="onContainerClicked($event)"
      class="modal fade"
      tabindex="-1"
      [class.center]="center"
      [ngClass]="{ in: visibleAnimate }"
      [ngStyle]="{
        display: visible ? (center ? 'flex' : 'block') : 'none',
        opacity: visibleAnimate ? 1 : 0
      }"
    >
      <div class="modal-dialog">
        <div class="modal-content" [class.center]="center">
          <img
            *ngIf="!disableCloseBtn"
            src="/assets/img/x-shape.png"
            srcset="
              /assets/img/x-shape@2x.png 2x,
              /assets/img/x-shape@3x.png 3x
            "
            class="x-button"
            (click)="hide()"
          />
          <div class="modal-header">
            <ng-content select=".app-modal-header"></ng-content>
          </div>
          <div class="modal-body">
            <ng-content select=".app-modal-body"></ng-content>
          </div>
          <div class="modal-footer">
            <ng-content select=".app-modal-footer"></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .modal {
        background: rgba(0, 0, 0, 0.6);
        color: black;
      }
      .modal.center {
        align-items: center;
        padding-top: 100px;
      }
      .modal .modal-dialog {
        top: 30%;
        max-width: 756px;
      }
      .modal.center  .modal-dialog {
        top: 0;
      }
      .modal .modal-header,
      .modal .modal-body,
      .modal .modal-small-header {
        padding: 0 96px;
      }
      .modal .modal-content {
        border: 0;
        border-top: 6px solid #8833F5;
        border-radius: 0;
      }
      .modal .x-button {
        width: 20px;
        right: 20px;
        top: 20px;
        position: absolute;
        cursor: pointer;
      }
      .modal .modal-header {
        display: initial;
        border-bottom: 0;
        text-align: left;
        padding-top: 56px;
        font-family: 'nantes-bold';
        font-size: 32px;
        margin-bottom: 40px;
      }
      .modal .modal-header {
        display: initial;
      }
      .modal .modal-footer {
        margin-top: 32px;
        background-color: #f0f0f0;
        height: 108px;
        border-top: 0;
      }
      .modal.center .modal-footer {
        min-height: 108px;
      }

      ::ng-deep .small-header {
        font-family: 'campton-book-medium';
      }
      ::ng-deep .small-header .header-label {
        font-size: 17px;
        height: 30px;
      }
      ::ng-deep .small-header .header-description {
        font-size: 12px;
        font-family: 'campton-book-light';
        line-height: 14px;
        height: 65px;
      }
      ::ng-deep .small-header .xl-font-size {
        font-size: 2em;
      }
      ::ng-deep .small-header .l-font-size {
        font-size: 1.2em;
      }

      ::ng-deep .modal-button {
        width: 75px;
        margin-right: 40px;
        cursor: pointer;
        font-family: 'campton-book-medium';
      }
      ::ng-deep .modal-button.disabled {
        cursor: initial;
        opacity: 0.5;
      }
      ::ng-deep .modal-button.saved {
        color: green;
        cursor: initial;
      }
      ::ng-deep .modal-button.failed {
        color: red;
        cursor: initial;
      }

      ::ng-deep .loading-gif {
        margin-right: 40px;
        height: 32px;
      }

      ::ng-deep .char-count {
        margin-top: 12px;
        text-align: right;
        width: 100%;
        margin-bottom: 20px;
        font-family: 'campton-book-light';
      }
    `
  ]
})
export class ModalComponent {
  @Output()
  onHide = new EventEmitter<boolean>();
  @Input()
  disableBackClick: boolean;
  @Input()
  disableCloseBtn: boolean;
  @Input()
  center: boolean;
  @Input()
  public visible = false;
  public visibleAnimate = false;

  constructor() {
    // default value
    this.disableBackClick = false;
    this.disableCloseBtn = false;
  }

  async show() {
    this.visible = true;
    return setTimeout(() => (this.visibleAnimate = true), 100);
  }

  async hide() {
    this.visibleAnimate = false;
    if (this.onHide) {
      this.onHide.emit(this.visible);
    }
    return setTimeout(() => (this.visible = false), 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if (
      !this.disableBackClick &&
      (event.target as HTMLElement).classList.contains('modal')
    ) {
      this.hide();
    }
  }
}

// Should follow this form in HTML:
// <app-modal #modal>
//     <div class="app-modal-header">
//         Your Header
//     </div>
//     <div class="app-modal-body">
//         Your content. Can include form fields, etc.
//     </div>
//     <div class="app-modal-footer">
//         Your footer. Can contain buttons, etc.
//         <button type="button" class="btn btn-primary">Save changes</button>
//     </div>
// </app-modal>
