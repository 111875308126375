﻿import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  QueryList
} from '@angular/core';
import { Tab } from './tab.component';

@Component({
  selector: 'tabs',
  styleUrls: ['tabs.component.scss'],
  template: `
    <div class="wrapper">
      <ul class="main-nav">
        <li
          class="nav-item"
          *ngFor="let tab of tabs; let i = index"
          (click)="selectTab(tab)"
          [class.active]="tab.active"
        >
          <a
            class="nav-link"
            [class.nav-link__active]="tab.active"
            [routerLink]="[tab.link]"
          >
            {{ tab.title }}
          </a>
        </li>
      </ul>
    </div>
    <ng-content></ng-content>
  `
})
export class Tabs implements AfterContentInit {
  @ContentChildren(Tab)
  tabs: QueryList<Tab>;

  constructor(protected cdr: ChangeDetectorRef) {}

  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    const activeTabs = this.tabs.filter(tab => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: Tab) {
    // deactivate all tabs
    this.tabs.toArray().forEach(tab => (tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;

    this.cdr.detectChanges();
  }
}
