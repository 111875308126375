import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RegisterService } from '../../../services/register.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  token: string;
  @Input('password')
  pass: string;
  @Input()
  confirmPassword: string;
  subscription: Subscription;
  @Input()
  passwordReset = false;
  @Input()
  passwordResetFailed = false;
  errorText = `Required. Minimum six characters,
   at least one uppercase letter, one lowercase letter,
   one number and one special character
   (!"#$%&'()*+,-./:;<=>?@[\]^_\`{|}~).
   No white space permitted`;

  constructor(
    private registerService: RegisterService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = params['token'];
      if (!this.token) {
        this.router.navigate(['/login']);
      }
    });
  }

  resetPassword() {
    if (this.pass === this.confirmPassword) {
      this.subscription = this.registerService.resetPassword(this.token, this.pass).subscribe(
        res => {
          console.log('Success..');
          console.log(res);
          this.passwordReset = true;
        },
        error => {
          console.log('Error...');
          console.log(error);
          this.passwordResetFailed = true;
        }
      );
    }
  }
}
