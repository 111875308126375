import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'average'
})
export class AveragePipe implements PipeTransform {
  transform(value: number, total: number, modelName: string): any {
    return `${Math.fround(value)}/${total} ${modelName}`;
  }
}
