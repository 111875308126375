import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AccountType, DeveloperModel } from 'app/models/stripe-account';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-account-details-form',
  templateUrl: './user-account-details-form.component.html',
  styleUrls: ['./user-account-details-form.component.scss'],
  exportAs: 'appUserAccountDetailsForm'
})
export class UserAccountDetailsFormComponent {
  @Input()
  isWhite = false;
  @Input()
  isInModal = false;
  @Input()
  readOnly = false;
  @Input()
  accountType: AccountType;
  @Input()
  accountTypeSelection: 'standard' | 'iban';
  @Input()
  set parent(form: FormGroup) {
    if (!!form) {
      this._parent = form;
      console.log(form);
    }
  }
  get parent() {
    return this._parent;
  }
  private _parent: FormGroup;

  @Input()
  set countryTemplate(model: DeveloperModel) {
    if (!!model) {
      this._countryTemplate = model;
    }
  }
  get countryTemplate() {
    return this._countryTemplate;
  }
  private _countryTemplate: DeveloperModel;

  // null - default to 'iban'
  get isIban() {
    return (
      _.get(this.countryTemplate, 'stripeAccount.externalAccount.iban.present', null) &&
      ('iban' === this.accountTypeSelection || null === this.accountTypeSelection)
    );
  }
  get isStandard() {
    return 'standard' === this.accountTypeSelection;
  }

  constructor() {}
}
