import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const MATERIAL_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MaterialTextareaComponent),
  multi: true
};

@Component({
  selector: 'app-material-textarea',
  templateUrl: './material-textarea.component.html',
  styleUrls: ['./material-textarea.component.scss'],
  providers: [MATERIAL_INPUT_VALUE_ACCESSOR]
})
export class MaterialTextareaComponent implements ControlValueAccessor {
  private _value: any;
  private isValid: boolean;

  @Input()
  minLength = 10;
  @Input()
  isDisabled = false;
  @Input()
  maxLength = 1000;
  @Input()
  label: string;
  @Input()
  isChanged = false;

  @Output()
  blurEvent: EventEmitter<string> = new EventEmitter();

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.notifyValueChange(value);
  }

  get valid() {
    return this.isValid;
  }

  onChange: (value) => {};
  onTouched: () => {};

  constructor() {}

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  handleBlur() {
    this.blurEvent.emit(this.value);
  }
}
