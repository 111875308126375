import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RegisterService, RegSteps } from '../../../services/register.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  providers: [RegisterService],
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private registerService: RegisterService, private userService: UserService) {
    this.user = userService.getUser();
    if (
      !this.isLoggedIn() && 
      !this.registerService.user.emailAddress &&
      !this.router.url.includes('distribution-agreement')
    ) {
      this.router.navigate(['/register']);
    }
  }
  page = 0;
  user: any;

  routerMap = RegSteps;

  existingUser = false;

  subscription: Subscription;

  isLoggedIn(): boolean {
    if (this.user) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.subscription = this.registerService.registerStepSource.subscribe(newPage => {
      this.page = newPage;
      if (this.page > 0) {
        if (this.page === 2 || this.page > 3) {
          this.existingUser = true;
        } else {
          this.existingUser = false;
        }
        const route = this.routerMap[this.page];
        this.router.navigate(['/register' + route]);
      } else {
        localStorage.setItem('user', '');
      }
    });
  }

  back(): void {
    if (this.page > 0) {
      this.page--;
      if (this.page === 2 || this.page > 3) {
        this.existingUser = true;
      } else {
        this.existingUser = false;
      }
      const route = this.routerMap[this.page];
      this.router.navigate(['/register' + route], { queryParams: { back: 1 }});
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
