﻿import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { App } from 'app/models/app';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

@Injectable()
export class AppDetailsResolver implements Resolve<App> {
  constructor(private svcApp: AppService) {}

  currentApp$: Observable<App>;

  resolve(route: ActivatedRouteSnapshot): Observable<App> {
    const id = route.paramMap.get('id') || route.parent.paramMap.get('id');
    const productVersionId = route.paramMap.get('productVersionId') || route.parent.paramMap.get('productVersionId');
    this.currentApp$ = this.svcApp.getAppDetails(id, productVersionId);
    return this.currentApp$;
  }
}
