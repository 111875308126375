import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatProgressSpinnerModule, MatBadgeModule, MatTooltipModule, MatIconModule, } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentCommService } from '@services/component-comm.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BankDetailAdapter } from 'app/adapters/bank-detail.adapter';
import { BankDetailService } from 'app/services/bank-detail.service';
import { DpDatePickerModule } from 'ng2-date-picker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { MainMenuDropdownPopupComponent } from '../apps.module/components/my-apps/new-app/dropdow-popup/dropdown-popup.component';
import { AuthGuard } from '../guards/auth-guard.service';
import { AppMaterialModule } from '../material.module/material.module';
import { AppService } from '../services/app.service';
import { ContactService } from '../services/contact.service';
import { DashboardService } from '../services/dashboard.service';
import { DocsService } from '../services/docs.service';
import { IapService } from '../services/iap.service';
import { LoginService } from '../services/login.service';
import { NotificationsService } from '../services/notifications.service';
import { RegisterService } from '../services/register.service';
import { SubmitAppService } from '../services/submit-app.service';
import { ToolsService } from '../services/tools.service';
import { UserService } from '../services/user.service';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { ApkComponent } from './components/apk/apk.component';
import { BackNextBarComponent } from './components/back-next-bar/back-next-bar.component';
import { BasePieChartComponent } from './components/base-pie-chart/base-pie-chart.component';
import { Base64FileEncoderComponent } from './components/base64-file-encoder/base64-file-encoder.component';
import { ButtonComponent } from './components/button/button.component';
import { CalendarRangePickerComponent } from './components/calendar-range-picker/calendar-range-picker.component';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { CharLimitComponent } from './components/char-limit/char-limit.component';
import { CheckboxSpinnerComponent } from './components/checkbox-spinner/checkbox-spinner.component';
import { ConfimationModalComponent } from './components/confimation-modal/confirmation-modal.component';
import { MessageDialogServiceImpl, MessageDialogServiceToken, } from './components/confimation-modal/dialog.service';
import { PublicDetailsComponent } from './components/public-details/public-details.component';
import { CountryDropdownComponent } from './components/country-dropdown/country-dropdown.component';
import { CustomLinearGradientComponent } from './components/custom-linear-gradient/custom-linear-gradient.component';
import { DashboardDownloadComponent } from './components/dashboard-download/dashboard-download.component';
import { CustomDropdownComponent } from './components/dashboard-menu/components/custom-dropdown/custom-dropdown.component';
import { DashboardMenuComponent } from './components/dashboard-menu/dashboard-menu.component';
import { DistributionAgreementDetailsComponent } from './components/distribution-agreement-details/distribution-agreement-details.component';
import { DobMaterialInputComponent } from './components/dob-material-input/dob-material-input.component';
import { FooterComponent } from './components/footer/footer.component';
import { InfoHoverComponent } from './components/info-hover/info-hover.component';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { AppLogoutComponent } from './components/logout/logout.component';
import { MainMenuDropdownComponent } from './components/main-menu/dropdown/dropdown.component';
import { MobileTabletMainMenuDropdownComponent } from './components/main-menu/dropdown/mobile-tablet-dropdown.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { MobileTabletMainMenuComponent } from './components/main-menu/mobile-tablet-main-menu.component';
import { MaterialInputComponent } from './components/material-input/material-input.component';
import { MaterialTextareaComponent } from './components/material-textarea/material-textarea.component';
import { ModalButtonComponent } from './components/modal-button/modal-button.component';
import { ModalNavComponent } from './components/modal-nav/modal-nav.component';
import { ModalComponent } from './components/modal/modal.component';
import { NameFormComponent } from './components/name-form/name-form.component';
import { NextButtonComponent } from './components/next-button/next-button.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaymentInfoContainerComponent } from './components/payment-info-container/payment-info-container.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { QuestionDrawerComponent } from './components/question-drawer/question-drawer.component';
import { RadioButtonOptionComponent } from './components/radio-button-option/radio-button-option.component';
import { RadioButtonSetComponent } from './components/radio-button-set/radio-button-set.component';
import { ReportButtonComponent } from './components/report-button/report-button.component';
import { RightMenuComponent } from './components/right-menu/right-menu.component';
import { SortCodeComponent } from './components/sort-code/sort-code.component';
import { StepIndicatorComponent } from './components/step-indicator/step-indicator.component';
import { SubMenuErrorComponent } from './components/sub-menu-error/sub-menu-error.component';
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { Tab } from './components/tabs/tab.component';
import { Tabs } from './components/tabs/tabs.component';
import { ToggleSelectorComponent } from './components/toggle-selector/toggle-selector.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { VariableAlertModalComponent } from './components/variable-alert-modal/variable-alert-modal.component';
import { VariableModalComponent } from './components/variable-modal/variable-modal.component';
import { IbanDirective } from './directives/iban-validator.directive';
import { AppIsEqualValidatorDirective } from './directives/is-equal-validator.directive.directive';
import { LeiaFileDropZoneDirective } from './directives/leia-file-drop-zone.directive';
import { ErrorBadgeComponent } from './components/error-badge/error-badge.component';
import { DynamicFormComponent } from '@apps/components/dynamic-form/dynamic-form.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
    FooterComponent,
    ModalComponent,
    ButtonComponent,
    MainMenuComponent,
    MobileTabletMainMenuComponent,
    MainMenuDropdownComponent,
    MobileTabletMainMenuDropdownComponent,
    MainMenuDropdownPopupComponent,
    NextButtonComponent,
    QuestionDrawerComponent,
    ToggleSelectorComponent,
    UserDetailsComponent,
    NotificationComponent,
    RightMenuComponent,
    BasePieChartComponent,
    ApkComponent,
    DashboardMenuComponent,
    CustomDropdownComponent,
    PaginationComponent,
    PublicDetailsComponent,
    SubMenuComponent,
    Tab,
    Tabs,
    MaterialInputComponent,
    MaterialTextareaComponent,
    CalendarRangePickerComponent,
    InputErrorComponent,
    ErrorBadgeComponent,
    AppIsEqualValidatorDirective,
    DashboardDownloadComponent,
    RadioButtonSetComponent,
    ChangePasswordModalComponent,
    CharLimitComponent,
    CustomLinearGradientComponent,
    VariableAlertModalComponent,
    ModalButtonComponent,
    ModalNavComponent,
    CountryDropdownComponent,
    IbanDirective,
    DobMaterialInputComponent,
    RadioButtonOptionComponent,
    SortCodeComponent,
    InfoHoverComponent,
    DistributionAgreementDetailsComponent,
    SubMenuErrorComponent,
    StepIndicatorComponent,
    ReportButtonComponent,
    PaymentInfoContainerComponent,
    NameFormComponent,
    AddressFormComponent,
    Base64FileEncoderComponent,
    VariableModalComponent,
    CheckboxSpinnerComponent,
    AppLogoutComponent,
    ConfimationModalComponent,
    LeiaFileDropZoneDirective,
    PhoneInputComponent,
    DynamicFormComponent,
    BackNextBarComponent
  ],
  entryComponents: [
    ConfimationModalComponent,
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    NgxChartsModule,
    DpDatePickerModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    PaginationModule.forRoot(),
    NgbDatepickerModule.forRoot(),
    MaterialFileInputModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }
      }
    })
  ],
  providers: [
    AppService,
    NotificationsService,
    DocsService,
    LoginService,
    RegisterService,
    SubmitAppService,
    ToolsService,
    UserService,
    BankDetailAdapter,
    BankDetailService,
    AuthGuard,
    ContactService,
    DashboardService,
    IapService,
    ComponentCommService,
    { provide: MessageDialogServiceToken, useClass: MessageDialogServiceImpl }
  ],
  exports: [
    FooterComponent,
    ModalComponent,
    ButtonComponent,
    MainMenuComponent,
    MobileTabletMainMenuComponent,
    MainMenuDropdownPopupComponent,
    NextButtonComponent,
    QuestionDrawerComponent,
    ToggleSelectorComponent,
    UserDetailsComponent,
    NotificationComponent,
    RightMenuComponent,
    ApkComponent,
    BasePieChartComponent,
    DashboardMenuComponent,
    CustomDropdownComponent,
    PaginationComponent,
    PublicDetailsComponent,
    SubMenuComponent,
    Tab,
    Tabs,
    MaterialInputComponent,
    MaterialTextareaComponent,
    CalendarRangePickerComponent,
    InputErrorComponent,
    AppIsEqualValidatorDirective,
    DashboardDownloadComponent,
    RadioButtonSetComponent,
    ChangePasswordModalComponent,
    CharLimitComponent,
    CustomLinearGradientComponent,
    VariableAlertModalComponent,
    ModalButtonComponent,
    ModalNavComponent,
    CountryDropdownComponent,
    IbanDirective,
    DobMaterialInputComponent,
    RadioButtonOptionComponent,
    SortCodeComponent,
    InfoHoverComponent,
    DistributionAgreementDetailsComponent,
    SubMenuErrorComponent,
    StepIndicatorComponent,
    ReportButtonComponent,
    PaymentInfoContainerComponent,
    NameFormComponent,
    AddressFormComponent,
    Base64FileEncoderComponent,
    VariableModalComponent,
    CheckboxSpinnerComponent,
    FlexLayoutModule,
    AppLogoutComponent,
    ConfimationModalComponent,
    LeiaFileDropZoneDirective,
    PhoneInputComponent,
    ErrorBadgeComponent,
    DynamicFormComponent,
    BackNextBarComponent
  ]
})
export class AppCommonModule {}
