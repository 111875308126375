import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { App } from 'app/models/app';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

@Injectable()
export class AppAppByUserIDResolver implements Resolve<App[]> {
  constructor(private svcApp: AppService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<App[]> {
    return this.svcApp.getAppsByUserID(route.paramMap.get('id') || route.parent.paramMap.get('id'));
  }
}
