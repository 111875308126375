export const environment = {
  env_name: 'dev',
  hmr: false,
  production: false,
  api: {
    url: 'https://cms.services.leialoft.com/wp-json/wp/v2/',
    tools: {
      url: 'documents'
    }
  },
  leiaservices: {
    url: 'https://develop-leialoft-service.leialoft-development.com/portal-api/1.0/', // dev
    client_id: 'leialoft', // dev
    client_secret: '3fc0c049-d355-4c21-a1f4-64912387c46f', // dev
    // url: 'https://services.stage.leialoft.com/portal-api/1.0/', // stage
    // client_id: 'leialoft', // stage
    // client_secret: '9c883264-fd76-4001-8d69-586b7f74d378', // stage
    login: 'auth'

    // time: {
    //   url: 'servertime',
    //   type: 'GET'
    // },
    // register: {
    //   email: {
    //     url: 'user',
    //     type: 'PUT'
    //   },
    //   validate_email: {
    //     url: 'user/verification',
    //     type: 'POST'
    //   },
    //   resend_code: {
    //     url: 'user/regcode',
    //     type: 'PUT'
    //   },
    //   details: {
    //     url: 'user',
    //     type: 'POST'
    //   },
    //   forgot_password: {
    //     url: 'user/forgotpassword',
    //     type: 'POST'
    //   },
    //   reset_password: {
    //     url: 'user/resetpassword',
    //     type: 'POST'
    //   },
    //   change_password: {
    //     url: 'user/changepassword',
    //     type: 'PUT'
    //   },
    //   agreements: {
    //     url: 'user/agreements',
    //     type: 'POST'
    //   }
    // },
    // tools: 'tools',
    // apps: {
    //   url: 'app/',
    //   details: {
    //     url: 'details/',
    //     type: 'GET'
    //   },
    //   list: {
    //     url: `list/`,
    //     type: 'GET'
    //   },
    //   lists: {
    //     url: 'lists',
    //     type: 'GET'
    //   },
    //   images: {
    //     url: 'images',
    //     query: '?product_id={{leia_product_id}}&type=screenshot&filename={{leia_test_filename}}',
    //     type: 'POST'
    //   },
    //   delete_image: {
    //     url: 'images',
    //     query: '?product_id={{leia_product_id}}&screenshot_id={{leia_test_filename}}',
    //     type: 'DELETE'
    //   },
    //   sendApkToDev: {
    //     url: 'send',
    //     type: 'POST'
    //   },
    //   deleteAPK: {
    //     url: 'remove',
    //     query: '?product_id={{leia_product_id}}&file_id={{leia_file_id}}',
    //     type: 'POST'
    //   },
    //   submit: {
    //     url: 'submit/',
    //     type: 'POST',
    //     sub_path: '{appId}'
    //   },
    //   approve: {
    //     url: 'approve/',
    //     type: 'PUT',
    //     sub_path: '{appId}'
    //   },
    //   unpublish: {
    //     url: 'unpublish/',
    //     type: 'POST',
    //     sub_path: '{productId}'
    //   },
    //   reject: {
    //     url: 'reject/',
    //     type: 'PUT',
    //     sub_path: '{appId}'
    //   },
    //   auto: {
    //     url: 'auto/',
    //     type: 'POST',
    //     sub_path: '{productId}',
    //   },
    //   upload: {
    //     url: 'upload',
    //     query: '?product_id={{leia_product_id}}',
    //     type: 'POST'
    //   },
    //   apk: {
    //     url: 'apk/',
    //     type: 'GET'
    //   },
    //   uploadApkFile: (id, versionId, fileName) => {
    //     return {
    //       url: `app/upload?product_id=${id}&product_version_id=${versionId}&filename=${fileName}`,
    //       type: 'POST'
    //     };
    //   }
    // },
    // admin: {
    //   status: {
    //     url: 'admin/status',
    //     type: 'PUT'
    //   },
    //   users: {
    //     url: 'admin/user',
    //     type: 'GET'
    //   }
    // },
    // contact: {
    //   contact: {
    //     url: 'user/contact',
    //     type: 'POST'
    //   }
    // },
    // dashboard: {
    //   url: 'dashboard',
    //   applist: {
    //     url: 'apps/',
    //     type: 'GET'
    //   },
    //   developer: {
    //     url: 'developer',
    //     type: 'GET'
    //   },
    //   admin: {
    //     url: 'admin',
    //     type: 'GET'
    //   },
    //   apps: {
    //     url: 'apps',
    //     summary: {
    //       url: 'summary'
    //     }
    //   }
    // },
    // user: {
    //   url: 'user',
    //   type: 'GET',

    //   unique: {
    //     url: 'user/uniquedev',
    //     type: 'POST'
    //   },
    //   bank: {
    //     url: 'user/bank',
    //     type: 'POST'
    //   }
    // },
    // iap: {
    //   getIaps: {
    //     url: 'iap/',
    //     type: 'GET',
    //     sub_path: '{productId}'
    //   },
    //   saveIap: {
    //     url: 'iap',
    //     type: 'POST'
    //   },
    //   upload: {
    //     url: 'iap/upload/',
    //     type: 'POST',
    //     sub_path: '{productId}'
    //   },
    //   download: {
    //     url: 'iap/download/template',
    //     type: 'GET'
    //   }
    // },
    // notifications: {
    //   create: {
    //     url: 'notifications',
    //     type: 'POST'
    //   },
    //   get: {
    //     url: 'notifications',
    //     type: 'GET'
    //   },
    //   delete_notification: {
    //     url: 'notifications/',
    //     type: 'DELETE',
    //     sub_path: '{notificationId}'
    //   }
    // },
    // stripe: {
    //   createOrUpdateDev: {
    //     url: 'stripe/partner',
    //     type: 'POST'
    //   },
    //   getDetails: {
    //     url: 'stripe/partner',
    //     type: 'GET'
    //   },
    //   getPaymentListForDeveloper: {
    //     url: 'stripe/payments/developer',
    //     type: 'GET'
    //   },
    //   getPaymentDetailByTransferGroup: {
    //     url: 'stripe/payments/developer/details/',
    //     type: 'GET'
    //   },
    //   getPaymentListForAdmin: {
    //     url: 'stripe/payments/admin',
    //     type: 'GET'
    //   },
    //   getPaymentDetailBytransferKey: {
    //     url: 'stripe/payments/admin/details/',
    //     type: 'GET'
    //   },
    //   getPaymentDetailForUpcoming: {
    //     url: 'stripe/payments/admin/details',
    //     type: 'GET'
    //   },
    //   holdPayment: {
    //     url: 'stripe/hold',
    //     type: 'POST'
    //   },
    //   getTransferStatusByPartnerId: {
    //     url: 'stripe/transfer/',
    //     type: 'GET'
    //   },
    //   payNow: {
    //     url: 'stripe/paynow',
    //     type: 'POST'
    //   },
    //   getHeldListForAdmin: {
    //     url: 'stripe/payments/admin/held',
    //     type: 'GET'
    //   },
    //   getUserPaymentList: {
    //     url: 'stripe/payments/admin/user/',
    //     type: 'GET'
    //   },
    //   report: {
    //     url: 'stripe/payments/report',
    //     type: 'GET',
    //     developer: {
    //       url: 'developer'
    //     },
    //     admin: {
    //       all: {
    //         url: 'admin'
    //       },
    //       developer: {
    //         url: 'user'
    //       }
    //     }
    //   },
    //   getUserPaymentDetailBytransferKey: (userId, transferKey) => {
    //     return transferKey
    //       ? {
    //           url: `stripe/payments/admin/user/${userId}/details/${transferKey}`,
    //           type: 'GET'
    //         }
    //       : {
    //           url: `stripe/payments/admin/user/${userId}/details`,
    //           type: 'GET'
    //         };
    //   }
    // }
  }
};
