export class RedirectUri {
  constructor(
    public clientId: string,
    public state: string,
    public redirectUri: string
  ) {}

  isValid() {
    return this.clientId && this.state && this.redirectUri;
  }
}
