import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { User, UserStatus } from 'app/models/user';
import { UserService } from 'app/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AppAdminUserResolver implements Resolve<User> {
  constructor(protected svcUser: UserService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<User> {
    return this.svcUser.getUserById(route.paramMap.get('id') || route.parent.paramMap.get('id')).pipe(
      map(user => {
        if (!user.status) {
          user.status = UserStatus.NOT_APPROVED;
        }
        return user;
      })
    );
  }
}
