import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChange
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { ErrorProviderLeaf } from 'app/models/error-provider.model';
import { AppService } from '../../../services/app.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'toggle-selector',
  templateUrl: './toggle-selector.component.html',
  styleUrls: ['./toggle-selector.component.scss']
})
export class ToggleSelectorComponent
  implements OnChanges, DoCheck, AfterViewInit, ErrorProviderLeaf {
  @Input()
  checked = false;
  @Input()
  disabled = false;
  @Input()
  label: string;
  @Input()
  locale: any = {
    enabled: false
  };
  @Input()
  isLocale = true;
  @Input()
  showPriceInput = false;
  @Input()
  enabled = true;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('price', { read: ElementRef })
  price: ElementRef;
  @ViewChild('price', { read: NgModel })
  priceModel: NgModel;

  private _toggled = false;
  private _touched = false;
  get touched() {
    return this._touched;
  }
  get toggled() {
    return this._toggled;
  }
  get dirty() {
    return this.priceModel && this.priceModel.dirty;
  }
  get error() {
    // return this.checked && this.touched && (!this.dirty || !this.priceValid);
    return this.checked && this.touched && !this.priceValid;
  }
  set content(content: ElementRef) {
    this.price = content;
    this.price.nativeElement.focus();
  }

  get priceValid() {
    const price = Number(this.locale.price);
    if (isNaN(price) || price < 0 || this.locale.price === '') {
      return false;
    }
    return true;
  }

  constructor(
    private userService: UserService,
    private appService: AppService,
    private _eref: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngDoCheck() {
    this.cdr.detectChanges();
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!this.enabled) {
      this.checked = this.enabled;
      return;
    }

    if (this.isLocale) {
      this.checked = this.locale.enabled;
    }

    if (simpleChanges.checked) {
      if (simpleChanges.checked.isFirstChange()) {
        return;
      }
      this._toggled =
        simpleChanges.checked.firstChange ===
        simpleChanges.checked.currentValue;
      this._touched = !simpleChanges.checked.firstChange;
    }
  }

  toggleEnabled() {
    if (!this.enabled) {
      this.checked = this.enabled;
      return;
    }

    if (!this.locale) {
      this.checked = !this.checked;
    }
  }

  @HostListener('document:click', ['$event'])
  offClickHandler(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      if (
        event.target.className === 'add-price-button' ||
        event.target.className === 'price-label'
      ) {
      } else {
        this.showPriceInput = false;
      }
    }
    this.cdr.detectChanges();
  }

  onEnter() {
    this.showPriceInput = false;
  }

  localeClick() {
    if (!this.disabled) {
      if (!this.enabled) {
        this.locale.enabled = this.checked = this.enabled;
        this.valueChange.emit({ localeEnabled: this.locale.enabled });
        return;
      }

      if (this.isLocale) {
        this.locale.enabled = !this.locale.enabled;
        this.checked = !this.checked;
        this.valueChange.emit({ localeEnabled: this.locale.enabled });
      }
    }
  }
  showPrice() {
    if (!this.disabled) {
      this.showPriceInput = true;
    }
  }

  getPriceFormatted() {
    if (!this.priceValid) {
      this.locale.price = '';
    }
    return this.locale.price;
  }

  priceChange(price) {
    this.locale.price = price;
    this.valueChange.emit({ price });
  }
}
