import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VerficationDocumentModel } from 'app/models/stripe-account';

@Component({
  selector: 'app-payment-verification',
  templateUrl: './payment-verification.component.html',
  styleUrls: ['./payment-verification.component.scss']
})
export class PaymentVerificationComponent {
  @Input()
  parent: FormGroup;
  @Input()
  isWhite = false;
  @Input()
  isDisabled = false;
  @Input()
  readOnly = false;
  @Input()
  set template(model: VerficationDocumentModel) {
    if (!!model) {
      this._template = model;
    }
  }
  get template() {
    return this._template;
  }
  private _template: VerficationDocumentModel;
}
