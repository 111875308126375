import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  AdvancedPieChartComponent,
  ColorHelper,
  LegendComponent
} from '@swimlane/ngx-charts';
import * as tinygradient from 'tinygradient';
import { IColorScheme } from '../../../models/color-scheme.model';
import { ILegendOptions } from '../../../models/legend-options.model';
import { PieChartData } from '../../../models/pie-chart-data.model';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChartComponent extends AdvancedPieChartComponent {
  @Input()
  get innerRadius() {
    return this._innerRadius;
  }
  set innerRadius(value: number) {
    if (value) {
      this._innerRadius = value;
    }
  }
  @Input()
  get outerRadius() {
    return this._outerRadius;
  }
  set outerRadius(value: number) {
    if (value) {
      this._outerRadius = value;
    }
  }
  get data() {
    return this._data;
  }
  @Input()
  set data(value: PieChartData[]) {
    if (value) {
      this._data = value;
      this.colorsRGB = tinygradient(['#C89DFF', '#6B2ACC']).rgb(
        this.data.length
      );
      this.colorScheme = {
        name: 'custom',
        selectable: true,
        group: 'ordinal',
        domain: this.colorsRGB.map(val => '#' + val.toHex())
      };
      this.scheme = this.colorScheme;
      this.domain = this.data.map(val => val.name);
      this.colors = new ColorHelper(
        this.colorScheme,
        this.colorScheme.group,
        this.domain
      );
      this.legendOptions = {
        // scaleType: 'legend',
        domain: this.domain,
        colors: this.colors,
        title: ''
      };
    }
  }

  constructor(
    public el: ElementRef,
    public cd: ChangeDetectorRef,
    public zone: NgZone,
    private renderer: Renderer2,
    private svcDashboard: DashboardService
  ) {
    super(el, zone, cd);
  }
  @ViewChild(PieChartComponent) chart: AdvancedPieChartComponent;
  @ViewChild(LegendComponent) legend;

  _innerRadius = 70;
  _outerRadius = 100;
  @Input() animations = false;
  @Input() gradient = false;

  private _data = [];

  width: number;
  height: number;

  // options
  showLabels = true;
  explodeSlices = false;
  doughnut = false;
  arcWidth = 0.25;
  transform = `translate(100, 100)`;
  colorScheme: IColorScheme;
  view: number[];
  tmp: string[];
  legendOptions: ILegendOptions = {
    scaleType: 'legend',
    colors: null,
    domain: [],
    title: ''
  };
  labelToHexColorMap: any[];
  colorsRGB;

  // Override super.update to prevent it from setting the innerRadius and outerRadius
  // Might break future re-use of this component
  update() {}
}
