import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'back-next-bar',
  templateUrl: './back-next-bar.component.html',
  styleUrls: ['./back-next-bar.component.scss']
})
export class BackNextBarComponent implements OnInit {
  @Input() nextDisabled: boolean

  @Output() backClick: EventEmitter<any> = new EventEmitter()
  @Output() nextClick: EventEmitter<any> = new EventEmitter()

  noBack = false
  noNext = false

  ngOnInit() {
    this.noBack = this.backClick.observers.length === 0
    this.noNext = this.nextClick.observers.length === 0
  }

  backClicked() {
    this.backClick.emit(true)
  }

  nextClicked() {
    if (!this.nextDisabled) {
      this.nextClick.emit(true)
    }
  }
}
