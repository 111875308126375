import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  Inject,
  ElementRef,
  ViewEncapsulation,
  OnInit,
  HostListener
} from '@angular/core';
import { App, AppVersion } from '@models/app';
import { AppCalendarDateRange } from '@models/app-calendar-date-range';
import { AppData } from '@models/app-data';
import { NgbMomentjsAdapter } from '../../../adapters/ngb-momentjs-adapter';
import {
  MenuChoice,
  MenuChoices,
  MomentRange
} from '../../../models/moment-range';
import { DashboardService } from '../../../services/dashboard.service';
import { UserService } from '../../../services/user.service';
import { ModalComponent } from '../modal/modal.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { MessageDialogServiceToken, MessageDialogServiceImpl, dialogButtons } from '../confimation-modal/dialog.service';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss'],
  providers: [NgbMomentjsAdapter],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardMenuComponent implements OnInit {
  @ViewChild(ModalComponent)
  modal: ModalComponent;
  @ViewChild('versionSelector')
  versionSelector: CustomDropdownComponent;
  @ViewChild('calendar') calendarModalContent: ElementRef;

  private _apps: App[] = [];
  @Input()
  set apps(value: App[]) {
    if (value) {
      this._apps = value;
    }
  }
  get apps() {
    return this._apps;
  }
  @Input()
  showMenuBorder = true;
  @Input()
  showAllAppsDropdown = true;
  @Input()
  showVersions = false;

  @Output()
  onAppsSelected = new EventEmitter<AppData>();
  @Output()
  onDateRangeChange = new EventEmitter<MomentRange>();

  
  dateRanges = MenuChoices;
  dateToUnderline = MenuChoice.Lifetime;
  dateCustom: MomentRange;
  displayCustomDate = false;
  appsChoice: App | App[] = this.apps;
  versions: AppVersion[] = [];
  versionChoice: AppVersion = null;
  dateFilterSelectVal = "Lifetime";
  selectedDateRange: MomentRange = MenuChoices.find((mc: MomentRange) => mc.label === "Lifetime");

  windowWidth: number;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
  }
  constructor(
    public svcDashboard: DashboardService,
    public adapter: NgbMomentjsAdapter,
    public svcUser: UserService,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {}

  ngOnInit() {
    this.windowWidth = window.innerWidth;
  }

  getCalendarCount(): number {
    if (this.windowWidth && this.windowWidth > 730) {
      return 2;
    } else {
      return 1;
    }
  }

  findCurrentRange() {
    const res = this.dateRanges.find(
      range => range.label === this.dateToUnderline
    );

    if (res.label === MenuChoice.Custom) {
      return this.dateCustom;
    }

    return res;
  }

  isDateUnderlined(choice: MenuChoice) {
    if (choice === this.dateToUnderline) {
      return true;
    }
    return false;
  }

  isDateCustom(range: MomentRange) {
    return range.label === MenuChoice.Custom;
  }

  handleAppSelected(apps: App | undefined) {
    this.appsChoice = apps;
    this.versions = apps && apps.versions ? apps.versions : [];
    this.versionChoice = null;
    if (this.versionSelector) {
      this.versionSelector.selectedApp = null;
    }
    this.refreshDashboardData();
  }

  handleAppVersionSelected(version: AppVersion) {
    this.versionChoice = version;
    this.refreshDashboardData();
  }

  handleDateSelected(range: AppCalendarDateRange) {
    this.displayCustomDate = true;
    this.dateCustom = {
      label: MenuChoice.Custom,
      from: this.adapter.toModel(range.fromDate),
      to: this.adapter.toModel(range.toDate),
      unit: 'days'
    };
    this.onAppsSelected.emit(new AppData(this.appsChoice, this.versionChoice));
  }

  refreshDashboardData() {
    this.onAppsSelected.emit(new AppData(this.appsChoice, this.versionChoice));
    const range = MenuChoices.find(rng => rng.label === this.dateToUnderline);
    if (this.displayCustomDate) {
      this.emitDateCustom();
    } else {
      this.emitDateChange(range, this.dateToUnderline);
    }

  }

  compareFn(app1: App, app2: App) {
    const res = app1 && app2 ? app1.title === app2.title : app1 === app2;
    return res;
  }

  trackByFn(index: number, item: App) {
    return index;
  }

  emitDateChange(range: MomentRange, dateToUnderline: MenuChoice) {
    this.displayCustomDate = false;
    this.onDateRangeChange.emit(range);
    this.dateToUnderline = dateToUnderline;
  }

  emitDateCustomOld() {
    this.modal.hide();
    if (this.dateCustom) {
      this.displayCustomDate = true;
      this.dateToUnderline = MenuChoice.Custom;

      this.onDateRangeChange.emit(this.dateCustom);
    }
  }

  emitDateCustom() {
    if (this.dateCustom) {
      this.displayCustomDate = true;
      this.dateToUnderline = MenuChoice.Custom;

      this.onDateRangeChange.emit(this.dateCustom);
    }
  }

  showRangePicker(range: MomentRange) {
    this.dialogService.openMessageDialog({
      title: "Select Range",
      bodyContentRef: this.calendarModalContent,
      actions: [dialogButtons.ok, dialogButtons.cancel],
      hPadding: "30px",
      headerPaddingTop: "20px",
      headerMarginBotttom: "20px",
      footerMinHeight: "80px",
      noScrollBody: true,
    },
    {
      width: "auto",
      maxWidth: "99vw"
    }).afterClosed().subscribe(res => {
      if (res > 0) {
        this.selectedDateRange = range;
        this.emitDateCustom();
      }
    });
  }

  selectDateRange(range: MomentRange) {
    if(this.isDateCustom(range)) {
      this.showRangePicker(range);
    } else {
      this.selectedDateRange = range;
      this.emitDateChange(range, range.label)
    }
  }
}
