import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { PaymentAdminList } from 'app/models/payment-admin';
import { PaymentService } from 'app/services/payment.service';
import { Observable } from 'rxjs';

@Injectable()
export class AppPaymentByUserIdResolver implements Resolve<PaymentAdminList> {
  constructor(private svcPayment: PaymentService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PaymentAdminList> {
    return this.svcPayment.getUserListById(
      route.paramMap.get('id') || route.parent.paramMap.get('id'),
      this.svcPayment.currentPage,
      this.svcPayment.itemsPerPage
    );
  }
}
