import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-total',
  templateUrl: './total.component.html',
  styleUrls: ['./total.component.scss']
})
export class TotalComponent {
  @Input()
  title;
  @Input()
  value;
  @Input()
  percentChange;
  @Input()
  currency = false;

  constructor() {}
}
