import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { dialogButtons, MessageDialogServiceImpl, MessageDialogServiceToken, } from '@common/components/confimation-modal/dialog.service';
import { ModalComponent } from '@common/components/modal/modal.component';
import { INavigationGuardComponent } from '@common/components/navigation-guard/navigation-guard.component';
import { RadioButtonOption } from '@common/components/radio-button-set/radio-button-set.component';
import { VariableModalComponent } from '@common/components/variable-modal/variable-modal.component';
import { Country, CountryType } from '@enums/country-type.enum';
import { VerificationStatus } from '@enums/verification-status.enum';
import { CountryCode } from '@models/country-code.model';
import { DeveloperModel } from '@models/stripe-account';
import { BankDetailService } from '@services/bank-detail.service';
import { UtilService } from '@services/util.service';
import { VariableAlertModalComponent } from 'app/common.module/components/variable-alert-modal/variable-alert-modal.component';
import deepdash from 'deepdash';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
const _ = deepdash(lodash);

export const fieldLabelsPubDetails = {
  rllDeveloperName: "RLL Developer Name",
  name: "Full Name",
  email: "Primary Email",
  iban: "IBAN",
  taxID: "Tax ID",
  addressOne: "Address #1",
  city: "City",
  stateOrProvince: "State/Province",
  zipOrPostalCode: "Zip/Postal Code",
  address: "Address #1",
  dob: "Date of Birth",
  accountNumber: "Account Number",
  bsb: "BSB",
  front: "Front of ID Document",
  back: "Back of ID Document",
  fullName: "Full Name",
  date: "Date of Acceptance of TOS",
  personalIdNumber: "SSN",
  sortCode: "Sort Code",
  routingNumber: "Routing Number",
}

export interface IPubDetailError {
  sectionName?: string;
  controlNames: string[];
}

@Component({
  selector: 'app-publishing-details',
  templateUrl: './publishing-details.component.html',
  styleUrls: ['./publishing-details.component.scss']
})
export class PublishingDetailsComponent implements OnInit, OnDestroy, INavigationGuardComponent {
  @HostBinding('class')
  classes = 'container-fluid';
  @ViewChild('updateSuccessModal')
  updateSuccessModal: ModalComponent;
  @ViewChild('bankDetailSetupModal')
  bankDetailSetupModal: ModalComponent;
  @ViewChild('submitConfirmModal')
  submitConfirmModal: VariableAlertModalComponent;
  @ViewChild('errorModal')
  errorModal: VariableAlertModalComponent;
  @ViewChild(VariableModalComponent)
  navGuardModal: VariableModalComponent;
  @ViewChild('formDir') formDir: FormGroupDirective;
  // tslint:disable-next-line:no-output-on-prefix
  @Input()
  readOnly = false;
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSuccess = new EventEmitter();

  private readonly _accountOptions = [
    { name: 'Individual', value: 'individual' },
    { name: 'Company', value: 'company' }
  ];
  private _accountType: RadioButtonOption;
  private _countryType: CountryCode;
  private _shouldShowSetup = false;
  private _countryTemplate: DeveloperModel;

  public error;
  public isSubmitSuccess = false;
  public form: FormGroup;

  // public closeConfirmed: Subject<boolean> = null;

  modalTest = 0;

  get formDeveloperNameControl() {
    return this.form.get(['contactInfo', 'developerName']);
  }

  get isWhite() {
    return true;
  }

  get hasCountry() {
    const formValue = this.form.get(['stripeAccount', 'country']).value;
    return !!formValue && formValue !== '';
  }

  get formCountryControl() {
    return this.form.get(['stripeAccount', 'country']);
  }

  get externalAccountCountry() {
    const country = !!this.form ? this.form.get(['stripeAccount', 'country']) : null;
    return !!country ? country.value : null;
  }

  get accountOptions() {
    return this._accountOptions;
  }
  get accountType() {
    return this._accountType;
  }
  set accountType(rbOption: RadioButtonOption) {
    if (!!rbOption) {
      const { value } = rbOption;
      if (!!value) {
        const formAccountTypeControl = this.form.get(['stripeAccount', 'type']);

        const formCountryControl = this.form.get(['stripeAccount', 'country']);

        this._accountType = rbOption;
        this.updateForm(value);
        this.clearBankValidationErrors();
        if (formCountryControl.value) {
          const country = Country[formCountryControl.value as CountryType];
          const ctryVal = formCountryControl.value;

          switch (value) {
            case 'company':
              this.countryTemplate = this.bankDetailService.selectCompanyDeveloper(formCountryControl.value as CountryType);
              break;
            case 'individual':
              this.countryTemplate = this.bankDetailService.selectIndividualDeveloper(formCountryControl.value as CountryType);
              break;
          }

          // send 'null' to all form groups / controls
          this.form.reset();
          this.formDir.resetForm();

          // Reset the accountTypeConrol's value
          formAccountTypeControl.setValue(value);
          this.form.get(['stripeAccount', 'country']).setValue(ctryVal);
          // formCountryControl.setValue(country.alpha2);

          // this.countryType = country;
        }
      }
    }
  }
  get countryType() {
    return this._countryType;
  }
  set countryType(country: CountryCode) {
    if (!!country) {
      const { alpha2 } = country;
      const formCountryControl = this.form.get(['stripeAccount', 'country']);
      const formAccountTypeControl = this.form.get(['stripeAccount', 'type']);
      const formCountryControlVal = formCountryControl.value;
      const formAccountTypeControlVal = formAccountTypeControl.value;
      const prevAlpha2 = _.get(this._countryType, 'alpha2', null);

      if (prevAlpha2 !== country.alpha2) {
        this.form.reset();
        this.formDir.resetForm();
      }

      this._countryType = country;

      formCountryControl.setValue(formCountryControlVal);
      formAccountTypeControl.setValue(formAccountTypeControlVal);

      this._accountType = this.findAccountTypeOption(formAccountTypeControlVal);

      if (!!this.accountType) {
        const { value } = this.accountType;
        switch (value) {
          case 'company':
            this.countryTemplate = this.bankDetailService.selectCompanyDeveloper(alpha2 as CountryType);
            break;
          case 'individual':
            this.countryTemplate = this.bankDetailService.selectIndividualDeveloper(alpha2 as CountryType);
            break;
        }
        this.bankDetailService.copyUserContactInfoTo(this.form as FormGroup);
        this.cdr.detectChanges();
        this.clearBankValidationErrors();
      }
    }
  }

  get shouldShowSetup() {
    return this._shouldShowSetup;
  }
  set shouldShowSetup(showing: boolean) {
    this._shouldShowSetup = showing;
  }
  get countryTemplate() {
    return this._countryTemplate;
  }
  set countryTemplate(value: DeveloperModel) {
    if (!!value) {
      this._countryTemplate = value;
      this.addControlsFromTemplate(value);
      this.form.markAsPristine();
    }
  }
  serverTime$ = this.bankDetailService.serverTime.pipe(
    pluck('serverTime'),
    map(time => moment(time))
  );

  constructor(
    private bankDetailService: BankDetailService,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {}

  ngOnInit() {
    this.form = this.createForm();

    this.bankDetailService.getDetail().subscribe(
      (res: any) => {
        const idDocumentsExist = res.verificationDocumentsExist;
        lodash.set(res, 'stripeAccount.externalAccount.accountTypeSelection', { value: null });
        this.updateForm(res.accountType || res.stripeAccount.type);
        this.form.patchValue(res);
        this.isSubmitSuccess = this.readOnly = this.isReadOnly(res.verificationStatus as VerificationStatus, idDocumentsExist);
        this.shouldShowSetup = false;
      },
      err => {
        if (err.status === 400) {
          this.shouldShowSetup = true;
        }
      }
    );
  }

  clearErrors(fg: FormGroup) {
    if (fg && fg.controls) {
      for (const key of Object.keys(fg.controls)) {
        fg.controls[key].markAsPristine();
        fg.controls[key].markAsUntouched();
      }
    }
  }
  clearBankValidationErrors() {
    if (this.form
      && this.form.controls
      && this.form.controls.stripeAccount
      && (this.form.controls.stripeAccount as FormGroup).controls
      && (this.form.controls.stripeAccount as FormGroup).controls.externalAccount) {
      const bankFG: FormGroup = (this.form.controls.stripeAccount as FormGroup).controls.externalAccount as FormGroup;
      if (bankFG.controls["accountNumber"]) {
        this.clearErrors(bankFG.controls["accountNumber"] as FormGroup);
      }
      if (bankFG.controls["bankCode"]) {
        this.clearErrors(bankFG.controls["bankCode"] as FormGroup);
      }
      if (bankFG.controls["branchCode"]) {
        this.clearErrors(bankFG.controls["branchCode"] as FormGroup);
      }
      if (bankFG.controls["bsb"]) {
        this.clearErrors(bankFG.controls["bsb"] as FormGroup);
      }
      if (bankFG.controls["iban"]) {
        this.clearErrors(bankFG.controls["iban"] as FormGroup);
      }
      if (bankFG.controls["routingNumber"]) {
        this.clearErrors(bankFG.controls["routingNumber"] as FormGroup);
      }
      if (bankFG.controls["sortCode"]) {
        this.clearErrors(bankFG.controls["sortCode"] as FormGroup);
      }
    }
  }

  isReadOnly(status: VerificationStatus, idDocumentsExist: boolean) {
    switch (status) {
      case VerificationStatus.COMPLETED:
      case VerificationStatus.PENDING:
      case VerificationStatus.REJECTED:
        return !idDocumentsExist;
      default:
        return false;
    }
  }

  // Handle refresh and tab exits
  @HostListener('window:beforeunload', ['$event'])
  guardNavigation($event: Event) {
    if (this.form.dirty && !this.isSubmitSuccess) {
      return false;
    }
    return true;
  }

  // confirmCloseDialogOK(){
  //   this.form.markAsPristine();
  //   this.closeConfirmed.next(true);
  //   if (this.closeConfirmed) {
  //     this.closeConfirmed.complete();
  //   }
  //   this.closeConfirmed = null;
  //   this.navGuardModal.modal.hide();
  // }

  // confirmCloseDialogCancel(){
  //   this.navGuardModal.modal.hide();
  // }
  // confirmCloseDialogHidden() {
  //   this.closeConfirmed.next(false);
  //   if (this.closeConfirmed) {
  //     this.closeConfirmed.complete();
  //   }
  //   this.closeConfirmed = null;
  // }
  // confirmClose(): Observable<boolean> | boolean {
  //   if (this.closeConfirmed) {
  //     this.closeConfirmed.complete();
  //   }
  //   this.closeConfirmed = new Subject();
  //   this.navGuardModal.headerText = 'Are you sure you want to do that?';
  //   this.navGuardModal.showAlert('If you leave now, your Publishing Details Profile will not be verified');
  //   return this.closeConfirmed.asObservable();
  // }
  // // Handle navigation away, called by Deactivate Guard
  // async showNavigationGuardModal() {
  //   this.navGuardModal.headerText = 'Are you sure you want to do that?';
  //   return this.navGuardModal.showAlert('If you leave now, your Publishing Details Profile will not be verified');
  // }

  confirmNavigation() {
    if (!this.form.dirty) {
      return true;
    }
    return this.dialogService.openMessageDialog({
      actions: [dialogButtons.yesLeave, dialogButtons.noStay],
      title: "Are you sure you want to do that?",
      text: "If you leave now, your Publishing Details Profile will not be verified.",
    }).afterClosed().pipe(
      map(res => res > 0),
    );
    // this.modalTest = 1 - this.modalTest;
    // if (this.modalTest) {
    // } else {
    //   return this.confirmClose();
    // }
  }

  updateForm(accountType: 'company' | 'individual') {
    const formStripeAccount = this.form.get('stripeAccount') as FormGroup;
    const formAddress = formStripeAccount.get('address');
    const formTaxID = formStripeAccount.get('taxID');
    const formBusinessName = formStripeAccount.get('name');

    if (accountType === 'company') {
      if (!formAddress) {
        formStripeAccount.addControl(
          'address',
          this.fb.group({
            addressOne: this.fb.group({
              value: null
            }),
            addressTwo: this.fb.group({
              value: null
            }),
            city: this.fb.group({
              value: null
            }),
            stateOrProvince: this.fb.group({
              value: null
            }),
            zipOrPostalCode: this.fb.group({
              value: null
            })
          }) // E.g. - AT - not required
        );
      }
      if (!formTaxID) {
        formStripeAccount.addControl(
          'taxID',
          this.fb.group({
            value: null
          })
        );
      }
      if (!formBusinessName) {
        formStripeAccount.addControl(
          'name',
          this.fb.group({
            value: null
          })
        );
      }
    } else {
      if (!!formAddress) {
        formStripeAccount.removeControl('address');
      }
      if (!!formTaxID) {
        formStripeAccount.removeControl('taxID');
      }
      if (!!formBusinessName) {
        formStripeAccount.removeControl('name');
      }
    }
  }

  setControlErrors(control: FormGroup) {
    if (control === null) {
      return;
    }

    const controls = control.controls;
    // tslint:disable-next-line:forin
    for (const name in controls) {
      const iControl = controls[name];
      if (iControl instanceof FormGroup) {
        this.setControlErrors(iControl);
      } else if (iControl instanceof FormArray) {
        // tslint:disable-next-line:forin
        for (const i in iControl.controls) {
          const ctrlEl = iControl.controls[i];
          if (ctrlEl instanceof FormGroup) {
            this.setControlErrors(ctrlEl);
          } else {
            ctrlEl.markAsDirty();
          }
        }
      } else {
        iControl.markAsDirty();
      }
    }
  }

  // Keeping these two around for debugging purposes
  // log(a) {
  //   console.log('FORM', a);
  //   return true;
  // }

  // logInvalidControls(control: FormGroup) {
  //   const controls = control.controls;
  //   // tslint:disable-next-line:forin
  //   for (const name in controls) {
  //     const iControl = controls[name];
  //     if (iControl instanceof FormGroup) {
  //       if (iControl.invalid) {
  //         console.log(name);
  //         this.logInvalidControls(iControl);
  //       }
  //     } else if (iControl instanceof FormArray) {
  //       // tslint:disable-next-line:forin
  //       for (const i in iControl.controls) {
  //         const ctrlEl = iControl.controls[i];
  //         if (ctrlEl instanceof FormGroup) {
  //           if (ctrlEl.invalid) {
  //             console.log(i);
  //             this.logInvalidControls(ctrlEl);
  //           }
  //         } else {
  //           if (ctrlEl.invalid) {
  //             console.log(ctrlEl);
  //           }
  //         }
  //       }
  //     } else {
  //       if (iControl.invalid) {
  //         console.log(iControl);
  //       }
  //     }
  //   }
  // }
  addErrorsToList(errList: IPubDetailError[], section: string, ctrls: any){
    let err: IPubDetailError;
    if (!section) {
      err = errList.find(e => !e.sectionName);
    } else {
      err = errList.find(e => e.sectionName === section);
    }
    if (!err) {
      err = { sectionName: section, controlNames: null };
      errList.push(err);
    }
    if (!err.controlNames || !err.controlNames.length) {
      err.controlNames = this.getControlError(ctrls);
    } else {
      err.controlNames.push(...this.getControlError(ctrls));
    }
  }

  getFormErrors(fg: FormGroup) {
    const errCtrls: IPubDetailError[] = [];
    if (fg && fg.controls && fg.controls.contactInfo) {
      this.addErrorsToList(errCtrls, "Developer Contact", fg.controls.contactInfo);
    }
    if (fg && fg.controls && fg.controls.stripeAccount
      && (fg.controls.stripeAccount as any).controls
      && (fg.controls.stripeAccount as any).controls.name){
        this.addErrorsToList(errCtrls, "Business Information", { controls: { name: (fg.controls.stripeAccount as any).controls.name } });
    }
    if (fg && fg.controls && fg.controls.stripeAccount
      && (fg.controls.stripeAccount as any).controls
      && (fg.controls.stripeAccount as any).controls.taxID){
        this.addErrorsToList(errCtrls, "Business Information", { controls: { taxID: (fg.controls.stripeAccount as any).controls.taxID } });
    }
    if (fg && fg.controls && fg.controls.stripeAccount
      && (fg.controls.stripeAccount as any).controls
      && (fg.controls.stripeAccount as any).controls.address) {
      this.addErrorsToList(errCtrls, "Business Information", (fg.controls.stripeAccount as any).controls.address);
    }
    if (fg && fg.controls && fg.controls.stripeAccount
      && (fg.controls.stripeAccount as any).controls
      && (fg.controls.stripeAccount as any).controls.accountRepresentatives
      && (fg.controls.stripeAccount as any).controls.accountRepresentatives.controls
      && (fg.controls.stripeAccount as any).controls.accountRepresentatives.controls.length) {
      (fg.controls.stripeAccount as any).controls.accountRepresentatives.controls.forEach(_fg => {
        if (_fg.controls) {
          const accRep = { controls: {} };
          for (const key in _fg.controls) {
            if (key !== "address") {
              accRep.controls[key] = _fg.controls[key];
            }
          }
          this.addErrorsToList(errCtrls, "Account Representative", accRep);
          if(_fg.controls.address) {
            this.addErrorsToList(errCtrls, "Account Representative", _fg.controls.address);
          }
        }
      });
    }
    if (fg && fg.controls && fg.controls.stripeAccount
      && (fg.controls.stripeAccount as any).controls
      && (fg.controls.stripeAccount as any).controls.externalAccount) {
      this.addErrorsToList(errCtrls, "Banking Information", (fg.controls.stripeAccount as any).controls.externalAccount);
    }
    if (fg && fg.controls && fg.controls.stripeAccount
      && (fg.controls.stripeAccount as any).controls
      && (fg.controls.stripeAccount as any).controls.verificationDocument
      && (fg.controls.stripeAccount as any).controls.verificationDocument.controls
      && (fg.controls.stripeAccount as any).controls.verificationDocument.controls.document) {
      this.addErrorsToList(errCtrls, "Verification Documents", (fg.controls.stripeAccount as any).controls.verificationDocument.controls.document);
    }
    if (fg && fg.controls && fg.controls.stripeAccount
      && (fg.controls.stripeAccount as any).controls
      && (fg.controls.stripeAccount as any).controls.tosAcceptance) {
      this.addErrorsToList(errCtrls, "Terms of Service", (fg.controls.stripeAccount as any).controls.tosAcceptance);
    }
    return errCtrls;
  }

  getControlError(ctrls: any): string[] {
    const errorCtrls: string[] = [];
    for(const ctrlName in ctrls.controls) {
      if (!ctrls.controls[ctrlName].valid) {
        errorCtrls.push(fieldLabelsPubDetails[ctrlName]?fieldLabelsPubDetails[ctrlName]:ctrlName);
      }
    }
    return errorCtrls;
  }

  showModal(fg: FormGroup) {
    if (!fg.valid) {
      let ctrlErrText = "";
      this.getFormErrors(fg).forEach((err:IPubDetailError) => {
        if (err.controlNames && err.controlNames.length) {
          let crtlList = "";
          err.controlNames.forEach(cn => crtlList += "<li>" + cn + "</li>");
          if (err.sectionName) {
            ctrlErrText += "<li>" + err.sectionName + "<ul>" + crtlList + "</ul></li>"
          } else {
            ctrlErrText += crtlList;
          }
        }
      });
      ctrlErrText = "Please fill out the fields below appropriately:<br/><ul>" + ctrlErrText + "</ul>";
      this.dialogService.openMessageDialog({
        title: "Looks Like There Are Some Errors",
        htmlText: ctrlErrText,
        actions: [dialogButtons.ok],
      });
      // this.submitConfirmModal.headerText = 'Invalid Form';
      // this.submitConfirmModal.showAlert(`Please fill in the missing fields`);
    } else {
      this.submitConfirmModal.headerText = 'Thank You!';
      this.submitConfirmModal.showAlert(
        `You’re on your way to being able to submit premium apps and apps with in-app purchases in the LeiaLoft store.
      Verification usually takes 24-48 hours, but in some instances could take longer depending upon the specific
      circumstances. We’ll send an email as soon as verification is complete and will reach out should there be any issues.`
      );
    }
  }

  makePOSTRequest({ value, valid }: FormGroup) {
    if (!!valid) {
      this.bankDetailService.stripePOSTPartner(value).subscribe(
        res => {
          this.shouldShowSetup = false;
          this.isSubmitSuccess = this.readOnly = true;
          this.util.recurseFormControls(this.form, (ctrl)=>{
            ctrl.markAsPristine();
            ctrl.markAsUntouched();
          });
        },
        (err: HttpErrorResponse) => {
          this.handleError(err);
        }
      );
    }
  }

  handleError(err: HttpErrorResponse) {
    if ('code' in err.error) {
      this.errorModal.headerText = err.error.code;
      this.errorModal.showAlert(err.error.message);
    } else if ('error' in err.error) {
      if ('message' in err.error.error) {
        this.errorModal.headerText = err.error.status;
        this.errorModal.showAlert(err.error.error.message);
        return;
      }
      if (err.error && err.error.error && err.error.error.raw && err.error.error.raw.message) {
        this.errorModal.headerText = "An error occured";
        this.errorModal.showAlert(err.error.error.raw.message);
        return;
      }
    }
  }

  findAccountTypeOption(option: 'individual' | 'company'): RadioButtonOption {
    return this.accountOptions.find(op => op.value === option);
  }

  createRepresentativeControl<T>(representative: T) {
    const fg = this.fb.group({});
    _.eachDeep(
      _.omitDeep(representative, ['present', 'description']),
      (value, key, path, depth, parent, parentKey, parentPath, parents) => {
        if (depth === 0) {
          fg.addControl(key, this.fb.group({}));
        } else {
          let parentControl = fg.get(parentPath) as FormGroup;
          if (!parentControl) {
            fg.addControl(parentKey, this.fb.group(parent[key]));
          } else {
            if (parentControl instanceof FormControl) {
              parentControl = this.fb.group({});
              parentControl.addControl(key, this.fb.control(value));
            } else {
              if (value === null) {
                parentControl.addControl(key, this.fb.control(value));
              } else {
                parentControl.addControl(key, this.fb.group(value));
              }
            }
          }
        }
      }
    );
    return fg;
  }

  handleOnFinishClick() {
    this.bankDetailSetupModal.hide();
    this.onSuccess.emit();
  }

  handleOnSubmitApp() {
    this.router.navigate(['/main/my-apps']);
  }

  clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  addControlsFromTemplate(template: DeveloperModel) {
    if (!!template) {
      const reps = template.stripeAccount.accountRepresentatives;
      const formArray = this.form.get(['stripeAccount', 'accountRepresentatives']) as FormArray;
      this.clearFormArray(formArray);
      reps.forEach(rep => {
        const control = this.createRepresentativeControl(rep);
        formArray.push(control);
      });
      this.bankDetailService.copyUserContactInfoTo(this.form as FormGroup);
      // this.cdr.detectChanges();
    }
  }

  // TODO: Please, Keep as last method in class, because of size
  createForm() {
    return this.fb.group({
      contactInfo: this.fb.group({
        name: this.fb.group({
          firstName: this.fb.group({
            value: null
          }),
          lastName: this.fb.group({
            value: null
          })
        }),
        email: this.fb.group({
          value: null
        }),
        phoneNumber: this.fb.group({
          value: null
        }),
        rllDeveloperName: this.fb.group({
          value: null
        })
      }),
      stripeAccount: this.fb.group({
        type: this.findAccountTypeOption('individual').value,
        country: null,
        externalAccount: this.fb.group({
          // Should have default equal to stripeAccount.country OR it might not be needed any longer
          country: this.externalAccountCountry,
          // Add to model?
          accountTypeSelection: null,
          routingNumber: this.fb.group({
            value: null
          }),
          accountNumber: this.fb.group({
            value: null
          }),
          bsb: this.fb.group({
            value: null
          }),
          iban: this.fb.group({
            value: null
          }),
          sortCode: this.fb.group({
            value: null
          }),
          bankCode: this.fb.group({
            value: null
          }),
          branchCode: this.fb.group({
            value: null
          })
        }),
        accountRepresentatives: this.fb.array([this.fb.group({})]),
        tosAcceptance: this.fb.group({
          fullName: this.fb.group({
            value: null
          }),
          date: this.fb.group({
            value: null
          }),
          ip: this.fb.group({
            value: null
          })
        }),
        verificationDocument: this.fb.group({
          document: this.fb.group({
            front: this.fb.group({
              value: null
            }),
            back: this.fb.group({
              value: null
            })
          })
        })
      })
    });
  }

  ngOnDestroy() {
    // if (this.closeConfirmed) {
    //   this.closeConfirmed.complete();
    //   this.closeConfirmed = null;
    // }
  }
}
