import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tool } from '../../../models/tool';
import { AppService } from '../../../services/app.service';
import { ToolsService } from '../../../services/tools.service';

@Component({
  selector: 'app-tools-documentation',
  templateUrl: './tools-documentation.component.html',
  styleUrls: ['./tools-documentation.component.scss'],
  providers: [ToolsService]
})
export class ToolsDocumentationComponent implements OnInit, OnDestroy {
  tools$: Observable<Tool[]>;
  itemsPerRow: 2;

  @HostBinding('class') classes = 'container-fluid';

  constructor(private toolsService: ToolsService, private appService: AppService) {}

  ngOnInit() {
    // LLP-685 REDIRECT TO HTTPS://WWW.LEIAINC.COM/SDK
    window.location.href = 'https://www.leiainc.com/sdk'
    // Tools currently come in reverse of desired order due to WP migration.
    // TODO: Make the order determined based on actual docs instead of the order of the array.
    this.tools$ = this.toolsService.getAll().pipe(map(tools => tools.filter(tool => tool.showInToolsPage).reverse()));
    this.appService.setFooterWhite(true);
  }
  ngOnDestroy() {
    this.appService.setFooterWhite(true);
  }

  getDownload(url: string, toolId: string, downloadId: string) {
    this.toolsService.updateDownloadHistory(toolId, downloadId).subscribe(res => {
      window.location.href = url;
    });

  }
}
