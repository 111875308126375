import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-users-report',
  templateUrl: './users-report.component.html',
  styleUrls: ['./users-report.component.scss']
})
export class UsersReportComponent implements OnInit {

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  downloadReport() {
    this.userService
      .getUsersCSV()
      .subscribe((blob: any) => this.saveBlob(blob));

  }
  private saveBlob(blob: Blob) {
    const filename = 'user-report-' + Date.now() + '.csv';
    saveAs(blob, filename);
  }
}
