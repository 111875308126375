import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgModel } from '@angular/forms';
import { regxPatterns } from '@apps/components/dynamic-form/dynamic-form-validator.regexp';
import { RegisterService } from '@services/register.service';
import { Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-public-details',
  templateUrl: './public-details.component.html',
  styleUrls: ['./public-details.component.scss'],
})
export class PublicDetailsComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  updatedUser: User;
  @Input()
  title: string;
  @Input()
  readonly = false;
  @Output()
  update = new EventEmitter();
  @ViewChild('rllDeveloperName') rllDeveloperNameInput: NgModel;
  @ViewChild('publicEmail') publicEmailInput: NgModel;

  developerName$ = new Subject<string>();
  errorRLLDev: string;
  destroy$ = new Subject();
  rllDevName: string;
  emailRegX = regxPatterns.email;

  constructor(private userService: UserService, private registerService: RegisterService) {
    this.developerName$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(name => this.registerService.isUnique(name))
      )
      .pipe(
        catchError((err, src) => {
          this.setError(err);
          return src;
        })
      )
      .subscribe(res => this.setError(res));
  }

  ngOnInit() {
    if (!this.updatedUser.companyDetails) {
      this.updatedUser.companyDetails = {
        developerName: null,
        name: null,
      }
    }
    this.rllDevName = this.updatedUser.companyDetails.developerName;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  updateUser() {
    if (!(this.publicEmailInput.errors || this.rllDeveloperNameInput.errors)) {
      this.update.emit();
    }
  }

  propagateChange = (_: any) => {};
  writeValue(obj: any): void {
    this.updatedUser = obj;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  // The code below is moved as is from user-details.component.ts together with rllDeveloperNameInput
  // Keeping it intact without refactoring due to lack of time now.
  setError(res: any) {
    if (!!res) {
      if (res instanceof HttpErrorResponse) {
        if ('status' in res) {
          if (res.status === 400) {
            if ('error' in res) {
              if ('message' in res.error) {
                let errText;
                if (res.error.message === 'INVALID_DEVELOPERNAME') {
                  errText = `The Developer Name already exists please try another name`;
                  this.rllDeveloperNameInput.control.setErrors({
                    developerNameExists: errText
                  });
                  this.errorRLLDev = errText;
                }
                if (res.error.message === 'Missing parameter') {
                  errText = 'Required';
                  this.rllDeveloperNameInput.control.setErrors({ required: errText });
                  this.errorRLLDev = errText;
                }
              }
            }
          }
        }
      } else if ('status' in res) {
        let errText;
        if (res.status === 'INVALID') {
          errText = `The Developer Name already exists please try another name`;
          this.rllDeveloperNameInput.control.setErrors({
            developerNameExists: errText
          });
          this.errorRLLDev = errText;
        } else {
          errText = null;
          this.rllDeveloperNameInput.control.setErrors(null);
          this.updatedUser.companyDetails.developerName = this.rllDeveloperNameInput.value;
        }
      }
    }
  }

}
