import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterService } from '../../../../services/register.service';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  termsRead = false;
  existingUser = false;
  user: any = {};
  termsText: string;

  constructor(private registerService: RegisterService, private userService: UserService, private router: Router) {
    if (userService.getUser()) {
      Object.assign(this.user, userService.getUser());
      this.existingUser = true;
    } else {
      this.user = this.registerService.user;
    }
  }

  ngOnInit() {
    if (!(this.user.emailAddress && this.userService.getToken())) {
      this.router.navigate(['/login']);
    } else {
      if (this.registerService.registerStepSource.value !== 4) {
        setTimeout(()=>this.registerService.changeStep(4));
      }
      this.registerService.getAgreement('terms').subscribe(text => (this.termsText = text));
    }
  }

  onScroll(event) {
    if (event) {
      const element = event.target;
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
        this.termsRead = true;
      }
    }
  }

  agreeToTerms() {
    if (this.termsRead) {
      this.registerService.setUserAgreedToTermsAndConditions(this.user).subscribe(
        res => {
          if (this.existingUser) {
            this.user.userAcceptedTerms = true;
            this.userService.setUser(this.user);
            if (!this.user.userAcceptedEula) {
              this.registerService.changeStep(5);
            } else {
              this.router.navigate(['/main/dashboard']);
            }
          } else {
            this.registerService.changeStep(5);
          }
        },
        err => console.log(err)
      );
    }
  }

  cancel() {
    this.registerService.cancelRegistration();
  }
}
