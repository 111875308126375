import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStatus } from '@enums/app-status.enum';
import { App } from 'app/models/app';
import { AppService } from 'app/services/app.service';

export interface IAutoUpdateChange {
  autoUpdate: boolean;
  appId: string;
}

@Component({
  selector: 'app-app-main-tile',
  templateUrl: './app-main-tile.component.html',
  styleUrls: ['./app-main-tile.component.scss']
})
export class AppMainTileComponent implements OnInit {
  // app will need to use app model eventually.
  @Input()
  app: App;

  @Input()
  isAdmin: boolean;

  @Output() rowClick = new EventEmitter<App>();
  @Output() titleClick = new EventEmitter<App>();
  @Output() updateAutoUpdate = new EventEmitter<IAutoUpdateChange>();

  showSpinner: boolean;
  hasUpdateError: boolean;
  updateErrorMessage: string;

  constructor(private appService: AppService) {}

  ngOnInit() { }

  getStatusMap(val) {
    return this.appService.getStatusMap(val);
  }

  columnClick(ev: MouseEvent, colTitle?: string) {
    if (colTitle === "title") {
      this.titleClick.emit(this.app);
    } else {
      this.rowClick.emit(this.app);
    }
  }

  allowAutoUpdateEdit() {
    return !(
      this.app.appStatus === AppStatus.awaiting_approval
      || this.app.appStatus === AppStatus.live
      || this.app.appStatus === AppStatus.not_submitted
    );
  }
  autoUpdateChange(val) {
    this.showSpinner = true;
    this.appService.saveAppAutoUpdate(this.app.id, val).subscribe(
      (res: any) => {
        this.hasUpdateError = false;
        this.showSpinner = false;
        this.app.forceUpdate = res.state;
        this.updateAutoUpdate.emit({
          autoUpdate: val,
          appId: this.app.id,
        });
      },
      (err) => {
        this.showSpinner = false;
        this.hasUpdateError = true;
        this.app.forceUpdate = !val;
        if (err && err.error) {
          this.updateErrorMessage = "";
          if (err.error.code) {
            this.updateErrorMessage = err.error.code + ": "
          }
          if (err.error.message) {
            this.updateErrorMessage += err.error.message;
          }
        }
        if (this.updateErrorMessage === "") {
          this.updateErrorMessage = err.text ? err.text():"Saving the autoUpdate flag failed.";
        }
      }
    );
  }
}
