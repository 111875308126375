import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterService } from '../../../../services/register.service';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-distribution-agreement',
  templateUrl: './distribution-agreement.component.html',
  styleUrls: ['./distribution-agreement.component.scss']
})
export class DistributionAgreementComponent implements OnInit {
  termsRead = false;
  existingUser = false;
  user: any = {};
  distText: string;

  constructor(private registerService: RegisterService, private userService: UserService, private router: Router) {
    if (userService.getUser()) {
      Object.assign(this.user, userService.getUser());
      this.existingUser = true;
    } else {
      this.user = this.registerService.user;
    }
  }

  ngOnInit() {
    if (this.registerService.registerStepSource.value !== 6) {
      setTimeout(()=>this.registerService.changeStep(6));
    }
  }

  onScroll(event) {
    if (event) {
      const element = event.target;
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
        this.termsRead = true;
      }
    }
  }

  agreeToDistributionAgreement() {
    this.setToDistributionAgreement(true);
  }

  skipDistributionAgreement() {
    this.setToDistributionAgreement(false);
  }

  setToDistributionAgreement(agreed) {
    if (!agreed || this.termsRead) {
      this.registerService.setUserAgreedToDistributionAgreement(this.user, agreed).subscribe(
        res => {
          this.user.userAcceptedDistribution = agreed;
          this.userService.setUser(this.user);
          this.router.navigate(['/main/dashboard']);
        },
        err => console.log(err)
      );
    }
  }
}
