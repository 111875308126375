﻿import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'tab',
  styleUrls: ['tab.component.scss'],
  template: `
    <div [hidden]="!active" class="pane"><ng-content></ng-content></div>
  `
})
export class Tab {
  @Input('tabTitle')
  title: string;
  @Input('tabLink')
  link: string;
  @Input()
  private _active = false;
  get active() {
    return this._active;
  }
  set active(active: boolean) {
    this._active = active;
    this.cdr.detectChanges();
  }

  constructor(protected cdr: ChangeDetectorRef) {}
}
