import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { LeiaNotification } from '../models/leia-notification';
@Injectable()
export class NotificationsService {
  constructor(private http: HttpClient, @Inject(leiaEndpointToken) private leiaEndpoints) {}

  getAllNotifications(): Observable<LeiaNotification[]> {
    return this.http.get<LeiaNotification[]>(
      environment.leiaservices.url + this.leiaEndpoints.notifications.get.url
    );
  }
  deleteNotification(notificationId: number) {
    return this.http.delete(
      environment.leiaservices.url + this.leiaEndpoints.notifications.delete_notification.url + notificationId
    );
  }
  create(notification: LeiaNotification) {
    const url = environment.leiaservices.url + this.leiaEndpoints.notifications.create.url;
    return this.http.post(url, notification);
  }
}
