import { Component } from '@angular/core';
import { SubMenu } from '../../../models/sub-menu';

@Component({
  selector: 'tools-help',
  templateUrl: './tools-help.component.html',
  styleUrls: ['./tools-help.component.scss']
})
export class ToolsHelpComponent {
  menu: SubMenu[] = [
    {
      title: 'Tools & Documentation',
      router: 'documentation'
    },
    {
      title: 'Frequently Asked Questions',
      router: 'faq'
    },
    // {
    //   title: 'Forum',
    //   router: 'forum'
    // },
    {
      title: 'Contact Us',
      router: 'contact-us'
    }
  ];

  constructor() {}
}
