import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Tool } from '../../../models/tool';
import { ToolsService } from '../../../services/tools.service';

@Component({
  selector: 'app-right-menu',
  templateUrl: './right-menu.component.html',
  styleUrls: ['./right-menu.component.scss'],
  providers: [ToolsService]
})
export class RightMenuComponent implements OnInit {
  tools: Tool[];
  constructor(private toolsService: ToolsService) {}

  ngOnInit() {
    this.getDocuments();
  }
  getDocuments() {
    this.toolsService
      .getAll()
      .pipe(map(tools => tools.filter(tool => !!tool.documentUid)))
      .subscribe(
        res => {
          this.tools = res;
        },
        err => {
          console.log('error');
          console.log(err);
        }
      );
  }
  getDocumentation(uid: string) {
    return this.tools.filter(tool => tool.documentUid === uid)[0];
  }
}
