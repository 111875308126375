import { Component, Input, OnInit } from '@angular/core';
import { WorldMapData } from '../../../models/world-map-data.model';

@Component({
  selector: 'app-legend-item',
  templateUrl: './legend-item.component.html',
  styleUrls: ['./legend-item.component.scss']
})
export class LegendItemComponent implements OnInit {
  private _worldMapData;
  get worldMapData() {
    return this._worldMapData;
  }
  @Input() set worldMapData(value: WorldMapData[]) {
    if (!!value) {
      this._worldMapData = value.sort((a, b) => b.value - a.value);
    }
  }

  constructor() {}

  ngOnInit() {}
}
