import { Component, Inject, OnInit } from '@angular/core';
import { leiaGlobal } from 'endpoints';
import { SubmitData } from '../../../models/submit-data';
import { SubmitAppService } from '../../../services/submit-app.service';

@Component({
  selector: 'app-app-submission',
  templateUrl: './app-submission.component.html',
  styleUrls: ['./app-submission.component.scss'],
  providers: [SubmitAppService]
})
export class AppSubmissionComponent implements OnInit {
  filename = '';
  file: any;
  fileNotValid = true;
  submitData: SubmitData = new SubmitData();
  submitComplete = false;

  constructor(
    private submitAppService: SubmitAppService,
    @Inject(leiaGlobal) private leiaGlobalConfig
  ) {}

  ngOnInit() {}
  uploadFile($event): void {
    this.file = $event.target.files[0];
    const filesize = this.file.size / 1024 / 1024;
    if (/\.(apk)$/i.test(this.file.name) === false) {
      alert('The file must be an APK');
    } else if (filesize > this.leiaGlobalConfig.apkMaxSize) {
      alert(`The file upload limit is ${this.leiaGlobalConfig.apkMaxSize}MB`);
    } else {
      this.fileNotValid = false;
      this.filename = $event.target.files[0].name;
    }
  }

  onSubmit(): void {
    console.log('Form submitted...');
    // Convert filename
    this.submitData.url = this.filename;
    this.submitData.file = this.file;
    this.submitComplete = true;
    this.submitAppService.submitApp(this.submitData);
  }

  autoResize(e): void {
    const element =
      typeof e === 'object' ? e.target : document.getElementById(e);
    const scrollHeight = element.scrollHeight + 1;
    element.style.height = scrollHeight + 'px';
  }
}
