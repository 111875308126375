import { Component, Input, OnInit } from '@angular/core';
import { Payment } from 'app/models/payment';
import { saveAs } from 'file-saver';
import { PaymentService } from '../../../services/payment.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.scss']
})
export class ReportButtonComponent implements OnInit {
  @Input() item: Payment;
  @Input() filename: string;
  @Input() userId: string;
  @Input() type: string;
  @Input() page: number;
  @Input() perPage: number;
  @Input() productId: string;
  @Input() transferKey: string;

  constructor(
    private svcUser: UserService,
    private svcPayment: PaymentService
  ) {}

  ngOnInit() {}

  public getReport(item) {
    const admin = this.svcUser.isAdmin();
    const detail = this.type === 'details';

    this.svcPayment
      .downloadCSVData(
        this.userId,
        this.type,
        this.page,
        this.perPage,
        detail ? item.productId : undefined,
        detail ? item.transferKey : undefined,
        admin
      )
      .subscribe(blob => this.saveBlob(blob as Blob));
  }

  private saveBlob(blob: Blob) {
    saveAs(blob, `${this.filename}-${Date.now()}.csv`);
  }
}
