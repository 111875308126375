import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publishing',
  templateUrl: './publishing.component.html',
  styleUrls: ['./publishing.component.scss']
})
export class PublishingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
