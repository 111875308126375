import { regxPatterns } from "@apps/components/dynamic-form/dynamic-form-validator.regexp";
import { IDynForm } from "@apps/components/dynamic-form/dynamic-form.component";
import { IDynFormFieldType } from "@apps/components/dynamic-form/dynamic-form.service";
import {
    EPubDetRelationTypes,
    pdfGetAddress,
    pdfGetBasicOwner,
    pdfGetCompany,
    pdfGetIndividual,
    pdfGetSSN,
    pdfGetVerificationDoc,
    pdfGetAddVerificationDoc,
    pdfIban,
    pdfRoutingAccount,
    pdfTOS,
    setRequired,
} from "./pub-details-form.definition";

const gbPCCaption = "pubDetails.postCode";

export const DynFormDef_gb_individual: IDynForm = {
    id: "gb_individual",
    name: "Publishing Details",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.indivInfo",
            description: "pubDetails.sections.indivInfoDesc",
            fields: [...pdfGetIndividual(undefined, undefined, gbPCCaption, 0, regxPatterns.alphanumericWS, true).filter(i => !i.id.includes("state"))],
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: [...pdfIban],
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: [...pdfTOS("compRepFullName_EU")],
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescEU",
            singleColumn: true,
            fields: [],
        },
        {
            id: "addInfoPhotoID",
            subTitle: "pubDetails.sections.addInfoPhotoID",
            subDesc: "pubDetails.sections.compOwnerPhotoIDDesc",
            fields: [...pdfGetVerificationDoc("individual", true, true)],
        },
        {
            id: "addInfoAddDoc",
            subTitle: "Additional Documentation",
            subDesc: "A document showing address, either a passport, local ID card, or utility bill from a well-known utility company.",
            fields: [...pdfGetAddVerificationDoc("individual", true)]
        }
    ],
};

// ***************************************************************************************
// ************************************* Company *****************************************
// ***************************************************************************************


export const DynFormDef_gb_company: IDynForm = {
    id: "gb_company",
    name: "pubDetails.title",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.compInfo",
            description: "pubDetails.sections.compInfoDesc",
            fields: pdfGetCompany(undefined, undefined, gbPCCaption, 0, regxPatterns.alphanumericWS, true).filter(i => !i.id.includes("state")),
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: [...pdfIban],
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: pdfTOS(),
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescEU",
            singleColumn: true,
            fields: [],
        },
        {
            id: "addInfoComprep",
            subTitle: "pubDetails.sections.companyOwner",
            subDesc: "pubDetails.sections.companyOwnerDesc",
            fields: [
              ...pdfGetBasicOwner(EPubDetRelationTypes.owner, true), 
              ...pdfGetAddress("owners#" + EPubDetRelationTypes.owner, undefined, undefined, gbPCCaption, 0, regxPatterns.alphanumericWS, true).filter(i => !i.id.includes('state'))],
        },
        {
            id: "addInfoPhotoID",
            subTitle: "pubDetails.sections.compOwnerPhotoID",
            subDesc: "pubDetails.sections.compOwnerPhotoIDDesc",
            fields: [...pdfGetVerificationDoc("owners#" + EPubDetRelationTypes.owner, true, true)]
        },
        {
            id: "addInfoAddDoc",
            subTitle: "pubDetails.sections.compOwnerAddDoc",
            subDesc: "pubDetails.sections.compOwnerAddDocDesc",
            fields: [...pdfGetAddVerificationDoc("owners#" + EPubDetRelationTypes.owner, true)]
        },
    ]
}