import { IDynForm } from "@apps/components/dynamic-form/dynamic-form.component";
import { IDynFormField, IDynFormFieldType, } from "@apps/components/dynamic-form/dynamic-form.service";
import { stateAU } from "@models/country-code.model";
import {
    EPubDetRelationTypes,
    pdfGetBasicOwner,
    pdfGetCompany,
    pdfGetIndividual,
    pdfGetSSN,
    pdfGetVerificationDoc,
    pdfGetAddVerificationDoc,
    pdfRoutingAccount,
    pdfTOS,
    pdfGetPhone,
    pdfGetAddress,
} from "./pub-details-form.definition";

export const pdfAUBank: IDynFormField[] = [
    {
        id: "external_account#bsb",
        name: "pubDetails.BSB",
        required: true,
        schema: {
            type: IDynFormFieldType.string,
        }
    },
    {
        id: "external_account#account_number",
        name: "pubDetails.external_account_account_number",
        required: true,
        schema: {
            type: IDynFormFieldType.string,
        }
    }
];

export const DynFormDef_au_individual: IDynForm = {
    id: "au_individual",
    name: "Publishing Details",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.indivInfo",
            description: "pubDetails.sections.indivInfoDesc",
            fields: [...pdfGetIndividual("pubDetails.stateAU", stateAU, "pubDetails.postCode", 4, null, true)],
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: pdfAUBank,
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: [...pdfTOS()],
        },
        {
            id: "addInfo",
            name: "pubDetails.sections.addVerification",
            description: "pubDetails.sections.addVerificationDescAU",
            singleColumn: true,
            fields: [],
        },
        {
            id: "addInfoPhotoID",
            subTitle: "Photo ID",
            subDesc: "State-issued ID or Government-issued passport belonging to Company Representative",
            fields: [...pdfGetVerificationDoc("individual", true, true)],
        },
        {
            id: "addInfoAddrDoc",
            subTitle: "Additional Documentation",
            subDesc: "A document showing address, either a passport, local ID card, or utility bill from a well-known utility company.",
            fields: [...pdfGetAddVerificationDoc("individual", true)]
        }
    ],
};

// ***************************************************************************************
// ************************************* Company *****************************************
// ***************************************************************************************


export const DynFormDef_au_company: IDynForm = {
    id: "au_company",
    name: "pubDetails.title",
    sections: [
        {
            id: "compInfo",
            name: "pubDetails.sections.compInfo",
            description: "pubDetails.sections.compInfoDesc",
            fields: [...pdfGetCompany("pubDetails.stateAU", stateAU, "pubDetails.postCode", 4, null, true)],
        },
        {
            id: "bankInfo",
            name: "pubDetails.sections.bankInfo",
            description: "pubDetails.sections.bankInfoDesc",
            fields: pdfAUBank,
        },
        {
            id: "paymentToS",
            name: "pubDetails.sections.paymentToS",
            description: "pubDetails.sections.paymentToSDesc",
            fields: pdfTOS(),
        },
                {
            id: "companyOwner",
            subTitle: "pubDetails.sections.companyOwner",
            subDesc: "pubDetails.sections.companyOwnerDesc",
            fields: [
              ...pdfGetBasicOwner(EPubDetRelationTypes.owner, true), 
              ...pdfGetAddress("owners#" + EPubDetRelationTypes.owner, "pubDetails.stateAU", stateAU, "pubDetails.postCode", 4, null, true)],
        },
        {
            id: "compOwnerPhotoID",
            subTitle: "pubDetails.sections.compOwnerPhotoID",
            subDesc: "pubDetails.sections.compOwnerPhotoIDDesc",
            fields: [...pdfGetVerificationDoc("owners#" + EPubDetRelationTypes.owner, true, true)]
        },
        {
            id: "compOwnerAddDoc",
            subTitle: "pubDetails.sections.compOwnerAddDoc",
            subDesc: "pubDetails.sections.compOwnerAddDocDesc",
            fields: [...pdfGetAddVerificationDoc("owners#" + EPubDetRelationTypes.owner, true)]
        }
    ]
}