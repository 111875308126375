import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppStatus } from '@enums/app-status.enum';
import { App, AppVersion } from '@models/app';
import { AppData } from '@models/app-data';
import { byAlpha2 } from 'iso-country-codes';
import { isArray } from 'lodash';
import * as moment from 'moment';
import {
  AdminDashboardPageData,
  AdminDashboardServerResponse
} from '../models/admin-dashboard-page-data.model';
import { LineChartDataElement } from '../models/line-chart-data-element.model';
import { MenuChoice, MenuChoices, MomentRange } from '../models/moment-range';
import { UserStatus } from '../models/user';
import { WorldMapData } from '../models/world-map-data.model';
import { AppService } from '../services/app.service';
import { DashboardService } from '../services/dashboard.service';
import { LoadMoreItems } from './components/summary/summary.component';

@Component({
  selector: 'app-admin-dashboard-page',
  templateUrl: './admin-dashboard.page.component.html',
  styleUrls: ['./admin-dashboard.page.component.scss']
})
export class AdminDashboardPageComponent {
  now = moment(new Date());
  rangeToday = MenuChoices.find(val => val.label === MenuChoice.Lifetime);
  values: AdminDashboardPageData = {
    app: null,
    version: null,
    dashboardData: {
      appsAwaitingApproval: 0,
      usersAwaitingApproval: 0,
      totalDownloadRevenue: [],
      totalIAPRevenue: [],
      dwlByUserData: [],
      summaryItems$: this.svcDashboard.getSummaryItemData(5, 1),
      worldMapData: []
    }
  };
  apps: App[] = null;

  constructor(
    public svcDashboard: DashboardService,
    private svcApp: AppService,
    private router: Router
  ) {
    this.populateData(this.rangeToday);
    this.svcApp
      .getAdminApps()
      .subscribe(apps => this.apps = apps);

  }

  populateData(range: MomentRange) {
    const from = range.from.clone();
    const to = range.to.clone();
    const appId = this.values.app ? this.values.app.id : null;
    const versionId = this.values.version ? this.values.version.id : null;
    const lifetime = range.label === MenuChoice.Lifetime;
    const admin = true;

    if (lifetime) {
      this.svcDashboard
        .getAllDashboardData(from, to, appId, versionId, lifetime, admin)
        .subscribe(data =>
          this.setDataValues(data as AdminDashboardServerResponse)
        );
    } else {
      this.svcDashboard
        .getAllDashboardData(from, to, appId, versionId, lifetime, admin)
        .subscribe(data =>
          this.setDataValues(data as AdminDashboardServerResponse)
        );
    }
  }

  setDataValues(data: AdminDashboardServerResponse) {
    this.values.dashboardData.usersAwaitingApproval = data.userAwaitingApproval;
    this.values.dashboardData.appsAwaitingApproval = data.appAwaitingApproval;
    this.values.dashboardData.dwlByUserData = this.svcDashboard.mapServerResponse(
      'Downloads',
      data.downloads
    );
    this.values.dashboardData.totalDownloadRevenue = [
      {
        name: 'Developer Split',
        value: data.totalDownloadRevenue.developerSplit
      },
      {
        name: 'Leia Split',
        value: data.totalDownloadRevenue.leiaSplit
      }
    ];
    if (
      !data.totalDownloadRevenue.developerSplit &&
      !data.totalDownloadRevenue.leiaSplit
    ) {
      this.values.dashboardData.totalDownloadRevenue = [];
    }
    this.values.dashboardData.totalIAPRevenue = [
      {
        name: 'Developer Split',
        value: data.totalIAPRevenue.developerSplit
      },
      {
        name: 'Leia Split',
        value: data.totalIAPRevenue.leiaSplit
      }
    ];
    if (
      !data.totalIAPRevenue.developerSplit &&
      !data.totalIAPRevenue.leiaSplit
    ) {
      this.values.dashboardData.totalIAPRevenue = [];
    }

    this.values.dashboardData.worldMapData = data.downloadsByCountry.map<WorldMapData>(
      item => {
        return {
          code: byAlpha2[item.countryCode],
          value: item.value
        };
      }
    );
  }

  handleAppsSelected(apps: AppData) {
    if (!isArray(apps.app)) {
      this.values.app = apps.app;
      this.values.version = apps.version;
    }
  }

  onDownloadsByUser(data: LineChartDataElement[]) {
    return this.svcDashboard.sum(data);
  }

  onLoadMoreSummaryItems(event: LoadMoreItems) {
    this.values.dashboardData.summaryItems$ = this.svcDashboard.getSummaryItemData(
      event.itemsPerRow,
      event.row
    );
  }

  showAwaitingApps() {
    this.router.navigate(['/main/admin-apps'], { queryParams: { filter: AppStatus.awaiting_approval } });
  }

  showAwaitingUsers() {
    this.router.navigate(['/main/users'], { queryParams: { filter: UserStatus.NOT_APPROVED } });
  }

}
