import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, NgModel, ValidationErrors } from '@angular/forms';
import * as uuid from 'uuid/v4';

@Component({
  selector: 'app-material-input',
  templateUrl: './material-input.component.html',
  styleUrls: ['./material-input.component.scss']
})
export class MaterialInputComponent implements ControlValueAccessor {
  @Input()
  set errors(errors: ValidationErrors) {
    this._errors = errors;
    if (!!errors && !!errors.message && !this.errorText.includes(errors.message)) {
      const err = errors.message + this.errorText;
      this.errorText = err;
    }
  }
  get errors() {
    return this._errors;
  }

  uuids = new Map<number, uuid>();
  private _id = 0;
  private _value: any;
  private _errors: ValidationErrors;
  isHovered = false;

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.notifyValueChange(value);
  }

  get id() {
    if (!this.uuids[this._id]) {
      this.uuids[this._id] = uuid();
    }

    return this.uuids[this._id];
  }

  @ViewChild(NgModel)
  input: NgModel;
  @Output()
  change = new EventEmitter<any>();
  @Output()
  keyup = new EventEmitter<KeyboardEvent>();
  @Input()
  name;
  @Input()
  hasInfo = false;
  @Input()
  infoText: string;
  @Input()
  label: string;
  @Input()
  type: string;
  @Input()
  isWhite = false;
  @Input()
  dirty = false;
  @Input()
  errorText = 'An error occurred!';
  @Input()
  isDisabled = false;
  @Input()
  isHidden = false;
  @Input()
  mimeType;
  @Input()
  maxLength = 100;
  @Input()
  isChanged = false;

  onChange: (value) => {};
  onTouched: () => {};

  constructor(@Optional() @Self() public ngControl: NgControl, private cdr: ChangeDetectorRef) {
    this.type = 'text';
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  markAsPristine() {
    this.input.control.reset();
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    if (value === null) {
      this.input.control.markAsPristine();
    }

    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
