import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IRGBColor {
    r: number;
    g: number;
    b: number;
}

@Component({
  selector: 'app-checkbox-spinner',
  templateUrl: './checkbox-spinner.component.html',
  styleUrls: ['./checkbox-spinner.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxSpinnerComponent),
    multi: true
  }]
})
export class CheckboxSpinnerComponent implements AfterViewInit, ControlValueAccessor {

  isChecked: boolean;
  isDisabled: boolean;
  spinnerSize = 25;
  showSpinner: boolean;

  @Input()
  set disabled(val: boolean) {
      this.isDisabled = val;
      this.setCssVars();
  }
  get disabled(): boolean {
      return this.isDisabled;
  }
  @Input()
  set spinnerVisible(val: boolean) {
    this.showSpinner = val;
    this.isDisabled = this.showSpinner;
  }
  get spinnerVisible(): boolean {
      return this.showSpinner;
  }
  @Input()
  set checked(val: boolean) {
      this.isChecked = val;
      this.setCssVars();
  }
  get checked(): boolean {
      return this.isChecked;
  }
  @Input()
  checkColor: string;
  @Input()
  checkSize: string;
  @Input()
  labelText: string;
  @Input()
  spinnerSizePixel: number;
  @Input()
  hoverColor: string;
  @Input() checkMark = false;

  @Output()
  click = new EventEmitter<MouseEvent>();
  @Output()
  valueChange = new EventEmitter<boolean>()

  @ViewChild('checkboxElement') el: ElementRef;


  private onTouched;
  private onChange;

  private checkColorDefault = "#444444";
  private checkSizeDefault = "25px";
  private checkHoverColorDefault = "#aaaaaa";

  private _checkColor = this.checkColorDefault;
  private _checkSize = this.checkSizeDefault;
  private _checkHover = this.checkHoverColorDefault;

//   constructor() {
//     this.onChange = (val:any) => {
//         this.isChecked = val;
//     };
//   }

  handleClick(ev: MouseEvent) {
    this.click.emit(ev);
  }

  toggle() {
    if (this.isDisabled) {
      return;
    }
    this.isChecked = !this.isChecked;
    this.setCssVars();
    if (this.onChange) {
        this.onChange(this.isChecked);
    }
    this.valueChange.emit(this.isChecked);
  }

  ngAfterViewInit() {
    if (this.disabled) {
        this.isDisabled = true;
    }
    this.setCssVars();
  }

  setCssVars() {
    let cssVars = "";
    if (this.checkColor) {
        this._checkColor = this.checkColor;
    }

    if (this.isDisabled) {
        this._checkHover = this.isChecked?this._checkColor:"transparent";
    } else if (this.hoverColor) {
        this._checkHover = this.isChecked?this._checkColor:this.hoverColor;
    } else {
        this._checkHover = this.checkHoverColorDefault;
        if (this._checkColor) {
            const rgb: IRGBColor = this.hexToRgb(this._checkColor);
            const alpha = this.isChecked?"0.8":"0.2";
            if (rgb) {
                this._checkHover = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ", " + alpha + ")";
            }
        }
    }

    if (this.checkSize) {
        this._checkSize = this.checkSize;
    }
    cssVars += "--check-color: " + this._checkColor + ";";
    cssVars += "--check-size: " + this._checkSize + ";";
    cssVars += "--check-hover-color: " + this._checkHover + ";";

    if (!this.spinnerSizePixel && this._checkSize.endsWith("px")) {
        const spinSize = Number(this._checkSize.replace("px", ""));
        if (!isNaN(spinSize)) {
            this.spinnerSize = spinSize;
        }
    }
    if (this.spinnerSizePixel) {
        this.spinnerSize = this.spinnerSizePixel;
    }
    this.el.nativeElement.style.cssText = cssVars;
  }

  writeValue(val: any): void {
      this.isChecked = val;
      this.setCssVars();
  }

  registerOnChange(fn: (any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState ? (isDisabled: boolean) : void {
      this.isDisabled = isDisabled;
      this.setCssVars();
  }

  hexToRgb(hex):IRGBColor {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}
}
