import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroupDirective, NgModel, } from '@angular/forms';
import { ActivatedRoute, Router, } from '@angular/router';
import { UserService } from '@services/user.service';
import { UtilService } from '@services/util.service';
import { Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { User } from '../../../../models/user';
import { RegisterService, RegStatus, RegSteps, } from '../../../../services/register.service';
import { countriesFull } from '@models/country-code.model';
import { regxPatterns } from '@apps/components/dynamic-form/dynamic-form-validator.regexp';

@Component({
  selector: 'app-your-details',
  templateUrl: './your-details.component.html',
  styleUrls: ['./your-details.component.scss']
})
export class YourDetailsComponent implements OnDestroy {
  error: string;
  user: User = null;
  destroy$ = new Subject();
  developerName$ = new Subject<string>();
  @Input()
  password: string;
  @Input()
  confirmPassword: string;
  @ViewChild('confirm')
  ngConfirmPassword: NgModel;
  @ViewChild('password')
  ngPassword: NgModel;
  @ViewChild('rllDeveloperName')
  rllDeveloperName: NgModel;
  @ViewChild('yourDetailsForm') yourDetailsForm: FormGroupDirective;
  countries = countriesFull;
  emailRegX = regxPatterns.email;

  errorText = `Required. Minimum six characters,
   at least one uppercase letter, one lowercase letter,
   one number and one special character
   (!"#$%&'()*+,-./:;<=>?@[\]^_\`{|}~).
   No white space permitted`;

  constructor(
    private registerService: RegisterService,
    private userService: UserService,
    private utilService: UtilService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.user = this.userService.user || this.registerService.user;
    if (!this.user.emailAddress) {
      this.router.navigate(['/register']);
    } else {
      this.registerService.getRegStatus(this.user.emailAddress).subscribe((res => {
        if ((res.status === RegStatus.CREATED || res.status === RegStatus.COMPLETED) && !this.userService.getToken()) {
          this.router.navigate(['/login'], { queryParams: { useEmail: this.user.emailAddress }});
        } else {
          let regStep = RegSteps[2];
          if (this.route.snapshot.queryParams && this.route.snapshot.queryParams.back) {
            regStep = RegSteps[3];
          }
          this.registerService.checkRegStep(res.status, regStep, this.user.emailAddress);
        }
      }));
    }

    this.developerName$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(name => this.registerService.isUnique(name))
      )
      .pipe(
        catchError((err, src) => {
          this.setError(err);
          return src;
        })
      )
      .subscribe(res => this.setError(res));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  touchForm() {
    this.utilService.recurseFormControls(this.yourDetailsForm.form, (ctrl) => {
      ctrl.markAsTouched();
      ctrl.markAsDirty();
    });
  }

  // Should really be in an adapter, and the if's should be squashed together
  setError(res: any) {
    if (!!res) {
      if (res instanceof HttpErrorResponse) {
        if ('status' in res) {
          if (res.status === 400) {
            if ('error' in res) {
              if ('message' in res.error) {
                let errText;
                if (res.error.message === 'INVALID_DEVELOPERNAME') {
                  errText = `The Developer Name already exists please try another name`;
                  this.rllDeveloperName.control.setErrors({
                    developerNameExists: errText
                  });
                  this.error = errText;
                }
                if (res.error.message === 'Missing parameter') {
                  errText = 'Required';
                  this.rllDeveloperName.control.setErrors({ required: errText });
                  this.error = errText;
                }
              }
            }
          }
        }
      } else if ('status' in res) {
        let errText;
        if (res.status === 'INVALID') {
          errText = `The Developer Name already exists please try another name`;
          this.rllDeveloperName.control.setErrors({
            developerNameExists: errText
          });
          this.error = errText;
        } else {
          errText = null;
          this.rllDeveloperName.control.setErrors(null);
        }
      }
    }
  }

  completeUserDetails() {
    if (!this.yourDetailsForm.valid) {
      this.touchForm();
      return;
    }
    if (this.user.password === this.confirmPassword) {
      this.registerService.setUserDetails(this.user).subscribe(
        (res: any) => {
          this.user.companyDetails = {
            developerName: res.developerName,
            name: undefined,
          };
          this.user.userId = res.userId;
          this.user.partnerId = res.partnerId;
          this.user.developerName = res.developerName;
          this.user.firstName = res.firstName;
          this.user.lastName = res.lastName;
          this.user.publicEmail = res.publicEmail;
          this.user.marketingOptIn = res.marketingOptIn;
          if (res.token) {
            this.user.token = res.token;
          }
          this.userService.user = this.user;
          this.registerService.user = this.user;

          this.registerService.changeStep(3);
        },
        msg => {
          console.log('Error:');
          console.log(msg);
        }
      );
    }
  }
}
