import { Component, Input, Inject, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormModal } from 'app/classes/form-modal';
import { MessageDialogServiceToken, MessageDialogServiceImpl, dialogButtons, IDialogAction } from '@common/components/confimation-modal/dialog.service';
import { Observable, of, throwError } from 'rxjs';
import { take, map, catchError, tap } from 'rxjs/operators';
import { filter } from 'topojson';
import { NgForm } from '@angular/forms';
import { PaymentService } from '@services/payment.service';
import { MatDialogRef } from '@angular/material';
import { PaymentAdminDetail, PaymentAdmin } from '@models/payment-admin';
import { PaymentDeveloper } from '@models/payment-developer';

@Component({
  selector: 'app-pay-now-modal',
  templateUrl: './pay-now-modal.component.html',
  styleUrls: ['./pay-now-modal.component.scss']
})
export class PayNowModalComponent {
  @Input()
  set payment(val) {
    if (val) {
      this.dataModel.total = val.totalPayment;
    }
  }
  @Input() validationError: any;

  @ViewChild('modalTemplate') modalTemplate: ElementRef;
  @ViewChild('paynowForm') paynowForm: NgForm;

  isPasswordDirty = false;

  public dataModel = {
    total: null,
    password: null
  };
  errorText: string;
  confirmButton: IDialogAction = {
    caption: "Confirm",
    disabled: true,
    result: 1,
  }

  dialog: MatDialogRef<any, any>;

  constructor(
    public paymentSvc: PaymentService,
    @Inject(
      MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
    ) {
  }

  getDialog(): MatDialogRef<any, any> {
    return this.dialogService.openMessageDialog({
      title: "Pay Developer Now",
      bodyContentRef: this.modalTemplate,
      actions: [dialogButtons.cancel, this.confirmButton,],
    });
  }

  public openDialog(total: number): Observable<any> {
    this.confirmButton.disabled = true;
    this.dataModel.total = total;
    return this.getDialog().afterClosed().pipe(
      take(1),
      map(res => res > 0 ? this.dataModel.password : null),
    );
  }

  public startPayment(payment: PaymentAdminDetail | PaymentAdmin) {
    this.isPasswordDirty = true;
    this.dataModel.total = payment.totalPayment;
    this.dataModel.password = '';
    this.confirmButton.disabled = true;
    this.confirmButton.keepOpen = true;
    this.confirmButton.callBack = (dialog) => {
      this.confirmButton.disabled = true;
      const param: any = {
        transferGroup: payment.transferGroup,
        password: this.dataModel.password,
      };
      if (false && payment.transferKey) {
        param.transferKey = payment.transferKey;
      }
      this.paymentSvc.payNow(param).pipe(
        catchError((err) => {
          this.paynowForm.controls["password"].setErrors({ invalidCred: true });
          this.getErrorText(err);
          return of(null);
        }),
        tap(res => {
          if (res && res.status === "OK" ) {
            this.isPasswordDirty = false;
            dialog.close(res);
          }
          if (res && res.status === "ERROR") {
            this.paynowForm.controls["password"].setErrors({ invalidCred: true });
            this.getErrorText(res);
          }
        }),
      ).subscribe(
        ()=>{},
        ()=>{},
        ()=>this.confirmButton.disabled = false,
      );
    }
    this.dialog = this.getDialog();
    return this.dialog;
  }

  passwordKeyUp(ev: any) {
    if (ev && ev.keyCode === 13 && this.confirmButton.callBack && !this.confirmButton.disabled) {
      this.confirmButton.callBack(this.dialog);
    }
  }

  passwordChange(val: any) {
    this.isPasswordDirty = true;
    this.confirmButton.disabled = this.paynowForm.invalid;
    this.getErrorText(this.paynowForm.controls["password"].errors);
  }

  getErrorText(error?: any) {
    this.errorText = null;
    if (error && error.required) {
      this.errorText = "Password is required";
    }
    else if (error && error.invalidCred) {
      this.errorText = "Incorrect password."
    }
    else if (error && error.error && error.error.message === "INVALID_CREDENTIALS") {
      this.errorText = "Incorrect password."
    }
    else if (error) {
      this.errorText = "Error during payment";
    }
  }
}
