import { AfterViewInit, Component, Inject, OnInit, ViewChild, } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { MatRadioChange, MatSelectChange } from "@angular/material";
import { ActivatedRoute, ActivatedRouteSnapshot,  } from "@angular/router";
import { DynamicFormComponent, IDynForm, IDynFormModelChange, IDynFormSection, } from "@apps/components/dynamic-form/dynamic-form.component";
import { EDynFormFieldDateOption, IDynFormField, IDynFormFieldType, } from "@apps/components/dynamic-form/dynamic-form.service";
import { dialogButtons, MessageDialogService, MessageDialogServiceImpl, MessageDialogServiceToken, } from "@common/components/confimation-modal/dialog.service";
import { INavigationGuardComponent } from "@common/components/navigation-guard/navigation-guard.component";
import { VerificationStatus } from "@enums/verification-status.enum";
import { countriesFull, pubDetSupportedEUCountries, pubDetSupportedNonEUCountries } from "@models/country-code.model";
import { BankDetailService } from "@services/bank-detail.service";
import { UserService } from "@services/user.service";
import { UtilService } from "@services/util.service";
import * as _ from 'lodash';
import * as moment from 'moment';
import * as publicIP from 'public-ip';
import { forkJoin, from, Observable, of, } from "rxjs";
import { flatMap, map, tap, } from "rxjs/operators";
import { DynFormDef_au_company, DynFormDef_au_individual, } from "./pub-details-form.au.definition";
import { DynFormDef_ca_company, DynFormDef_ca_individual, } from "./pub-details-form.ca.definitions";
import { EPubDetRelationTypes } from "./pub-details-form.definition";
import { DynFormDef_eu_company, DynFormDef_eu_individual, } from "./pub-details-form.eu.definition";
import { DynFormDef_gb_company, DynFormDef_gb_individual, } from "./pub-details-form.gb.definition";
import { DynFormDef_us_company, DynFormDef_us_individual, } from "./pub-details-form.us.definitions";
import { DynFormDef_ie_company, DynFormDef_ie_individual, } from "./pub-details-form.ie.definitions";
import { EPubDetailAccountTypes, IPubDetailModel, PubDetailModelDefault, PubDetailOwnerDefault, } from "./pub-details.model";
import { TranslateService } from "@ngx-translate/core";


@Component({
    selector: 'app-pub-details',
    templateUrl: './pub-details.component.html',
    styleUrls: ['./pub-details.component.scss']
  })
export class PubDetailsComponent implements OnInit, AfterViewInit, INavigationGuardComponent {
    model: any = {};
    country = "US";
    userCountry = "US";
    countries = countriesFull;
    forms = {
        individual: {
            US: DynFormDef_us_individual,
            IE: DynFormDef_ie_individual,
            EU: DynFormDef_eu_individual,
            CA: DynFormDef_ca_individual,
            AU: DynFormDef_au_individual,
            GB: DynFormDef_gb_individual,
        },
        company: {
            US: DynFormDef_us_company,
            IE: DynFormDef_ie_company,
            EU: DynFormDef_eu_company,
            CA: DynFormDef_ca_company,
            AU: DynFormDef_au_company,
            GB: DynFormDef_gb_company,
        }
    }
    accountOptions = [
        { name: 'pubDetails.accountOptions.individual', value: EPubDetailAccountTypes.individual },
        { name: 'pubDetails.accountOptions.company', value: EPubDetailAccountTypes.company }
    ];
    accountType = EPubDetailAccountTypes.individual;
    readOnly = false;

    currentForm = DynFormDef_us_company;
    formWithValue: { form: IDynForm, value: any } = null;
    defaultValue: any = {};

    saveInProgress = false;

    stripeFinalized = false;
    unsupportedCountry = false;
    noStripeAccount = false;
    stripeStatus: VerificationStatus;
    providedFields: any;

    @ViewChild('dynFormCtrl') dynFormCtrl: DynamicFormComponent;

    constructor(
        private utilService: UtilService,
        private userService: UserService,
        private bankService: BankDetailService,
        private route: ActivatedRoute,
        private trans: TranslateService,
        @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
    ) {}

    ngOnInit() {
        const parr = this.route.snapshot.data.preload;
        // if (parr && parr.length && parr[0] && [VerificationStatus.COMPLETED, VerificationStatus.ENABLED].includes(parr[0].status)) {
        if (parr && parr.length && parr[0]) {
            if ([VerificationStatus.COMPLETED].includes(parr[0].status)) {
                this.stripeFinalized = true;
            }
            this.stripeStatus = parr[0].status;
        }
        if (parr && parr.length > 1) {
            let res: IPubDetailModel;
            if (parr[1]) {
                res = parr[1];
                this.providedFields = parr[1].provided_fields;
            } else {
                res = PubDetailModelDefault;
            }
            if (res && res.stripe_account && res.stripe_account.country) {
                this.userCountry = res.stripe_account.country;
            } else {
                this.userCountry = this.userService.user.country;
            }
            if (res.stripe_account) {
                this.accountType = res.stripe_account.business_type;
                // this.userCountry = res.stripe_account.country;
            } else {
                this.noStripeAccount = true;
                res.stripe_account = _.cloneDeep(PubDetailModelDefault.stripe_account);
            }
            if (!res.bank_account) {
                res.bank_account = _.cloneDeep(PubDetailModelDefault.bank_account);
            }
            this.currentForm = _.cloneDeep(this.forms[this.accountType][this.country]);
            this.countryChange(this.userCountry);
            this.addToSLink();
            const model = this.createModel(res);
            if (model && !model.company_representative) {
                model.company_representative = this.getUserFullName();
            }
            // this.checkProvidedFields(model, this.currentForm);
            this.setDefaultPhoneCountry(model, this.userCountry);
            this.model = model;
            this.formWithValue = { form: this.currentForm, value: model };
        } else {
            this.currentForm = this.forms[this.accountType][this.country];
            const model: any = { company_representative: this.getUserFullName() };
            this.setDefaultPhoneCountry(model, this.userCountry);
            this.addToSLink();
            this.model = model;
            this.formWithValue = { form: this.currentForm, value: model };
        }
    }

    checkProvidedFields(model: any, form: IDynForm) {
        if ([VerificationStatus.REJECTED, VerificationStatus.PENDING, VerificationStatus.RESTRICTED, VerificationStatus.ENABLED].includes(this.stripeStatus)) {
            if (model['company#name']) {
                const f = this.getField('company#name');
                if (f) {
                    f.readOnly = true;
                    this.dynFormCtrl.setDisabled(true, [f]);
                }
            }
            if (this.providedFields) {
                const fl = [];
                if (this.providedFields.tax_id) {
                    fl.push('company#tax_id');
                }
                if (this.providedFields.personal_id || this.providedFields.ssn_last_4) {
                    fl.push('individual#id_number');
                }
                if (this.providedFields.owner && (this.providedFields.owner.ssn_last_4 || this.providedFields.owner.personal_id)) {
                    fl.push('owners#owner#id_number');
                }
                if (this.providedFields.director) {
                    fl.push('owners#director#id_number');
                }
                if (this.providedFields.executive) {
                    fl.push('owners#executive#id_number');
                }
                if (this.providedFields.account_opener) {
                    fl.push('owners#account_opener#id_number');
                }
                // if (this.providedFields.account_number) {
                    fl.push('external_account#account_number');
                // }
                // if (this.providedFields.routing_number) {
                    fl.push('external_account#routing_number');
                // }
                // if (this.providedFields.iban) {
                    fl.push('external_account#iban');
                // }
                // if (this.providedFields.bsb) {
                    fl.push('external_account#bsb');
                // }

                if (fl.length) {
                    fl.forEach(f => this.updateProvidedField(f));
                }
            }
        }
    }

    updateProvidedField(id: string, infoText = "pubDetails.sensitiveFieldInfo") {
        const f = this.getField(id);
        if (f) {
            if (infoText) {
                f.infoText = infoText;
            }
            this.model[f.id] = null;
            f.required = false;
        }
    }

    findField(form: IDynForm, callback: (field: IDynFormField) => boolean) {
        if (form && form.sections && form.sections.length) {
            // tslint:disable-next-line:prefer-for-of
            for (let cs = 0; cs < form.sections.length; cs++) {
                const s = form.sections[cs];
                // tslint:disable-next-line:prefer-for-of
                for (let cf = 0; cf < s.fields.length; cf++) {
                    if (callback(s.fields[cf])) {
                        return s.fields[cf];
                    }
                }
            }
            return null;
        }
    }

    setDefaultPhoneCountry(model: any, defaultCountry: string) {
        if (model) {
            this.currentForm.sections.forEach(s => {
                if (s && s.fields && s.fields.length) {
                    s.fields.forEach(f => {
                        if (f.schema.type === IDynFormFieldType.phone && !model[f.id]) {
                            model[f.id] = { countryCode: defaultCountry };
                        }
                    });
                }
            });
        }
    }
    getUserFullName(): string {
        let res = '';
        if (this.userService.user.firstName) {
            res = this.userService.user.firstName;
        }
        if (this.userService.user.lastName) {
            if (res) {
                res += " " + this.userService.user.lastName;
            } else {
                res = this.userService.user.lastName;
            }
        }
        return res;
    }

    ngAfterViewInit() {
        if (this.stripeFinalized) {
            setTimeout(() => this.dynFormCtrl.setDisabled(true));
        }
        setTimeout(() => {
            this.checkProvidedFields(this.model, this.currentForm);
            this.formWithValue = { value: this.model, form: this.currentForm };
        });
    }

    modelChange(val: IDynFormModelChange) {
        this.model = val.model;
        const prefx = "owners#director#";
        if (val.field && val.field.id && val.field.id.includes(prefx)) {
            const allFields = ['first_name', 'last_name', 'dob', 'address#line1', 'address#line2', 'address#city', 'address#state', 'address#postal_code', 'phone', 'verification#document#front', 'verification#document#back'];
            const reqFields = ['first_name', 'last_name', 'dob', 'address#line1', 'address#city', 'address#state', 'address#postal_code',];
            const section: IDynFormSection = this.currentForm.sections.find(s => s.id === "compDirector");
            const sectionPID: IDynFormSection = this.currentForm.sections.find(s => s.id === "compDirectorPhotoID");
            const fieldList: IDynFormField[] = [];
            if (section && section.fields && section.fields.length) {
                fieldList.push(...section.fields);
            }
            if (sectionPID && sectionPID.fields && sectionPID.fields.length) {
                fieldList.push(...sectionPID.fields);
            }
            if (fieldList.length) {
                let hasVal = false;
                // tslint:disable-next-line:prefer-for-of
                for (let c = 0; c < allFields.length; c++) {
                    const fieldName = prefx + allFields[c];
                    const fld: IDynFormField = fieldList.find(f => f.id === fieldName);
                    if (fld) {
                        switch(fld.schema.type) {
                            case IDynFormFieldType.phone:
                                hasVal = val.model[fieldName] && val.model[fieldName].number && val.model[fieldName].number.length;
                                break;
                            default:
                                hasVal = !!val.model[fieldName];
                        }
                    }
                    if (hasVal) {
                        console.log("foundVal", prefx + allFields[c], val.model[prefx + allFields[c]]);
                        break;
                    }
                }
                if (section) {
                    reqFields.forEach(fieldName => {
                        const fld: IDynFormField = section.fields.find(f => f.id === prefx + fieldName);
                        if (fld) {
                            fld.required = hasVal;
                            if (!hasVal) {
                                const ctrl = this.dynFormCtrl.formGroup.get(prefx + fieldName);
                                if (ctrl) {
                                    ctrl.setErrors(null);
                                }
                            }
                        }
                    });
                }
            }
        }
    }

    getField(id: string): IDynFormField {
        const dynForm: IDynForm = this.forms[this.accountType][this.country];
        if (dynForm && dynForm.sections && dynForm.sections.length) {
            // tslint:disable-next-line:prefer-for-of
            for (let s = 0; s < dynForm.sections.length; s++) {
                if (dynForm.sections[s].fields && dynForm.sections[s].fields.length) {
                    // tslint:disable-next-line:prefer-for-of
                    for (let f = 0; f < dynForm.sections[s].fields.length; f++) {
                        if (id === dynForm.sections[s].fields[f].id) {
                            return dynForm.sections[s].fields[f];
                        }
                    }
                }
            }
        }
        return null;
    }

    valueToDB(dynField: IDynFormField, value?: any): any {
        let res: any;
        if (dynField) {
            const fieldName = dynField.id;
            if (!value) {
                value = this.model[fieldName];
            }
            switch(dynField.schema.type) {
                case IDynFormFieldType.datetime:
                    if (value && (value as moment.Moment).isValid) {
                        const m: moment.Moment = value;
                        switch (dynField.schema.dateType) {
                            case EDynFormFieldDateOption.unix:
                                res = m.unix();
                                break;
                            case EDynFormFieldDateOption.millisec:
                                res = m.valueOf();
                                break;
                            case EDynFormFieldDateOption.utc:
                                res = m.utc();
                                break;
                            default:
                                res = {
                                    day: m.date(),
                                    month: m.month() + 1,
                                    year: m.year(),
                                }
                        }
                    }
                    break;
                case IDynFormFieldType.upload:
                    if (value && value.files && value.files.length) {
                        res = this.utilService.readFile(this.model[fieldName].files[0]);
                    } else if (value && value.length) {
                        res = value;
                    }
                    break;
                case IDynFormFieldType.phone:
                    if (value) {
                        if (value.internationalNumber || value.number) {
                            res = value.number;
                        }
                        if (value && value.length) {
                            res = value;
                        }
                    }
                    break;
                default:
                    if (value === '') {
                        res = null;
                    } else {
                        res = value;
                    }
            }
        }
        return res;
    }

    isStripeDate(val: any): boolean {
        if (val && val.day && val.month && val.year) {
            return true;
        }
        return false;
    }

    getObjPaths(obj: any, path: string, propList: Array<{ path: string, value: any}>) {
        if (typeof obj === "object" && obj && !this.isStripeDate(obj)) {
            Object.keys(obj).forEach(prop => {
                if (obj.hasOwnProperty(prop)) {
                    this.getObjPaths(obj[prop], path + "#" + prop, propList);
                }
            });
        } else {
            propList.push({ path: path, value: obj });
        }
    }

    valueFromDB(fieldId: string, value: any, dest: any) {
        const field = this.getField(fieldId);
        if (field) {
            switch (field.schema.type) {
                case IDynFormFieldType.datetime:
                    if (value) {
                        let m: moment.Moment;
                        if (this.isStripeDate(value)) {
                            m = moment.utc();
                            m.set('year', value.year);
                            m.set('month', value.month - 1);
                            m.set('date', value.day);
                            dest[fieldId] = m;
                        } else {
                            m = moment.unix(value);
                        }
                        dest[fieldId] = m;
                    }
                    break;
                default:
                    dest[fieldId] = value;
            }
        }

    }

    setProps(source: any, dest: any, prefix: string) {
        Object.keys(source).forEach(prop => {
            if (source.hasOwnProperty(prop) && source[prop]) {
                const list = [];
                this.getObjPaths(source[prop], prefix + prop, list);
                list.forEach(i => this.valueFromDB(i.path, i.value, dest));
            }
        });
    }

    createModel(newModel: IPubDetailModel) {
        const model: any = {};
        this.accountType = newModel.stripe_account.business_type;
        this.setProps(newModel.bank_account, model, "external_account#");
        this.setProps(newModel.stripe_account[this.accountType], model, this.accountType + "#");
        this.setProps(newModel.stripe_account.tos_acceptance, model, "tos_acceptance#");
        if (newModel.owners && newModel.owners.length) {
            newModel.owners.forEach(o => {
                Object.keys(EPubDetRelationTypes).forEach(r => {
                    if (o.relationship && o.relationship[r]) {
                        this.setProps(o, model, "owners#" + r + "#");
                    }
                });
            });
        }
        return model;
    }

    saveToAPIModel(fieldName: string, rec: IPubDetailModel, value?: any) {
        const path = fieldName.split('#');
        const field: IDynFormField = this.getField(fieldName);
        const dbValue = this.valueToDB(field, value);
        if (path && path.length) {
            if (path[0] === 'individual' || path[0] === 'company') {
                rec.stripe_account[path[0]].address.country = this.userCountry;
                if (path[0] === 'individual') {
                    rec.stripe_account.individual.email = this.userService.user.emailAddress;
                }
                _.set(rec.stripe_account, path, dbValue);
                // rec.stripe_account = _.merge(rec.stripe_account, value);
            } else if (path[0] === 'external_account') {
                _.set(rec.bank_account, path.slice(1, path.length), dbValue);
                // rec.bank_account = _.merge(rec.bank_account, value.external_account);
            } else if (path[0] === 'tos_acceptance') {
                _.set(rec.stripe_account.tos_acceptance, path.slice(1, path.length), dbValue);
                // rec.stripe_account.tos_acceptance = _.merge(rec.stripe_account.tos_acceptance, value.tos_acceptance);
            } else if (path[0] === 'owners') {
                const ownerType = path[1];
                let ownerRec = rec.owners.find(o => o.relationship && o.relationship[ownerType]);
                if (!ownerRec) {
                    ownerRec = _.cloneDeep(PubDetailOwnerDefault);
                    if (!rec.owners) {
                        rec.owners = [];
                    }
                    rec.owners.push(ownerRec);
                }
                ownerRec.address.country = this.userCountry;
                ownerRec.email = this.userService.user.emailAddress;

                _.set(ownerRec, path.slice(2, path.length), this.valueToDB(field, value));
                ownerRec.relationship[ownerType] = true;
            } else {
                _.set(rec.stripe_account, path, this.valueToDB(field, value))
            }
        }
    }

    save() {
        if (!this.dynFormCtrl.formGroup.valid) {
            this.dynFormCtrl.touchForm();
            return;
        }
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        const files: Array<{ fieldName: string, value: Observable<string>}> = [];
        const rec: IPubDetailModel = _.cloneDeep(PubDetailModelDefault);
        rec.stripe_account.business_type = this.accountType;
        Object.keys(this.model).forEach(fieldName => {
            if (this.model.hasOwnProperty(fieldName)) {

                const dynField: IDynFormField = this.getField(fieldName);
                if (dynField.schema.type === IDynFormFieldType.upload) {
                    files.push({
                        fieldName: fieldName,
                        value: this.valueToDB(dynField),
                    });
                } else {
                    this.saveToAPIModel(fieldName, rec);
                };
            }
        });

        rec.stripe_account.country = this.userCountry;
        rec.bank_account.country = this.userCountry;
        rec.stripe_account.email = this.userService.user.emailAddress;

        if (rec. owners && rec.owners.length) {
            rec.owners = rec.owners.sort((a, b) => {
                const ac = a.relationship.owner ? 0 : (rec.owners.indexOf(a) + 1);
                const bc = b.relationship.owner ? 0 : (rec.owners.indexOf(b) + 1);
                return ac - bc;
            });
        }

        const ipAndSave = from( publicIP.v4()).pipe(
            flatMap(ipAddr => {
                rec.stripe_account.tos_acceptance.ip = ipAddr;
                if ([VerificationStatus.REJECTED, VerificationStatus.PENDING, VerificationStatus.RESTRICTED, VerificationStatus.ENABLED].includes(this.stripeStatus)) {
                    if (rec && rec.stripe_account && rec.stripe_account.company) {
                        if (!rec.stripe_account.company.tax_id) {
                            delete rec.stripe_account.company.tax_id
                        }
                    }
                    if (rec && rec.stripe_account && rec.stripe_account.individual) {
                        if (!rec.stripe_account.individual.id_number) {
                            delete rec.stripe_account.individual.id_number;
                        }
                    }
                    if (rec && rec.bank_account) {
                        if (!rec.bank_account.routing_number) {
                            delete rec.bank_account.routing_number;
                        }
                        if (!rec.bank_account.account_number) {
                            delete rec.bank_account.account_number;
                        }
                        if (!rec.bank_account.iban) {
                            delete rec.bank_account.iban;
                        }
                        if (!rec.bank_account.bsb) {
                            delete rec.bank_account.bsb;
                        }
                    }
                    if (rec.owners && rec.owners.length) {
                        rec.owners.forEach(o => {
                            if (!o.id_number) {
                                delete o.id_number;
                            }
                        })
                    }
                }
                return this.bankService.pubDetailsSave(rec);
            }),
        );
        let saveAPI: Observable<any>;
        if (files.length) {
            const obsArr = [];
            files.forEach(f => {
                if (f.value) {
                    obsArr.push(f.value.pipe(
                        tap(fileContent => this.saveToAPIModel(f.fieldName, rec, fileContent)),
                        // tap(fileContent => this.saveToAPIModel(f.fieldName, rec, fileContent.substring(0, 50))),
                        // tap(fileContent => _.set(rec, f.fieldArr.join('.'), fileContent.substring(0, 50))),
                    ));
                }
            });
            if (obsArr.length) {
                saveAPI = forkJoin(obsArr).pipe(
                    flatMap(() => ipAndSave,
                ));
            } else {
                saveAPI = ipAndSave;
            }
        } else {
            saveAPI = ipAndSave;
        }
        saveAPI.pipe(
            tap(res => {
                this.dynFormCtrl.setPristine();
                if (res && res.account) {
                    this.providedFields = res.account.provided_fields;
                    this.checkProvidedFields(this.model, this.currentForm);
                }
            }),
            flatMap(res => this.bankService.bankAccountExists),
        ).subscribe(
            (res) => {
                this.saveInProgress = false;
                // if (res && [VerificationStatus.COMPLETED, VerificationStatus.ENABLED].includes(res.status)) {
                if (res && [VerificationStatus.COMPLETED].includes(res.status)) {
                    this.stripeFinalized = true;
                    this.dynFormCtrl.setDisabled(true);
                }
                this.dialogService.openMessageDialog({
                    title: "pubDetails.successModal.title",
                    text: "pubDetails.successModal.text",
                    actions: [dialogButtons.ok],
                });
            },
            (err) => {
                this.saveInProgress = false;
                this.handleError(err);
            }
        )
        // rec.stripe_account.company.name = this.model.company_name;
    }

    handleError(err: any) {
        if (err && err.error && err.error.error) {
            const error = err.error.error;
            if (error.raw && error.raw.message) {
                this.dialogService.openMessageDialog({
                    title: "pubDetails.saveErrorTitle",
                    text: error.raw.message,
                    actions: [dialogButtons.ok],
                });
                return;
            }
            if (error.message) {
                this.dialogService.openMessageDialog({
                    title: "pubDetails.saveErrorTitle",
                    text: error.message,
                    actions: [dialogButtons.ok],
                });
                return;
            }
        }
        this.dialogService.openMessageDialog({
            title: "pubDetails.saveErrorTitle",
            text: "pubDetails.saveErorMessageDefault",
            actions: [dialogButtons.ok],
        });
    }

    countryChange(value: string) {
        this.userCountry = value;
        const supportedEUCountries = pubDetSupportedEUCountries;
        const supportedNonEUCountries = pubDetSupportedNonEUCountries;
        const currCtry = this.countries.find(c => c.alpha2Code === this.userCountry);
        if (supportedEUCountries.includes(this.userCountry)) {
            this.country = 'EU';
            this.unsupportedCountry = false;
        } else if (supportedNonEUCountries.includes(this.userCountry)) {
            this.country = this.userCountry;
            this.unsupportedCountry = false;
        } else {
            this.unsupportedCountry = true;
            this.currentForm = {
                id: "nsc",
                name: "pubDetails.unsupportedCountry",
                sections: [
                    {
                        id: "nscs",
                        subTitle: this.userCountry?"pubDetails.unsupportedCountry":"pubDetails.missingCountry",
                        subTitleParam: currCtry?currCtry.name:null,
                        subDesc: this.userCountry?"pubDetails.unsupportedCountryDesc":"pubDetails.missingCountryDesc",
                        singleColumn: true,
                    }
                ]
            };
            this.formWithValue = { form: this.currentForm, value: {} };
            return;
        }
        this.initForm();
    }

    accountTypeChange(value: MatRadioChange) {
        this.accountType = value.value;
        this.initForm();
    }

    addToSLink() {
        if (this.currentForm && this.currentForm.sections && this.currentForm.sections.length) {
            const sectionToS = this.currentForm.sections.find(s => s.id === "paymentToS");
            if (sectionToS) {
                let desc: string = this.trans.instant("pubDetails.sections.paymentToSDesc");
                const tosLinkText: string = this.trans.instant("pubDetails.sections.paymentToSLink");
                const tosLinkRef = "https://stripe.com/" + this.userCountry.toLowerCase() + "/connect-account/legal#translation";
                desc = desc + '<br><a href="' + tosLinkRef + '" target="_blank">' + tosLinkText + '</a>';
                sectionToS.description = desc;
            }
        }
    }

    initForm() {
        if (this.forms && this.forms[this.accountType] && this.forms[this.accountType][this.country]) {
            const newModel: any = {};
            this.currentForm = this.forms[this.accountType][this.country];
            this.addToSLink();
            const sections = this.currentForm.sections;
            if (sections && sections.length) {
                sections.forEach(s => {
                    if (s && s.fields && s.fields.length) {
                        s.fields.forEach(f => {
                            switch (f.schema.type) {
                                case IDynFormFieldType.datetime:
                                    if (this.model[f.id]) {
                                        const m: moment.Moment = this.model[f.id];
                                        if (m.isValid && m.toString()) {
                                            newModel[f.id] = this.model[f.id];
                                        }
                                    }
                                    break;
                                case IDynFormFieldType.phone:
                                    if (this.model[f.id] && this.model[f.id].number) {
                                        newModel[f.id] = this.model[f.id].number;
                                    }
                                    break;
                                default:
                                    if (this.model[f.id]) {
                                        newModel[f.id] = this.model[f.id];
                                    }
                                }
                        });
                    }
                });
                this.defaultValue = newModel;
                this.model = _.cloneDeep(newModel);
                this.setDefaultPhoneCountry(this.defaultValue, this.userCountry);

                this.formWithValue = {
                    form: this.currentForm,
                    value: this.defaultValue,
                }
            }
        }
    }

    touchForm() {
        this.dynFormCtrl.touchForm();
    }

    confirmNavigation() {
        if (this.dynFormCtrl.formGroup.dirty) {
            return this.dialogService.openMessageDialog({
                actions: [dialogButtons.yesLeave, dialogButtons.noStay],
                title: "pubDetails.confirmNavTitle",
                text: "pubDetails.confirmNavText",
              }).afterClosed().pipe(
                map(res => res > 0),
              );
        }
        return !(this.dynFormCtrl.formGroup.dirty);
    }

}

