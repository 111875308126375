import { AppStatus } from '../enums/app-status.enum';
import { APK } from './apk';

export interface AppDetailsItem {
  locale?: string;
  title?: string;
  description?: string;
  releaseNote?: string;
  // release_notes?: string;
}

export interface AppDetailsChanges {
  fieldName: string;
  changedAt: string;
  oldValue: string;
  newValue: string;
}

export interface AppVersion {
  id: string;
  title: string;
}

export enum AppType {
  'app' = 'app',
  'game' = 'game'
}

export class App {
  constructor(
    public packageName?: string,
    public id?: string,
    public releaseDate?: string,
    public type?: string,
    public requiresAuth?: boolean,
    public details = new Array<AppDetailsItem>(),
    public languages: any[] = [],
    public prices: any[] = [],
    public genres: any[] = [],
    public thumbnail?: string,
    public cover?: string,
    public channelImage?: string,
    public productVersionId?: string,
    public modified?: string,
    public locales: any[] = [],
    public copyright?: string,
    public promoVideo?: any,
    public promoVideoPreview?: any,
    public promoVideoTitle?: string,
    public screenshots: any[] = [],
    public developer?: string,
    public developerName?: string,
    public appSize: number = 0,
    public hasInAppPurchases?: boolean,
    public hasAds?: boolean,
    public worldwideAccess?: boolean,
    public worldwidePrice?: boolean,
    public exclude_china?: boolean,
    public version: string = '',
    public appType: AppType = AppType.app,
    public categories: string[] = [],
    public channelDeeplinkUrl?: any,
    public rejectionNote?: string,
    public description?: string,
    public forChildren?: boolean,
    public deviceModel?: string,
    public submitTried?: boolean,
    public packageID?: string,
    public packages?: APK[],
    // Valid values?: "live", "rejected", "submitted", "not_submitted"
    public appStatus: AppStatus = AppStatus.not_submitted,
    public appVersion?: string,
    public downloads?: number,
    // Really sting<UUID>
    public lastUpdate?,
    public rejection_notes?,
    public revenue?,
    public title?,
    public userRating?,
    public forceUpdate?: boolean,
    public versions?: AppVersion[],
    public minimumGuarantee?: number
  ) {}
}
