import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  dialogButtons,
  dprmConfirmLeavePageDialog,
  IDialogAction,
  MessageDialogServiceImpl,
  MessageDialogServiceToken
} from '@common/components/confimation-modal/dialog.service';
import { INavigationGuardComponent } from '@common/components/navigation-guard/navigation-guard.component';
import { AppStatus } from '@enums/app-status.enum';
import { VerificationStatus } from '@enums/verification-status.enum';
import { AppDetailsResolver } from '@guards/app-details.resolver';
import { App, AppDetailsChanges, AppDetailsItem } from '@models/app';
import { InAppPurchase } from '@models/iap';
import { AppService, IAppPermissions } from '@services/app.service';
import { ComponentCommService } from '@services/component-comm.service';
import { IapService } from '@services/iap.service';
import { RegisterService } from '@services/register.service';
import { UserService } from '@services/user.service';
import { UtilService } from '@services/util.service';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, flatMap, map, merge, share, take, tap } from 'rxjs/operators';
import { appDetailMinTitleLength } from './app-edit-details/app-edit-details.component';
import {
  statusMissingDialogText,
  statusRejectedDialogText,
  statusUnverifiedDialogText,
  successZeroPrices
} from './app-edit-dialog-texts';
import {
  iapPriceNaNValidator,
  maxPriceValidator,
  noWhiteSpaceValidator,
  trimmedMinLength
} from './app-edit.validators';
import { leiaGlobal } from 'endpoints';


export const excludeAgeRating = ['zh_CN'];

export interface IMenuEntry {
  title: string;
}
export interface IAppSubmitResult {
  opType: 'save' | 'submit';
  res: any;
  cmd?: saveCmd;
}

const messageOnlyFreeApp = 'Due to unverified bank information, app price and in-app purchase cannot be set.';

enum saveCmd {
  zeroPrices = 'zeroPrices',
  navAppList = 'navAppList',
  navPubDetails = 'navPubDetails',
  saveApp = 'saveApp'
}

@Component({
  selector: 'app-edit',
  templateUrl: './app-edit.component.html',
  styleUrls: ['./app-edit.component.scss']
})
export class AppEditComponent implements OnInit, INavigationGuardComponent {
  isAdmin: boolean;
  hasPermission = true;
  onlyFreeApp = false;
  user: any = null;
  app: App;
  appChanges: AppDetailsChanges[];
  lists: any;
  readOnly = false;

  selectedTab = 0;
  appForm: FormGroup;
  iapForm: FormGroup;

  rejectionNotes: string;
  rejectTextExpanded = false;

  saveInProgress = false;

  messageOnlyFreeApp = messageOnlyFreeApp;
  public AppStatus = AppStatus;

  @ViewChild('distAgreement') distAgreement: ElementRef;

  tabErrors = {
    details: 'Please review the app details for missing or incorrectly filled fields,',
    locales: 'Please review the locales tab for missing or incorrectly filled fields,',
    imagery: 'Please check if all required images have been uploaded',
    packages: 'Please upload at least one APK package',
    iap: 'Please review the In-App-Purchases form for missing or incorrectly filled fields',
    iapSubmit: 'Please save or discard any changes made to the In-App-Purchases form'
  };

  private iapRequiredGroupValidator(locale: string): ValidatorFn {
    return (fg: FormGroup): ValidationErrors | null => {
      const selectedDefaults = this.app.details.map(d => d.locale);
      const err: any = {};
      let ctrlTitle: string = fg.get('title').value;
      let ctrlDesc: string = fg.get('description').value;
      if (ctrlTitle && ctrlTitle.length) {
        ctrlTitle = ctrlTitle.trim();
      }
      if (ctrlDesc && ctrlDesc.length) {
        ctrlDesc = ctrlDesc.trim();
      }

      if (this.leiaGlobalConfig.defaultLocales.includes(locale) && selectedDefaults.includes(locale)) {
        if (!this.hasValue(ctrlDesc)) {
          err.descRequired = true;
        }
        if (!this.hasValue(ctrlTitle)) {
          err.titleRequired = true;
        }
      } else {
        if (this.hasValue(ctrlTitle) && !this.hasValue(ctrlDesc)) {
          err.descRequired = true;
        }
        if (this.hasValue(ctrlDesc) && !this.hasValue(ctrlTitle)) {
          err.titleRequired = true;
        }
      }
      if (err.titleRequired || err.descRequired) {
        if (err.titleRequired) {
          fg.get('title').setErrors({ required: true });
        }
        if (err.descRequired) {
          fg.get('description').setErrors({ required: true });
        }
        return err;
      } else {
        if (!(this.leiaGlobalConfig.defaultLocales.includes(locale) && selectedDefaults.includes(locale))) {
          fg.get('title').setErrors(null);
          fg.get('description').setErrors(null);
        }
        return null;
      }
    };
  }

  hasValue(v: any): boolean {
    return !(v === null || v === undefined || v === '');
  }

  private localeGroupValidator(locale: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (this.app && this.app.locales && this.app.locales.length) {
        if (this.app.locales.includes(locale)) {
          if (control.value === null || control.value === undefined || control.value === '') {
            return { required: true };
          }
        }
      }
      return null;
    };
  }

  constructor(
    private userService: UserService,
    private appService: AppService,
    private iapService: IapService,
    private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
    protected svcUtil: UtilService,
    protected svcResolver: AppDetailsResolver,
    protected cdr: ChangeDetectorRef,
    protected commService: ComponentCommService,
    @Inject(leiaGlobal) private leiaGlobalConfig,
    @Inject(MessageDialogServiceToken) protected dialogService: MessageDialogServiceImpl
  ) {
    this.user = userService.getUser();
    this.isAdmin = this.userService.isAdmin();
  }

  getTitle() {
    const detail = this.app.details
      .sort((a, b) => (a.locale > b.locale ? 1 : a.locale < b.locale ? -1 : 0))
      .find(detailLocale => this.leiaGlobalConfig.defaultLocales.includes(detailLocale.locale));

    if (detail && detail.title !== '') {
      return detail.title;
    } else {
      return this.svcUtil.first(this.app.details) ? this.svcUtil.first(this.app.details).title : '';
    }
  }

  getMinimumGuarantee() {
    return `$${this.app.minimumGuarantee / 1000}k`
  }

  back() {
    if (this.isAdmin) {
      this.router.navigate(['/main/admin-apps']);
    } else {
      this.router.navigate(['/main/my-apps']);
    }
  }

  tabSelect(ev: number) {
    this.selectedTab = ev;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls)
      .map(x => formGroup.controls[x])
      .forEach((control: any) => {
        control.markAsTouched();
        if (control.controls) {
          this.markFormGroupTouched(control);
        }
      });
  }

  checkGroupError(fg: FormGroup): boolean {
    return Object.keys(fg.controls)
      .map(c => {
        return {
          name: c,
          control: fg.controls[c]
        }
      })
      .some(ctrl => {
        if (ctrl.control instanceof FormControl) {
          if (ctrl.control.invalid && ctrl.control.touched) {
            return true;
          }
        }
        if (ctrl.control instanceof FormGroup) {
          return this.checkGroupError(ctrl.control);
        }
      });
  }

  tabHasError(groupName: string): boolean {
    return this.appForm.controls[groupName].touched && !this.appForm.controls[groupName].valid;
  }

  iapHasError() {
    return this.iapForm.get('isEdit').value && this.iapForm.invalid;
  }
  iapUnsaved() {
    return this.iapForm.get('isEdit').value && this.iapForm.dirty;
  }

  statusRestrictIAPPrice(status: VerificationStatus): boolean {
    return ![VerificationStatus.COMPLETED, VerificationStatus.ENABLED, VerificationStatus.VERIFIED_OVERRIDE].includes(
      status
    );
  }

  ngOnInit() {
    this.app = this.route.snapshot.data.appDetails;
    this.appChanges = this.isAdmin ? this.route.snapshot.data.appChanges : [];
    this.lists = this.route.snapshot.data.lists;

    const permissions: IAppPermissions = this.route.snapshot.data.appPermissions;
    if (!this.app || !permissions || !permissions.canCreateOrEditApps) {
      this.hasPermission = false;
      setTimeout(() => {
        this.dialogService
          .openMessageDialog({
            title: 'Access Denied',
            text: 'You have no permission to view this information or an internal error has occured.',
            actions: [dialogButtons.ok]
          })
          .afterClosed()
          .subscribe(() => this.navigateBack());
      });
      return;
    }

    if (!this.isAdmin) {
      this.onlyFreeApp = this.statusRestrictIAPPrice(permissions.bankAccountStatus);
    }

    this.readOnly = this.isAdmin || (!this.isAdmin && this.app && this.app.appStatus === AppStatus.awaiting_approval);

    // ************************************* Details fields *************************************
    let hasGenres: string = null;
    if (this.app && this.app.genres && this.app.genres.length) {
      hasGenres = '1';
    }

    const detailsGroup: any = {
      appType: new FormControl({ value: this.app.appType, disabled: this.readOnly }, Validators.required),
      forChildren: new FormControl({ value: this.app.forChildren, disabled: this.readOnly }, Validators.required),
      hasAds: new FormControl({ value: this.app.hasAds, disabled: this.readOnly }, Validators.required),
      copyright: new FormControl({ value: this.app.copyright, disabled: this.readOnly }, [
        Validators.required,
        noWhiteSpaceValidator
      ]),
      hasGenre: new FormControl(hasGenres, Validators.required),
    };

    this.lists.language.forEach(lang => {
      const appDetail: AppDetailsItem = this.app.details.find(det => det.locale === lang.locale);
      const defVal = appDetail || {};
      const hasData =
        !!defVal && ((defVal.title && defVal.title.length) || (defVal.description && defVal.description.length));

      detailsGroup[lang.locale] = new FormGroup({
        title: new FormControl(
          { value: defVal.title, disabled: this.readOnly && this.isAdmin },
          hasData ? [Validators.required, noWhiteSpaceValidator] : undefined
        ),
        description: new FormControl(
          { value: defVal.description, disabled: this.readOnly && this.isAdmin },
          hasData ? [Validators.required, trimmedMinLength(appDetailMinTitleLength), noWhiteSpaceValidator] : undefined
        )
      });
    });

    // ************************************* IAP fields *************************************
    const selectedDefaults = this.app.details
      .filter(d => this.leiaGlobalConfig.defaultLocales.includes(d.locale))
      .map(d => d.locale);
    const iapGroup: any = {
      sku: new FormControl({ value: null, disabled: this.readOnly || this.onlyFreeApp }, Validators.required),
      hasLocale: new FormControl(null, Validators.required),
      isEdit: new FormControl()
    };
    this.lists.language.forEach(lang => {
      iapGroup[lang.locale] = new FormGroup({
        title: new FormControl({ value: null, disabled: this.readOnly || this.onlyFreeApp }),
        description: new FormControl({ value: null, disabled: this.readOnly || this.onlyFreeApp })
      });
    });
    this.lists.locales.forEach(loc => {
      iapGroup[loc.country_locale] = new FormGroup({
        localeSelected: new FormControl({
          value: selectedDefaults.includes(loc.country_locale),
          disabled: this.readOnly || this.onlyFreeApp
        }),
        price: new FormControl({ value: null, disabled: this.readOnly })
      });
    });

    const iapFormGroup: FormGroup = new FormGroup(iapGroup);
    this.lists.language.forEach(lang => {
      iapFormGroup.controls[lang.locale].setValidators(this.iapRequiredGroupValidator(lang.locale));
    });
    this.lists.locales.forEach(loc => {
      iapFormGroup.controls[loc.country_locale]
        .get('price')
        .setValidators([maxPriceValidator(200), iapPriceNaNValidator]);
    });
    this.iapForm = iapFormGroup;

    // ************************************* Locales fields *************************************
    const localesGroup: any = {};

    if (!this.app.prices || !this.app.prices.length) {
      this.app.prices.push({
        productId: this.app.id,
        locale: this.leiaGlobalConfig.defaultLocales[0],
        price: null,
        ageRating: null
      });
    }

    [...this.lists.locales, { locale: 'WW' }].forEach(loc => {
      const defValue: any = this.app.prices.find(price => price.locale === loc.locale) || {};
      const hasValue = this.app.locales.some(locale => locale === loc.locale);

      localesGroup[loc.locale] = new FormGroup({
        price: new FormControl(
          { value: defValue.price, disabled: this.readOnly || this.onlyFreeApp },
          Validators.compose([this.localeGroupValidator(loc.locale), maxPriceValidator(200)])
        ),
        ageRating: new FormControl(
          { value: defValue.ageRating, disabled: this.readOnly },
          excludeAgeRating.includes(loc.locale) ? null : Validators.compose([this.localeGroupValidator(loc.locale)])
        ),
        localeSelected: new FormControl({
          value: hasValue,
          disabled: loc.locale === this.leiaGlobalConfig.defaultLocales[0] || this.readOnly
        })
      });
    });

    // ************************************* Imagery fields *************************************
    const scrLen = this.app.screenshots && this.app.screenshots.length ? this.app.screenshots.length : 0;
    const imageryGroup = new FormGroup({
      thumbnail: new FormControl(this.app.thumbnail, [Validators.required]),
      cover: new FormControl(this.app.cover, [Validators.required]),
      promoVideo: new FormControl(this.app.promoVideo),
      promoVideoPreview: new FormControl(this.app.promoVideoPreview),
      screenshots0: new FormControl(scrLen > 0 ? this.app.screenshots[0] : null, [Validators.required]),
      screenshots1: new FormControl(scrLen > 1 ? this.app.screenshots[1] : null, [Validators.required]),
      screenshots2: new FormControl(scrLen > 2 ? this.app.screenshots[2] : null, [Validators.required]),
      screenshots3: new FormControl(scrLen > 0 ? this.app.screenshots[3] : null),
      screenshots4: new FormControl(scrLen > 0 ? this.app.screenshots[4] : null)
    });

    // ************************************* APK fields *************************************
    const packagesGroup = new FormGroup({
      packageCount: new FormControl(this.app.packages && this.app.packages.length ? this.app.packages.length : null, [
        Validators.required
      ])
    });

    // ************************************* Main form group *************************************
    this.appForm = new FormGroup({
      details: new FormGroup(detailsGroup),
      locales: new FormGroup(localesGroup),
      imagery: imageryGroup,
      packages: packagesGroup
    });
  }

  approveApp() {
    this.appService.approve(this.app.id).subscribe(result => {
      this.navigateBack();
    });
  }

  rejectApp() {
    this.appService.reject(this.app.id, this.rejectionNotes).subscribe(result => {
      this.navigateBack();
    });
  }

  navigateBack() {
    if (this.isAdmin) {
      this.router.navigate(['/main/admin-apps']);
    } else {
      this.router.navigate(['/main/my-apps']);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event) {
    if (!this.appForm || !this.iapForm || !(this.appForm.dirty || this.iapForm.dirty)) {
      return undefined;
    }
    event.returnValue = 'You have unsaved changes. Are you sure you want to close the page?';
    return 'You have unsaved changes. Are you sure you want to close the page?';
  }

  confirmNavigation(): boolean | Observable<boolean> {
    if (!this.appForm || !this.iapForm || !(this.appForm.dirty || this.iapForm.dirty)) {
      return true;
    }
    return this.dialogService
      .openMessageDialog(dprmConfirmLeavePageDialog)
      .afterClosed()
      .pipe(map(res => res > 0));
  }

  zeroPrices() {
    [...this.lists.locales, { locale: 'WW' }].forEach(loc => {
      const appLoc = this.app.prices.find(ap => ap.locale === loc.locale);
      if (appLoc && appLoc.price > 0) {
        appLoc.price = 0;
      }
      const ctrlSel = this.appForm
        .get('locales')
        .get(loc.locale)
        .get('localeSelected');
      const ctrlPrice = this.appForm
        .get('locales')
        .get(loc.locale)
        .get('price');
      if (ctrlSel.value) {
        ctrlPrice.setValue(0);
      }
    });
  }
  showErrorDialog(message: string = 'An error has occured, could not process your request.') {
    this.dialogService.openMessageDialog({
      title: 'Error Occured',
      text: message,
      actions: [dialogButtons.ok]
    });
  }

  showAlert(message: string) {
    this.dialogService.openMessageDialog({
      title: 'Alert',
      text: message,
      actions: [dialogButtons.ok]
    });
  }

  showSaveSuccess() {
    this.dialogService.openMessageDialog({
      title: 'Success',
      text: 'Your app details have been saved.',
      actions: [dialogButtons.ok]
    });
  }

  showStatusErrorDialog(actions: IDialogAction[], htmlText: string, title: string = 'There was a small problem') {
    return this.dialogService.openMessageDialog({
      title: title,
      htmlText: htmlText,
      actions: actions
    });
  }

  formsHaveError() {
    return this.appForm.invalid || this.iapHasError() || this.iapUnsaved();
  }

  touchFormGroups() {
    this.markFormGroupTouched(this.appForm);
    if (this.iapUnsaved() || this.iapHasError()) {
      this.markFormGroupTouched(this.iapForm);
    }
  }

  setFormPristine() {
    this.svcUtil.recurseFormControls(this.appForm, control => {
      control.markAsUntouched();
      control.markAsPristine();
    });
  }

  saveChanges(silent: boolean = false) {
    if ([AppStatus.live, AppStatus.awaiting_approval].includes(this.app.appStatus) && this.formsHaveError()) {
      this.touchFormGroups();
      return;
    }

    if (!this.saveInProgress) {
      this.saveInProgress = true;
      this.appService.saveOrUpdate(this.app).subscribe(
        res => {
          if (res && res.app) {
            if (res.app.appStatus) {
              this.app.appStatus = res.app.appStatus;
            }
            if (res.app.productVersionId) {
              this.app.productVersionId = res.app.productVersionId;
            }
          }
          if (!silent) {
            this.showSaveSuccess();
          }
          this.setFormPristine();
          this.saveInProgress = false;
        },
        err => {
          this.showErrorDialog();
          this.saveInProgress = false;
          console.log(err);
        }
      );
    }
  }

  submitApp() {
    let bankAccStatus: VerificationStatus;
    let appIAPs: InAppPurchase[];
    this.touchFormGroups();
    if (!this.formsHaveError() && !this.saveInProgress) {
      this.saveInProgress = true;

      let $start = of(true).pipe(take(1));

      // Diagram# 1.
      if (!this.user.userAcceptedDistribution) {
        // Diagram# 4.
        $start = this.dialogService
          .openMessageDialog({
            actions: [
              { caption: 'I Agree', result: true },
              { caption: 'Not now', result: false }
            ],
            title: 'Distribution Agreement',
            bodyContentRef: this.distAgreement
          })
          .afterClosed()
          .pipe(
            take(1),
            tap(res => {
              if (!res) {
                this.saveInProgress = false;
              }
            }),
            filter(res => res),
            flatMap(() => this.registerService.setUserAgreedToDistributionAgreement(this.user, true)),
            tap(res => {
              this.user.userAcceptedDistribution = true;
              this.userService.setUser(this.user);
            }),
            map(() => true)
          );
      }

      const $submit = this.appService.saveAndSubmit(this.app).pipe(tap(res => this.setFormPristine()));

      // Diagram# 2.
      const $checkIAP = $start.pipe(
        flatMap(() => this.iapService.getIAPsObs(this.app.id)),
        map(iaps => {
          appIAPs = iaps;
          const priceExists = this.app.prices.some(price => price.price > 0);
          const paidIapExists = iaps.filter(iap => iap.active).length > 0;
          return priceExists || paidIapExists;
        }),
        share()
      );
      // Diagram# 3.
      const $noIAPOrPrice = $checkIAP.pipe(
        filter(hasIAPOrPrice => !hasIAPOrPrice),
        flatMap(() => $submit),
        map(res => ({ opType: 'submit', res: res }))
      );
      // Diagram# 5.
      const $hasIAPOrPrice = $checkIAP.pipe(
        filter(hasIAPOrPrice => hasIAPOrPrice),
        flatMap(() => this.appService.canCreateOrEditApps()),
        map(permissions => {
          bankAccStatus = permissions.bankAccountStatus;
          return permissions.canCreateOrEditApps && !this.statusRestrictIAPPrice(permissions.bankAccountStatus);
        }),
        share()
      );
      // Diagram# 3 (from Diagram# 5)
      const $permCheckPassed = $hasIAPOrPrice.pipe(
        filter(hasPerm => hasPerm),
        flatMap(() => $submit),
        map(res => ({ opType: 'submit', res: res }))
      );
      const $permCheckFailed = $hasIAPOrPrice.pipe(
        filter(hasPerm => !hasPerm),
        flatMap(() => {
          switch (bankAccStatus) {
            // Diagram# 6
            case VerificationStatus.NONE:
              // Diagram# 7
              return this.showStatusErrorDialog(
                [
                  {
                    caption: 'Publishing Details',
                    // Diagram# 8
                    result: saveCmd.navPubDetails
                  },
                  {
                    caption: 'Cancel',
                    result: saveCmd.saveApp
                  }
                ],
                statusMissingDialogText
              ).afterClosed();
              break;
            // Diagram# 9
            case VerificationStatus.REJECTED:
              // Diagram #10
              return this.showStatusErrorDialog(
                [
                  {
                    caption: 'Make My App Free',
                    // Diagram# 11
                    result: saveCmd.zeroPrices
                  },
                  {
                    caption: 'Cancel Submission',
                    // Diagram# 12
                    result: saveCmd.navAppList
                  }
                ],
                statusRejectedDialogText
              ).afterClosed();
              break;
            // Diagram# 13
            case VerificationStatus.PENDING:
            case VerificationStatus.RESTRICTED_SOON:
            case VerificationStatus.RESTRICTED:
              // Diagram# 14
              return this.showStatusErrorDialog(
                [
                  {
                    caption: 'Continue With Free App',
                    // Diagram# 15
                    result: saveCmd.zeroPrices
                  },
                  {
                    caption: 'Cancel App Submission',
                    // Diagram# 16
                    result: saveCmd.navAppList
                  }
                ],
                statusUnverifiedDialogText
              ).afterClosed();
              break;
            default:
              return of(saveCmd.saveApp);
              break;
          }
        }),
        flatMap(dialogRes => {
          let $save = this.appService.saveOrUpdate(this.app).pipe(
            tap(res => this.setFormPristine()),
            map(res => ({ opType: 'save', res: res, cmd: dialogRes }))
          );
          if (dialogRes === saveCmd.zeroPrices) {
            // Diagram# 11
            // Diagram# 15
            this.zeroPrices();
            if (appIAPs && appIAPs.length) {
              const obsArr = [];
              appIAPs
                .filter(iap => iap.active)
                .forEach(iap => {
                  iap.active = false;
                  obsArr.push(this.iapService.saveIAP(iap));
                });
              if (obsArr.length) {
                const $oSave = $save;
                $save = forkJoin(obsArr).pipe(flatMap(() => $oSave));
              }
            }
          }
          // Diagram# 17
          return $save;
        })
      );

      $noIAPOrPrice.pipe(merge($permCheckPassed, $permCheckFailed)).subscribe(
        (submitRes: IAppSubmitResult) => {
          const res = submitRes.res;
          let noStatusChange = false;
          if (submitRes.opType === 'submit') {
            // Diagram# 3
            if (res && res.status === 'INVALID_APK_FILE') {
              this.showAlert(`This APK has an invalid version code and version name.
                            All APKs submitted for an app update must contain a higher
                            Version Code and Version Number than the current Live Version.`);
            } else if (res && res.status === 'INVALID_PRODUCT') {
              this.showAlert('Invalid product');
            } else if (res && res.status === 'OK') {
              if (res.app) {
                noStatusChange =
                  this.app.productVersionId === res.app.productVersionId && this.app.appStatus === res.app.appStatus;
                this.app.productVersionId = res.app.productVersionId;
                this.app.appStatus = res.app.appStatus;
              } else {
                this.app.appStatus = AppStatus.awaiting_approval;
              }
              if (noStatusChange) {
                this.dialogService.openMessageDialog({
                  title: 'Thank You!',
                  htmlText: `It looks like none of your changes require approval.<br/> <br/>
                                    Your App has been updated, and the changes should be reflected in the Red | LeiaLoft application shortly!`,
                  actions: [dialogButtons.ok]
                });
              } else {
                this.dialogService.openMessageDialog({
                  title: 'Thank You!',
                  text: `Your app has been submitted for review and a confirmation email has been
                                    sent. You will receive an email with the review decision as soon as your
                                    app has been reviewed.`,
                  actions: [dialogButtons.ok]
                });
              }
            } else {
              this.showInvalidMissingMetadataError();
            }
          }
          if (submitRes.opType === 'save') {
            if (submitRes.cmd) {
              switch (submitRes.cmd as saveCmd) {
                // Diagram# 8
                case saveCmd.navPubDetails:
                  this.router.navigate(['/main', 'my-account', 'publishing-details']);
                  break;
                // Diagram# 12
                // Diagram# 16
                case saveCmd.navAppList:
                  this.router.navigate(['/main', 'my-apps']);
                  break;
                case saveCmd.zeroPrices:
                  this.dialogService.openMessageDialog({
                    title: 'Heads Up',
                    htmlText: successZeroPrices,
                    actions: [
                      {
                        caption: 'Cancel Submission',
                        callBack: () => this.router.navigate(['/main', 'my-apps'])
                      },
                      {
                        caption: 'Review Changes'
                      }
                    ]
                  });
                  break;
                case saveCmd.saveApp:
                  this.showSaveSuccess();
                  break;
              }
            } else {
              this.showSaveSuccess();
            }
          }
          this.saveInProgress = false;
        },
        err => {
          if (err && err.error && err.error.status) {
            switch (err.error.status) {
              case 'INVALID_OR_MISSING_METADATA':
                this.showInvalidMissingMetadataError();
                break;
              case 'INVALID_APK_FILE':
                this.showAlert(`This APK has an invalid version code and version name.
                                    All APKs submitted for an app update must contain a higher
                                    Version Code and Version Number than the current Live Version.`);
                break;
              case 'INVALID_PRODUCT':
                this.showAlert('Invalid product.');
                break;
              case 'MISSING_APK':
                this.showAlert('No APK present, cannot submit the app.');
                break;
              case 'UNDER_APPROVAL':
                this.showAlert(
                  'Another app version is already awaiting approval, cannot submit the currently selected version.'
                );
                break;
              default:
                this.showErrorDialog();
                break;
            }
          } else {
            this.showErrorDialog();
          }
          console.log(err);
          this.saveInProgress = false;
        }
      );
    }
  }

  showInvalidMissingMetadataError() {
    this.dialogService.openMessageDialog({
      title: 'Alert',
      htmlText: `An error occured during App submission. <br/>
                Please check if your APK file has passed validation.`,
      actions: [dialogButtons.ok]
    });
  }

  selectBackTab() {
    this.selectedTab = (this.selectedTab - 1) % 5;
  }

  selectNextTab() {
    this.selectedTab = (this.selectedTab + 1) % 5;
  }
}
