import { NgModule } from '@angular/core';
import { AveragePipe } from './average.pipe';
import { FormatYAxisPipe } from './format-y-axis.pipe';
import { WrapFirstCharacterPipe } from './wrap-first-character.pipe';
import { TestAppPipe } from './test-app.pipe';

@NgModule({
  imports: [],
  declarations: [WrapFirstCharacterPipe, AveragePipe, FormatYAxisPipe, TestAppPipe],
  exports: [WrapFirstCharacterPipe, AveragePipe, FormatYAxisPipe, TestAppPipe]
})
export class PipesModule {}
