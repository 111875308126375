import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-variable-modal',
  templateUrl: './variable-modal.component.html',
  styleUrls: ['./variable-modal.component.scss']
})
export class VariableModalComponent {
  @ViewChild(ModalComponent)
  modal: ModalComponent;
  @ContentChild(TemplateRef)
  buttons: TemplateRef<any>;
  @Input()
  headerText: string;
  @Input()
  bodyText: string;
  @Input()
  visible = false;
  @Input()
  center: boolean;
  @Input()
  inProgress = false;
  @Output() hideModal = new EventEmitter<boolean>();

  showAlert(message: string) {
    this.visible = true;
    this.bodyText = message;
    this.modal.show();
  }

  modalHidden(ev: any) {
    this.hideModal.emit(true);
  }
}
