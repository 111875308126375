import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaterialInputComponent } from 'app/common.module/components/material-input/material-input.component';
import { User } from '../../../models/user';

export const BANK_DETAILS_SUMMARY_FORM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BankDetailsSummaryComponent),
  multi: true
};

@Component({
  selector: 'app-bank-details-summary',
  templateUrl: './bank-details-summary.component.html',
  styleUrls: ['./bank-details-summary.component.scss'],
  providers: [BANK_DETAILS_SUMMARY_FORM_VALUE_ACCESSOR]
})
export class BankDetailsSummaryComponent {
  @Input()
  readonly = false;
  @ViewChildren(MaterialInputComponent)
  inputs: QueryList<MaterialInputComponent>;

  @Output()
  update = new EventEmitter();
  constructor() {}

  _value: User;

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.notifyValueChange(value);
  }

  onChange: (value) => {};
  onTouched: () => {};

  markAsPristine() {
    this.inputs.forEach(input => input.markAsPristine());
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateUser() {
    this.update.emit();
  }
}
