import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, AfterViewInit, } from '@angular/core';
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { ErrorProviderLeaf } from 'app/models/error-provider.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { App } from '../../../../../../models/app';
import { Locale } from '../../../../../../models/locale';

@Component({
  selector: 'app-locale-row',
  templateUrl: './locale-row.component.html',
  styleUrls: ['./locale-row.component.scss']
})
export class AppLocaleRowComponent implements OnInit, OnDestroy, AfterViewInit, ErrorProviderLeaf {
  @ViewChild('priceInput')
  priceInput: ElementRef;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input()
  app: App = null;
  @Input()
  showPriceInput = false;
  @Input()
  locale: any;
  @Input()
  price: Locale;
  @Input()
  readOnly = false;
  @ViewChild('form') ngForm: NgForm;
  worldwide = false;
  worldwidePrice = false;
  error = false;
  showError = false;

  lastPrice: any;
  lastAgeRating: any;

  ratings = ['3+', '7+', '12+', '16+'];

  private onDestroy$: Subject<boolean> = new Subject();

  constructor(private _eref: ElementRef, private route: ActivatedRoute) {}

  localeToggle() {
    if (this.readOnly || this.locale.locale === 'en_US') {
      return;
    } else if (this.worldwide) {
      this.app.worldwidePrice = this.worldwidePrice = !this.worldwidePrice;
      this.valueChange.emit({
        "WW": this.worldwidePrice,
      });
      this.price.price = null;
      this.price.ageRating = null;
    } else if (this.app.locales.includes(this.locale.locale)) {
      this.app.locales.splice(this.app.locales.indexOf(this.locale.locale), 1);
      this.error = this.showError = false;

      // Clear price and ageRating
      const price = this.app.prices.find(p => p.locale === this.locale.locale);
      price.price = null;
      price.ageRating = null;
      this.valueChange.emit({
        [this.locale.locale]: false,
      });
    } else {
      this.app.locales.push(this.locale.locale);
      this.valueChange.emit({
        [this.locale.locale]: true,
      });
    }
    this.error = this.showError = this.checkError();
  }

  isChecked() {
    return this.worldwide
      ? this.worldwidePrice
      : this.app.locales
      ? this.app.locales.includes(this.locale.locale)
      : false;
  }

  showPrice() {
    if (!this.readOnly) {
      this.showPriceInput = true;
      if (this.priceInput && this.priceInput.nativeElement) {
        setTimeout(()=> { this.priceInput.nativeElement.focus() })
      }
    }
  }

  onEnter() {
    if (Number.isNaN(this.price.price) || this.price.price < 0) {
      this.price.price = null;
    }

    this.showPriceInput = false;
  }

  ngOnInit() {
    const { readOnly, devReadOnly } = this.route.snapshot.data;

    this.readOnly = readOnly;
    this.worldwide = this.locale.locale === 'WW';
    this.worldwidePrice = this.app.worldwidePrice;

    this.price = this.app.prices.find(detailLocale => {
      return detailLocale.locale === this.locale.locale;
    });

    if (!this.price) {
      this.price = {
        productId: this.app.id,
        locale: this.locale.locale,
        price: null,
        ageRating: null
      };

      this.app.prices.push(this.price);
    }
    this.lastPrice = this.price.price;
    this.lastAgeRating = this.price.ageRating;

    this.error = this.showError = this.checkError(true);
  }

  ngAfterViewInit() {
    // this.ngForm.valueChanges.pipe(
    //   takeUntil(this.onDestroy$),
    // ).subscribe((change => {
    //   let doEmit = false;
    //   if ((change.price != this.lastPrice) && (!((!this.lastPrice) && (!change.price))) && (!(isNaN(change.price) && (!this.lastPrice)))) {
    //     this.lastPrice = change.price;
    //     doEmit = true;
    //   }
    //   if ((change.ageRating != this.lastAgeRating && (!((!change.ageRating) && (!this.lastAgeRating))))) {
    //     this.lastAgeRating = change.ageRating;
    //     doEmit = true;
    //   }
    //   if (doEmit) {
    //     this.valueChange.emit(change);
    //   }
    // }));
  }

  checkError(skipEmitChange?: boolean) {
    if (!skipEmitChange) {
      this.valueChange.emit({
        locale: this.price && this.price.locale,
        enabled: this.price && this.price.enabled,
        price: this.price && this.price.price,
        ageRating: this.price && this.price.ageRating,
      });
    }
    if (
      this.price &&
      this.isChecked() &&
      (!this.price.ageRating || this.price.price == null)
    ) {
      this.error = this.showError = true;
      return true;
    } else {
      this.error = this.showError = false;
      return false;
    }
  }

  openESRBExternalSite() {
    window.open('http://www.esrb.org/', '_blank');
  }

  shouldShowAddPrice() {
    return this.isChecked() && !this.showPriceInput && this.price.price == null;
  }

  shouldShowPriceLabel() {
    return this.isChecked() && !this.showPriceInput && this.price.price >= 0;
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
