import { AfterViewInit, Directive, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";

export const dropErrMultipleFiles = "Just a single file can be dropped here.";
export const dropErrInvalidExt = "Only the following file types are allowed: {$1}";

@Directive({
    selector: '[appLeiaFileDropZone]'
  })
  export class LeiaFileDropZoneDirective implements AfterViewInit {
    @Input() private hoverColor: string;
    @Input() private allowMulti = false;
    @Input() private allowedExtensions : string[];
    @Output() private droppedFiles : EventEmitter<FileList> = new EventEmitter();
    @Output() private dropError : EventEmitter<string> = new EventEmitter();
    @HostBinding('style.background') private background;

    private originalBackground;

    constructor() { }

    ngAfterViewInit() {
        this.originalBackground = this.background;
    }

    @HostListener('dragover', ['$event']) public onDragOver(evt){
        this.eventStopPrevent(evt);
        if (this.hoverColor) {
            this.background = this.hoverColor;
        }
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt){
        this.eventStopPrevent(evt);
        if (this.hoverColor) {
            this.background = this.originalBackground;
        }
    }

    @HostListener('drop', ['$event']) public onDrop(evt){
        evt.preventDefault();
        evt.stopPropagation();
        if (this.hoverColor) {
            this.background = this.originalBackground;
        }
        const dataTrans = this.getDataTransfer(evt);
        if (!(dataTrans && dataTrans.files)) {
            return;
        }
        const files = dataTrans.files;
        if ((!this.allowMulti) && files && files.length > 1) {
            this.dropError.emit(dropErrMultipleFiles);
            return;
        }
        if(files && files.length > 0){
            // Drop event "files" is an Array<>, better use for loop here
            for(let cnt = 0; cnt < files.length; cnt++) {
                const file: File = files[cnt];
                const ext = file.name.split('.')[file.name.split('.').length - 1];
                if(this.allowedExtensions && this.allowedExtensions.length && this.allowedExtensions.indexOf(ext) === -1){
                    const errMsg: string = dropErrInvalidExt.replace("{$1}", this.allowedExtensions.join(", "));
                    this.dropError.emit(errMsg);
                    return;
                }
            }
            this.droppedFiles.emit(files);
        }
    }

    getDataTransfer(evt) {
        if (evt.dataTransfer) {
            return evt.dataTransfer;
        }
        if (evt.originalEvent && evt.originalEvent.dataTransfer) {
            return evt.originalEvent.dataTransfer;
        }
        return null;
    }

    eventStopPrevent(evt) {
        if (evt) {
            if (evt.preventDefault) {
                evt.preventDefault();
            }
            if (evt.stopPropagation) {
                evt.stopPropagation();
            }

        }
    }
  }