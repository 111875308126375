import { AdminDashboardPageComponent } from '@admin.dashboard/admin-dashboard.page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppsTableComponent } from '@apps/components/apps-table/apps-table.component';
import { MyAppsComponent } from '@apps/components/my-apps/my-apps.component';
import { AppDetailsComponent } from '@apps/components/my-apps/new-app/details/details.component';
import { AppIapComponent } from '@apps/components/my-apps/new-app/iap/iap.component';
import { AppLocalesComponent } from '@apps/components/my-apps/new-app/locales/locales.component';
import { AppNewAppComponent } from '@apps/components/my-apps/new-app/new-app.component';
import { AppPackagesComponent } from '@apps/components/my-apps/new-app/packages/packages.component';
import { UsersComponent } from '@apps/components/users/users.component';
import { AppImageryComponent } from '@apps/containers/imagery/imagery.component';
import { AreasOfInterestComponent } from '@auth/components/create-account/areas-of-interest/areas-of-interest.component';
import { CreateAccountComponent } from '@auth/components/create-account/create-account.component';
import { DistributionAgreementComponent } from '@auth/components/create-account/distribution-agreement/distribution-agreement.component';
import { EulaComponent } from '@auth/components/create-account/eula/eula.component';
import { GetStartedComponent } from '@auth/components/create-account/get-started/get-started.component';
import { TermsAndConditionsComponent } from '@auth/components/create-account/terms-and-conditions/terms-and-conditions.component';
import { ValidateEmailComponent } from '@auth/components/create-account/validate-email/validate-email.component';
import { YourDetailsComponent } from '@auth/components/create-account/your-details/your-details.component';
import { ForgotPasswordComponent } from '@auth/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@auth/components/forgot-password/reset-password.component';
import { LoginComponent } from '@auth/components/login/login.component';
import { OauthLoginComponent } from '@auth/components/oauth-login/oauth-login.component';
import { AppLogoutComponent } from '@common/components/logout/logout.component';
import { NavigationGuard } from '@common/components/navigation-guard/navigation-guard.component';
import { AccountComponent } from '@core/components/account/account.component';
import { ContactUsComponent } from '@core/components/contact-us/contact-us.component';
import { DiscourseForumRedirectComponent } from '@core/components/discourse-forum-redirect/discourse-forum-redirect.component';
import { FaqComponent } from '@core/components/faq/faq.component';
import { MainComponent } from '@core/components/main/main.component';
import { MyAccountComponent } from '@core/components/my-account/my-account.component';
import { PublishingComponent } from '@core/components/publishing/publishing.component';
import { ResourcesComponent } from '@core/components/resources/resources.component';
import { ToolsHelpComponent } from '@core/components/tools-help/tools-help.component';
import { ToolsDocumentationComponent } from '@core/containers/tools-documentation/tools-documentation.component';
import { DashboardPageComponent } from '@dashboard/dashboard.page.component';
import { AppPaymentByUserIdResolver } from '@guards/admin-payment-by-user-id.resolver';
import { AppAdminUserResolver } from '@guards/admin-user.resolver';
import { AdminGuard } from '@guards/admin.guard';
import { AppAppByUserIDResolver } from '@guards/app-by-user-id.resolver';
import { AppDetailsChangesResolver } from '@guards/app-details-changes.resolver';
import { AppDetailsResolver } from '@guards/app-details.resolver';
import { AppListsResolver } from '@guards/app-lists.resolver';
import { AppGuard } from '@guards/app.guard';
import { AuthGuard } from '@guards/auth-guard.service';
import { AppHeldAdminPaymentListResolver } from '@guards/payment-admin-held-list.resolver';
import { PaymentAdminListResolver } from '@guards/payment-admin-list.resolver';
import { CanDeactivateGuard } from '@guards/publishing-details-deactivate.guard';
import { ReadonlyResolver } from '@guards/readonly.resolver';
import { PartnersPageComponent } from '@partners/partners.page.component';
import { DistributionComponent } from '@payments/components/distribution/distribution.component';
import { PaymentComponent } from '@payments/components/payment/payment.component';
import { PublishingDetailsComponent } from '@stripe/containers/publishing-details/publishing-details.component';
import { AppEditComponent } from '@apps/components/my-apps/app-edit/app-edit.component';
import { AppPermissionResolver } from '@guards/app-permission.resolver';
import { PubDetailsComponent } from '@stripe/containers/publishing-details/pub-details.component';
import { PubDetailsResolver } from '@stripe/containers/publishing-details/pub-details.resolver';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin',
        component: AdminDashboardPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'documentation',
        component: ToolsDocumentationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        component: DashboardPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'my-apps',
        component: MyAppsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-apps',
        component: MyAppsComponent
      },
      {
        path: 'users',
        resolve: [AdminGuard],
        component: UsersComponent
      },
      {
        path: 'users/:id',
        component: PartnersPageComponent,
        resolve: {
          user: AppAdminUserResolver,
          apps: AppAppByUserIDResolver,
          payments: AppPaymentByUserIdResolver
        },
        children: [
          {
            path: '',
            redirectTo: 'details',
            pathMatch: 'full'
          },
          {
            path: 'details',
            component: AccountComponent,
          },
          {
            path: 'apps',
            component: AppsTableComponent
          },
          {
            path: 'revenue',
            component: PaymentComponent
          }
        ]
      },
      {
        path: 'account',
        component: AccountComponent
      },
      {
        path: 'account/:id',
        component: AccountComponent
      },
      {
        path: 'my-account',
        component: MyAccountComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'account',
            component: AccountComponent
          },
          {
            path: 'publishing-details',
            component: PubDetailsComponent,
            // component: PublishingDetailsComponent,
            canDeactivate: [NavigationGuard],
            resolve: { preload: PubDetailsResolver },
          }
        ]
      },
      {
        path: 'resources',
        component: ResourcesComponent,
        children: [
          {
            path: '',
            redirectTo: 'publishing',
            pathMatch: 'full'
          },
          {
            path: 'faq',
            component: FaqComponent
          },
          {
            path: 'publishing',
            component: PublishingComponent
          }
        ]
      },
      {
        path: 'tools',
        component: ToolsHelpComponent,
        children: [
          {
            path: '',
            redirectTo: 'faq',
            pathMatch: 'full'
          },
          {
            path: 'documentation',
            component: ToolsDocumentationComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'faq',
            component: FaqComponent
          },
          {
            path: 'contact-us',
            component: ContactUsComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'revenue-payments',
        component: PaymentComponent
      },
      {
        path: 'distribution',
        component: DistributionComponent,
        resolve: {
          payments: PaymentAdminListResolver,
          heldPayments: AppHeldAdminPaymentListResolver
        }
      },
      {
        path: 'forum',
        component: DiscourseForumRedirectComponent
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: AppLogoutComponent,
  },
  {
    path: 'oauth',
    component: OauthLoginComponent
  },
  {
    path: '',
    redirectTo: 'main/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'resources',
    redirectTo: 'resources/faq',
    pathMatch: 'full'
  },
  {
    // This hsould be it's own auth.routing.module.ts
    path: 'register',
    component: CreateAccountComponent,
    children: [
      {
        path: '',
        redirectTo: 'get-started',
        pathMatch: 'full'
      },
      {
        path: 'get-started',
        component: GetStartedComponent
      },
      {
        path: 'validate-email',
        component: ValidateEmailComponent
      },
      {
        path: 'your-details',
        component: YourDetailsComponent
      },
      {
        path: 'areas-of-interest',
        component: AreasOfInterestComponent
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent
      },
      {
        path: 'eula',
        component: EulaComponent
      },
      {
        path: 'distribution-agreement',
        component: DistributionAgreementComponent
      }
    ]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent
  },
  {
    path: 'app/editapp/:id/:productVersionId',
    canActivate: [AuthGuard, AppGuard],
    canDeactivate: [NavigationGuard],
    component: AppEditComponent,
    resolve: {
      appDetails: AppDetailsResolver,
      appChanges: AppDetailsChangesResolver,
      appPermissions: AppPermissionResolver,
      readOnly: ReadonlyResolver,
      lists: AppListsResolver,
    }
  },
  {
    path: 'app/edit/:id/:productVersionId',
    canActivate: [AuthGuard, AppGuard],
    component: AppNewAppComponent,
    resolve: {
      appDetails: AppDetailsResolver,
      appChanges: AppDetailsChangesResolver,
    },
    children: [
      {
        path: '',
        redirectTo: 'locales',
        pathMatch: 'full'
      },
      {
        path: 'locales',
        component: AppLocalesComponent,
        resolve: {
          readOnly: ReadonlyResolver,
          lists: AppListsResolver
        },
        canDeactivate: [NavigationGuard],
      },
      {
        path: 'details',
        component: AppDetailsComponent,
        resolve: {
          readOnly: ReadonlyResolver,
          lists: AppListsResolver
        },
        canDeactivate: [NavigationGuard],
      },
      {
        path: 'imagery',
        component: AppImageryComponent,
        resolve: {
          readOnly: ReadonlyResolver,
        }
      },
      {
        path: 'iap',
        component: AppIapComponent,
        resolve: {
          readOnly: ReadonlyResolver,
          lists: AppListsResolver
        },
        canDeactivate: [NavigationGuard],
      },
      {
        path: 'packages',
        component: AppPackagesComponent,
        resolve: {
          readOnly: ReadonlyResolver,
          lists: AppListsResolver
        },
        canDeactivate: [NavigationGuard],
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled'
    })
  ],
  providers: [
    AdminGuard,
    AppGuard,
    ReadonlyResolver,
    AppDetailsResolver,
    AppDetailsChangesResolver,
    AppListsResolver,
    PaymentAdminListResolver,
    AppHeldAdminPaymentListResolver,
    AppAppByUserIDResolver,
    AppAdminUserResolver,
    AppPaymentByUserIdResolver,
    CanDeactivateGuard,
    AppPermissionResolver,
    PubDetailsResolver,
    // PublishingDetailsCanDeactivateGuard,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
