import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren, } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { dialogButtons, MessageDialogServiceImpl, MessageDialogServiceToken, } from '@common/components/confimation-modal/dialog.service';
import { ComponentCommService } from '@services/component-comm.service';
import { AppService } from 'app/services/app.service';
import { UtilService } from 'app/services/util.service';
import { Observable, Subject } from 'rxjs';
import { map, pluck, takeUntil, } from 'rxjs/operators';
import { AppDetailsResolver } from '../../../../../guards/app-details.resolver';
import { App } from '../../../../../models/app';
import { GridPanelComponent } from '@swimlane/ngx-charts';

export const appLocaleWorldWide = "WW"
export const localeCN = "zh_CN";
export const localeUS = "en_US";

@Component({
  selector: 'app-edit-locales',
  templateUrl: './app-edit-locales.component.html',
  styleUrls: ['./app-edit-locales.component.scss'],
  providers: [AsyncPipe]
})
export class AppEditLocalesComponent
implements OnInit, OnDestroy {

    @Input() readOnly = false;
    @Input() app: App;
    @Input() formGroup: FormGroup;
    @Input() lists: any;
    @Input() priceReadOnly = false;

    @Output() nextClick: EventEmitter<any> = new EventEmitter();

    saveClicked = false;

  locales: any = null;
  allPossibleLocales;
  onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected svcResolver: AppDetailsResolver,
    private pipeAsync: AsyncPipe,
    private util: UtilService,
    private appService: AppService,
    private commService: ComponentCommService,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {
  }

    localeFieldChange(locale: string, fieldName: string, value: any) {
        const appPrice: any = this.app.prices.find(item => item.locale === locale);
        if (!appPrice) {
            const price = {
                productId: this.app.id,
                locale: locale,
                price: null,
                ageRating: null,
            };
            price[fieldName] = value;
            this.app.prices.push(price);
        } else {
            appPrice[fieldName] = value;
        }
    }

    localeSelectionChange(locale: string, localeGroup: FormGroup, value: boolean) {
        const appPrice: any = this.app.prices.find(item => item.locale === locale);
        const localeIndex = this.app.locales.indexOf(locale);
        if (value) {
            if (localeIndex < 0) {
                this.app.locales.push(locale)
            }
            if (!appPrice) {
                const price = {
                    productId: this.app.id,
                    locale: locale,
                    price: this.priceReadOnly?0:null,
                    ageRating: null,
                };
                this.app.prices.push(price);
                localeGroup.get('ageRating').setValue(null);
            }
            if (locale === appLocaleWorldWide) {
                this.app.worldwidePrice = true;
            }
            if (this.priceReadOnly) {
                localeGroup.get("price").setValue(0);
            }
        } else {
            if (localeIndex >= 0) {
                this.app.locales.splice(localeIndex, 1);
            }
            if (appPrice) {
                this.app.prices.splice(this.app.prices.indexOf(appPrice), 1);
                localeGroup.get('price').setValue(null);
                localeGroup.get('ageRating').setValue(null);
            }
            if (locale === appLocaleWorldWide) {
                this.app.worldwidePrice = false;
            }
        }
        localeGroup.get('price').updateValueAndValidity();
        localeGroup.get('ageRating').updateValueAndValidity();

        this.setDefaultLocale(locale);
    }

    setDefaultLocale(locale: string) {
        if (locale !== localeUS) {
            const cnChecked = this.app.locales.includes(localeCN);
            const group: FormGroup = (this.formGroup.controls[localeUS] as FormGroup);

            if (!this.app.locales.includes(localeUS) && !cnChecked) {
                group.get('localeSelected').setValue(true);
                this.localeSelectionChange(localeUS, group, true)
            }

            if (cnChecked) {
                group.get('localeSelected').enable();
            } else {
                group.get('localeSelected').disable();
            }
        }
    }

    ngOnInit() {
      this.allPossibleLocales = this.lists.locales;
      Object.keys(this.formGroup.controls).forEach(ctrlName => {
        if (this.formGroup.controls[ctrlName] instanceof FormGroup) {
            const group: FormGroup = (this.formGroup.controls[ctrlName] as FormGroup);
            group.get("price")
                .valueChanges
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(val => this.localeFieldChange(ctrlName, "price", val));
            group.get("ageRating")
                .valueChanges
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(val => this.localeFieldChange(ctrlName, "ageRating", val));
            group.get("localeSelected")
                .valueChanges
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(val => this.localeSelectionChange(ctrlName, group, val));
        }
    });
    this.setDefaultLocale(null);
}

  get getWorldwide() {
    return {
      locale: appLocaleWorldWide,
      country_name: 'Worldwide'
    };
  }

    ngOnDestroy(){
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    nextClicked() {
        this.nextClick.emit('details');
    }
}
