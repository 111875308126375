import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const RADIO_BUTTON_VALUE_ACCESOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioButtonSetComponent),
  multi: true
};

export interface RadioButtonOption {
  name: string;
  value: any;
  selected?: boolean;
}

@Component({
  selector: 'app-radio-button-set',
  templateUrl: './radio-button-set.component.html',
  styleUrls: ['./radio-button-set.component.scss'],
  providers: [RADIO_BUTTON_VALUE_ACCESOR]
})
export class RadioButtonSetComponent implements ControlValueAccessor {
  @Input()
  isWhite = false;
  @Input()
  label = '';
  @Input()
  readOnly = false;
  private _selectedOption: RadioButtonOption;
  get selectedOption() {
    return this._selectedOption;
  }
  @Input()
  set selectedOption(option: RadioButtonOption) {
    if (!!option) {
      this._selectedOption = option;
    }
  }
  @Output()
  selectedOptionChange = new EventEmitter<RadioButtonOption>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onClick = new EventEmitter<MouseEvent>();
  @ViewChildren('img')
  radioOptions: QueryList<ElementRef>;

  onChange: (value) => {};
  onTouched: () => {};

  private _options: RadioButtonOption[];
  get options() {
    return this._options;
  }
  @Input()
  set options(options: RadioButtonOption[]) {
    if (!!options && options.length > 0) {
      this._options = options;
      if (!!this.selectedOption) {
        this.selectOption(this.selectedOption);
      }
    }
  }

  private _value: any;
  get value() {
    return this._value;
  }
  set value(value) {
    this._value = value;
    this.notifyValueChange(value);
  }

  selectOption(option: RadioButtonOption) {
    if (!!this.options) {
      if (!!option) {
        this.deselectAllOptions();
        const toChange = this.options.find(op => op.name === option.name);
        if (!!toChange) {
          toChange.selected = true;
          this.value = toChange.value;
          // this.selectedOptionChange.emit(toChange);
        } else {
          throw new Error(
            `[RadioButtonSetComponent] -- Unknown Option ${option}`
          );
        }
      }
    }
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    if (value !== this._value) {
      if (
        value !== null &&
        value !== undefined &&
        !!this.options &&
        this.options.length > 0
      ) {
        this.selectOption(this.options.find(op => op.value === value));
      }
      // this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onRadioSelection(option: RadioButtonOption) {
    if (!this.readOnly) {
      this.selectOption(option);
      // this.value = option.value;
      // this.onClick.emit(ev);
      this.selectedOptionChange.emit(option);
    }
  }

  deselectAllOptions() {
    this._options = this.options
      ? this.options.map(item => ({ ...item, selected: false }))
      : null;
  }
}
