export enum EPubDetailAccountTypes {
    company = 'company',
    individual = 'individual',
}

export interface IPubDetailOwner         {
    "first_name": string,
    "last_name": string,
    "phone": string,
    "email": string,
    "id_number": string,
    "dob": {
        "day": string,
        "month": string,
        "year": string
    },
    "address": {
        "city": string,
        "country": string,
        "state": string,
        "line1": string,
        "line2": string,
        "postal_code": string
    },
    "relationship": {
        "account_opener"?: boolean,
        "director"?: boolean;
        "executive"?: boolean;
        "owner"?: boolean;
    },
    "verification": {
        "document": {
            "front": string,
            "back": string
        }
    }
}



export interface IPubDetailModel {
    "bank_account": {
        "country": string;
        "account_number": string;
        "routing_number": string;
        "iban": string;
        "bsb": string;
        "sortCode": string;
        "bankCode": string;
        "branchCode": string
    },
    "stripe_account": {
        "country": string;
        "email": string;
        "business_type": EPubDetailAccountTypes;
        "business_profile": {
            "support_email": string;
            "support_phone": string;
            "url": string
        },
        "company": {
            "phone": string;
            "name": string;
            "tax_id": string;
            "address": {
                "city": string;
                "country": string;
                "state": string;
                "line1": string;
                "line2": string;
                "postal_code": string
            }
        },
        "individual": {
            "first_name": string;
            "last_name": string;
            "phone": string;
            "email": string;
            "id_number": string;
            "dob": {
                "day": string;
                "month": string;
                "year": string
            },
            "address": {
                "city": string;
                "country": string;
                "state": string;
                "line1": string;
                "line2": string;
                "postal_code": string
            },
            "verification": {
                "document": {
                    "front": string;
                    "back": string
                }
            }
        },
        "tos_acceptance": {
            "ip": string;
            "date": string
        }
    },
    "owners": IPubDetailOwner[]
}

export const PubDetailOwnerDefault: IPubDetailOwner = {
    "first_name": null,
    "last_name": null,
    "phone": null,
    "email": null,
    "id_number": null,
    "dob": {
        "day": null,
        "month": null,
        "year": null
    },
    "address": {
        "city": null,
        "country": null,
        "state": null,
        "line1": null,
        "line2": null,
        "postal_code": null
    },
    "relationship": {
        "account_opener": false,
        "director": false,
        "executive": false,
        "owner": false,
    },
    "verification": {
        "document": {
            "front": null,
            "back": null
        }
    }
}



export const PubDetailModelDefault: IPubDetailModel = {
    "bank_account": {
        "country": null,
        "account_number": null,
        "routing_number": null,
        "iban": null,
        "bsb": null,
        "sortCode": null,
        "bankCode": null,
        "branchCode": null
    },
    "stripe_account": {
        "country": null,
        "email": null,
        "business_type": EPubDetailAccountTypes.individual,
        "business_profile": {
            "support_email": null,
            "support_phone": null,
            "url": null
        },
        "company": {
            "phone": null,
            "name": null,
            "tax_id": null,
            "address": {
                "city": null,
                "country": null,
                "state": null,
                "line1": null,
                "line2": null,
                "postal_code": null
            }
        },
        "individual": {
            "first_name": null,
            "last_name": null,
            "phone": null,
            "email": null,
            "id_number": null,
            "dob": {
                "day": null,
                "month": null,
                "year": null
            },
            "address": {
                "city": null,
                "country": null,
                "state": null,
                "line1": null,
                "line2": null,
                "postal_code": null
            },
            "verification": {
                "document": {
                    "front": null,
                    "back": null
                }
            }
        },
        "tos_acceptance": {
            "ip": null,
            "date": null
        }
    },
    "owners": [
    ]
}