import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { leiaEndpointToken } from 'endpoints';
import { UserService } from './user.service';

@Injectable()
export class SubmitAppService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    @Inject(leiaEndpointToken) private leiaEndpoints,
  ) {}

  submitApp(data) {
    const user = this.userService.getUser();
    // Upload file
    const uploadUrl =
      this.leiaEndpoints.apps.upload.url + data.title + '/';
    const formData: FormData = new FormData();
    formData.append('uploaded_data', data.file, data.file.name);
    this.http.post(uploadUrl + data.file.name, formData).subscribe(
      data => {
        console.log('Success...');
      },
      error => {
        console.log('Error...');
        console.log(error);
      }
    );
    // Upload metadata as a text file
    const metadata = JSON.stringify({
      email: user.emailAddress,
      name: user.firstName + ' ' + user.lastName,
      company: user.companyName,
      title: data.title,
      notes: data.notes,
      filename: data.file.name
    });
    const textFileName = data.title + '-metadata.txt';
    const blob = new Blob([metadata], { type: 'plain/text' });
    const metaForm: FormData = new FormData();
    metaForm.append('uploaded_data', blob, textFileName);
    this.http.post(uploadUrl + textFileName, metaForm).subscribe(
      data => {
        console.log('Success text file...');
      },
      error => {
        console.log('Error text file...');
        console.log(error);
      }
    );
  }
}
