import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariableAlertModalComponent } from 'app/common.module/components/variable-alert-modal/variable-alert-modal.component';
import { ErrorProviderLeaf } from 'app/models/error-provider.model';
import { leiaEndpointToken } from 'endpoints';
import { Observable, Subject } from 'rxjs';
import { flatMap, pluck, takeUntil } from 'rxjs/operators';
import { AppDetailsResolver } from '../../../guards/app-details.resolver';
import { App, AppDetailsChanges } from '../../../models/app';
import { AppService } from '../../../services/app.service';
import { UserService } from '../../../services/user.service';
import { UtilService } from '../../../services/util.service';

type UploaderImage =
  | 'cover'
  | 'thumbnail'
  | 'promoVideo'
  | 'promoVideoPreview'
  | 'screenshots0'
  | 'screenshots1'
  | 'screenshots2'
  | 'screenshots3'
  | 'screenshots4'
  | '';

@Component({
  selector: 'app-imagery',
  templateUrl: './imagery.component.html',
  styleUrls: ['./imagery.component.scss'],
  providers: [AsyncPipe]
})
export class AppImageryComponent implements OnInit, ErrorProviderLeaf {
  @ViewChild('uploader')
  uploader;

  @ViewChild('uploaderPng')
  uploaderPng;

  @ViewChild('uploaderMp4')
  uploaderMp4;

  @ViewChild(VariableAlertModalComponent)
  alertModal: VariableAlertModalComponent;

  @Input()
  readOnly = false;

  onDestroy$: Subject<boolean> = new Subject();
  isAdmin = false;
  fileExtensionError = false;
  imageDimensionsError = false;
  isDownloadTypeDisabled: any = {};
  dropHoverColor = "#eee";
  private _dirty = false;
  private _dirtyThumbnail = false;
  private _dirtyChannelImage = false;
  private _dirtyCoverImage = false;
  private _dirtyScreenshot = false;

  private _navigatingAway = false;
  set navigatingAway(value) {
    this._navigatingAway = value;
  }
  get thumbnailError() {
    return (
      (this._navigatingAway && !this._dirtyThumbnail && !this.app.thumbnail) ||
      (this._dirtyThumbnail && !this.app.thumbnail)
    );
  }
  get coverError() {
    return (
      (this._navigatingAway && !this._dirtyCoverImage && !this.app.cover) || (this._dirtyCoverImage && !this.app.cover)
    );
  }
  get routeURL() {
    return this.pipeAsync.transform(this.route.url);
  }
  get screenShotError() {
    return (
      (this._navigatingAway && !this._dirtyScreenshot && this.app.screenshots && this.app.screenshots.length < 3) ||
      (this._dirtyScreenshot && this.app.screenshots && this.app.screenshots.length < 3)
    );
  }
  get error() {
    return (
      this.fileExtensionError ||
      this.imageDimensionsError ||
      this.thumbnailError ||
      this.screenShotError ||
      this.coverError
    );
  }
  app$: Observable<App> = this.route.parent.data.pipe(pluck('appDetails'));
  app: App = this.pipeAsync.transform(this.app$);
  appChanges$: Observable<AppDetailsChanges[]> = this.route.parent.data.pipe(pluck('appChanges'));
  appChanges: AppDetailsChanges[];

  file: any;
  url: string = null;
  uploaderImageType: UploaderImage = '';

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private userService: UserService,
    protected svcResolver: AppDetailsResolver,
    protected svcUtil: UtilService,
    protected cdr: ChangeDetectorRef,
    protected pipeAsync: AsyncPipe,
    @Inject(leiaEndpointToken) private leiaEndpoints,
  ) {
    this.isAdmin = userService.isAdmin();
  }

  navigationStart() {
    this.navigatingAway = true;
    this.appService.navigateAway(true);
  }

  ngOnInit() {
    const { readOnly } = this.route.snapshot.data;

    this.readOnly = readOnly && this.isAdmin;

    this.appChanges$.pipe(takeUntil(this.onDestroy$)).subscribe((appChanges: AppDetailsChanges[]) => {
      this.appChanges = appChanges;
    });
  }

  setDirtyStatus(imageType: UploaderImage) {
    switch (imageType) {
      case 'screenshots0' || 'screenshots1' || 'screenshots2' || 'screenshots3' || 'screenshots4':
        this._dirtyScreenshot = true;
        break;
      case 'thumbnail':
        this._dirtyThumbnail = true;
        break;
      case 'cover':
        this._dirtyCoverImage = true;
        break;
    }
  }

  dropFile(imageType: UploaderImage, files: FileList) {
    if (this.readOnly) {
      return;
    }

    this.setDirtyStatus(imageType);
    this.uploaderImageType = imageType;

    switch (imageType) {
        case "thumbnail": {
          this.uploaderPng.nativeElement.files = files;
          this.uploaderPng.nativeElement.dispatchEvent(new Event("change"));
          break;
        }
        case "cover":
        case "promoVideoPreview" :
        case "screenshots0" :
        case "screenshots1" :
        case "screenshots2" :
        case "screenshots3" :
        case "screenshots4" : {
          this.uploader.nativeElement.files = files;
          this.uploader.nativeElement.dispatchEvent(new Event("change"));
          break;
        }
        case "promoVideo" : {
          this.uploaderMp4.nativeElement.files = files;
          this.uploaderMp4.nativeElement.dispatchEvent(new Event("change"));
          break;
        }
      }
  }

  uploaderClick(imageType: UploaderImage) {
    if (this.readOnly) {
      return;
    }
    this.setDirtyStatus(imageType);
    this.uploaderImageType = imageType;
    this.uploader.nativeElement.click();
  }

  uploaderPngClick(imageType: UploaderImage) {
    if (this.readOnly) {
      return;
    }

    this.setDirtyStatus(imageType);
    this.uploaderImageType = imageType;
    this.uploaderPng.nativeElement.click();
  }

  uploaderVideoClick() {
    if (this.readOnly) {
      return;
    }
    this.uploaderMp4.nativeElement.click();
  }

  uploadImage($event) {
    if (this.readOnly) {
      return;
    }
    // TODO: Needs to use image uploader to AWS (similar to old submission apps)
    this.file = this.svcUtil.first($event.target.files);

    if ($event.target.files && this.file && this.uploaderImageType.length > 0) {
      const reader = new FileReader();

      const filesize = this.file.size / 1024 / 1024;

      this.fileExtensionError = this.checkExtensionsError();
      if (!this.fileExtensionError) {
        reader.onload = (event: any) => {
          const image = new Image();
          image.onload = () => {
            this.imageDimensionsError = this.checkDimensionImageError(image.height, image.width);
            if (!this.imageDimensionsError) {
              this.uploadNewImage(this.file, this.uploaderImageType);
            }
          };
          image.src = event.target.result;
        };
        reader.readAsDataURL(this.file);
      }
    }
  }

  checkExtensionsError() {
    switch (this.uploaderImageType) {
      case 'thumbnail':
        if (/\.(png)$/i.test(this.file.name) === false) {
          this.alertModal.showAlert('The file must be a PNG');
          return true;
        }
        break;
      case 'cover':
      case 'promoVideoPreview':
      case 'screenshots0':
      case 'screenshots1':
      case 'screenshots2':
      case 'screenshots3':
      case 'screenshots4':
        if (
          /\.(png)$/i.test(this.file.name) === false &&
          /\.(jpg)$/i.test(this.file.name) === false &&
          /\.(jpeg)$/i.test(this.file.name) === false
        ) {
          this.alertModal.showAlert('The file must be a PNG or JPG');
          return true;
        }
        break;
      case 'promoVideo':
        if (/\.(mp4)$/i.test(this.file.name) === false) {
          this.alertModal.showAlert('The file must be a MP4');
          return true;
        }
        break;
    }

    return false;
  }

  checkDimensionImageError(height: number, width: number) {
    switch (this.uploaderImageType) {
      case 'thumbnail':
        if (width !== 512 || height !== 512) {
          this.alertModal.showAlert('The App Icon must be 512x512px');
          return true;
        }
        break;

        case 'cover':
        if (width !== 480 || height !== 384) {
          this.alertModal.showAlert('The Hero image must be 480x384px');
          return true;
        }
        break;

      case 'promoVideoPreview':
        if ((height === 360 && width === 640) || (height === 640 && width === 360)) {
          return false;
        } else {
          this.alertModal.showAlert('The promotional video image must be 360x640px or 640x360px');
          return true;
        }
      case 'screenshots0':
      case 'screenshots1':
      case 'screenshots2':
      case 'screenshots3':
      case 'screenshots4':
        if (
          (height === 360 && width === 640) ||
          (height === 640 && width === 360) ||
          (height === 1280 && width === 720) ||
          (height === 720 && width === 1280)
        ) {
          return false;
        } else {
          this.alertModal.showAlert(
            'The screenshots must be min 360x640px or 640x360px, max 720x1280px or 1280x720px '
          );
          return true;
        }
    }

    return false;
  }

  uploadVideo($event) {
    if (this.readOnly) {
      return;
    }
    // TODO: Needs to use image uploader to AWS (similar to old submission apps)
    if ($event.target.files && $event.target.files[0]) {
      const reader = new FileReader();

      this.file = $event.target.files[0];
      const filesize = this.file.size / 1024 / 1024;

      if (/\.(mp4)$/i.test(this.file.name) === false) {
        this.alertModal.showAlert('The file must be a MP4');
      } else {
        reader.onload = (event: any) => {
          const videoTitle = ($event && $event.target && $event.target.files && $event.target.files.length)
            ?$event.target.files[0].name:"";
          this.uploaderImageType = 'promoVideo';
          this.uploadNewImage($event.target.files[0], this.uploaderImageType);
          this.app[this.uploaderImageType] = null;
          this.app.promoVideoTitle = videoTitle;
        };
        reader.readAsDataURL($event.target.files[0]);
      }
    }
  }

  uploadNewImage(data, uploaderImageType: UploaderImage) {
    if (this.readOnly) {
      return;
    }

    const type = uploaderImageType.includes("screenshots")?"screenshot":uploaderImageType;

    this.uploaderPng.nativeElement.value = '';
    this.uploaderMp4.nativeElement.value = '';
    this.uploader.nativeElement.value = '';

    console.log(data);

    if (this.app && this.app.id) {
      let screenshotNum: number = null;

      let httpCall: Observable<any> = this.appService.uploadToimagery(this.app, type, data);
      this.isDownloadTypeDisabled[uploaderImageType] = true;
      if (uploaderImageType.includes('screenshots')) {
        screenshotNum = Number(uploaderImageType[uploaderImageType.length - 1]);
        if (isNaN(screenshotNum) || screenshotNum === null) {
          console.log("Error: AppImageryComponent.uploadNewImage(): incorrect upload type/screenshot number.");
          this.isDownloadTypeDisabled[uploaderImageType] = false;
          return;
        }
        if (this.app.screenshots[screenshotNum]) {
          httpCall = this.appService.deleteScreenshoot(this.app, screenshotNum).pipe(
            flatMap((res)=>{
              this.app.screenshots[screenshotNum].url = null;
              return this.appService.uploadToimagery(this.app, type, data);
            })
          );
        } else {
          const screenshot = {
            product_id: this.app.id,
            url: null,
          };
          this.app.screenshots[screenshotNum] = screenshot;
        }
      } else {
        this.app[uploaderImageType] = null;
      }
      httpCall.subscribe(
        (res: any) => {
          console.log('Success upload of image...');
          console.log(res);
          if (res && res.result && res.result.Location) {
            if (type === "screenshot" && (screenshotNum !== null) && this.app.screenshots && this.app.screenshots[screenshotNum]) {
              this.app.screenshots[screenshotNum].url = res.result.Location;
              this.app.screenshots[screenshotNum].screenshotId = res.screenshotId;
            } else {
              this.app[type] = res.result.Location;
            }
            this.isDownloadTypeDisabled[uploaderImageType] = false;
          }
        },
        error => {
            this.isDownloadTypeDisabled[uploaderImageType] = false;
            console.log('Error...');
            console.log(error);
        }
      );
    }
  }

  deleteImage(type) {
    this.appService.deleteImage(this.app, type).subscribe(r => {
      switch (type) {
        case 'thumbnail':
          this.app.thumbnail = '';
          break;
        case 'cover':
          this.app.cover = '';
          break;
        case 'promoVideoPreview':
          this.app.promoVideoPreview = '';
          break;
        case 'promoVideo':
          this.app.promoVideo = '';
          this.app.promoVideoTitle = '';
          break;
      }
    });
  }

  deleteScreenshot(screenshotId) {
    this.appService.deleteScreenshoot(this.app, screenshotId).subscribe(r => {
      this.app.screenshots.splice(screenshotId, 1);
    });
  }

  downloadImage(image) {
    if (image) {
      window.location.href = image;
    }
  }

  dropErrorDialog(errMsg: string) {
    this.alertModal.showAlert(errMsg);
  }

  checkChanges(title: string) {
    return this.appChanges ? this.appChanges.some(chg => chg.fieldName === title) : false;
  }
}
