import { Component, HostBinding, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { VariableAlertModalComponent } from 'app/common.module/components/variable-alert-modal/variable-alert-modal.component';
import { ContactService } from '../../../services/contact.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  providers: [ContactService]
})
export class ContactUsComponent {
  @ViewChild(VariableAlertModalComponent)
  alert: VariableAlertModalComponent;
  @ViewChild('textArea', { read: NgModel })
  messageInput: NgModel;

  message: string;

  @HostBinding('class') classes = 'container-fluid';

  constructor(private contactService: ContactService) {}

  contactUs() {
    this.contactService.contactUsEmail(this.message).subscribe(
      res => {
        this.alert.headerText = 'Success';
        this.alert.bodyText =
          'Your message has been received. We’ll get back to you shortly.';
        this.alert.modal.show();
        this.messageInput.reset();
      },
      error => {
        this.alert.headerText = 'Error';
        this.alert.bodyText = 'Error sending e-mail.';
        this.alert.modal.show();
      }
    );
  }
}
