import { Component } from '@angular/core';

@Component({
  selector: 'app-discourse-forum-redirect',
  templateUrl: './discourse-forum-redirect.component.html',
  styleUrls: ['./discourse-forum-redirect.component.scss']
})
export class DiscourseForumRedirectComponent {
  handleRedirect(link: string) {
    if (!!link) {
      window.open(link, '_blank');
    }
  }
}
