export const statusMissingDialogText = `
    <div>
        <div>
            It looks like you are Submitting either a Paid App and/or your App has In-App Purchases.
            In order to continue, we need some banking information in order to facilitate payment.
        </div>

        <div>
            Don't worry. You can complete Submitting your App after your Publishing Details Profile
            is complete. Just return to the <b>My Apps</b> page after your Publishing Details Profile
            is complete and click on your Unsubmitted App to complete your submission.
        </div>

        <div>
            Click <b>Publishing Details</b> below, to complete your Publishing Details Profile.
        </div>
    </div>
`;

export const statusUnverifiedDialogText = `
    <div>
        <div>
            We're still verifying the Publishing Details that were provided in your application.
            This process can take anywhere from 24-48 hours from the time of submission.
            If you are Submitting either a Paid App and/or your App has In-App Purchases, we need
            to complete the verification process before you can continue.
        </div>

        <div>
            You should receive an email as soon as your details are verified.
            If you need immediate assistance, please feel free to contact us at help@leiainc.com
        </div>

        <div>
            If your app will be available for free in all regions and/or will not feature
            In-App Purchases, please click Continue to proceed with your App Submission.
            You'll be able to submit Premium apps once your Publishing Details are fully verified.
        </div>
    </div>
`;


export const statusRejectedDialogText = `
    <div>
        <div>
            It looks like we ran into some trouble verifying the details you've provided in your
            R|LL Publishing Details Profile Application. These things happen from time to time as
            we want to provide the highest level of security possible across the R|LL Platform.
            If you are Submitting either a Paid App and/or your App has In-App Purchases,
            we need to complete the verification process before you can continue.
        </div>

        <div>
            While we'll be reaching out shortly to follow up and will try to resolve any issues as soon
            as possible, please feel free to contact <b>help@leiainc.com</b>
            if you have any immediate questions.
        </div>

        <div>
            Don't worry, if your app will be Free in all Regions and will not have In-App Purchases,
            please click <b>Continue</b> to proceed with your App Submission. You'll be able to submit
            Premium apps as soon your Publishing Details Profile is fully verified.
        </div>
    </div>
`;


export const successZeroPrices = `
    We adjusted your submission so all prices are set to Zero and IAPs have been marked as inactive.<br/>
    Please review and resubmit.<br/>
    <br/>
    Don't worry, after your Publishing Details are confirmed, you can modify prices and IAPs
`;