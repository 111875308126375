import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { leiaEndpointToken } from 'endpoints';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Moment } from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { DashboardServerReponse } from '../models/dashboard-page-data.model';
import { LineChartDataElement } from '../models/line-chart-data-element.model';
import { SummaryItemModel } from '../models/summary-item.model';
import { AppService } from './app.service';
import { UtilService } from './util.service';
import { DownloadListType } from '@enums/download-list-type.enum';

export const NOT_ENOUGH_DATA = {
  // name: 'Not Enough Data',
  name: 'N/A',
  value: 1
};
export interface CalendarPoint {
  date: string; // ISO YYYY-MM-DD
  value: number;
}

@Injectable()
export class DashboardService {
  private ary = new Array<Observable<SummaryItemModel[]>>();
  now = moment(new Date());
  dashboardData: DashboardServerReponse;

  constructor(
    private svcApp: AppService,
    private http: HttpClient,
    private svcUtil: UtilService,
    @Inject(leiaEndpointToken) private leiaEndpoints,
  ) {}

  mapServerResponse(appName: string, input: CalendarPoint[]): LineChartDataElement[] {
    return [
      {
        name: appName,
        series: input.map<{ name: Date; value: number }>(item => {
          return {
            name: moment(item.date).toDate(),
            value: item.value
          };
        })
      }
    ];
  }

  lineChartDataSum(data: LineChartDataElement[]) {
    if (data && data.length > 0) {
      return data.map(datum => {
        if (datum.series && datum.series.length > 0) {
          return datum.series
            .map(item => item.value)
            .reduce((prevDataPoint, currDataPoint) => prevDataPoint + currDataPoint);
        }
      });
    }
    return 0;
  }

  sum(data: LineChartDataElement[]) {
    if (data && data.length > 0) {
      const sum = data.map(datum => {
        if (datum.series && datum.series.length > 0) {
          const innerSum = datum.series.reduce((prevDataPoint, currDataPoint) => {
            return {
              name: currDataPoint.name,
              value: prevDataPoint.value + currDataPoint.value
            };
          });
          return innerSum.value;
        }
      });
      const firstEl = this.svcUtil.first(sum);
      return !!firstEl ? firstEl.toFixed(2) : 0;
    }
  }

  getAllDashboardData(startDate: Moment, endDate: Moment, appId?: string, version?: string, lifetime = false, admin = false) {
    const url = this.generateRequestURL(startDate, endDate, false, appId, version, lifetime, admin);
    return this.http.get(url);
  }

  downloadCSVData(startDate: Moment, endDate: Moment, appId?: string, version?: string, lifetime = false, admin = false, listType: DownloadListType = DownloadListType.DATA) {
    const url = this.generateRequestURL(startDate, endDate, true, appId, version, lifetime, admin, listType);

    return this.http.get(url, { responseType: 'blob' });
  }

  private generateRequestURL(
    startDate: Moment,
    endDate: Moment,
    csv = false,
    appId?: string,
    versionId?: string,
    lifetime = false,
    admin = false,
    listType: DownloadListType = DownloadListType.DATA
  ) {
    let baseUrl = `${environment.leiaservices.url}/${this.leiaEndpoints.dashboard.url}/${
      this.leiaEndpoints.dashboard.developer.url
    }`;
    if (admin) {
      baseUrl = `${environment.leiaservices.url}/${this.leiaEndpoints.dashboard.url}/${
        this.leiaEndpoints.dashboard.admin.url
      }`;
    } else if (listType === DownloadListType.IAP) {
      baseUrl = `${environment.leiaservices.url}/${this.leiaEndpoints.iap.getIapPurchases.url}`;
    } else if (listType === DownloadListType.IAPLOG) {
      baseUrl = `${environment.leiaservices.url}/${this.leiaEndpoints.iap.getIapLogReport.url}`;
    }

    let params = new HttpParams()
      .set('startDate', startDate.format('YYYY-MM-DD'))
      .set('endDate', endDate.format('YYYY-MM-DD'));
    if (csv) {
      params = params.set('type', 'CSV');
    }
    let url = `${baseUrl}?${params}`;

    if (lifetime && !!appId) {
      params = new HttpParams().set('productId', appId);
      if (versionId) {
        params = params.set('versionId', versionId);
      }
      if (csv) {
        params = params.set('type', 'CSV'); // &type=CSV
      }
      url = `${baseUrl}?${params}`;
      return url; // /developer?productId=appId
    }

    if (lifetime || (lifetime && admin)) {
      if (csv) {
        params = new HttpParams().set('type', 'CSV');
        url = `${baseUrl}?${params}`;
        return url; // /developer&type=CSV
      }
      return baseUrl; // /developer
    }

    if (!appId) {
      // Get all unfiltered data from start to end dates
      // /developer?startDate=startDate&endDate=endDate
      return url;
    } else {
      // Get data filtered by appId
      // /developer?startDate=startDate&endDate=endDate&productId=appId
      params = params.set('productId', appId);
      if (versionId) {
        params = params.set('versionId', versionId);
      }
      url = `${baseUrl}?${params}`;

      return url;
    }
  }

  getSummaryItemData(itemsPerRow: number, rowTarget: number) {
    let counter = 0;
    this.ary = [];
    while (counter < rowTarget) {
      this.ary.push(this.queueRequests(itemsPerRow, ++counter));
    }

    return forkJoin(...this.ary);
  }

  private queueRequests(itemsPerRow: number, rowTarget: number): Observable<SummaryItemModel[]> {
    let url = `${environment.leiaservices.url}${this.leiaEndpoints.dashboard.url}/${
      this.leiaEndpoints.dashboard.apps.url
    }/${this.leiaEndpoints.dashboard.apps.summary.url}`;
    const params = new HttpParams().set('pageSize', itemsPerRow.toString()).set('page', rowTarget.toString());
    url = `${url}?${params}`;

    return this.http.get<SummaryItemModel[]>(url);
  }
}
