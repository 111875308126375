import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-payment-row-detail',
  templateUrl: './payment-row-detail.component.html',
  styleUrls: ['./payment-row-detail.component.scss']
})
export class PaymentRowDetailComponent {
  @Input()
  paymentDetail;
  @Input()
  detailRowAttributes;
  @Input()
  showButtons: boolean;
  @Input()
  groupPaymentsEnabled = false;
  @Output()
  onHoldClick = new EventEmitter();
  @Output()
  onReleaseClick = new EventEmitter();
  @Output()
  onPayNowClick = new EventEmitter();
  @Output() authClick = new EventEmitter();
}
