import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss']
})
export class NextButtonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
