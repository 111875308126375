import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressModel } from 'app/models/stripe-account';

export interface AddressInputLabels {
  addressOneLabel: string;
  addressTwoLabel: string;
  cityLabel: string;
  stateOrProvinceLabel: string;
  zipOrPostalCodeLabel: string;
}

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent {
  @Input()
  isWhite: boolean;
  @Input()
  readOnly = false;
  @Input()
  required = true;
  @Input()
  addressLabels: AddressInputLabels;
  @Input()
  parent: FormGroup;
  @Input()
  addressTemplate: AddressModel;
}
