import { ElementRef, Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";
import * as _ from "lodash"
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfimationModalComponent } from "./confirmation-modal.component";

export interface IDialogAction {
    caption: string;
    result?: any;
    customClass?: string;
    keepOpen?: boolean;
    disabled?: boolean;
    callBack?(dialog: MatDialogRef<ConfimationModalComponent>): any;
};

export interface IMessageDialogData {
    actions?: IDialogAction[];
    title?: string;
    text?: string;
    htmlText?: string;
    disableBackClick?: boolean;
    disableCloseButton?: boolean;
    disableClose?: boolean;
    bodyContentRef?: ElementRef;
    footerContentRef?: ElementRef;
    headerContentRef?: ElementRef;
    noScrollBody?: boolean;
    hPadding?: string;
    headerPaddingTop?: string;
    headerMarginBotttom?: string;
    footerMinHeight?: string;
};

export interface IDefaultDialogButtons {
    ok: IDialogAction;
    yes: IDialogAction;
    yesLeave: IDialogAction;
    confirm: IDialogAction;
    save: IDialogAction;
    continue: IDialogAction;
    no: IDialogAction;
    noStay: IDialogAction,
    cancel: IDialogAction;
};

export enum dialogResult {
    ok = 1,
    yes = 2,
    confirm = 3,
    save = 4,
    continue = 5,
    cancel = -1,
    no = -2,
}
export const dialogButtons: IDefaultDialogButtons = {
    ok: {
        caption: "OK",
        result: dialogResult.ok,
    },
    yes: {
        caption: "Yes",
        result: dialogResult.yes,
    },
    yesLeave: {
        caption: "Yes - I want to leave",
        result: dialogResult.yes,
    },
    confirm: {
        caption: "Confirm",
        result: dialogResult.confirm,
    },
    save: {
        caption: "Save",
        result: dialogResult.save,
    },
    continue: {
        caption: "Continue",
        result: dialogResult.save,
    },
    cancel: {
        caption: "Cancel",
        result: dialogResult.cancel,
    },
    no: {
        caption: "No",
        result: dialogResult.no,
    },
    noStay: {
        caption: "No - I'll stay",
        result: dialogResult.no,
    }
}

export const dprmConfirmLeavePageDialog: IMessageDialogData = {
    actions: [dialogButtons.yesLeave, dialogButtons.noStay],
    title: "Are you sure you want to do that?",
    text: "If you leave now, your unsaved changes will be lost.",
};

export const MessageDialogServiceToken: InjectionToken<MessageDialogService>
= new InjectionToken<MessageDialogService>("leia.message.dialog.service");

export interface MessageDialogService {
    openMessageDialog(dialogData: IMessageDialogData): MatDialogRef<ConfimationModalComponent>;
}

@Injectable()
export class MessageDialogServiceImpl implements OnDestroy, MessageDialogService {

    constructor (private dialog: MatDialog) {}

    onDestroy$: Subject<boolean> = new Subject();

    openMessageDialog(dialogData: IMessageDialogData, matDialogOptions?: MatDialogConfig): MatDialogRef<ConfimationModalComponent> {

        let options: MatDialogConfig = {
            width: "50%",
            panelClass: "custom-dialog-container",
            maxHeight: "90vh",
            maxWidth: "90vw",
            minWidth: "280px",
            disableClose: dialogData.disableClose,
            data: {
                messageData: dialogData,
            }
        };
        if (matDialogOptions) {
            options = _.merge(options, matDialogOptions);
        }
        const dialogRef = this.dialog.open(ConfimationModalComponent, options);
        dialogRef.backdropClick().pipe(takeUntil(this.onDestroy$)).subscribe(
            (ev: MouseEvent) => {
                if (dialogData.disableClose) {
                    if (dialogData.disableBackClick === false) {
                        dialogRef.close();
                    }
                } else {
                    if (!(dialogData.disableBackClick === true)) {
                        dialogRef.close();
                    }
                }
            }
        )
        return dialogRef;

    }

    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
