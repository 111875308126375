import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NameModel } from 'app/models/stripe-account';

@Component({
  selector: 'app-name-form',
  templateUrl: './name-form.component.html',
  styleUrls: ['./name-form.component.scss']
})
export class NameFormComponent {
  @Input()
  isWhite: boolean;
  @Input()
  parent: FormGroup;
  @Input()
  nameTemplate: NameModel;
  @Input()
  readOnly = false;
  @Input()
  required = true;
}
