import { Component, EventEmitter, Inject, Input, OnInit, Output, LOCALE_ID, } from '@angular/core';
import { AbstractControl, FormGroup, } from '@angular/forms';
import { UtilService } from '@services/util.service';
import { leiaGlobal } from 'endpoints';
import * as _ from 'lodash';
import { ByteFormatPipe } from 'ngx-material-file-input';
import { DynamicFormService, IDynFormField, IDynFormFieldOption, IDynFormFieldType, IDynFormFieldValidatorType, IDynFormLengthValidationOptions, } from './dynamic-form.service';
import { getLocaleDateFormat, FormatWidth } from '@angular/common';

export interface IDynForm {
    name: string;
    id: string;
    sections: IDynFormSection[];
}

export interface IDynFormSection {
    name?: string;
    id: string;
    description?: string;
    subTitle?: string;
    subTitleParam?: any;
    subDesc?: string;
    fields?: IDynFormField[];
    singleColumn?: boolean;
}

export interface IDynFormModelChange {
    field: IDynFormField;
    value: any;
    model: any;
}


@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
    providers: [ByteFormatPipe],
})
export class DynamicFormComponent implements OnInit {

    formGroup: FormGroup;
    form: IDynForm;
    model: any = {};
    modelDefault: any = {};
    localeDateFormat: string;

    // fields: IDynFormField[];
    validatorTypes = IDynFormFieldValidatorType;

    get allowedFileExtensions() {
        return this.globalCfg.publishingDetails.allowedExtensions;
    }

    @Input()
    set formWithValue(formAndValue: { form: IDynForm, value: any }) {
        if (formAndValue && formAndValue.form) {
            this.formGroup = null;
            this.model = {};
            setTimeout(() => {
                this.initFormGroup(formAndValue.form);
                this.form = formAndValue.form;
                this.defaultValue = formAndValue.value;
            });
        }
    }

    @Input()
    set dynForm(form: IDynForm) {
        this.formGroup = null;
        this.model = {};
        setTimeout(()=>{
            this.initFormGroup(form);
            this.form = form;
        });
    }
    get dynForm(): IDynForm {
        return this.form;
    }

    @Input()
    set defaultValue(newModel: any) {
        if (!newModel) {
            return;
        }
        const defs: any = {};
        Object.keys(newModel).forEach(p => {
            if (newModel.hasOwnProperty(p)) {
                const ctrl = this.formGroup.get(p);
                if (ctrl) {
                    ctrl.setValue(newModel[p]);
                    defs[p] = newModel[p];
                    ctrl.markAsUntouched();
                    ctrl.markAsPristine();
                }
            }
        });
        this.modelDefault = defs;
    }
    get defaultValue(): any {
        return this.modelDefault;
    }

    @Input() optionalFieldSuffix: string;
    @Input() showDatePattern: boolean;

    @Output() valueChange: EventEmitter<IDynFormModelChange> = new EventEmitter();

    public ctrlType = IDynFormFieldType;

    constructor(
        private formSvc: DynamicFormService,
        private utilService: UtilService,
        @Inject(leiaGlobal) private globalCfg,
        @Inject(LOCALE_ID) private localeId: string,
    ) { }


    ngOnInit() {
        if (this.localeId && this.showDatePattern) {
            this.localeDateFormat = getLocaleDateFormat(this.localeId, FormatWidth.Short);
            if (this.localeDateFormat && this.localeDateFormat.length) {
                this.localeDateFormat = this.localeDateFormat.toUpperCase();
                if (this.localeDateFormat === "M/D/YY" || this.localeDateFormat === "M/D/YYYY") {
                    this.localeDateFormat = "MM/DD/YYYY";
                }
                if (this.localeDateFormat === "D/M/YY" || this.localeDateFormat === "D/M/YYYY") {
                    this.localeDateFormat = "DD/MM/YYYY";
                }
            }
        }
    }

    // getFieldSortIndex(field: IDynFormField): number {
    //     if (field.id === 'summary') {
    //         return 0;
    //     } else
    //     if (field.id === 'issuetype') {
    //         return 1;
    //     } else {
    //         return this.fields.indexOf(field) + 2;
    //     }
    // }

    // setFieldList(form: IDynForm) {
    //     const fl: IDynFormField[] = [];
    //     form.sections.forEach(s => fl.push(...s.fields));
    //     this.fields = fl;
    //     if (this.fields.find(f => !!f.sortOrder)) {
    //         this.fields.sort((a, b) => {
    //             return a.sortOrder - b.sortOrder;
    //         });
    //     }
    // }

    getFieldLength(field: IDynFormField, value: string): number {
        if (value && value.length) {
            if (field && field.schema.validators && field.schema.validators.length) {
                const v = field.schema.validators.filter(i => i.type === IDynFormFieldValidatorType.length);
                if (v && v.length) {
                    switch (v[0].lengthOption) {
                        case IDynFormLengthValidationOptions.ignoreWhiteSpace:
                            return value.replace(/\s/g,'').length;
                        case IDynFormLengthValidationOptions.ignoreWhiteSpaceMinus:
                            return value.replace(/-|\s/g,'').length;
                        case IDynFormLengthValidationOptions.trim:
                            return value.trim().length;
                        default:
                            return value.length;
                    }
                } else {
                    return value.length;
                }
            } else {
                return value.length;
            }
        }
        return 0;
    }
    initFormGroup(form: IDynForm) {
        const formCtrls: IDynFormField[] = [];
        if (form && form.sections && form.sections.length){
            form.sections.forEach(s => {
                if (s.fields && s.fields.length) {
                    formCtrls.push(...s.fields);
                }
            });
            // this.setFieldList(form);
            this.formGroup = this.formSvc.createDynForm(formCtrls);
            formCtrls.forEach((field: IDynFormField) => {
                this.formGroup.get(field.id).valueChanges.subscribe(val => this.modelChange(field, val));
            });
        }
    }

    getSelectOptions(field: IDynFormField): IDynFormFieldOption[] {
        return field.allowedValues;
    }

    modelChange(field: IDynFormField, value: any) {
        this.model[field.id] = value;
        this.valueChange.emit({
            field,
            value,
            model: this.model,
        });
    }

    getCharCount(field: IDynFormField): { maxLength: number, minLength: number } {
        if (!(field && field.schema)) {
            return null;
        }
        let v = field.schema.validators;
        if (v && v.length) {
            v = field.schema.validators.filter(i => i.type === IDynFormFieldValidatorType.length);
            if (v && v.length) {
                return { maxLength: v[0].maxLength, minLength: v[0].minLength };
            }
        }
        return null;
    }

    setPristine() {
        this.utilService.recurseFormControls(this.formGroup, ctrl => {
            ctrl.markAsPristine();
            ctrl.markAsUntouched();
        });
    }

    touchForm() {
        this.utilService.recurseFormControls(this.formGroup, ctrl => ctrl.markAsTouched());
    }

    setDisabled(isDisabled: boolean, fields?: IDynFormField[]) {
        if (this.formGroup) {
            Object.keys(this.formGroup.controls).forEach(c => {
                if (!fields || fields.find(f => f.id === c)) {
                    const ctrl = this.formGroup.controls[c];
                    if (ctrl) {
                        if (isDisabled) {
                            ctrl.disable();
                        } else {
                            ctrl.enable();
                        }
                    }
                }
            });

        }
    }
}
