import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() year: Date = new Date();
  isWhite = true;
  private subscription: Subscription;
  private colorObserver: Observable<boolean>;
  constructor(private appService: AppService) {
    this.colorObserver = this.appService.footerSubscribe();
  }

  ngOnInit() {
    this.subscription = this.colorObserver.subscribe(value => {
      this.isWhite = value;
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
