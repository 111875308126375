import { Component, OnInit } from '@angular/core';
import { LeiaNotification } from '../../../models/leia-notification';
import { NotificationsService } from '../../../services/notifications.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [NotificationsService]
})
export class NotificationsComponent implements OnInit {
  public notifications: LeiaNotification[] = [];
  constructor(private notificationService: NotificationsService) {}

  get hasNotifications() {
    return this.notifications.length > 0;
  }

  ngOnInit() {
    this.notificationService.getAllNotifications().subscribe(notifications => {
      this.notifications = notifications;
    });
  }
  onClose(notification: LeiaNotification) {
    this.notificationService
      .deleteNotification(notification.notificationId)
      .subscribe(() => {
        this.notifications = this.notifications.filter(
          n => n.notificationId !== notification.notificationId
        );
      });
  }
}
