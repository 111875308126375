import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { environment } from 'environments/environment';
import { electronicFormatIBAN, extractIBAN } from 'ibantools';

@Directive({
  selector: '[appIBANValidatorDirective]',
  providers: [{ provide: NG_VALIDATORS, useExisting: IbanDirective, multi: true }]
})
export class IbanDirective implements Validator {
  @Input('appIBANValidatorDirective')
  country: string;

  validate(c: AbstractControl): { [key: string]: any } {
    const devEnv = environment.env_name === 'dev';
    if (!devEnv  && !!this.country && !!c.value ) {
      const iban = extractIBAN(electronicFormatIBAN(c.value));
      if (!!iban.valid && iban.countryCode === this.country) {
        return null;
      } else {
        return { iban: { value: c.value } };
      }
    }
    return null;
  }
}
