import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'testAppPipe' })
export class TestAppPipe implements PipeTransform {
  transform(value: any[], hideTestApps: boolean): any[] {
    // Filter by app genre with Angular UI
    if (!value) return;
    if (hideTestApps)
      return value.filter(app => {
        const genres = app.genres && app.genres.split(',');
        return genres && genres.indexOf('test') === -1;
      });
    else return value;
  }
}
