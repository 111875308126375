import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ViewChild
} from '@angular/core';
import { UtilService } from 'app/services/util.service';
import { byAlpha2 } from 'iso-country-codes';
import { isArray, last } from 'lodash';
import { App } from '../models/app';
import {
  DashboardPageData,
  DashboardServerReponse
} from '../models/dashboard-page-data.model';
import { LineChartDataElement } from '../models/line-chart-data-element.model';
import { MenuChoice, MenuChoices, MomentRange } from '../models/moment-range';
import { WorldMapData } from '../models/world-map-data.model';
import { AppService } from '../services/app.service';
import { DashboardService } from '../services/dashboard.service';
import { AppData } from '@models/app-data';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard.page.component.html',
  styleUrls: ['./dashboard.page.component.scss']
})
export class DashboardPageComponent implements DoCheck {
  @ViewChild('avgRatingContainer')
  avgRatingContainer: DashboardPageComponent;
  values: DashboardPageData = {
    app: null,
    dashboardData: {
      averageRating: null,
      downloadRevenue: null,
      downloadsByApplication: null,
      downloadsByCountry: null,
      downloadsByUser: null,
      iapRevenue: null,
      totalDownloadRevenue: null,
      totalDownloads: null,
      totalIAPRevenue: null
    }
  };
  rangeToday = MenuChoices.find(val => val.label === MenuChoice.Lifetime);
  apps: App[] = null;

  private _avgRating = 0;
  get averageRating() {
    return this._avgRating;
  }
  set averageRating(value: number) {
    if (!!value) {
      this._avgRating = value;
    }
  }
  constructor(
    protected svcDashboard: DashboardService,
    protected svcApp: AppService,
    protected svcUtil: UtilService,
    protected cdr: ChangeDetectorRef
  ) {
    this.populateData(this.rangeToday);
    this.svcApp
      .getApps()
      .subscribe(
        apps =>
          (this.apps = apps.filter(app => (app.appStatus as any) === 'live'))
      );
  }

  ngDoCheck() {
    this.cdr.detectChanges();
  }

  populateData(range: MomentRange) {
    if (range.label !== MenuChoice.Lifetime) {
      if (this.values.app && this.values.app.id) {
        this.svcDashboard
          .getAllDashboardData(
            range.from.clone(),
            range.to.clone(),
            this.values.app.id
          )
          .subscribe(data =>
            this.setDataValues(data as DashboardServerReponse)
          );
      } else {
        this.svcDashboard
          .getAllDashboardData(range.from.clone(), range.to.clone())
          .subscribe(data =>
            this.setDataValues(data as DashboardServerReponse)
          );
      }
    } else {
      if (this.values.app && this.values.app.id) {
        this.svcDashboard
          .getAllDashboardData(
            range.from.clone(),
            range.to.clone(),
            this.values.app.id,
            null,
            true
          )
          .subscribe(data =>
            this.setDataValues(data as DashboardServerReponse)
          );
      } else {
        this.svcDashboard
          .getAllDashboardData(range.from.clone(), range.to.clone(), null, null, true)
          .subscribe(data =>
            this.setDataValues(data as DashboardServerReponse)
          );
      }
    }
  }

  setDataValues(data: DashboardServerReponse) {
    this.values.dashboardData.totalDownloads =
      data.totalDownloads.totalDownload;
    this.values.dashboardData.totalDownloadRevenue =
      data.totalDownloadRevenue.totalRevenue;
    this.values.dashboardData.totalIAPRevenue = data.totalIAPRevenue.iapRevenue;

    const appName = this.values.app ? this.values.app.title : 'All Apps';

    this.values.dashboardData.downloadsByUser = this.svcDashboard.mapServerResponse(
      appName,
      data.downloadsByUser
    );
    this.values.dashboardData.downloadsByApplication = data.downloadsByApplication.map<{
      name: string;
      value: number;
    }>(item => {
      return {
        name: item.title,
        value: item.value
      };
    });
    this.values.dashboardData.downloadsByCountry = data.downloadsByCountry.map<
      WorldMapData
    >(item => {
      return {
        code: byAlpha2[item.countryCode],
        value: item.value
      };
    });
    this.values.dashboardData.downloadRevenue = this.svcDashboard.mapServerResponse(
      appName,
      data.downloadRevenue
    );
    this.values.dashboardData.iapRevenue = this.svcDashboard.mapServerResponse(
      appName,
      data.iapRevenue
    );
    this.values.dashboardData.averageRating = this.svcDashboard.mapServerResponse(
      appName,
      data.averageRating
    );
  }

  handleAppsSelected(apps: AppData) {
    if (!isArray(apps.app)) {
      this.values.app = apps.app;
    }
  }

  onDownloadsByUser(data: LineChartDataElement[]) {
    return this.svcDashboard.sum(data);
  }
  onDownloadsRevenue(data: LineChartDataElement[]) {
    return this.svcDashboard.sum(data);
  }
  onIAPRevenue(data: LineChartDataElement[]) {
    return this.svcDashboard.sum(data);
  }
  onAverageRating(data: LineChartDataElement[]) {
    if (!!data && data.length > 0) {
      return this.svcUtil.first(
        data.map(datum => {
          if (!!datum.series && datum.series.length > 0) {
            const latestAverage = last(datum.series);
            if (!!latestAverage) {
              return latestAverage.value;
            }
            return 0;
          }
          return 0;
        })
      );
    }
    return 0;
  }
}
