import { Component, OnInit } from '@angular/core';
import { PartnersPageComponent } from 'app/partners.module/partners.page.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  onActivate(event) {
    if (event instanceof PartnersPageComponent) {
      window.scroll(0, 0);
    }
  }
}
