import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NgForm, NgModel, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MessageDialogServiceImpl,
  MessageDialogServiceToken
} from '@common/components/confimation-modal/dialog.service';
import { ComponentCommService } from '@services/component-comm.service';
import { MaterialTextareaComponent } from 'app/common.module/components/material-textarea/material-textarea.component';
import { ToggleSelectorComponent } from 'app/common.module/components/toggle-selector/toggle-selector.component';
import { UtilService } from 'app/services/util.service';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, pluck, takeUntil } from 'rxjs/operators';
import { AppDetailsResolver } from '../../../../../guards/app-details.resolver';
import { App, AppDetailsChanges, AppDetailsItem } from '../../../../../models/app';
import { AppService } from '../../../../../services/app.service';
import { UserService } from '../../../../../services/user.service';
import { localeCN, localeUS } from '../app-edit-locales/app-edit-locales.component';
import { noWhiteSpaceValidator, trimmedMinLength } from '../app-edit.validators';
import { leiaGlobal } from 'endpoints';

export const appDetailMinTitleLength = 10;
export const commChannelAppDetailsChange = 'comChannel.appDetailsChange';

export interface IDetailsLocaleData {
  title?: string;
  description?: string;
}

@Component({
  selector: 'app-edit-details',
  templateUrl: './app-edit-details.component.html',
  styleUrls: ['./app-edit-details.component.scss'],
  providers: [AsyncPipe]
})
//   INavigationGuardComponent,
export class AppEditDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(ToggleSelectorComponent)
  children: QueryList<ToggleSelectorComponent>;
  @ViewChild('titleText', { read: NgModel })
  titleTextModel: NgModel;
  @ViewChild('copyrightText', { read: NgModel })
  copyrightTextModel: NgModel;
  @ViewChild(MaterialTextareaComponent, { read: NgModel })
  matTextArea: NgModel;
  @ViewChild('form') ngForm: NgForm;

  @Input() app: App;
  @Input() appChanges: AppDetailsChanges[];
  @Input() lists: any;
  @Input() formGroup: FormGroup;

  @Input() readOnly = false;

  @Output() backClick: EventEmitter<any> = new EventEmitter();
  @Output() nextClick: EventEmitter<any> = new EventEmitter();

  language: string;
  genres: any;
  languages: any;

  defaultLocale: null;

  detail: AppDetailsItem = {};

  isDirty = false;
  onDestroy$: Subject<boolean> = new Subject();
  lastChange: any = null;
  startValueCheck = false;
  appAssigned = false;
  saveClicked = false;
  isAdmin = false;

  minTitleLength = appDetailMinTitleLength;
  disableDefault = true;

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    protected svcResolver: AppDetailsResolver,
    protected cdr: ChangeDetectorRef,
    protected pipeAsync: AsyncPipe,
    private svcUtil: UtilService,
    private router: Router,
    private userService: UserService,
    private commService: ComponentCommService,
    @Inject(leiaGlobal) private leiaGlobalConfig,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl
  ) {
    this.isAdmin = userService.isAdmin();
    this.defaultLocale = this.leiaGlobalConfig.defaultLocales[0];
  }

  getAppDetail(lang: string): AppDetailsItem {
    return this.app.details.find((detailLocale) => detailLocale.locale === lang);
  }

  hasLanguage(lang: string) {
    const l: AppDetailsItem = this.getAppDetail(lang);
    return !!l;
  }

  setLangActive(lang: string) {
    this.language = lang;
    this.focusTitleInput(lang);
  }

  focusTitleInput(lang: string) {
    setTimeout(() => {
      const el: HTMLElement = document.getElementById('ctrlTitle_' + lang);
      if (el) {
        el.focus();
      }
    });
  }

  trimControlValue(fieldName: string, locale?: string) {
    const ctrl: AbstractControl = locale
      ? this.formGroup.controls[locale].get(fieldName)
      : this.formGroup.get(fieldName);
    const val: string = ctrl.value;
    if (val && val.length) {
      ctrl.setValue(val.trim());
      ctrl.updateValueAndValidity();
    }
  }

  languageToggle(checked: boolean, lang: string) {
    this.language = lang;
    const currLang: AppDetailsItem = this.getAppDetail(lang);
    if (checked) {
      if (!currLang) {
        this.app.details.push({
          locale: this.language,
          title: '',
          description: '',
          releaseNote: ''
        });
        this.setLocaleValidators(lang);
        this.formGroup.controls[lang].get('title').markAsUntouched();
        this.formGroup.controls[lang].get('description').markAsUntouched();
        this.commService.getChannel(commChannelAppDetailsChange).next(true);
      }
      this.setLangActive(lang);
    } else {
      const idx = this.app.details.indexOf(currLang);
      if (idx >= 0) {
        this.app.details.splice(idx, 1);
        this.commService.getChannel(commChannelAppDetailsChange).next(true);
      }
      this.setLocaleValidators(lang, true);
    }

    this.setDefaultLocale(lang);
  }

  setDefaultLocale(lang: string) {
    if (lang !== localeUS) {
      const cnChecked = this.hasLanguage(localeCN);
      const usChecked = this.hasLanguage(localeUS);

      if (!usChecked && !cnChecked) {
        this.languageToggle(true, localeUS);
      }
      this.disableDefault = !cnChecked;
    }
  }

  ngAfterViewInit() {

  }

  fieldChange(fieldName: string, val: any) {
    this.app[fieldName] = val;
  }

  localeFieldChange(locale: string, fieldName: string, val: any) {
    let appDet: AppDetailsItem = this.app.details.find((item) => item.locale === locale);
    if (!appDet) {
      if (val && val.length) {
        appDet = {
          locale: locale,
          title: null,
          description: null
        };
        appDet[fieldName] = val;
        this.app.details.push(appDet);
        this.setLocaleValidators(locale);
        this.commService.getChannel(commChannelAppDetailsChange).next(true);
      }
    } else {
      appDet[fieldName] = val;
    }
  }

  ngOnInit() {
    this.genres = this.lists.genre;
    this.languages = this.lists.language;

    if (!this.app.genres) {
      this.app.genres = [];
    }

    if (this.app.details.length === 0) {
      this.selectDefaultLanguage();
    }

    Object.keys(this.formGroup.controls).forEach((ctrlName) => {
      const ctrl = this.formGroup.controls[ctrlName];
      if (ctrl instanceof FormGroup) {
        ctrl
          .get('title')
          .valueChanges.pipe(takeUntil(this.onDestroy$))
          .subscribe((val) => this.localeFieldChange(ctrlName, 'title', val));
        ctrl
          .get('description')
          .valueChanges.pipe(takeUntil(this.onDestroy$))
          .subscribe((val) => this.localeFieldChange(ctrlName, 'description', val));
      }
      if (ctrl instanceof FormControl) {
        ctrl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((val) => this.fieldChange(ctrlName, val));
      }
    });
    this.setDefaultLocale(null);
    const selectedDefaults = this.app.details
      .filter((d) => this.leiaGlobalConfig.defaultLocales.includes(d.locale))
      .map((d) => d.locale);
    this.language = this.app.details[0] ? this.app.details[0].locale : selectedDefaults[0];
  }

  hasContent(str: string) {
    return !!str && str.length;
  }

  cascadeFormUnTouched() {
    const touched = Object.keys(this.formGroup.controls).some((name) => this.formGroup.controls[name].touched);
    if (!touched) {
      this.formGroup.markAsUntouched();
    }
  }

  setLocaleValidators(locale: string, clear?: boolean) {
    if (clear) {
      this.formGroup.controls[locale].get('title').clearValidators();
      this.formGroup.controls[locale].get('title').setValue('');
      this.formGroup.controls[locale].get('title').setErrors(null);
      this.formGroup.controls[locale].get('description').clearValidators();
      this.formGroup.controls[locale].get('description').setValue('');
      this.formGroup.controls[locale].get('description').setErrors(null);
      this.formGroup.controls[locale].updateValueAndValidity();
      this.formGroup.controls[locale].get('description').markAsUntouched();
      this.formGroup.controls[locale].get('title').markAsUntouched();
      this.cascadeFormUnTouched();
    } else {
      this.formGroup.controls[locale]
        .get('description')
        .setValidators([Validators.required, trimmedMinLength(appDetailMinTitleLength), noWhiteSpaceValidator]);
      this.formGroup.controls[locale].get('title').setValidators([Validators.required, noWhiteSpaceValidator]);
      this.formGroup.controls[locale].get('description').updateValueAndValidity();
      this.formGroup.controls[locale].get('title').updateValueAndValidity();
    }
  }

  selectDefaultLanguage() {
    const selectedDefaults = this.app.details
      .filter((d) => this.leiaGlobalConfig.defaultLocales.includes(d.locale))
      .map((d) => d.locale);
    this.language = selectedDefaults[0];
    this.detail = {
      locale: this.language,
      title: '',
      description: '',
      releaseNote: ''
    };
    this.app.details.push(this.detail);
    this.commService.getChannel(commChannelAppDetailsChange).next(true);
  }

  toggleGenre(genre) {
    if (!this.readOnly) {
      if (this.app.genres && this.app.genres.includes(genre)) {
        this.app.genres.splice(this.app.genres.indexOf(genre), 1);
        if (!this.app.genres.length) {
          this.formGroup.get('hasGenre').setValue(null);
          this.formGroup.get('hasGenre').markAsTouched();
        }
      } else {
        this.app.genres = [];
        this.app.genres.push(genre);
        this.formGroup.get('hasGenre').setValue(genre);
        this.formGroup.get('hasGenre').markAsTouched();
      }
    }
  }

  hasGenre(): boolean {
    if (this.app && this.app.genres && this.app.genres.length) {
      return !!this.app.genres.some((appGenre) => !!this.genres.find((gen) => gen.genre_id === appGenre));
    }
    return false;
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  checkChanges(title: string) {
    return this.appChanges ? this.appChanges.some((chg) => chg.fieldName === `${title}_${this.language}`) : false;
  }

  backClicked() {
    this.backClick.emit(true);
  }

  nextClicked() {
    this.nextClick.emit(true);
  }
}
