import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from 'app/common.module/app.common.module';
import { AppMaterialModule } from 'app/material.module/material.module';
import { CompanyAccountComponent } from './components/company-account/company-account.component';
import { ContactInfoFormComponent } from './components/contact-info-form/contact-info-form.component';
import { IndividualAccountComponent } from './components/individual-account/individual-account.component';
import { IpAddressInputComponent } from './components/ip-address-input/ip-address-input.component';
import { MaterialDateInputComponent } from './components/material-date-input/material-date-input.component';
import { MaterialDatePickerInputComponent } from './components/material-date-picker-input/material-date-picker-input.component';
import { PaymentVerificationComponent } from './components/payment-verification/payment-verification.component';
import { RepresentativeBankDetailComponent } from './components/representative-bank-detail/representative-bank-detail.component';
import { SetUpPaymentDetailsComponent } from './components/set-up-payment-details/set-up-payment-details.component';
import { TosAcceptanceFormComponent } from './components/tos-acceptance-form/tos-acceptance-form.component';
import { UserAccountDetailsFormComponent } from './components/user-account-details-form/user-account-details-form.component';
import { PubDetailsComponent } from './containers/publishing-details/pub-details.component';
import { PublishingDetailsComponent } from './containers/publishing-details/publishing-details.component';
import { IsMatchFirstLastNameDirective } from './is-match-first-last-name.directive';
import { MatRadioModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PaymentVerificationComponent,
    RepresentativeBankDetailComponent,
    PublishingDetailsComponent,
    IndividualAccountComponent,
    CompanyAccountComponent,
    SetUpPaymentDetailsComponent,
    UserAccountDetailsFormComponent,
    TosAcceptanceFormComponent,
    IpAddressInputComponent,
    MaterialDatePickerInputComponent,
    MaterialDateInputComponent,
    ContactInfoFormComponent,
    IsMatchFirstLastNameDirective,
    PubDetailsComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    MatRadioModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [
    PaymentVerificationComponent,
    RepresentativeBankDetailComponent,
    PublishingDetailsComponent,
    IndividualAccountComponent,
    CompanyAccountComponent,
    SetUpPaymentDetailsComponent,
    UserAccountDetailsFormComponent,
    MaterialDateInputComponent,
    ContactInfoFormComponent,
    PubDetailsComponent,
  ]
})
export class AppStripeModule {}
