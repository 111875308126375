import { Component, EventEmitter, Output, Input } from '@angular/core';
import { NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AppCalendarDateRange } from '../../../models/app-calendar-date-range';
import {
  CustomDatepickerI18n,
  I18n
} from './calendar-range-picker-i18n.service';

@Component({
  selector: 'app-calendar-range-picker',
  templateUrl: './calendar-range-picker.component.html',
  styleUrls: ['./calendar-range-picker.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class CalendarRangePickerComponent {
  @Input() calendarCount = 2;
  @Output() dateSeleced = new EventEmitter<AppCalendarDateRange>();
  private hoveredDate: NgbDate;
  private fromDate: NgbDate;
  private toDate: NgbDate;
  public dateSelectSeed = 0;

  constructor() {}

  onDateSelection(date: NgbDate) {
    const _date = new NgbDate(date.year, date.month, date.day);
    if (!this.fromDate && !this.toDate) {
      this.fromDate = _date;
    } else if (this.fromDate && !this.toDate && _date.after(this.fromDate)) {
      this.toDate = _date;
    } else {
      this.toDate = null;
      this.fromDate = _date;
    }

    // the range has been picked
    if (this.fromDate && this.toDate) {
      this.dateSeleced.emit({ fromDate: this.fromDate, toDate: this.toDate });
    }
    this.dateSelectSeed = 1 - this.dateSelectSeed;
  }

  private isHovered = (date: NgbDate) =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    date.after(this.fromDate) &&
    date.before(this.hoveredDate);

  private isInside = (date: NgbDate) =>
    date.after(this.fromDate) && date.before(this.toDate);

  isRange = (date: NgbDate) => {
    const _date = new NgbDate(date.year, date.month, date.day);
    return (
      _date.equals(this.fromDate) ||
      _date.equals(this.toDate) ||
      this.isInside(_date) ||
      this.isHovered(_date)
    );
  };

  isStart = (date: NgbDate) => {
    const _date = new NgbDate(date.year, date.month, date.day);
    return _date.equals(this.fromDate);
  };

  isEnd = (date: NgbDate) => {
    const _date = new NgbDate(date.year, date.month, date.day);
    return _date.equals(this.toDate);
  };

  onNavigate(ev: any) {
    this.hoveredDate = null;
  }
}
