import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RegisterService } from '@services/register.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UserService,
    private registerService: RegisterService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    return this.loginService.isLoggedIn().pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          const user = this.userService.getUserLocal();
          // If the user.reasons prop is empty in the DB, it comes back as [""] (1 element: empty string), instead of just an empty array
          // TODO: Laszlo change the reasons prop to come back as a legitimately empty array if empty and change this to check against this.user.reasons.length > 0
          if (!user.reasons || !user.reasons.length || !user.reasons[0]) {
            this.router.navigate(['/register/areas-of-interest']);
            return false;
          } else if (!user.userAcceptedTerms) {
            this.router.navigate(['/register/terms-and-conditions']);
            return false;
          } else if (!user.userAcceptedEula) {
            this.router.navigate(['/register/eula']);
            return false;
          } else {
            return true;
          }
        }

        // Navigate to the login page.
        this.loginService.logout();
        return false;
      })
    );
  }
}
