import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as uuid from 'uuid/v4';

@Component({
  selector: 'app-summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.scss']
})
export class SummaryItemComponent implements OnInit {
  numWholeStars = new Array<number>();
  percentFill = 0;
  uuids = new Map<number, uuid>();

  @Input() title = '';
  @Input() downloads = 100;
  @Input() iap = 100;
  @Input() revenue = 100;
  @Input() thumbnail = '';

  private _rating = 3;
  get rating() {
    return this._rating;
  }
  @Input()
  set rating(value: number) {
    if (value && value >= 0 && value <= 5) {
      const wholeStars = Math.floor(value);
      this.numWholeStars = new Array<number>(wholeStars);

      const fractionalStar = (value - wholeStars) * 100;
      this.percentFill = Math.round(fractionalStar);

      this._rating = value;
    }
  }

  getGradient(star) {
    const routeFromRoot = this.route.snapshot.pathFromRoot.map(path => path.url).join('/');
    return `url(${routeFromRoot}#${this.generateUUID(star)})`;
  }

  getPercentFill(star: number) {
    if (star < this.numWholeStars.length) {
      return 100;
    } else if (star < this.rating) {
      return this.percentFill;
    } else {
      return 0;
    }
  }

  generateUUID(index: number) {
    if (!this.uuids[index]) {
      this.uuids[index] = uuid();
    }

    return this.uuids[index];
  }

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {}
}
