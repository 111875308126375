import { ChangeDetectorRef, Component, Input, Optional, Self, ViewChild } from '@angular/core';
import { FormGroup, NgControl } from '@angular/forms';
import { MaterialInputComponent } from 'app/common.module/components/material-input/material-input.component';
import { FileReaderAvailability } from 'app/enums/file-reader-availability.enum';
import { UtilService } from 'app/services/util.service';
import { VariableAlertModalComponent } from '../variable-alert-modal/variable-alert-modal.component';

@Component({
  selector: 'app-base64-file-encoder',
  templateUrl: './base64-file-encoder.component.html',
  styleUrls: ['./base64-file-encoder.component.scss']
  // providers: [BASE64_FILE_ENCODER_VALUE_ACCESSOR]
})
export class Base64FileEncoderComponent {
  @Input() parent: FormGroup;
  @Input()
  label: string;
  @Input()
  readOnly = false;
  @Input()
  mimeType = 'image/jpeg, image/png';
  @Input()
  isWhite = false;
  @Input()
  maxFileSizeMB = 5;
  @ViewChild(VariableAlertModalComponent)
  modal: VariableAlertModalComponent;
  @ViewChild(MaterialInputComponent)
  matInput: MaterialInputComponent;

  private fileReader = new FileReader();

  set value(value) {
    this._value = value;
    this.notifyValueChange(value);
  }
  get value() {
    return this._value;
  }
  private _value: string | ArrayBuffer;

  onChange: (value) => {};
  onTouched: () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private svcUtil: UtilService,
    private cdr: ChangeDetectorRef
  ) {
    this.fileReader.onload = this.onFileLoad;
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  onFileLoad = ($event: ProgressEvent) => {
    this.value = this.fileReader.result;
    this.cdr.detectChanges();
  };

  onFile($event: FileList) {
    if (!!$event && $event.length > 0) {
      const file = this.svcUtil.first($event);
      const filesize = Number((file.size / 1024 / 1024).toFixed(4)); // MB

      this.label = file.name;

      if (filesize > this.maxFileSizeMB) {
        this.modal.headerText = `File size too large.`;
        this.modal.showAlert(`Try again with a file size less than 5MB`);
        this.matInput.value = null;
      }

      if (this.fileReader.readyState !== FileReaderAvailability.LOADING) {
        this.fileReader.readAsDataURL(file);
      } else {
        // Display loading bar
      }
    }
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
