import { AfterViewInit, ChangeDetectorRef, Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';

@Component({
  selector: 'app-sort-code',
  templateUrl: './sort-code.component.html',
  styleUrls: ['./sort-code.component.scss']
})
export class SortCodeComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  name;
  @Input()
  isWhite = false;
  @Input()
  parent: FormGroup;
  @Input()
  readOnly = false;

  get thisControl() {
    return this.parent.get('value');
  }

  get value() {
    return this._value;
  }
  set value(value) {
    this._value = value;
    this.notifyValueChange(value);
  }
  private _value: string;

  onChange: (value) => {};
  onTouched: () => {};

  constructor(@Optional() @Self() public ngControl: NgControl, protected cdr: ChangeDetectorRef) {
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  toModel(sortCode: string) {
    if (!!sortCode) {
      return sortCode.replace(/(\d{2})-(\d{2})-(\d{2})/, '$1$2$3');
    }
    return sortCode;
  }

  fromModel(sortCode: string) {
    if (!!sortCode) {
      return sortCode.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');
    }
    return sortCode;
  }

  writeValue(value: any): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
