import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgModel, ValidationErrors } from '@angular/forms';
import { regxPatterns } from '@apps/components/dynamic-form/dynamic-form-validator.regexp';
import { IPhoneNumber, PhoneInputComponent } from '@common/components/phone-input/phone-input.component';
import { countriesFull, pubDetSupportedEUCountries, pubDetSupportedNonEUCountries } from '@models/country-code.model';
import { AppService } from '@services/app.service';
import { BankDetailService } from '@services/bank-detail.service';
import { of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import {
  ChangePasswordModalComponent,
  ChangePasswordModalData
} from '../change-password-modal/change-password-modal.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements ControlValueAccessor, AfterViewInit {
  modalData: ChangePasswordModalData = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  oldPasswordIncorrect: ValidationErrors;
  isSaved: boolean;
  isSaving: boolean;
  saveButtonText: string;

  @ViewChild(ChangePasswordModalComponent)
  modal: ChangePasswordModalComponent;
  @ViewChild('firstName') firstNameInput: NgModel;
  @ViewChild('lastName') lastNameInput: NgModel;
  @ViewChild('country') countryInput: NgModel;
  @ViewChild('phone') phoneInput: NgModel;
  @Input()
  updatedUser: User;
  @Input()
  title: string;
  @Input()
  readonly = false;
  @Output()
  update = new EventEmitter();

  countryDisabled = false;
  countryList = countriesFull;
  emailRegX = regxPatterns.email;
  phoneValue: any;

  get user() {
    return this.userService.user;
  }

  constructor(
    private userService: UserService,
    protected cdr: ChangeDetectorRef,
    private appService: AppService,
    private bankService : BankDetailService,
  ) {
    this.phoneValue = this.userService.user.phoneNumber;

    this.userService.getUserRemote().pipe(
      flatMap(usr => {
        if (this.isSupportedCountry(usr.country)) {
          return this.bankService.getDetail().pipe(
            map((details: any) => !!details.stripeAccount),
          );
        } else {
          return of(usr.appCount > 0);
        }
      }),
    ).subscribe(lockCountry => this.countryDisabled = lockCountry);
  }

  isSupportedCountry(countryCode: string) {
    if (pubDetSupportedEUCountries.includes(countryCode)
     || pubDetSupportedNonEUCountries.includes(countryCode)) {
       return true;
     }
     return false;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    this.updatedUser = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  isAdmin() {
    return this.userService.isAdmin();
  }

  updateUser() {
    this.phoneInput.control.markAsTouched();
    if (!(this.firstNameInput.errors || this.lastNameInput.errors
      || this.countryInput.errors || this.phoneInput.errors)) {
      this.update.emit();
    }
  }

  phoneNumberChange(phoneNumber: IPhoneNumber) {
    if (phoneNumber && phoneNumber.number) {
      this.updatedUser.phoneNumber = phoneNumber.internationalNumber?phoneNumber.internationalNumber:phoneNumber.number;
    } else {
      this.updatedUser.phoneNumber = null;
    }
  }
}
