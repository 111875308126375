import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Country } from 'app/enums/country-type.enum';
import { CountryCode } from 'app/models/country-code.model';
import { byAlpha2 as country } from 'iso-country-codes';

export const COUNTRY_DROPDOWN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CountryDropdownComponent),
  multi: true
};

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss'],
  providers: [COUNTRY_DROPDOWN_VALUE_ACCESSOR]
})
export class CountryDropdownComponent implements ControlValueAccessor {
  @Input()
  isWhite = false;
  @Input()
  readOnly = false;
  @Output()
  selectedCountryChange = new EventEmitter<CountryCode>();
  @Input()
  selectedCountry: CountryCode;

  title: string;
  showDropdownItems = false;
  supportedCountries = ([
    Country.AT,
    Country.AU,
    Country.BE,
    Country.CA,
    Country.FR,
    Country.SE,
    Country.GB,
    Country.US
  ] as unknown) as CountryCode;

  onChange: (value) => {};
  onTouched: () => {};

  get value() {
    return this._value;
  }
  set value(value: string) {
    console.log(value);
    if (!!value) {
      this.selectedCountry = country[value];
    }
    this._value = value;
    this.notifyValueChange(value);
  }
  private _value: string;

  constructor(protected el: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onclick(ev: MouseEvent) {
    if (!this.el.nativeElement.contains(ev.target)) {
      this.showDropdownItems = false;
    }
  }

  handleCountrySelected(cntry: CountryCode) {
    this.showDropdownItems = false;
    this.selectedCountry = cntry;
    this.value = cntry.alpha2;
    this.selectedCountryChange.emit(cntry);
  }

  elipsizeText(title: string) {
    if (title.length > 30) {
      return title.substr(0, 30) + ' . . .';
    } else {
      return title;
    }
  }

  notifyValueChange(value) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: string): void {
    if (!!value && value !== this._value) {
      this._value = value;
      this.handleCountrySelected(Country[value]);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
