﻿import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PipesModule } from 'app/pipes/pipes.module';
import { CssVarsModule } from 'ngx-css-variables';
import { AppCommonModule } from '../common.module/app.common.module';
import { NotificationsComponent } from '../core.module/components/notifications/notifications.component';
import { DashboardService } from '../services/dashboard.service';
import { ChartHostDirective } from './chart-host.directive';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LegendItemComponent } from './components/legend-item/legend-item.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { TotalComponent } from './components/total/total.component';
import { VerticalPieChartComponent } from './components/vertical-pie-chart/vertical-pie-chart.component';
import {
  BarHighlightDirective,
  WorldMapComponent,
  WorldMapHighlighDirective
} from './components/world-map/world-map.component';
import { DashboardPageComponent } from './dashboard.page.component';

@NgModule({
  declarations: [
    ChartHostDirective,
    DashboardPageComponent,
    DashboardComponent,
    BarHighlightDirective,
    WorldMapHighlighDirective,
    LegendItemComponent,
    LineChartComponent,
    PieChartComponent,
    VerticalPieChartComponent,
    TotalComponent,
    WorldMapComponent,
    NotificationsComponent
  ],
  imports: [
    AppCommonModule,
    CommonModule,
    NgxChartsModule,
    FormsModule,
    PipesModule,
    CssVarsModule.forRoot()
  ],
  exports: [
    ChartHostDirective,
    DashboardPageComponent,
    DashboardComponent,
    LegendItemComponent,
    LineChartComponent,
    PieChartComponent,
    VerticalPieChartComponent,
    TotalComponent,
    WorldMapComponent
  ],
  providers: [DashboardService]
})
export class AppDashboardPageModule {}
