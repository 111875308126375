import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { PaymentAdmin, PaymentAdminDetail } from 'app/models/payment-admin';
import { PaymentService } from 'app/services/payment.service';
import { Observable, of } from 'rxjs';
import { map, pluck, take, filter, flatMap, tap } from 'rxjs/operators';
import { MessageDialogServiceToken, MessageDialogServiceImpl, dialogButtons } from '@common/components/confimation-modal/dialog.service';
import { PayNowModalComponent } from '../pay-now-modal/pay-now-modal.component';

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent {

  @ViewChild('payModal') payModal: PayNowModalComponent;

  public tableAttributes = [
    {
      name: 'Period'
    },
    {
      name: 'Total Rev.',
      attr: 'totalRevenue'
    },
    {
      name: 'Leia Rev.',
      attr: 'leiaRevenue'
    },
    {
      name: 'Tax Collected',
      attr: 'taxCollected'
    },
    {
      name: 'Total Pmnt',
      attr: 'totalPayment'
    },
    {
      name: 'Held',
      attr: 'isHeld'
    }
  ];
  public previousPayments$: Observable<PaymentAdmin[]> = this.route.data.pipe(
    pluck('payments', 'previousPayments', 'data')
  );
  public upcomingPayments$: Observable<PaymentAdmin[]> = this.route.data.pipe(
    pluck<Data, any>('payments', 'upcomingPayments'),
    map(payment => ((payment && payment.length) ? payment : []))
    // map(payment => (payment && (payment.intervalStart || payment.intervalEnd) ? [payment] : []))
  );
  public previousPaymentsTotalCount = this.route.data.pipe(pluck('payments', 'previousPayments', 'maxCount'));
  public heldPayment = {
    items: this.route.data.pipe(pluck('heldPayments')),
    isExpand: false
  };

  public validationError: ValidationErrors;

  public selectedItem: PaymentAdminDetail;

  constructor(
    public paymentSvc: PaymentService,
    public route: ActivatedRoute,
    @Inject(MessageDialogServiceToken) private dialogService: MessageDialogServiceImpl,
  ) {}

  // We don't use this yet, as the pagination component does nothing currently, so it's not tested
  private getPayments(page) {
    this.paymentSvc
      .getListForAdmin(page, this.paymentSvc.itemsPerPage)
      .subscribe(({ previousPayments, upcomingPayments }) => {
        this.upcomingPayments$ =
          upcomingPayments // && (upcomingPayments.intervalStart || upcomingPayments.intervalEnd)
            ? of(upcomingPayments as any)
            : of([]);
        this.previousPayments$ = of(previousPayments.data);
        this.previousPaymentsTotalCount = of(previousPayments.maxCount);
      });
  }

  // We don't use this yet, as the pagination component does nothing currently, so it's not tested
  public getPage(pageObj) {
    this.paymentSvc.currentPage = pageObj.page - 1;
    this.getPayments(this.paymentSvc.currentPage);
  }

  public holdPayment({ form, modal }) {
    this.validationError = null;

    if (form.invalid) {
      return;
    }

    this.paymentSvc.holdPayment({ form, modal }, this.selectedItem).subscribe(
      result => {
        this.heldPayment.items = of(result);
        this.selectedItem.isHeld = !this.selectedItem.isHeld;
        this.closeFormModal({ form, modal });
      },
      (err: HttpErrorResponse) => {
        this.validationError = err.error;
      }
    );
  }

  public startPaymentAPI(item: PaymentAdminDetail) {
    this.selectedItem = item;
    this.payModal.startPayment(item).afterClosed().pipe(
      tap(res => this.getPayments(this.paymentSvc.currentPage)),
    ).subscribe(
      ()=>{},
      (err) => this.dialogService.openMessageDialog({
        title: "An Error Occurred",
        text: this.getPayErrorText(err),
        actions: [dialogButtons.ok],
      }),
    );
  }

  public startPayment(item: PaymentAdminDetail) {
    this.selectedItem = item;
    if (item && item.totalPayment) {
      this.payModal.openDialog(this.selectedItem.totalPayment).pipe(
        take(1),
        filter(res => res),
        flatMap((res) => {
          if (res) {
            const param: any = {
              transferGroup: this.selectedItem.transferGroup,
              password: res,
            };
            if (this.selectedItem.transferKey) {
              param.transferKey = this.selectedItem.transferKey;
            }
            return this.paymentSvc.payNow(param);
          }
          return of(false);
        }),
        tap(res => {
          this.getPayments(this.paymentSvc.currentPage);
        })
      ).subscribe(
        () => {},
        (err) => this.dialogService.openMessageDialog({
          title: "An Error Occurred",
          text: this.getPayErrorText(err),
          actions: [dialogButtons.ok],
        }),
      );
    }
  }

  getPayErrorText(err: any): string {
    if (err) {
      if ( err.error && err.error.message === "INVALID_CREDENTIALS") {
        return "Incorrect password."
      }
    }
    return "Please repeat your request later."
  }

  public payPayment({ form, modal }) {
    this.validationError = null;
    if (!form.invalid) {
      this.paymentSvc
        .payNow({
          transferGroup: this.selectedItem.transferGroup,
          password: form.value.password
        })
        .subscribe(
          result => {
            this.getPayments(this.paymentSvc.currentPage);
            this.closeFormModal({ form, modal });
          },
          (err: HttpErrorResponse) => {
            this.validationError = err.error;
          }
        );
    }
  }

  public closeFormModal({ form, modal }) {
    form.reset();
    modal.hide();
  }

  public getDetail(payment: PaymentAdmin) {
    this.paymentSvc.getDetail(payment, false);
  }

  public getUpcomingDetail(payment: PaymentAdmin) {
    this.paymentSvc.getDetail(payment, true);
  }

  public authorizePayment(payment: PaymentAdmin) {
    this.paymentSvc.authorizePayment(payment.transferGroup, payment.transferKey)
      .subscribe(
        () => {
          payment.isAuthorized = !payment.isAuthorized;
        },
        (err) => this.dialogService.openMessageDialog({
          title: "Error Authorizing Payment",
          text: "An error occured during payment authorization",
          actions: [dialogButtons.ok],
        }),
      );
  }

}
