import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

export interface INavigationGuardComponent {
    confirmNavigation(
        nextState: RouterStateSnapshot,
        ActivatedRouteSnapshot?: ActivatedRouteSnapshot,
        routerStateSnapshot?: RouterStateSnapshot
    ): Observable<boolean>|boolean;
}

@Injectable({
    providedIn: 'root',
  })
  export class NavigationGuard implements CanDeactivate<INavigationGuardComponent> {

    canDeactivate(
      component: INavigationGuardComponent,
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
      nextState: RouterStateSnapshot,
    ): Observable<boolean> | boolean {
        return component.confirmNavigation(nextState, route, state);
    }
  }