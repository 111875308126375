import {
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import {
  FormControl,
  NG_VALIDATORS,
  NgModel,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Directive({
  selector:
    '[appValidateIsEqual][ngModel],[appValidateIsEqual][formControl],[appValidateIsEqual][formControlName]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AppIsEqualValidatorDirective),
      multi: true
    }
  ]
})
export class AppIsEqualValidatorDirective implements Validator {
  @Input('appValidateIsEqual')
  model: NgModel;
  @Input()
  reverse = false;
  @Output()
  validationComplete = new EventEmitter<any>();

  validate(control: FormControl): null | ValidationErrors {
    const left = control.value;
    const right = this.model.value;
    // tslint:disable-next-line:triple-equals
    const isValid = left == right;

    if (this.model && this.model.control) {
      const ctrl = this.model.control;
      if (!isValid && !this.reverse) {
        const err = {
          validateIsEqual: false
        };
        return err;
      }

      if (!isValid && this.reverse) {
        const err = { validateIsEqual: false };
        ctrl.setErrors(err);
      }

      if (isValid && this.reverse) {
        if (ctrl.errors) {
          delete ctrl.errors['validateIsEqual'];
        }
        if (ctrl.errors === null) {
          ctrl.setErrors(null);
        }
      }

      return null;
    }
    throw new Error(`"#templateVar="ngModel" not set`);
  }
}
