import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-variable-alert-modal',
  templateUrl: './variable-alert-modal.component.html',
  styleUrls: ['./variable-alert-modal.component.scss']
})
export class VariableAlertModalComponent {
  @ViewChild(ModalComponent)
  modal: ModalComponent;
  @Input()
  headerText: string;
  @Input()
  bodyText: string;
  @Input()
  visible = false;
  @Input()
  visibleCancel = false;
  @Input()
  inProgress = false;
  @Input()
  center: boolean;
  @Input()
  okText: string;
  @Output()
  clickOk: EventEmitter<any> = new EventEmitter();

  showAlert(message: string) {
    this.visible = true;
    this.modal.show();
    this.bodyText = message;
  }

  onClick($event: MouseEvent) {
    this.clickOk.emit();
    this.modal.hide();
  }
}
