import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-char-limit',
  templateUrl: './char-limit.component.html',
  styleUrls: ['./char-limit.component.scss']
})
export class CharLimitComponent {
  @Input()
  maxCharLength: number;
  @Input()
  displayText: string;
  @Input()
  hasError: boolean;
  @Input()
  errorText: string;
}
