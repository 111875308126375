import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-payment-button',
  templateUrl: './payment-button.component.html',
  styleUrls: ['./payment-button.component.scss']
})
export class PaymentButtonComponent {
  @Input()
  payment;
  @Input()
  title;
  @Output()
  onClick = new EventEmitter();
}
