import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input()
  disabled: boolean;
  @Input()
  @HostBinding('class.isWhite')
  isWhite = false;
  @Input()
  text: string;
  @Input()
  isLoading = false;
  @Input()
  iconArrowForward = false;
  @Input()
  displayIcon = true;
  @Input()
  type;
  @Input()
  hasHover = false;
  @Input()
  onHoverText = '';
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onClick = new EventEmitter<MouseEvent>();

  handleClick(ev: MouseEvent) {
    this.onClick.emit(ev);
  }
}
