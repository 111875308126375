import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MaterialInputComponent } from 'app/common.module/components/material-input/material-input.component';
import { Subscription } from 'rxjs';
import { RegisterService } from '../../../services/register.service';
import { regxPatterns } from '@apps/components/dynamic-form/dynamic-form-validator.regexp';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Input()
  email: string;
  @Input()
  emailSent = false;
  @ViewChild(MaterialInputComponent)
  emailInput: MaterialInputComponent;

  subscription: Subscription;

  emailRegX = regxPatterns.email;

  constructor(private registerService: RegisterService) {}

  ngOnInit() {}

  resetPassword() {
    this.subscription = this.registerService.forgotPassword(this.email).subscribe(
      res => {
        this.emailSent = true;
      },
      ({ error: { code, message } }) => {
        this.emailInput.errors = { code, message };
        this.emailInput.errorText = message;
      }
    );
  }
}
