import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CountryType } from '@enums/country-type.enum';
import { AccountRepresentativeModel, DeveloperModel } from 'app/models/stripe-account';
import { AddressInputLabels } from '../../../common.module/components/address-form/address-form.component';

@Component({
  selector: 'app-representative-bank-detail',
  templateUrl: './representative-bank-detail.component.html',
  styleUrls: ['./representative-bank-detail.component.scss']
})
export class RepresentativeBankDetailComponent implements AfterViewInit {
  @Input()
  repIndex;
  @Input()
  readOnly = false;
  @Input()
  required = true;
  @Input()
  isWhite = false;
  @Input()
  parent: FormGroup;

  @Input()
  set countryTemplate(template: DeveloperModel) {
    if (!!template) {
      this._template = template;
    }
  }
  get countryTemplate() {
    return this._template;
  }
  private _template;

  get personalIDLabel() {
    const cntry = this.parent.get('stripeAccount.country').value as CountryType;
    switch (cntry) {
      case 'US':
        return 'SSN - Last 4';
      case 'CA':
        return 'Social Insurance Number';
      default:
        return 'Personal ID';
    }
  }

  get reps() {
    return this.countryTemplate.stripeAccount.accountRepresentatives;
  }

  get repNameAsControl() {
    return this.parent.get(['stripeAccount', 'accountRepresentatives', this.repIndex, 'name']);
  }

  get repsAsControls() {
    return this.parent.get(['stripeAccount', 'accountRepresentatives', this.repIndex]);
  }
  get addressLabels(): AddressInputLabels {
    return this._addressLabels;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  private readonly _addressLabels: AddressInputLabels = {
    addressOneLabel: 'Address #1',
    addressTwoLabel: 'Address #2',
    cityLabel: 'City',
    stateOrProvinceLabel: 'State/Province',
    zipOrPostalCodeLabel: 'Zip/Postal Code'
  };

  _value: AccountRepresentativeModel[];

  onChange: (value) => {};
  onTouched: () => {};

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
