import { BankModel } from './bank.model';
import { CompanyModel } from './company.model';
import { StripeBankDetails } from './stripe-account';

export enum UserStatus  {
  NOT_APPROVED = 'not_approved',
  SUSPENDED = 'suspended',
  APPROVED = 'approved',
  INACTIVE = 'inactive'
}

export class User {
  constructor(
    public userId?: string,
    public partnerId?: string,
    public emailAddress?: string,
    public firstName?: string,
    public lastName?: string,
    public companyName?: string,
    public recoveryEmail?: string,
    public marketingOptIn?: number,
    public roles?: string[],
    public token?: any,
    public password?: string,
    public phoneNumber?: string,
    public secondaryPhoneNumber?: string,
    public reasons: string[] = [],
    public developerName?: string,
    public publicEmail?: string,
    public companyDetails: CompanyModel = {
      developerName: undefined,
      name: undefined
    },
    public bankingDetails: BankModel = {
      numRouting: undefined,
      numAccount: undefined
    },
    public userAcceptedTerms?: boolean,
    public numApps?: number,
    public userAcceptedEula?: boolean,
    public userAcceptedDistribution?: boolean,
    public status: UserStatus = UserStatus.NOT_APPROVED,
    public isCompany?: boolean,
    public stripeBankDetails: StripeBankDetails = {
      accountNumber: null,
      bankCode: null,
      branchCode: null,
      bsb: null,
      country: null,
      iban: null,
      routingNumber: null,
      sortCode: null
    },
    public country?: string,
    public appCount?: number,
    createdAt?: string,
  ) {}
}
