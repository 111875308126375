import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStatus } from '../../../enums/app-status.enum';
import { LoginService } from '../../../services/login.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-mobile-tablet-main-menu',
  templateUrl: './mobile-tablet-main-menu.component.html',
  styleUrls: ['./mobile-tablet-main-menu.component.scss']
})
export class MobileTabletMainMenuComponent implements OnInit {
  @Input() user: any = null;
  @Input() isHideDetail: boolean;
  @Input() isNewApp = false;
  @Input() status: AppStatus;
  public hasNotSubmit = false;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private router: Router
  ) {
    this.user = userService.getUser();
  }

  ngOnInit() {
    this.hasNotSubmit = AppStatus[this.status] === AppStatus.not_submitted;
    this.isHideDetail =
      typeof this.isHideDetail !== 'undefined' ? this.isHideDetail : false;
  }

  isLoggedIn(): boolean {
    let shouldShow = false;
    // If the user.reasons prop is empty in the DB, it comes back as [""] (1 element: empty string), instead of just an empty array
    // TODO: Laszlo change the reasons prop to come back as a legitimately empty array if empty and change this to check against this.user.reasons.length > 0
    if (
      this.user &&
      this.user.reasons[0] !== '' &&
      this.user.userAcceptedTerms &&
      this.user.userAcceptedEula
    ) {
      shouldShow = true;
    }

    return shouldShow && !this.isHideDetail;
  }

  isAdmin(): boolean {
    return this.userService.isAdmin();
  }

  logout(): void {
    this.loginService.logout();
  }

  logoClick() {
    if (this.isLoggedIn()) {
      this.router.navigate([
        `/main/${this.userService.isAdmin() ? 'admin' : 'dashboard'}`
      ]);
    }
  }

}