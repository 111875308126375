export enum ApkStatus {
    PROCESSING = 'PROCESSING',
    MISSING_VERSION_CODE_OR_NAME = 'MISSING_VERSION_CODE_OR_NAME',
    INVALID_FILE = 'INVALID_FILE',
    INVALID_PACKAGEID = 'INVALID_PACKAGEID',
    INVALID_CERTIFICATE = 'INVALID_CERTIFICATE',
    INVALID_VERSION_NAME = 'INVALID_VERSION_NAME',
    INVALID_VERSION_CODE = 'INVALID_VERSION_CODE',
    INVALID_PACKAGE_DATA = 'INVALID_PACKAGE_DATA',
    INVALID_CERTIFICATE_AND_VERSIONCODE = 'INVALID_CERTIFICATE_AND_VERSIONCODE'
}