﻿import { CountryType } from 'app/enums/country-type.enum';
import { IDynFormFieldOption } from '@apps/components/dynamic-form/dynamic-form.service';

export interface CountryCode {
  name: string;
  altName?: string;
  alpha2?: CountryType;
  alpha3?: string;
  numeric?: string;
}

export interface ICountry {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  nativeName: string;
  numericCode: string;
}

export const pubDetSupportedEUCountries = ['AT', 'FR', 'BE', 'SE', 'DE', 'DK', 'ES', 'FI', 'IT', 'LU', 'NL', 'NO', 'PT', 'CH'];
export const pubDetSupportedNonEUCountries = ['GB', 'US', 'AU', 'CA', 'IE'];

export const stateCA: IDynFormFieldOption[] = [
  { id:"AB", name:"Alberta" },
  { id:"BC", name:"British Columbia" },
  { id:"MB", name:"Manitoba" },
  { id:"NB", name:"New Brunswick" },
  { id:"NL", name:"Newfoundland and Labrador" },
  { id:"NS", name:"Nova Scotia" },
  { id:"ON", name:"Ontario" },
  { id:"PE", name:"Prince Edward Island" },
  { id:"QC", name:"Quebec" },
  { id:"SK", name:"Saskatchewan" },
];

export const stateAU: IDynFormFieldOption[] = [
    {
        "name":"New South Wales",
        "id":"NSW"
    },
    {
        "name":"Victoria",
        "id":"VIC"
    },
    {
        "name":"Queensland",
        "id":"QLD"
    },
    {
        "name":"Tasmania",
        "id":"TAS"
    },
    {
        "name":"South Australia",
        "id":"SA"
    },
    {
        "name":"Western Australia",
        "id":"WA"
    },
    {
        "name":"Northern Territory",
        "id":"NT"
    },
    {
        "name":"Australian Capital Territory",
        "id":"ACT"
    }
];

export const stateIE: IDynFormFieldOption[] = [
  {
     "name":"Co. Antrim",
     "id":"ANT"
  },
  {
     "name":"Co. Armagh",
     "id":"ARM"
  },
  {
     "name":"Co. Carlow",
     "id":"CAR"
  },
  {
     "name":"Co. Cavan",
     "id":"CAV"
  },
  {
     "name":"Co. Clare",
     "id":"CLA"
  },
  {
     "name":"Co. Cork",
     "id":"COR"
  },
  {
     "name":"Co. Donegal",
     "id":"DON"
  },
  {
     "name":"Co. Down",
     "id":"DOW"
  },
  {
     "name":"Co. Dublin",
     "id":"DUB"
  },
  {
     "name":"Co. Fermanagh",
     "id":"FER"
  },
  {
     "name":"Co. Galway",
     "id":"GAL"
  },
  {
     "name":"Co. Kerry",
     "id":"KER"
  },
  {
     "name":"Co. Kildare",
     "id":"KID"
  },
  {
     "name":"Co. Kilkenny",
     "id":"KIK"
  },
  {
     "name":"Co. Leitrim",
     "id":"LET"
  },
  {
     "name":"Co. Leix",
     "id":"LEX"
  },
  {
     "name":"Co. Limerick",
     "id":"LIM"
  },
  {
     "name":"Co. Londonderry",
     "id":"LDY"
  },
  {
     "name":"Co. Longford",
     "id":"LOG"
  },
  {
     "name":"Co. Louth",
     "id":"LOU"
  },
  {
     "name":"Co. Mayo",
     "id":"MAY"
  },
  {
     "name":"Co. Meath",
     "id":"MEA"
  },
  {
     "name":"Co. Monaghan",
     "id":"MOG"
  },
  {
     "name":"Co. Offaly",
     "id":"OFF"
  },
  {
     "name":"Co. Roscommon",
     "id":"ROS"
  },
  {
     "name":"Co. Sligo",
     "id":"SLI"
  },
  {
     "name":"Co. Tipperary",
     "id":"TIP"
  },
  {
     "name":"Co. Tyrone",
     "id":"TYR"
  },
  {
     "name":"Co. Waterford",
     "id":"WAT"
  },
  {
     "name":"Co. Westmeath",
     "id":"WEM"
  },
  {
     "name":"Co. Wexford",
     "id":"WEX"
  },
  {
     "name":"Co. Wicklow",
     "id":"WIC"
  }
];

export const stateUS: IDynFormFieldOption[] = [
    {
        "name": "Alabama",
        "id": "AL"
    },
    {
        "name": "Alaska",
        "id": "AK"
    },
    {
        "name": "American Samoa",
        "id": "AS"
    },
    {
        "name": "Arizona",
        "id": "AZ"
    },
    {
        "name": "Arkansas",
        "id": "AR"
    },
    {
        "name": "California",
        "id": "CA"
    },
    {
        "name": "Colorado",
        "id": "CO"
    },
    {
        "name": "Connecticut",
        "id": "CT"
    },
    {
        "name": "Delaware",
        "id": "DE"
    },
    {
        "name": "District Of Columbia",
        "id": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "id": "FM"
    },
    {
        "name": "Florida",
        "id": "FL"
    },
    {
        "name": "Georgia",
        "id": "GA"
    },
    {
        "name": "Guam",
        "id": "GU"
    },
    {
        "name": "Hawaii",
        "id": "HI"
    },
    {
        "name": "Idaho",
        "id": "ID"
    },
    {
        "name": "Illinois",
        "id": "IL"
    },
    {
        "name": "Indiana",
        "id": "IN"
    },
    {
        "name": "Iowa",
        "id": "IA"
    },
    {
        "name": "Kansas",
        "id": "KS"
    },
    {
        "name": "Kentucky",
        "id": "KY"
    },
    {
        "name": "Louisiana",
        "id": "LA"
    },
    {
        "name": "Maine",
        "id": "ME"
    },
    {
        "name": "Marshall Islands",
        "id": "MH"
    },
    {
        "name": "Maryland",
        "id": "MD"
    },
    {
        "name": "Massachusetts",
        "id": "MA"
    },
    {
        "name": "Michigan",
        "id": "MI"
    },
    {
        "name": "Minnesota",
        "id": "MN"
    },
    {
        "name": "Mississippi",
        "id": "MS"
    },
    {
        "name": "Missouri",
        "id": "MO"
    },
    {
        "name": "Montana",
        "id": "MT"
    },
    {
        "name": "Nebraska",
        "id": "NE"
    },
    {
        "name": "Nevada",
        "id": "NV"
    },
    {
        "name": "New Hampshire",
        "id": "NH"
    },
    {
        "name": "New Jersey",
        "id": "NJ"
    },
    {
        "name": "New Mexico",
        "id": "NM"
    },
    {
        "name": "New York",
        "id": "NY"
    },
    {
        "name": "North Carolina",
        "id": "NC"
    },
    {
        "name": "North Dakota",
        "id": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "id": "MP"
    },
    {
        "name": "Ohio",
        "id": "OH"
    },
    {
        "name": "Oklahoma",
        "id": "OK"
    },
    {
        "name": "Oregon",
        "id": "OR"
    },
    {
        "name": "Palau",
        "id": "PW"
    },
    {
        "name": "Pennsylvania",
        "id": "PA"
    },
    {
        "name": "Puerto Rico",
        "id": "PR"
    },
    {
        "name": "Rhode Island",
        "id": "RI"
    },
    {
        "name": "South Carolina",
        "id": "SC"
    },
    {
        "name": "South Dakota",
        "id": "SD"
    },
    {
        "name": "Tennessee",
        "id": "TN"
    },
    {
        "name": "Texas",
        "id": "TX"
    },
    {
        "name": "Utah",
        "id": "UT"
    },
    {
        "name": "Vermont",
        "id": "VT"
    },
    {
        "name": "Virgin Islands",
        "id": "VI"
    },
    {
        "name": "Virginia",
        "id": "VA"
    },
    {
        "name": "Washington",
        "id": "WA"
    },
    {
        "name": "West Virginia",
        "id": "WV"
    },
    {
        "name": "Wisconsin",
        "id": "WI"
    },
    {
        "name": "Wyoming",
        "id": "WY"
    }
];



export const countriesFull: ICountry[] = [
  {
     "name":"Afghanistan",
     "alpha2Code":"AF",
     "alpha3Code":"AFG",
     "nativeName":"افغانستان",
     "numericCode":"004"
  },
  {
     "name":"Åland Islands",
     "alpha2Code":"AX",
     "alpha3Code":"ALA",
     "nativeName":"Åland",
     "numericCode":"248"
  },
  {
     "name":"Albania",
     "alpha2Code":"AL",
     "alpha3Code":"ALB",
     "nativeName":"Shqipëria",
     "numericCode":"008"
  },
  {
     "name":"Algeria",
     "alpha2Code":"DZ",
     "alpha3Code":"DZA",
     "nativeName":"الجزائر",
     "numericCode":"012"
  },
  {
     "name":"American Samoa",
     "alpha2Code":"AS",
     "alpha3Code":"ASM",
     "nativeName":"American Samoa",
     "numericCode":"016"
  },
  {
     "name":"Andorra",
     "alpha2Code":"AD",
     "alpha3Code":"AND",
     "nativeName":"Andorra",
     "numericCode":"020"
  },
  {
     "name":"Angola",
     "alpha2Code":"AO",
     "alpha3Code":"AGO",
     "nativeName":"Angola",
     "numericCode":"024"
  },
  {
     "name":"Anguilla",
     "alpha2Code":"AI",
     "alpha3Code":"AIA",
     "nativeName":"Anguilla",
     "numericCode":"660"
  },
  {
     "name":"Antarctica",
     "alpha2Code":"AQ",
     "alpha3Code":"ATA",
     "nativeName":"Antarctica",
     "numericCode":"010"
  },
  {
     "name":"Antigua and Barbuda",
     "alpha2Code":"AG",
     "alpha3Code":"ATG",
     "nativeName":"Antigua and Barbuda",
     "numericCode":"028"
  },
  {
     "name":"Argentina",
     "alpha2Code":"AR",
     "alpha3Code":"ARG",
     "nativeName":"Argentina",
     "numericCode":"032"
  },
  {
     "name":"Armenia",
     "alpha2Code":"AM",
     "alpha3Code":"ARM",
     "nativeName":"Հայաստան",
     "numericCode":"051"
  },
  {
     "name":"Aruba",
     "alpha2Code":"AW",
     "alpha3Code":"ABW",
     "nativeName":"Aruba",
     "numericCode":"533"
  },
  {
     "name":"Australia",
     "alpha2Code":"AU",
     "alpha3Code":"AUS",
     "nativeName":"Australia",
     "numericCode":"036"
  },
  {
     "name":"Austria",
     "alpha2Code":"AT",
     "alpha3Code":"AUT",
     "nativeName":"Österreich",
     "numericCode":"040"
  },
  {
     "name":"Azerbaijan",
     "alpha2Code":"AZ",
     "alpha3Code":"AZE",
     "nativeName":"Azərbaycan",
     "numericCode":"031"
  },
  {
     "name":"Bahamas",
     "alpha2Code":"BS",
     "alpha3Code":"BHS",
     "nativeName":"Bahamas",
     "numericCode":"044"
  },
  {
     "name":"Bahrain",
     "alpha2Code":"BH",
     "alpha3Code":"BHR",
     "nativeName":"‏البحرين",
     "numericCode":"048"
  },
  {
     "name":"Bangladesh",
     "alpha2Code":"BD",
     "alpha3Code":"BGD",
     "nativeName":"Bangladesh",
     "numericCode":"050"
  },
  {
     "name":"Barbados",
     "alpha2Code":"BB",
     "alpha3Code":"BRB",
     "nativeName":"Barbados",
     "numericCode":"052"
  },
  {
     "name":"Belarus",
     "alpha2Code":"BY",
     "alpha3Code":"BLR",
     "nativeName":"Белару́сь",
     "numericCode":"112"
  },
  {
     "name":"Belgium",
     "alpha2Code":"BE",
     "alpha3Code":"BEL",
     "nativeName":"België",
     "numericCode":"056"
  },
  {
     "name":"Belize",
     "alpha2Code":"BZ",
     "alpha3Code":"BLZ",
     "nativeName":"Belize",
     "numericCode":"084"
  },
  {
     "name":"Benin",
     "alpha2Code":"BJ",
     "alpha3Code":"BEN",
     "nativeName":"Bénin",
     "numericCode":"204"
  },
  {
     "name":"Bermuda",
     "alpha2Code":"BM",
     "alpha3Code":"BMU",
     "nativeName":"Bermuda",
     "numericCode":"060"
  },
  {
     "name":"Bhutan",
     "alpha2Code":"BT",
     "alpha3Code":"BTN",
     "nativeName":"ʼbrug-yul",
     "numericCode":"064"
  },
  {
     "name":"Bolivia (Plurinational State of)",
     "alpha2Code":"BO",
     "alpha3Code":"BOL",
     "nativeName":"Bolivia",
     "numericCode":"068"
  },
  {
     "name":"Bonaire, Sint Eustatius and Saba",
     "alpha2Code":"BQ",
     "alpha3Code":"BES",
     "nativeName":"Bonaire",
     "numericCode":"535"
  },
  {
     "name":"Bosnia and Herzegovina",
     "alpha2Code":"BA",
     "alpha3Code":"BIH",
     "nativeName":"Bosna i Hercegovina",
     "numericCode":"070"
  },
  {
     "name":"Botswana",
     "alpha2Code":"BW",
     "alpha3Code":"BWA",
     "nativeName":"Botswana",
     "numericCode":"072"
  },
  {
     "name":"Bouvet Island",
     "alpha2Code":"BV",
     "alpha3Code":"BVT",
     "nativeName":"Bouvetøya",
     "numericCode":"074"
  },
  {
     "name":"Brazil",
     "alpha2Code":"BR",
     "alpha3Code":"BRA",
     "nativeName":"Brasil",
     "numericCode":"076"
  },
  {
     "name":"British Indian Ocean Territory",
     "alpha2Code":"IO",
     "alpha3Code":"IOT",
     "nativeName":"British Indian Ocean Territory",
     "numericCode":"086"
  },
  {
     "name":"United States Minor Outlying Islands",
     "alpha2Code":"UM",
     "alpha3Code":"UMI",
     "nativeName":"United States Minor Outlying Islands",
     "numericCode":"581"
  },
  {
     "name":"Virgin Islands (British)",
     "alpha2Code":"VG",
     "alpha3Code":"VGB",
     "nativeName":"British Virgin Islands",
     "numericCode":"092"
  },
  {
     "name":"Virgin Islands (U.S.)",
     "alpha2Code":"VI",
     "alpha3Code":"VIR",
     "nativeName":"Virgin Islands of the United States",
     "numericCode":"850"
  },
  {
     "name":"Brunei Darussalam",
     "alpha2Code":"BN",
     "alpha3Code":"BRN",
     "nativeName":"Negara Brunei Darussalam",
     "numericCode":"096"
  },
  {
     "name":"Bulgaria",
     "alpha2Code":"BG",
     "alpha3Code":"BGR",
     "nativeName":"България",
     "numericCode":"100"
  },
  {
     "name":"Burkina Faso",
     "alpha2Code":"BF",
     "alpha3Code":"BFA",
     "nativeName":"Burkina Faso",
     "numericCode":"854"
  },
  {
     "name":"Burundi",
     "alpha2Code":"BI",
     "alpha3Code":"BDI",
     "nativeName":"Burundi",
     "numericCode":"108"
  },
  {
     "name":"Cambodia",
     "alpha2Code":"KH",
     "alpha3Code":"KHM",
     "nativeName":"Kâmpŭchéa",
     "numericCode":"116"
  },
  {
     "name":"Cameroon",
     "alpha2Code":"CM",
     "alpha3Code":"CMR",
     "nativeName":"Cameroon",
     "numericCode":"120"
  },
  {
     "name":"Canada",
     "alpha2Code":"CA",
     "alpha3Code":"CAN",
     "nativeName":"Canada",
     "numericCode":"124"
  },
  {
     "name":"Cabo Verde",
     "alpha2Code":"CV",
     "alpha3Code":"CPV",
     "nativeName":"Cabo Verde",
     "numericCode":"132"
  },
  {
     "name":"Cayman Islands",
     "alpha2Code":"KY",
     "alpha3Code":"CYM",
     "nativeName":"Cayman Islands",
     "numericCode":"136"
  },
  {
     "name":"Central African Republic",
     "alpha2Code":"CF",
     "alpha3Code":"CAF",
     "nativeName":"Ködörösêse tî Bêafrîka",
     "numericCode":"140"
  },
  {
     "name":"Chad",
     "alpha2Code":"TD",
     "alpha3Code":"TCD",
     "nativeName":"Tchad",
     "numericCode":"148"
  },
  {
     "name":"Chile",
     "alpha2Code":"CL",
     "alpha3Code":"CHL",
     "nativeName":"Chile",
     "numericCode":"152"
  },
  {
     "name":"China",
     "alpha2Code":"CN",
     "alpha3Code":"CHN",
     "nativeName":"中国",
     "numericCode":"156"
  },
  {
     "name":"Christmas Island",
     "alpha2Code":"CX",
     "alpha3Code":"CXR",
     "nativeName":"Christmas Island",
     "numericCode":"162"
  },
  {
     "name":"Cocos (Keeling) Islands",
     "alpha2Code":"CC",
     "alpha3Code":"CCK",
     "nativeName":"Cocos (Keeling) Islands",
     "numericCode":"166"
  },
  {
     "name":"Colombia",
     "alpha2Code":"CO",
     "alpha3Code":"COL",
     "nativeName":"Colombia",
     "numericCode":"170"
  },
  {
     "name":"Comoros",
     "alpha2Code":"KM",
     "alpha3Code":"COM",
     "nativeName":"Komori",
     "numericCode":"174"
  },
  {
     "name":"Congo",
     "alpha2Code":"CG",
     "alpha3Code":"COG",
     "nativeName":"République du Congo",
     "numericCode":"178"
  },
  {
     "name":"Congo (Democratic Republic of the)",
     "alpha2Code":"CD",
     "alpha3Code":"COD",
     "nativeName":"République démocratique du Congo",
     "numericCode":"180"
  },
  {
     "name":"Cook Islands",
     "alpha2Code":"CK",
     "alpha3Code":"COK",
     "nativeName":"Cook Islands",
     "numericCode":"184"
  },
  {
     "name":"Costa Rica",
     "alpha2Code":"CR",
     "alpha3Code":"CRI",
     "nativeName":"Costa Rica",
     "numericCode":"188"
  },
  {
     "name":"Croatia",
     "alpha2Code":"HR",
     "alpha3Code":"HRV",
     "nativeName":"Hrvatska",
     "numericCode":"191"
  },
  {
     "name":"Cuba",
     "alpha2Code":"CU",
     "alpha3Code":"CUB",
     "nativeName":"Cuba",
     "numericCode":"192"
  },
  {
     "name":"Curaçao",
     "alpha2Code":"CW",
     "alpha3Code":"CUW",
     "nativeName":"Curaçao",
     "numericCode":"531"
  },
  {
     "name":"Cyprus",
     "alpha2Code":"CY",
     "alpha3Code":"CYP",
     "nativeName":"Κύπρος",
     "numericCode":"196"
  },
  {
     "name":"Czech Republic",
     "alpha2Code":"CZ",
     "alpha3Code":"CZE",
     "nativeName":"Česká republika",
     "numericCode":"203"
  },
  {
     "name":"Denmark",
     "alpha2Code":"DK",
     "alpha3Code":"DNK",
     "nativeName":"Danmark",
     "numericCode":"208"
  },
  {
     "name":"Djibouti",
     "alpha2Code":"DJ",
     "alpha3Code":"DJI",
     "nativeName":"Djibouti",
     "numericCode":"262"
  },
  {
     "name":"Dominica",
     "alpha2Code":"DM",
     "alpha3Code":"DMA",
     "nativeName":"Dominica",
     "numericCode":"212"
  },
  {
     "name":"Dominican Republic",
     "alpha2Code":"DO",
     "alpha3Code":"DOM",
     "nativeName":"República Dominicana",
     "numericCode":"214"
  },
  {
     "name":"Ecuador",
     "alpha2Code":"EC",
     "alpha3Code":"ECU",
     "nativeName":"Ecuador",
     "numericCode":"218"
  },
  {
     "name":"Egypt",
     "alpha2Code":"EG",
     "alpha3Code":"EGY",
     "nativeName":"مصر‎",
     "numericCode":"818"
  },
  {
     "name":"El Salvador",
     "alpha2Code":"SV",
     "alpha3Code":"SLV",
     "nativeName":"El Salvador",
     "numericCode":"222"
  },
  {
     "name":"Equatorial Guinea",
     "alpha2Code":"GQ",
     "alpha3Code":"GNQ",
     "nativeName":"Guinea Ecuatorial",
     "numericCode":"226"
  },
  {
     "name":"Eritrea",
     "alpha2Code":"ER",
     "alpha3Code":"ERI",
     "nativeName":"ኤርትራ",
     "numericCode":"232"
  },
  {
     "name":"Estonia",
     "alpha2Code":"EE",
     "alpha3Code":"EST",
     "nativeName":"Eesti",
     "numericCode":"233"
  },
  {
     "name":"Ethiopia",
     "alpha2Code":"ET",
     "alpha3Code":"ETH",
     "nativeName":"ኢትዮጵያ",
     "numericCode":"231"
  },
  {
     "name":"Falkland Islands (Malvinas)",
     "alpha2Code":"FK",
     "alpha3Code":"FLK",
     "nativeName":"Falkland Islands",
     "numericCode":"238"
  },
  {
     "name":"Faroe Islands",
     "alpha2Code":"FO",
     "alpha3Code":"FRO",
     "nativeName":"Føroyar",
     "numericCode":"234"
  },
  {
     "name":"Fiji",
     "alpha2Code":"FJ",
     "alpha3Code":"FJI",
     "nativeName":"Fiji",
     "numericCode":"242"
  },
  {
     "name":"Finland",
     "alpha2Code":"FI",
     "alpha3Code":"FIN",
     "nativeName":"Suomi",
     "numericCode":"246"
  },
  {
     "name":"France",
     "alpha2Code":"FR",
     "alpha3Code":"FRA",
     "nativeName":"France",
     "numericCode":"250"
  },
  {
     "name":"French Guiana",
     "alpha2Code":"GF",
     "alpha3Code":"GUF",
     "nativeName":"Guyane française",
     "numericCode":"254"
  },
  {
     "name":"French Polynesia",
     "alpha2Code":"PF",
     "alpha3Code":"PYF",
     "nativeName":"Polynésie française",
     "numericCode":"258"
  },
  {
     "name":"French Southern Territories",
     "alpha2Code":"TF",
     "alpha3Code":"ATF",
     "nativeName":"Territoire des Terres australes et antarctiques françaises",
     "numericCode":"260"
  },
  {
     "name":"Gabon",
     "alpha2Code":"GA",
     "alpha3Code":"GAB",
     "nativeName":"Gabon",
     "numericCode":"266"
  },
  {
     "name":"Gambia",
     "alpha2Code":"GM",
     "alpha3Code":"GMB",
     "nativeName":"Gambia",
     "numericCode":"270"
  },
  {
     "name":"Georgia",
     "alpha2Code":"GE",
     "alpha3Code":"GEO",
     "nativeName":"საქართველო",
     "numericCode":"268"
  },
  {
     "name":"Germany",
     "alpha2Code":"DE",
     "alpha3Code":"DEU",
     "nativeName":"Deutschland",
     "numericCode":"276"
  },
  {
     "name":"Ghana",
     "alpha2Code":"GH",
     "alpha3Code":"GHA",
     "nativeName":"Ghana",
     "numericCode":"288"
  },
  {
     "name":"Gibraltar",
     "alpha2Code":"GI",
     "alpha3Code":"GIB",
     "nativeName":"Gibraltar",
     "numericCode":"292"
  },
  {
     "name":"Greece",
     "alpha2Code":"GR",
     "alpha3Code":"GRC",
     "nativeName":"Ελλάδα",
     "numericCode":"300"
  },
  {
     "name":"Greenland",
     "alpha2Code":"GL",
     "alpha3Code":"GRL",
     "nativeName":"Kalaallit Nunaat",
     "numericCode":"304"
  },
  {
     "name":"Grenada",
     "alpha2Code":"GD",
     "alpha3Code":"GRD",
     "nativeName":"Grenada",
     "numericCode":"308"
  },
  {
     "name":"Guadeloupe",
     "alpha2Code":"GP",
     "alpha3Code":"GLP",
     "nativeName":"Guadeloupe",
     "numericCode":"312"
  },
  {
     "name":"Guam",
     "alpha2Code":"GU",
     "alpha3Code":"GUM",
     "nativeName":"Guam",
     "numericCode":"316"
  },
  {
     "name":"Guatemala",
     "alpha2Code":"GT",
     "alpha3Code":"GTM",
     "nativeName":"Guatemala",
     "numericCode":"320"
  },
  {
     "name":"Guernsey",
     "alpha2Code":"GG",
     "alpha3Code":"GGY",
     "nativeName":"Guernsey",
     "numericCode":"831"
  },
  {
     "name":"Guinea",
     "alpha2Code":"GN",
     "alpha3Code":"GIN",
     "nativeName":"Guinée",
     "numericCode":"324"
  },
  {
     "name":"Guinea-Bissau",
     "alpha2Code":"GW",
     "alpha3Code":"GNB",
     "nativeName":"Guiné-Bissau",
     "numericCode":"624"
  },
  {
     "name":"Guyana",
     "alpha2Code":"GY",
     "alpha3Code":"GUY",
     "nativeName":"Guyana",
     "numericCode":"328"
  },
  {
     "name":"Haiti",
     "alpha2Code":"HT",
     "alpha3Code":"HTI",
     "nativeName":"Haïti",
     "numericCode":"332"
  },
  {
     "name":"Heard Island and McDonald Islands",
     "alpha2Code":"HM",
     "alpha3Code":"HMD",
     "nativeName":"Heard Island and McDonald Islands",
     "numericCode":"334"
  },
  {
     "name":"Holy See",
     "alpha2Code":"VA",
     "alpha3Code":"VAT",
     "nativeName":"Sancta Sedes",
     "numericCode":"336"
  },
  {
     "name":"Honduras",
     "alpha2Code":"HN",
     "alpha3Code":"HND",
     "nativeName":"Honduras",
     "numericCode":"340"
  },
  {
     "name":"Hong Kong",
     "alpha2Code":"HK",
     "alpha3Code":"HKG",
     "nativeName":"香港",
     "numericCode":"344"
  },
  {
     "name":"Hungary",
     "alpha2Code":"HU",
     "alpha3Code":"HUN",
     "nativeName":"Magyarország",
     "numericCode":"348"
  },
  {
     "name":"Iceland",
     "alpha2Code":"IS",
     "alpha3Code":"ISL",
     "nativeName":"Ísland",
     "numericCode":"352"
  },
  {
     "name":"India",
     "alpha2Code":"IN",
     "alpha3Code":"IND",
     "nativeName":"भारत",
     "numericCode":"356"
  },
  {
     "name":"Indonesia",
     "alpha2Code":"ID",
     "alpha3Code":"IDN",
     "nativeName":"Indonesia",
     "numericCode":"360"
  },
  {
     "name":"Côte d'Ivoire",
     "alpha2Code":"CI",
     "alpha3Code":"CIV",
     "nativeName":"Côte d'Ivoire",
     "numericCode":"384"
  },
  {
     "name":"Iran (Islamic Republic of)",
     "alpha2Code":"IR",
     "alpha3Code":"IRN",
     "nativeName":"ایران",
     "numericCode":"364"
  },
  {
     "name":"Iraq",
     "alpha2Code":"IQ",
     "alpha3Code":"IRQ",
     "nativeName":"العراق",
     "numericCode":"368"
  },
  {
     "name":"Ireland",
     "alpha2Code":"IE",
     "alpha3Code":"IRL",
     "nativeName":"Éire",
     "numericCode":"372"
  },
  {
     "name":"Isle of Man",
     "alpha2Code":"IM",
     "alpha3Code":"IMN",
     "nativeName":"Isle of Man",
     "numericCode":"833"
  },
  {
     "name":"Israel",
     "alpha2Code":"IL",
     "alpha3Code":"ISR",
     "nativeName":"יִשְׂרָאֵל",
     "numericCode":"376"
  },
  {
     "name":"Italy",
     "alpha2Code":"IT",
     "alpha3Code":"ITA",
     "nativeName":"Italia",
     "numericCode":"380"
  },
  {
     "name":"Jamaica",
     "alpha2Code":"JM",
     "alpha3Code":"JAM",
     "nativeName":"Jamaica",
     "numericCode":"388"
  },
  {
     "name":"Japan",
     "alpha2Code":"JP",
     "alpha3Code":"JPN",
     "nativeName":"日本",
     "numericCode":"392"
  },
  {
     "name":"Jersey",
     "alpha2Code":"JE",
     "alpha3Code":"JEY",
     "nativeName":"Jersey",
     "numericCode":"832"
  },
  {
     "name":"Jordan",
     "alpha2Code":"JO",
     "alpha3Code":"JOR",
     "nativeName":"الأردن",
     "numericCode":"400"
  },
  {
     "name":"Kazakhstan",
     "alpha2Code":"KZ",
     "alpha3Code":"KAZ",
     "nativeName":"Қазақстан",
     "numericCode":"398"
  },
  {
     "name":"Kenya",
     "alpha2Code":"KE",
     "alpha3Code":"KEN",
     "nativeName":"Kenya",
     "numericCode":"404"
  },
  {
     "name":"Kiribati",
     "alpha2Code":"KI",
     "alpha3Code":"KIR",
     "nativeName":"Kiribati",
     "numericCode":"296"
  },
  {
     "name":"Kuwait",
     "alpha2Code":"KW",
     "alpha3Code":"KWT",
     "nativeName":"الكويت",
     "numericCode":"414"
  },
  {
     "name":"Kyrgyzstan",
     "alpha2Code":"KG",
     "alpha3Code":"KGZ",
     "nativeName":"Кыргызстан",
     "numericCode":"417"
  },
  {
     "name":"Lao People's Democratic Republic",
     "alpha2Code":"LA",
     "alpha3Code":"LAO",
     "nativeName":"ສປປລາວ",
     "numericCode":"418"
  },
  {
     "name":"Latvia",
     "alpha2Code":"LV",
     "alpha3Code":"LVA",
     "nativeName":"Latvija",
     "numericCode":"428"
  },
  {
     "name":"Lebanon",
     "alpha2Code":"LB",
     "alpha3Code":"LBN",
     "nativeName":"لبنان",
     "numericCode":"422"
  },
  {
     "name":"Lesotho",
     "alpha2Code":"LS",
     "alpha3Code":"LSO",
     "nativeName":"Lesotho",
     "numericCode":"426"
  },
  {
     "name":"Liberia",
     "alpha2Code":"LR",
     "alpha3Code":"LBR",
     "nativeName":"Liberia",
     "numericCode":"430"
  },
  {
     "name":"Libya",
     "alpha2Code":"LY",
     "alpha3Code":"LBY",
     "nativeName":"‏ليبيا",
     "numericCode":"434"
  },
  {
     "name":"Liechtenstein",
     "alpha2Code":"LI",
     "alpha3Code":"LIE",
     "nativeName":"Liechtenstein",
     "numericCode":"438"
  },
  {
     "name":"Lithuania",
     "alpha2Code":"LT",
     "alpha3Code":"LTU",
     "nativeName":"Lietuva",
     "numericCode":"440"
  },
  {
     "name":"Luxembourg",
     "alpha2Code":"LU",
     "alpha3Code":"LUX",
     "nativeName":"Luxembourg",
     "numericCode":"442"
  },
  {
     "name":"Macao",
     "alpha2Code":"MO",
     "alpha3Code":"MAC",
     "nativeName":"澳門",
     "numericCode":"446"
  },
  {
     "name":"Macedonia (the former Yugoslav Republic of)",
     "alpha2Code":"MK",
     "alpha3Code":"MKD",
     "nativeName":"Македонија",
     "numericCode":"807"
  },
  {
     "name":"Madagascar",
     "alpha2Code":"MG",
     "alpha3Code":"MDG",
     "nativeName":"Madagasikara",
     "numericCode":"450"
  },
  {
     "name":"Malawi",
     "alpha2Code":"MW",
     "alpha3Code":"MWI",
     "nativeName":"Malawi",
     "numericCode":"454"
  },
  {
     "name":"Malaysia",
     "alpha2Code":"MY",
     "alpha3Code":"MYS",
     "nativeName":"Malaysia",
     "numericCode":"458"
  },
  {
     "name":"Maldives",
     "alpha2Code":"MV",
     "alpha3Code":"MDV",
     "nativeName":"Maldives",
     "numericCode":"462"
  },
  {
     "name":"Mali",
     "alpha2Code":"ML",
     "alpha3Code":"MLI",
     "nativeName":"Mali",
     "numericCode":"466"
  },
  {
     "name":"Malta",
     "alpha2Code":"MT",
     "alpha3Code":"MLT",
     "nativeName":"Malta",
     "numericCode":"470"
  },
  {
     "name":"Marshall Islands",
     "alpha2Code":"MH",
     "alpha3Code":"MHL",
     "nativeName":"M̧ajeļ",
     "numericCode":"584"
  },
  {
     "name":"Martinique",
     "alpha2Code":"MQ",
     "alpha3Code":"MTQ",
     "nativeName":"Martinique",
     "numericCode":"474"
  },
  {
     "name":"Mauritania",
     "alpha2Code":"MR",
     "alpha3Code":"MRT",
     "nativeName":"موريتانيا",
     "numericCode":"478"
  },
  {
     "name":"Mauritius",
     "alpha2Code":"MU",
     "alpha3Code":"MUS",
     "nativeName":"Maurice",
     "numericCode":"480"
  },
  {
     "name":"Mayotte",
     "alpha2Code":"YT",
     "alpha3Code":"MYT",
     "nativeName":"Mayotte",
     "numericCode":"175"
  },
  {
     "name":"Mexico",
     "alpha2Code":"MX",
     "alpha3Code":"MEX",
     "nativeName":"México",
     "numericCode":"484"
  },
  {
     "name":"Micronesia (Federated States of)",
     "alpha2Code":"FM",
     "alpha3Code":"FSM",
     "nativeName":"Micronesia",
     "numericCode":"583"
  },
  {
     "name":"Moldova (Republic of)",
     "alpha2Code":"MD",
     "alpha3Code":"MDA",
     "nativeName":"Moldova",
     "numericCode":"498"
  },
  {
     "name":"Monaco",
     "alpha2Code":"MC",
     "alpha3Code":"MCO",
     "nativeName":"Monaco",
     "numericCode":"492"
  },
  {
     "name":"Mongolia",
     "alpha2Code":"MN",
     "alpha3Code":"MNG",
     "nativeName":"Монгол улс",
     "numericCode":"496"
  },
  {
     "name":"Montenegro",
     "alpha2Code":"ME",
     "alpha3Code":"MNE",
     "nativeName":"Црна Гора",
     "numericCode":"499"
  },
  {
     "name":"Montserrat",
     "alpha2Code":"MS",
     "alpha3Code":"MSR",
     "nativeName":"Montserrat",
     "numericCode":"500"
  },
  {
     "name":"Morocco",
     "alpha2Code":"MA",
     "alpha3Code":"MAR",
     "nativeName":"المغرب",
     "numericCode":"504"
  },
  {
     "name":"Mozambique",
     "alpha2Code":"MZ",
     "alpha3Code":"MOZ",
     "nativeName":"Moçambique",
     "numericCode":"508"
  },
  {
     "name":"Myanmar",
     "alpha2Code":"MM",
     "alpha3Code":"MMR",
     "nativeName":"Myanma",
     "numericCode":"104"
  },
  {
     "name":"Namibia",
     "alpha2Code":"NA",
     "alpha3Code":"NAM",
     "nativeName":"Namibia",
     "numericCode":"516"
  },
  {
     "name":"Nauru",
     "alpha2Code":"NR",
     "alpha3Code":"NRU",
     "nativeName":"Nauru",
     "numericCode":"520"
  },
  {
     "name":"Nepal",
     "alpha2Code":"NP",
     "alpha3Code":"NPL",
     "nativeName":"नेपाल",
     "numericCode":"524"
  },
  {
     "name":"Netherlands",
     "alpha2Code":"NL",
     "alpha3Code":"NLD",
     "nativeName":"Nederland",
     "numericCode":"528"
  },
  {
     "name":"New Caledonia",
     "alpha2Code":"NC",
     "alpha3Code":"NCL",
     "nativeName":"Nouvelle-Calédonie",
     "numericCode":"540"
  },
  {
     "name":"New Zealand",
     "alpha2Code":"NZ",
     "alpha3Code":"NZL",
     "nativeName":"New Zealand",
     "numericCode":"554"
  },
  {
     "name":"Nicaragua",
     "alpha2Code":"NI",
     "alpha3Code":"NIC",
     "nativeName":"Nicaragua",
     "numericCode":"558"
  },
  {
     "name":"Niger",
     "alpha2Code":"NE",
     "alpha3Code":"NER",
     "nativeName":"Niger",
     "numericCode":"562"
  },
  {
     "name":"Nigeria",
     "alpha2Code":"NG",
     "alpha3Code":"NGA",
     "nativeName":"Nigeria",
     "numericCode":"566"
  },
  {
     "name":"Niue",
     "alpha2Code":"NU",
     "alpha3Code":"NIU",
     "nativeName":"Niuē",
     "numericCode":"570"
  },
  {
     "name":"Norfolk Island",
     "alpha2Code":"NF",
     "alpha3Code":"NFK",
     "nativeName":"Norfolk Island",
     "numericCode":"574"
  },
  {
     "name":"Korea (Democratic People's Republic of)",
     "alpha2Code":"KP",
     "alpha3Code":"PRK",
     "nativeName":"북한",
     "numericCode":"408"
  },
  {
     "name":"Northern Mariana Islands",
     "alpha2Code":"MP",
     "alpha3Code":"MNP",
     "nativeName":"Northern Mariana Islands",
     "numericCode":"580"
  },
  {
     "name":"Norway",
     "alpha2Code":"NO",
     "alpha3Code":"NOR",
     "nativeName":"Norge",
     "numericCode":"578"
  },
  {
     "name":"Oman",
     "alpha2Code":"OM",
     "alpha3Code":"OMN",
     "nativeName":"عمان",
     "numericCode":"512"
  },
  {
     "name":"Pakistan",
     "alpha2Code":"PK",
     "alpha3Code":"PAK",
     "nativeName":"Pakistan",
     "numericCode":"586"
  },
  {
     "name":"Palau",
     "alpha2Code":"PW",
     "alpha3Code":"PLW",
     "nativeName":"Palau",
     "numericCode":"585"
  },
  {
     "name":"Palestine, State of",
     "alpha2Code":"PS",
     "alpha3Code":"PSE",
     "nativeName":"فلسطين",
     "numericCode":"275"
  },
  {
     "name":"Panama",
     "alpha2Code":"PA",
     "alpha3Code":"PAN",
     "nativeName":"Panamá",
     "numericCode":"591"
  },
  {
     "name":"Papua New Guinea",
     "alpha2Code":"PG",
     "alpha3Code":"PNG",
     "nativeName":"Papua Niugini",
     "numericCode":"598"
  },
  {
     "name":"Paraguay",
     "alpha2Code":"PY",
     "alpha3Code":"PRY",
     "nativeName":"Paraguay",
     "numericCode":"600"
  },
  {
     "name":"Peru",
     "alpha2Code":"PE",
     "alpha3Code":"PER",
     "nativeName":"Perú",
     "numericCode":"604"
  },
  {
     "name":"Philippines",
     "alpha2Code":"PH",
     "alpha3Code":"PHL",
     "nativeName":"Pilipinas",
     "numericCode":"608"
  },
  {
     "name":"Pitcairn",
     "alpha2Code":"PN",
     "alpha3Code":"PCN",
     "nativeName":"Pitcairn Islands",
     "numericCode":"612"
  },
  {
     "name":"Poland",
     "alpha2Code":"PL",
     "alpha3Code":"POL",
     "nativeName":"Polska",
     "numericCode":"616"
  },
  {
     "name":"Portugal",
     "alpha2Code":"PT",
     "alpha3Code":"PRT",
     "nativeName":"Portugal",
     "numericCode":"620"
  },
  {
     "name":"Puerto Rico",
     "alpha2Code":"PR",
     "alpha3Code":"PRI",
     "nativeName":"Puerto Rico",
     "numericCode":"630"
  },
  {
     "name":"Qatar",
     "alpha2Code":"QA",
     "alpha3Code":"QAT",
     "nativeName":"قطر",
     "numericCode":"634"
  },
  {
     "name":"Republic of Kosovo",
     "alpha2Code":"XK",
     "alpha3Code":"KOS",
     "nativeName":"Republika e Kosovës",
     "numericCode": null,
  },
  {
     "name":"Réunion",
     "alpha2Code":"RE",
     "alpha3Code":"REU",
     "nativeName":"La Réunion",
     "numericCode":"638"
  },
  {
     "name":"Romania",
     "alpha2Code":"RO",
     "alpha3Code":"ROU",
     "nativeName":"România",
     "numericCode":"642"
  },
  {
     "name":"Russian Federation",
     "alpha2Code":"RU",
     "alpha3Code":"RUS",
     "nativeName":"Россия",
     "numericCode":"643"
  },
  {
     "name":"Rwanda",
     "alpha2Code":"RW",
     "alpha3Code":"RWA",
     "nativeName":"Rwanda",
     "numericCode":"646"
  },
  {
     "name":"Saint Barthélemy",
     "alpha2Code":"BL",
     "alpha3Code":"BLM",
     "nativeName":"Saint-Barthélemy",
     "numericCode":"652"
  },
  {
     "name":"Saint Helena, Ascension and Tristan da Cunha",
     "alpha2Code":"SH",
     "alpha3Code":"SHN",
     "nativeName":"Saint Helena",
     "numericCode":"654"
  },
  {
     "name":"Saint Kitts and Nevis",
     "alpha2Code":"KN",
     "alpha3Code":"KNA",
     "nativeName":"Saint Kitts and Nevis",
     "numericCode":"659"
  },
  {
     "name":"Saint Lucia",
     "alpha2Code":"LC",
     "alpha3Code":"LCA",
     "nativeName":"Saint Lucia",
     "numericCode":"662"
  },
  {
     "name":"Saint Martin (French part)",
     "alpha2Code":"MF",
     "alpha3Code":"MAF",
     "nativeName":"Saint-Martin",
     "numericCode":"663"
  },
  {
     "name":"Saint Pierre and Miquelon",
     "alpha2Code":"PM",
     "alpha3Code":"SPM",
     "nativeName":"Saint-Pierre-et-Miquelon",
     "numericCode":"666"
  },
  {
     "name":"Saint Vincent and the Grenadines",
     "alpha2Code":"VC",
     "alpha3Code":"VCT",
     "nativeName":"Saint Vincent and the Grenadines",
     "numericCode":"670"
  },
  {
     "name":"Samoa",
     "alpha2Code":"WS",
     "alpha3Code":"WSM",
     "nativeName":"Samoa",
     "numericCode":"882"
  },
  {
     "name":"San Marino",
     "alpha2Code":"SM",
     "alpha3Code":"SMR",
     "nativeName":"San Marino",
     "numericCode":"674"
  },
  {
     "name":"Sao Tome and Principe",
     "alpha2Code":"ST",
     "alpha3Code":"STP",
     "nativeName":"São Tomé e Príncipe",
     "numericCode":"678"
  },
  {
     "name":"Saudi Arabia",
     "alpha2Code":"SA",
     "alpha3Code":"SAU",
     "nativeName":"العربية السعودية",
     "numericCode":"682"
  },
  {
     "name":"Senegal",
     "alpha2Code":"SN",
     "alpha3Code":"SEN",
     "nativeName":"Sénégal",
     "numericCode":"686"
  },
  {
     "name":"Serbia",
     "alpha2Code":"RS",
     "alpha3Code":"SRB",
     "nativeName":"Србија",
     "numericCode":"688"
  },
  {
     "name":"Seychelles",
     "alpha2Code":"SC",
     "alpha3Code":"SYC",
     "nativeName":"Seychelles",
     "numericCode":"690"
  },
  {
     "name":"Sierra Leone",
     "alpha2Code":"SL",
     "alpha3Code":"SLE",
     "nativeName":"Sierra Leone",
     "numericCode":"694"
  },
  {
     "name":"Singapore",
     "alpha2Code":"SG",
     "alpha3Code":"SGP",
     "nativeName":"Singapore",
     "numericCode":"702"
  },
  {
     "name":"Sint Maarten (Dutch part)",
     "alpha2Code":"SX",
     "alpha3Code":"SXM",
     "nativeName":"Sint Maarten",
     "numericCode":"534"
  },
  {
     "name":"Slovakia",
     "alpha2Code":"SK",
     "alpha3Code":"SVK",
     "nativeName":"Slovensko",
     "numericCode":"703"
  },
  {
     "name":"Slovenia",
     "alpha2Code":"SI",
     "alpha3Code":"SVN",
     "nativeName":"Slovenija",
     "numericCode":"705"
  },
  {
     "name":"Solomon Islands",
     "alpha2Code":"SB",
     "alpha3Code":"SLB",
     "nativeName":"Solomon Islands",
     "numericCode":"090"
  },
  {
     "name":"Somalia",
     "alpha2Code":"SO",
     "alpha3Code":"SOM",
     "nativeName":"Soomaaliya",
     "numericCode":"706"
  },
  {
     "name":"South Africa",
     "alpha2Code":"ZA",
     "alpha3Code":"ZAF",
     "nativeName":"South Africa",
     "numericCode":"710"
  },
  {
     "name":"South Georgia and the South Sandwich Islands",
     "alpha2Code":"GS",
     "alpha3Code":"SGS",
     "nativeName":"South Georgia",
     "numericCode":"239"
  },
  {
     "name":"Korea (Republic of)",
     "alpha2Code":"KR",
     "alpha3Code":"KOR",
     "nativeName":"대한민국",
     "numericCode":"410"
  },
  {
     "name":"South Sudan",
     "alpha2Code":"SS",
     "alpha3Code":"SSD",
     "nativeName":"South Sudan",
     "numericCode":"728"
  },
  {
     "name":"Spain",
     "alpha2Code":"ES",
     "alpha3Code":"ESP",
     "nativeName":"España",
     "numericCode":"724"
  },
  {
     "name":"Sri Lanka",
     "alpha2Code":"LK",
     "alpha3Code":"LKA",
     "nativeName":"śrī laṃkāva",
     "numericCode":"144"
  },
  {
     "name":"Sudan",
     "alpha2Code":"SD",
     "alpha3Code":"SDN",
     "nativeName":"السودان",
     "numericCode":"729"
  },
  {
     "name":"Suriname",
     "alpha2Code":"SR",
     "alpha3Code":"SUR",
     "nativeName":"Suriname",
     "numericCode":"740"
  },
  {
     "name":"Svalbard and Jan Mayen",
     "alpha2Code":"SJ",
     "alpha3Code":"SJM",
     "nativeName":"Svalbard og Jan Mayen",
     "numericCode":"744"
  },
  {
     "name":"Swaziland",
     "alpha2Code":"SZ",
     "alpha3Code":"SWZ",
     "nativeName":"Swaziland",
     "numericCode":"748"
  },
  {
     "name":"Sweden",
     "alpha2Code":"SE",
     "alpha3Code":"SWE",
     "nativeName":"Sverige",
     "numericCode":"752"
  },
  {
     "name":"Switzerland",
     "alpha2Code":"CH",
     "alpha3Code":"CHE",
     "nativeName":"Schweiz",
     "numericCode":"756"
  },
  {
     "name":"Syrian Arab Republic",
     "alpha2Code":"SY",
     "alpha3Code":"SYR",
     "nativeName":"سوريا",
     "numericCode":"760"
  },
  {
     "name":"Taiwan",
     "alpha2Code":"TW",
     "alpha3Code":"TWN",
     "nativeName":"臺灣",
     "numericCode":"158"
  },
  {
     "name":"Tajikistan",
     "alpha2Code":"TJ",
     "alpha3Code":"TJK",
     "nativeName":"Тоҷикистон",
     "numericCode":"762"
  },
  {
     "name":"Tanzania, United Republic of",
     "alpha2Code":"TZ",
     "alpha3Code":"TZA",
     "nativeName":"Tanzania",
     "numericCode":"834"
  },
  {
     "name":"Thailand",
     "alpha2Code":"TH",
     "alpha3Code":"THA",
     "nativeName":"ประเทศไทย",
     "numericCode":"764"
  },
  {
     "name":"Timor-Leste",
     "alpha2Code":"TL",
     "alpha3Code":"TLS",
     "nativeName":"Timor-Leste",
     "numericCode":"626"
  },
  {
     "name":"Togo",
     "alpha2Code":"TG",
     "alpha3Code":"TGO",
     "nativeName":"Togo",
     "numericCode":"768"
  },
  {
     "name":"Tokelau",
     "alpha2Code":"TK",
     "alpha3Code":"TKL",
     "nativeName":"Tokelau",
     "numericCode":"772"
  },
  {
     "name":"Tonga",
     "alpha2Code":"TO",
     "alpha3Code":"TON",
     "nativeName":"Tonga",
     "numericCode":"776"
  },
  {
     "name":"Trinidad and Tobago",
     "alpha2Code":"TT",
     "alpha3Code":"TTO",
     "nativeName":"Trinidad and Tobago",
     "numericCode":"780"
  },
  {
     "name":"Tunisia",
     "alpha2Code":"TN",
     "alpha3Code":"TUN",
     "nativeName":"تونس",
     "numericCode":"788"
  },
  {
     "name":"Turkey",
     "alpha2Code":"TR",
     "alpha3Code":"TUR",
     "nativeName":"Türkiye",
     "numericCode":"792"
  },
  {
     "name":"Turkmenistan",
     "alpha2Code":"TM",
     "alpha3Code":"TKM",
     "nativeName":"Türkmenistan",
     "numericCode":"795"
  },
  {
     "name":"Turks and Caicos Islands",
     "alpha2Code":"TC",
     "alpha3Code":"TCA",
     "nativeName":"Turks and Caicos Islands",
     "numericCode":"796"
  },
  {
     "name":"Tuvalu",
     "alpha2Code":"TV",
     "alpha3Code":"TUV",
     "nativeName":"Tuvalu",
     "numericCode":"798"
  },
  {
     "name":"Uganda",
     "alpha2Code":"UG",
     "alpha3Code":"UGA",
     "nativeName":"Uganda",
     "numericCode":"800"
  },
  {
     "name":"Ukraine",
     "alpha2Code":"UA",
     "alpha3Code":"UKR",
     "nativeName":"Україна",
     "numericCode":"804"
  },
  {
     "name":"United Arab Emirates",
     "alpha2Code":"AE",
     "alpha3Code":"ARE",
     "nativeName":"دولة الإمارات العربية المتحدة",
     "numericCode":"784"
  },
  {
     "name":"United Kingdom of Great Britain and Northern Ireland",
     "alpha2Code":"GB",
     "alpha3Code":"GBR",
     "nativeName":"United Kingdom",
     "numericCode":"826"
  },
  {
     "name":"United States of America",
     "alpha2Code":"US",
     "alpha3Code":"USA",
     "nativeName":"United States",
     "numericCode":"840"
  },
  {
     "name":"Uruguay",
     "alpha2Code":"UY",
     "alpha3Code":"URY",
     "nativeName":"Uruguay",
     "numericCode":"858"
  },
  {
     "name":"Uzbekistan",
     "alpha2Code":"UZ",
     "alpha3Code":"UZB",
     "nativeName":"O‘zbekiston",
     "numericCode":"860"
  },
  {
     "name":"Vanuatu",
     "alpha2Code":"VU",
     "alpha3Code":"VUT",
     "nativeName":"Vanuatu",
     "numericCode":"548"
  },
  {
     "name":"Venezuela (Bolivarian Republic of)",
     "alpha2Code":"VE",
     "alpha3Code":"VEN",
     "nativeName":"Venezuela",
     "numericCode":"862"
  },
  {
     "name":"Viet Nam",
     "alpha2Code":"VN",
     "alpha3Code":"VNM",
     "nativeName":"Việt Nam",
     "numericCode":"704"
  },
  {
     "name":"Wallis and Futuna",
     "alpha2Code":"WF",
     "alpha3Code":"WLF",
     "nativeName":"Wallis et Futuna",
     "numericCode":"876"
  },
  {
     "name":"Western Sahara",
     "alpha2Code":"EH",
     "alpha3Code":"ESH",
     "nativeName":"الصحراء الغربية",
     "numericCode":"732"
  },
  {
     "name":"Yemen",
     "alpha2Code":"YE",
     "alpha3Code":"YEM",
     "nativeName":"اليَمَن",
     "numericCode":"887"
  },
  {
     "name":"Zambia",
     "alpha2Code":"ZM",
     "alpha3Code":"ZMB",
     "nativeName":"Zambia",
     "numericCode":"894"
  },
  {
     "name":"Zimbabwe",
     "alpha2Code":"ZW",
     "alpha3Code":"ZWE",
     "nativeName":"Zimbabwe",
     "numericCode":"716"
  }
];