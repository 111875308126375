import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'app-total-revenue',
  templateUrl: './total-revenue.component.html',
  styleUrls: ['./total-revenue.component.scss']
})
export class TotalRevenueComponent implements AfterViewInit {
  @Input()
  title = ``;
  @Input()
  value;
  @Input()
  data = [];

  constructor(
    private cd: ChangeDetectorRef,
    public svcDashboard: DashboardService
  ) {}

  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
