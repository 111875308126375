import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISubMenuError } from 'app/models/sub-menu';

@Component({
  selector: 'app-sub-menu-error',
  templateUrl: './sub-menu-error.component.html',
  styleUrls: ['./sub-menu-error.component.scss']
})
export class SubMenuErrorComponent {
  @Input()
  menu: ISubMenuError[];
  @Output()
  navigationChange = new EventEmitter<any>();

  navChange(ev) {
    this.navigationChange.emit(ev);
  }
}
