import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-held-payments',
  templateUrl: './held-payments.component.html',
  styleUrls: ['./held-payments.component.scss']
})
export class HeldPaymentsComponent {
  @Input()
  heldPayment;
}
