import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService, IAppPermissions } from '../services/app.service';

@Injectable()
export class AppPermissionResolver implements Resolve<IAppPermissions> {
  constructor(private svcApp: AppService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAppPermissions> {
    return this.svcApp.canCreateOrEditApps();
  }
}
